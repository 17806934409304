import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Typography, Menu, Dropdown } from 'antd'
import { QrReader } from 'react-qr-reader'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { POST } from '../../utils/apis'
import { errorMessage, getAllUserData, successMessage } from '../../utils/helpers'
import { constraints } from '../../utils/constants'
const { Title } = Typography

const AddSavings = (props) => {
  const { user, userActions, reRender } = props
  const [visible, setVisible] = useState(false)
  const [visibleQR, setVisibleQR] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState({})
  const [coupon, setCoupon] = useState('')
  const [QRCode, setQRCode] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    getAllUserData(user?._id, userActions)
  }, [])

  useEffect(() => {
    if (QRCode && !loading) {
      handleOk(QRCode)
    }
  }, [QRCode])

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setVisible(true)} key='1'>Claim By Code</Menu.Item>
      <Menu.Item onClick={() => setVisibleQR(true)} key='2'>Claim By QR Code</Menu.Item>
    </Menu>
  )

  const handleOk = (code = null) => {
    setConfirmLoading(true)
    setLoading(true)

    axios.post(POST.CLAIM_COUPON, { couponCode: coupon || code, userId: user?._id })
      .then((res) => {
        const { data } = res
        if (data.success) {
          handleCancel()
          successMessage(data?.message)
          setVisible(false)
          setQRCode(null)
          setVisibleQR(false)
          setConfirmLoading(false)
          reRender()

          if (data?.advertisement) {
            setImage(data?.advertisement || {})
          }
        }
        else {
          console.log('else')
          errorMessage(data?.message)
        }
        setTimeout(() => {
          setLoading(false)
        }, 500)
      })
      .catch((e) => {
        console.log('e ', e)
        setVisible(false)
        setConfirmLoading(false)
        setLoading(false)
        errorMessage()
      })

    showModal1()
  }

  const handleCancel = () => {
    setVisible(false)
    setVisibleQR(false)
    showModal1()
    setCoupon('')
  }

  const showModal1 = () => {
    setIsModalVisible(true)
  }

  const handleCancel1 = () => {
    setImage({})

  }

  const handleCouponInput = (e) => {

    let value = e?.target?.value?.replace(/ /g, '')
    let event = e?.nativeEvent?.data

    if (event && ((value?.length === 3 || value?.length === 7) || (coupon?.length === 3 || coupon.length === 7))) {
      if (coupon?.length === 3 || coupon.length === 7) {
        value = `${coupon}-${event}`
      }
      else {
        value = `${value}-`
      }
    }

    setCoupon(value)
  }

  const scanQr = async qr => {
    let code = qr?.text

    if (code) {
      setQRCode(code)
    }
  }

  return (
    <div>
      <Dropdown overlay={menu} placement='bottomLeft'>
        <Button type='primary' className='btn-end'>
          Claim Coupon
        </Button>
      </Dropdown>
      <Modal
        title={<center>
          <Title level={4}>Coupon</Title>
        </center>}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        closable={false}
        footer={[
          <Button key='back' onClick={handleCancel} className='form-input'>
            Cancel
          </Button>,
          <Button
            key='ok'
            onClick={handleOk}
            disabled={coupon?.length !== 11}
            className='form-input'
            type='primary'
            loading={loading}
          >
            Claim Coupon
          </Button>
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h4>Enter your Coupon number</h4>
          <Input
            className='form-input'
            autoFocus
            required
            maxLength={11}
            value={coupon}
            onChange={handleCouponInput}
            placeholder='e.g: 456-786-ab6'
          />
        </div>
      </Modal>

      <Modal
        title={<center>
          <Title level={4}>Activate QR</Title>
        </center>}
        visible={visibleQR}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel} className='form-input'>
            Cancel
          </Button>
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h4>Scan your Qr</h4>
          <QrReader
            delay={2000}
            onError={e => errorMessage('WebCam Not Found!')}
            constraints={constraints}
            onResult={scanQr}
            style={{ width: '100%' }}
          />
        </div>
      </Modal>

      <Modal
        bodyStyle={{ padding: '0' }}
        footer={null}
        visible={Object.keys(image)?.includes('imageUrl')}
        onCancel={handleCancel1}
        closable={false}
        height={10}
      >
        <button class='close' onClick={handleCancel1}>
          <CloseCircleOutlined />
        </button>
        <div>
          <img style={{ width: '100%', height: '50vh' }} src={image?.imageUrl} alt='advertisement' />
        </div>
      </Modal>
    </div>
  )
}

export default AddSavings