import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Editor } from 'react-draft-wysiwyg'
import { Form, Button, Select, Spin, Checkbox, Avatar, DatePicker, Switch, Radio } from 'antd'
import PropTypes from 'prop-types'
import momentTz from 'moment-timezone'
import { inputPlace, requiredMessage, successNotification, warningMessage, errorMessage, getAllSegments, disabledDate, absoluteDateTz, relativeDateTz, absoluteLocalDateTz } from '../../utils/helpers'
import { GET, POST } from '../../utils/apis'
import axios from 'axios'
import { segmentForm, apiUrl, timeArray } from '../../utils/constants'

const { Option } = Select

const AddGuest = (props) => {
  const { onCancel, edit, adminActions, user } = props
  const [loading, setLoading] = useState(false)
  const [spinLoading, setSpinLoading] = useState(false)
  const [selectedSegment, setSelectedSegment] = useState({})
  const [guests, setGuests] = useState([])
  const [fixedDay, setFixedDay] = useState(edit?.fixedDay || false)
  const [postalCard, setPostalCard] = useState(edit?.postalCard || user?.subscription ? user?.subscription?.subscriptionTypes?.indexOf('postalCard') !== -1 ? true : false : false)
  const [switches, setSwitches] = useState({ ...edit })
  const formRef = useRef(null)
  const segments = useSelector(state => state?.adminReducer?.segments || [])
  const cards = useSelector(state => state?.adminReducer?.cards || [])
  let subscriptionEnd = user?.subscription?._id ? momentTz.utc(user?.subscription?.created).add(1, 'year').diff(momentTz.utc(), 'days') : 365
  let arr = Array.from(Array(subscriptionEnd).keys())

  useEffect(() => {
    getAllSegments(adminActions, user?._id)

    if (!user?.subscription?._id) {
      warningMessage('Please Select Subscription to send Messages!')
    }
  }, [])

  const onFinish = async (values) => {
    edit?._id && (values._id = edit?._id)
    edit?._id && (values.guest = edit?.guest?._id)
    edit?._id && (values.cardsQuantity = edit?.cardsQuantity)
    values.userId = user?._id
    values.fixedDay = fixedDay

    let currentDate = momentTz()

    values = { ...switches, ...values }

    values.date1 = values.date1 || switches.date1 || null
    values.date2 = values.date2 || switches.date2 || null
    values.date3 = values.date3 || switches.date3 || null
    values.date4 = values.date4 || switches.date4 || null
    values.date5 = values.date5 || switches.date5 || null

    if (values?.sendingType1 === 'postalCard' && !fixedDay) {
      let date1 = absoluteLocalDateTz(values?.date1, 0)

      if (date1 < currentDate) {
        return errorMessage('Please Select Future date and Time in Date 1')
      }

      values.sendingDate1 = await absoluteDateTz(values?.date1, 0)
      values.date1 = values?.date1?.toString()
    }
    else if (values?.absolute1) {
      let date1 = absoluteLocalDateTz(values?.date1, values?.hour1)

      if (date1 < currentDate) {
        return errorMessage('Please Select Future date and Time in Date 1')
      }

      values.sendingDate1 = await absoluteDateTz(values?.date1, values?.hour1)
      values.date1 = values?.date1?.toString()
    }
    else {
      values.sendingDate1 = await relativeDateTz(values?.date1, values?.hour1)
    }

    if (values?.date2) {
      if (values?.sendingType2 === 'postalCard' && !fixedDay) {
        let date2 = absoluteLocalDateTz(values?.date2, 0)

        if (date2 < currentDate) {
          return errorMessage('Please Select Future date and Time in Date 1')
        }

        values.sendingDate2 = await absoluteDateTz(values?.date2, 0)
        values.date2 = values?.date2?.toString()
      }
      else if (values?.absolute2) {
        let date2 = absoluteLocalDateTz(values?.date2, values?.hour2)

        if (date2 < currentDate) {
          return errorMessage('Please Select Future date and Time in Date 2')
        }

        values.sendingDate2 = await absoluteDateTz(values?.date2, values?.hour2)
        values.date2 = values?.date2?.toString()
      }
      else {
        values.sendingDate2 = await relativeDateTz(values?.date2, values?.hour2)
      }
    }
    else {
      values.sendingDate2 = null
    }

    if (values?.date3) {
      if (values?.sendingType3 === 'postalCard' && !fixedDay) {
        let date3 = absoluteLocalDateTz(values?.date3, 0)

        if (date3 < currentDate) {
          return errorMessage('Please Select Future date and Time in Date 3')
        }

        values.sendingDate3 = await absoluteDateTz(values?.date3, 0)
        values.date3 = values?.date3?.toString()
      }
      else if (values?.absolute3) {
        let date3 = absoluteLocalDateTz(values?.date3, values?.hour3)

        if (date3 < currentDate) {
          return errorMessage('Please Select Future date and Time in Date 3')
        }

        values.sendingDate3 = await absoluteDateTz(values?.date3, values?.hour3)
        values.date3 = values?.date3?.toString()
      }
      else {
        values.sendingDate3 = await relativeDateTz(values?.date3, values?.hour3)
      }
    }
    else {
      values.sendingDate3 = null
    }

    if (values?.date4) {
      if (values?.sendingType4 === 'postalCard' && !fixedDay) {
        let date4 = absoluteLocalDateTz(values?.date4, 0)

        if (date4 < currentDate) {
          return errorMessage('Please Select Future date and Time in Date 4')
        }

        values.sendingDate4 = await absoluteDateTz(values?.date4, 0)
        values.date4 = values?.date4?.toString()
      }
      else if (values?.absolute4) {
        let date4 = absoluteLocalDateTz(values?.date4, values?.hour4)

        if (date4 < currentDate) {
          return errorMessage('Please Select Future date and Time in Date 4')
        }

        values.sendingDate4 = await absoluteDateTz(values?.date4, values?.hour4)
        values.date4 = values?.date4?.toString()
      }
      else {
        values.sendingDate4 = await relativeDateTz(values?.date4, values?.hour4)
      }
    }
    else {
      values.sendingDate4 = null
    }

    if (values?.date5) {
      if (values?.sendingType5 === 'postalCard' && !fixedDay) {
        let date5 = absoluteLocalDateTz(values?.date5, 0)

        if (date5 < currentDate) {
          return errorMessage('Please Select Future date and Time in Date 5')
        }

        values.sendingDate5 = await absoluteDateTz(values?.date5, 0)
        values.date5 = values?.date5?.toString()
      }
      else if (values?.absolute5) {
        let date5 = absoluteLocalDateTz(values?.date5, values?.hour5)

        if (date5 < currentDate) {
          return errorMessage('Please Select Future date and Time in Date 5')
        }

        values.sendingDate5 = await absoluteDateTz(values?.date5, values?.hour5)
        values.date5 = values?.date5?.toString()
      }
      else {
        values.sendingDate5 = await relativeDateTz(values?.date5, values?.hour5)
      }
    }
    else {
      values.sendingDate5 = null
    }

    setLoading(true)

    axios.post(`${apiUrl}${POST.CREATE_SEGMENT_MESSAGE}`, values)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data.success) {
          successNotification(data?.message)
          return setTimeout(() => {
            onCancel()
          }, 300)
        }
        errorMessage(data.message)
      })
      .catch((e) => {
        setLoading(false)
        errorMessage()
      })
  }

  const createEditor = (val) => {
    // console.log('val', val)
    val.entityMap = {}
    // let editorState = EditorState.createWithContent(
    //     ContentState.createFromBlockArray(
    //         convertFromHTML(draftToHtml(val))
    //     )
    // )

    // console.log('***', draftToHtml(val))
    return val
  }

  const getSegmentData = (id) => {
    setSpinLoading(true)

    let obj = {
      userId: user?._id,
      segmentId: id
    }

    axios.get(`${GET.SEGMENT_BY_ID}/${id}`)
      .then((res) => {
        const { data } = res
        if (data?.success) {
          let seg = data.segment
          setSelectedSegment(seg)
          setFixedDay(seg?.fixedDay)
          delete seg?.created
          setSwitches({ ...seg })
          if (seg?.absolute1) {
            seg.date1 = momentTz(seg.date1)
          }
          if (seg?.absolute2 && seg.date2) {
            seg.date2 = momentTz(seg.date2)
          }
          if (seg?.absolute3 && seg.date3) {
            seg.date3 = momentTz(seg.date3)
          }
          if (seg?.absolute4 && seg.date4) {
            seg.date4 = momentTz(seg.date4)
          }
          if (seg?.absolute5 && seg.date5) {
            seg.date5 = momentTz(seg.date5)
          }

          delete switches?._id
          setSwitches({ ...switches })
          formRef?.current?.setFieldsValue(seg)
        }
        else {
          errorMessage()
        }
        setSpinLoading(false)
      })

    axios.post(`${GET.GUESTS_BY_SEGMENT}`, obj)
      .then((res) => {
        const { data } = res
        if (data?.success) {
          setGuests(data?.guests)
        }
      })
  }

  const changeFormat = (e, v, dataArr, reset = false) => {
    reset && !edit?._id && formRef.current.resetFields([dataArr[1]?.name])
    formRef?.current?.setFieldsValue({ [v]: e })
    setSwitches({ ...switches, [v]: e })

    let selectedDate = formRef.current.getFieldValue(dataArr[1]?.name)
    let postalCard = formRef.current.getFieldValue(dataArr[5]?.name)
    let isAbsolute = switches?.[dataArr[9]?.name] || false

    if (selectedDate === undefined) {
      formRef.current.setFieldsValue({ [dataArr[1]?.name]: momentTz() })
    }

    if (fixedDay && isAbsolute && postalCard === 'postalCard') {
      let hour = momentTz.utc().get('hour')
      let customDate = momentTz().add(hour >= 1 ? 2 : 1, 'days')
      selectedDate = formRef.current.getFieldValue(dataArr[1]?.name)
      if (selectedDate < customDate) {
        formRef.current.setFieldsValue({ [dataArr[1]?.name]: customDate })
      }
    }
  }

  const changeFormatRadio = (e, v, dataArr) => {
    let selectedDate = formRef.current.getFieldValue(dataArr[1]?.name)

    if (selectedDate === undefined) {
      formRef.current.setFieldsValue({ [dataArr[1]?.name]: momentTz() })
    }

    if (fixedDay && e === 'postalCard') {
      let hour = momentTz.utc().get('hour')
      let customDate = momentTz().add(hour >= 1 ? 2 : 1, 'days')
      selectedDate = formRef.current.getFieldValue(dataArr[1]?.name)
      formRef.current.setFieldsValue({ [dataArr[1]?.name]: customDate })
    }
    else if (!fixedDay && e === 'postalCard') {
      let hour = momentTz.utc().get('hour')
      let customDate = momentTz().add(hour >= 1 ? 2 : 1, 'days')
      selectedDate = formRef.current.getFieldValue(dataArr[1]?.name)
      formRef.current.setFieldsValue({ [dataArr[1]?.name]: customDate })
    }
    setSwitches({ ...switches, [v]: e })
  }

  const disabledFixedDate = (current) => {
    let hour = momentTz.utc().get('hour')

    let customDate = momentTz().add(hour >= 1 ? 2 : 1, 'days').format('YYYY-MM-DD')
    return current && current < momentTz(customDate, 'YYYY-MM-DD')
  }


  const validateDate = (d) => {
    try {
      let date = momentTz(d)

      return date
    }
    catch (e) {
      return momentTz()
    }
  }

  return (
    <div style={{ paddingBottom: 40 }}>
      <div className='card-form'>
        <div className='card2'>
          <p className='heading head-center'>{edit?._id ? 'Update' : 'Create'} Message</p>
          <Spin spinning={spinLoading}>
            <Form
              name='form'
              onFinish={onFinish}
              layout={'vertical'}
              ref={formRef}
            >
              <Form.Item
                name='segment'
                label='Segment'
                hasFeedback
                className='form-layout left-layout'
                initialValue={edit?.segment?._id}
                rules={[
                  {
                    required: true,
                    message: requiredMessage('Segment')
                  }
                ]}
              >
                <Select
                  showSearch
                  showArrow
                  allowClear
                  disabled={Boolean(edit?._id)}
                  className='form-input'
                  placeholder={`Select Segment`}
                  optionFilterProp='children'
                  onSelect={getSegmentData}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {segments?.map((v, i) => <Option value={v?._id} key={i}>{v?.name}</Option>)}
                </Select>
              </Form.Item>
              {selectedSegment?._id || edit?.segment?._id ? <div className='flex-row flex-mobile'>
                <Form.Item
                  name='fixedDay'
                  label='Send Exact Date'
                  className='form-layout left-layout'
                  style={{ flex: 1 }}
                  hasFeedback
                  initialValue={edit?.fixedDay}
                >
                  &nbsp;&nbsp;
                  <Checkbox
                    className='form-input'
                    checked={fixedDay}
                    onClick={() => setFixedDay(!fixedDay)}
                  /> &nbsp; Fixed Day
                </Form.Item>
              </div> : null}
              {!spinLoading && segmentForm?.map((v, i) => {
                return (
                  <div key={i}>
                    <Form.Item
                      name={v[5].name}
                      label={v[5].label}
                      className='form-layout'
                      style={{ flex: 1 }}
                      hasFeedback
                      initialValue={edit?.[v[5]?.name] || 'mail'}
                    >
                      <Radio.Group onChange={(e) => changeFormatRadio(e?.target?.value, v[5].name, v)}>
                        <div className='flex-row flex-mobile'>
                          <Radio value={'mail'}>Mail</Radio>
                          {user?.subscription && user?.subscription?.subscriptionTypes?.indexOf('whatsapp') !== -1 ? <Radio value={'whatsapp'}>Whatsapp</Radio> : null}
                          {user?.subscription && user?.subscription?.subscriptionTypes?.indexOf('postalCard') !== -1 ? <Radio value={'postalCard'}>Postal Card (Send through Postal Card)</Radio> : null}
                        </div>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      name={v[0]?.name}
                      label={v[0]?.label}
                      hasFeedback
                      initialValue={edit?.[v[0]?.name]}
                      rules={[
                        {
                          required: v[0]?.required,
                          message: requiredMessage(v[0]?.label)
                        }
                      ]}
                    >
                      <Editor
                        initialContentState={selectedSegment?.[v[0]?.name] && createEditor(selectedSegment?.[v[0]?.name]) || edit?.[v[0]?.name] && createEditor(edit?.[v[0]?.name])}
                        toolbarClassName='toolbarClassName'
                        wrapperClassName='wrapperClassName'
                        editorClassName='editorClassName'
                        placeholder={inputPlace(v[0]?.label)}
                        toolbar={{
                          options: ['inline', 'colorPicker', 'emoji', 'link', 'blockType', 'fontSize', 'fontFamily', 'embedded', 'list', 'textAlign', 'history'],
                        }}
                      />
                    </Form.Item>
                    {switches?.[v[5]?.name] === 'postalCard' && !fixedDay ? <Form.Item
                      name={v[1]?.name}
                      label={`${v[1]?.label} `}
                      className='form-layout left-layout'
                      style={{ flex: 3, marginTop: 24 }}
                      hasFeedback
                      rules={[
                        {
                          required: edit?._id ? false : v[1]?.required,
                          message: requiredMessage(v[1]?.label)
                        }
                      ]}
                    >
                      <DatePicker
                        picker='week'
                        style={{ width: '100%' }}
                        disabledDate={disabledDate}
                        defaultValue={edit?.[v[1]?.name] && !fixedDay ? validateDate(edit?.[v[1]?.name]) : momentTz()}
                        onChange={(e) => changeFormat(e, v[1]?.name, v)}
                      />
                    </Form.Item> : <div className='flex-row flex-mobile'>
                      {switches?.[v[9]?.name] ?
                        <Form.Item
                          name={v[1]?.name}
                          label={`${v[1]?.label} `}
                          className='form-layout left-layout'
                          style={{ flex: 3, marginTop: 24 }}
                          hasFeedback
                          rules={[
                            {
                              required: edit?._id ? false : v[1]?.required,
                              message: requiredMessage(v[1]?.label)
                            }
                          ]}
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            disabledDate={fixedDay && switches?.[v[5]?.name] === 'postalCard' ? disabledFixedDate : disabledDate}
                            defaultValue={edit?.[v[9]?.name] && switches?.[v[9]?.name] ? validateDate(edit?.[v[1]?.name]) : momentTz()}
                            onChange={(e) => changeFormat(e, v[1]?.name, v)}
                          />
                        </Form.Item> : <Form.Item
                          name={v[1]?.name}
                          label={`${v[1]?.label} (This date reflects the number of days the first message will be send after initial date)`}
                          className='form-layout left-layout'
                          style={{ flex: 3, marginTop: 3 }}
                          hasFeedback
                          rules={[
                            {
                              required: edit?._id ? false : v[1]?.required,
                              message: requiredMessage(v[1]?.label)
                            }
                          ]}
                        >
                          <Select
                            showSearch
                            showArrow
                            allowClear
                            defaultValue={!edit?.[v[9]?.name] ? Number(edit?.[v[1]?.name]) || null : null}
                            onSelect={(e) => changeFormat(e, v[1]?.name, v)}
                            className='form-input'
                            placeholder={'Select number of days after initial date to send this messages'}
                            optionFilterProp='children'
                            filterOption={(input, option) =>
                              option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {(fixedDay && switches?.[v[5]?.name] === 'postalCard' ? arr?.slice(1,) : arr)?.map((v, i) => <Option value={v + 1} key={i}>{v + 1}</Option>)}
                          </Select>
                        </Form.Item>}

                      <div className='form-layout right-layout'>
                        <Form
                          name={v[9]?.name}
                          label={`${v[9]?.label}`}
                          hasFeedback
                          initialValues={switches?.[v[9]?.name] || false}
                          rules={[
                            {
                              required: v[9]?.required,
                              message: requiredMessage(v[9]?.label)
                            }
                          ]}
                        >
                          <Switch
                            unCheckedChildren='Relative'
                            checkedChildren='Absolute'
                            defaultChecked={switches?.[v[9]?.name] || false}
                            onChange={(e) => changeFormat(e, v[9]?.name, v, true)}
                          />
                        </Form>
                        <Form.Item
                          name={v[8]?.name}
                          label={`${v[8]?.label}`}
                          initialValue={edit?.[v[8]?.name] || '00'}
                          hasFeedback
                          rules={[
                            {
                              required: v[8]?.required,
                              message: requiredMessage(v[8]?.label)
                            }
                          ]}
                        >
                          <Select
                            showSearch
                            showArrow
                            allowClear
                            className='form-input'
                            placeholder={'Select Time'}
                            optionFilterProp='children'
                            filterOption={(input, option) =>
                              option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {timeArray?.map((v, i) => <Option value={v} key={i}>{v}</Option>)}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>}
                    <Form.Item
                      name={v[2]?.name}
                      label={v[2]?.label}
                      initialValue={edit?.[v[2]?.name]?._id}
                      hasFeedback
                      rules={[
                        {
                          required: v[2]?.required,
                          message: requiredMessage(v[2]?.label)
                        }
                      ]}
                    >
                      <Select
                        showArrow
                        allowClear
                        className='form-input'
                        placeholder={`Select ${v[2]?.label}`}
                        optionFilterProp='children'
                      >
                        {cards?.map((v, i) => <Option value={v?._id} key={i}><Avatar src={v?.imageUrl} style={{ marginRight: 10, width: 34, height: 24 }} />{v?.designName}</Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                )
              })}
              {!edit?._id ? <Form.Item
                name={'guests'}
                label={'Select guest'}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please Select Guest'
                  }
                ]}
              >
                <Select
                  showSearch
                  showArrow
                  allowClear
                  className='form-input'
                  placeholder={`Select Guests`}
                  optionFilterProp='children'
                  mode='multiple'
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {guests?.map((v, i) => <Option value={v?._id} key={i}>{v?.name}</Option>)}
                </Select>
              </Form.Item> : null}
              <Form.Item>
                <Button
                  type='primary'
                  className='form-button'
                  block
                  htmlType='submit'
                  loading={loading}
                >
                  {edit?._id ? 'Update' : 'Create'}
                </Button>
                <Button
                  style={{ borderRadius: 4, marginTop: 6 }}
                  block
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </div>
    </div >
  )
}

AddGuest.propTypes = {
  onCancel: PropTypes.func.isRequired
}

export default AddGuest