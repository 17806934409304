import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import Person from '@mui/icons-material/Person'
import Logout from '@mui/icons-material/Logout'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'

const HeaderAppBar = (props) => {
    const { authActions, history, user } = props
    const [auth, setAuth] = React.useState(true)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const logout = () => {
        setAnchorEl(null)
        authActions?.removeUser()
    }

    return (
        <AppBar position='static'>
            <Toolbar>
                <IconButton
                    size='large'
                    edge='start'
                    color='inherit'
                    aria-label='menu'
                    sx={{ mr: 2 }}
                    onClick={() => props?.generalActions?.setCollapsed(!props?.inlineCollapsed)}
                >
                    <MenuIcon style={{ fontSize: 30 }} />
                </IconButton>
                <Typography variant='h6' component='div' className='mui-size' sx={{ flexGrow: 1 }}>
                    &nbsp;
                </Typography>
                {auth && (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography variant='h6' component='div' className='mui-size' sx={{ flexGrow: 1 }} style={{ marginTop: 14 }}>
                            {user?.userName}
                        </Typography>
                        <IconButton
                            size='large'
                            aria-label='account of current user'
                            aria-controls='menu-appbar'
                            aria-haspopup='true'
                            onClick={handleMenu}
                            color='inherit'
                        >
                            <AccountCircle style={{ fontSize: 30 }} />
                        </IconButton>
                        <Menu
                            id='menu-appbar'
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            style={{ marginTop: 40 }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose} className='mui-size'>
                                <Person style={{ fontSize: 22 }} /> &nbsp; Profile
                            </MenuItem>
                            <MenuItem onClick={logout} className='mui-size'>
                                <Logout style={{ fontSize: 22 }} /> &nbsp; Logout
                            </MenuItem>
                        </Menu>
                    </div>
                )}
            </Toolbar>
        </AppBar>
    )
}

export default HeaderAppBar
