import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Header from './Header'
import SideMenu from '../SideMenu/SideMenu'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import { addAllUsers, addAllSegments, addAllCards, addAllGroups, addAllUsersInvoices, addAllMessagesList, addAllReports, addAllFinancialReports, addAllStockReports, addAllPublicCards, addAllPostalCardsList, addAllBusinessUsers, addAllAdminFormReports, addAllProvisionReport } from '../../Redux/actions/adminActions'
import { addAllGuests, addAllSegmentMessage, addAllSubscriptions, addPostalCardPrice, addAllInvoices, addAllUserSegments, addAllCouponsSubscriptionDetail, addAllSavings, addAllForms, addAllFormReports, addAllCoupons, addNotifictaion, addAllCard, addAllAdvertisement, addAllLotterySavings, addWallet, addAllLotterySubscriptionDetail, addUserPendingGroups, addUserJoinedGroups } from '../../Redux/actions/userActions'
import { setCollapsed } from '../../Redux/actions/generalActions'
import { allPaths } from '../../utils/constants'
import { ADMIN } from '../../utils/apis'
import axios from 'axios'

const AdminLayout = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.authReducer.user)
    const inlineCollapsed = useSelector(state => state.generalReducer.inlineCollapsed)

    useEffect(() => {
        window.addEventListener('resize', setWindowWidth)
        setWindowWidth()
    }, [])

    const setWindowWidth = () => {
        window.innerWidth < 500 ? dispatch(setCollapsed(true)) : dispatch(setCollapsed(false))
    }

    const authActions = {
        loginUser: (e) => dispatch(loginUser(e)),
        removeUser: () => dispatch(removeUser())
    }

    const adminActions = {
        addAllUsers: (e) => dispatch(addAllUsers(e)),
        addAllSegments: (e) => dispatch(addAllSegments(e)),
        addAllCards: (e) => dispatch(addAllCards(e)),
        addAllGroups: (e) => dispatch(addAllGroups(e)),
        addAllPublicCards: (e) => dispatch(addAllPublicCards(e)),
        addAllUsersInvoices: (e) => dispatch(addAllUsersInvoices(e)),
        addAllReports: (e) => dispatch(addAllReports(e)),
        addAllAdminFormReports: (e) => dispatch(addAllAdminFormReports(e)),
        addAllFinancialReports: (e) => dispatch(addAllFinancialReports(e)),
        addAllProvisionReport: (e) => dispatch(addAllProvisionReport(e)),
        addAllStockReports: (e) => dispatch(addAllStockReports(e)),
        addAllMessagesList: (e) => dispatch(addAllMessagesList(e)),
        addAllPostalCardsList: (e) => dispatch(addAllPostalCardsList(e)),
        addAllBusinessUsers: (e) => dispatch(addAllBusinessUsers(e)),
    }

    const userActions = {
        addAllGuests: (e) => dispatch(addAllGuests(e)),
        addAllSegmentMessage: (e) => dispatch(addAllSegmentMessage(e)),
        addAllSubscriptions: (e) => dispatch(addAllSubscriptions(e)),
        addPostalCardPrice: (e) => dispatch(addPostalCardPrice(e)),
        addAllInvoices: (e) => dispatch(addAllInvoices(e)),
        addAllUserSegments: (e) => dispatch(addAllUserSegments(e)),
        addAllCouponsSubscriptionDetail: (e) => dispatch(addAllCouponsSubscriptionDetail(e)),
        addAllLotterySubscriptionDetail: (e) => dispatch(addAllLotterySubscriptionDetail(e)),
        addAllSavings: (e) => dispatch(addAllSavings(e)),
        addAllLotterySavings: (e) => dispatch(addAllLotterySavings(e)),
        addAllForms: (e) => dispatch(addAllForms(e)),
        addAllFormReports: (e) => dispatch(addAllFormReports(e)),
        addAllCoupons: (e) => dispatch(addAllCoupons(e)),
        addUserJoinedGroups: (e) => dispatch(addUserJoinedGroups(e)),
        addUserPendingGroups: (e) => dispatch(addUserPendingGroups(e)),
        addNotifictaion: (e) => dispatch(addNotifictaion(e)),
        addAllCard: (e) => dispatch(addAllCard(e)),
        addAllAdvertisement: (e) => dispatch(addAllAdvertisement(e)),
        addWallet: (e) => dispatch(addWallet(e))
    }

    const generalActions = {
        setCollapsed: (e) => dispatch(setCollapsed(e))
    }

    if (!user) {
        return (
            <Redirect to={allPaths?.ADMIN_LOGIN} />
        )
    }

    return (
        <Route
            {...rest}
            render={props => <AddMenu {...props} component={Component} user={user} authActions={authActions} generalActions={generalActions} inlineCollapsed={inlineCollapsed} userActions={userActions} adminActions={adminActions} dispatch={dispatch} />}
        />
    )
}

const AddMenu = ({ component: Component, ...props }) => {
    const { user, dispatch } = props

    useEffect(() => {
        if (user) {
            axios.get(`${ADMIN.ADMIN_BY_ID}/${user._id}`)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        dispatch(loginUser(data.admin))
                    }
                    else {
                        dispatch(removeUser())
                    }
                })
                .catch((e) => {
                    dispatch(removeUser())
                })
        }
    }, [props?.location?.pathname])

    if (!user) {
        return (
            <Redirect to={allPaths?.ADMIN_LOGIN} />
        )
    }

    return (
        <div className='helper-main'>
            <div className='menu-header'>
                <Header
                    {...props}
                />
            </div>
            <div className='menu-flex'>
                <div className='side-menu1'>
                    <SideMenu {...props} isAdmin={true} />
                </div>
                <div
                    className='helper-comp'
                    style={{
                        marginLeft: props?.inlineCollapsed ? 100 : 250,
                    }}
                >
                    <Component
                        {...props}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminLayout