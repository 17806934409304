import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import moment from 'moment-timezone'
import { Table, Button, DatePicker, Spin, Popconfirm, Form, Input, Select, Avatar } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { getAllData, convertTitle, successNotification, errorNotification, errorMessage, requiredMessage, inputPlace } from '../../../utils/helpers'
import { apiUrl } from '../../../utils/constants'
import { ADMIN } from '../../../utils/apis'

const { RangePicker } = DatePicker
const { Option } = Select

var arr = Array.from(Array(500).keys())

const StockReport = (props) => {
    const { user, adminActions } = props
    const [spin, setSpin] = useState({})
    const [loading, setLoading] = useState(false)
    const [addStock, setAddStock] = useState(false)
    const [spinLoad, setSpinLoad] = useState(false)
    const [date, setDate] = useState(moment())
    const reports = useSelector(state => state?.adminReducer?.stockReports || [])
    const cards = useSelector(state => state?.adminReducer?.publicCards || [])

    useEffect(() => {
        getAllData(user?._id, adminActions)
    }, [])

    const onFinish = (values) => {
        setLoading(true)

        axios.post(`${ADMIN.UPDATE_STOCK}/${user?._id}`, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(data?.message)
                    setAddStock(false)
                }
                else {
                    errorMessage(data.message)
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage()
            })
    }

    const createReport = (e) => {
        setSpinLoad(true)
        let dateRange = {
            date1: moment().format('YYYY-MM-DD'),
            date2: date.format('YYYY-MM-DD')
        }
        axios.post(`${apiUrl}${ADMIN.CREATE_STOCK_REPORT}/${user?._id}`, dateRange)
            .then((res) => {
                const { data } = res
                setSpinLoad(false)
                if (data?.success) {
                    successNotification('Report Successfully Created!')
                    adminActions?.addAllStockReports(data?.data || [])
                }
                else {
                    errorNotification(data?.message)
                }
            })
            .catch((e) => {
                errorNotification()
                setSpinLoad(false)
                console.log('e', e)
            })
    }

    const deleteReport = (_id) => {
        setSpin({ [_id]: true })
        axios.post(`${ADMIN.DELETE_STOCK_REPORT}/${user?._id}`, { _id })
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successNotification('Successfully Deleted!')
                    adminActions?.addAllStockReports(data?.data || [])
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }

    const disabledDate = (current) => {
        let customDate = moment().format('YYYY-MM-DD')
        return current && current < moment(customDate, 'YYYY-MM-DD')
    }

    let columns = [
        {
            title: 'Report Range',
            key: 'sendingDate',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e?.fileName}</p>
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (e) => <div style={{ paddingTop: 15, marginTop: -5, display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <a onClick={() => window.open(e?.reportUrl, '_blank')}>View</a>
                <Popconfirm
                    title={`Delete ${convertTitle(e?.fileName)}?`}
                    okText='Delete'
                    cancelText='Cancel'
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteReport(e?._id)}
                >
                    <a href="#"><DeleteOutlined style={{ color: 'red' }} /> Delete</a>
                    {spin[e?._id] ? <span className='demo-loading-container'>
                        &nbsp;<Spin />
                    </span> : null}
                </Popconfirm>
            </div>
        }
    ]

    return (
        <div>
            {!addStock ? <div className='flex-column menu-main'>
                <Spin spinning={spinLoad}>
                    <div className='flex-mh' style={{ display: 'flex', flexDirection: 'column', marginBottom: 20, width: 400 }}>
                        <h3>Stock Design Report</h3>
                        <div className='flex-mobile' style={{ display: 'flex' }}>
                            {/* <DatePicker
                                defaultValue={date}
                                onChange={(e) => setDate(e)}
                                disabledDate={disabledDate}
                                style={{ marginBottom: 10 }}
                            /> */}
                            <Button className='form-input' onClick={createReport}>Generate Stock Report</Button>
                        </div>
                        <Button className='form-input' type='primary' style={{ marginTop: 10 }} onClick={() => setAddStock(true)}>Add STock</Button>
                    </div>
                    <div className='user-table'>
                        <Table
                            columns={columns}
                            dataSource={reports}
                            pagination={{ hideOnSinglePage: true }}
                            scroll={{ x: 800 }}
                        />
                    </div>
                </Spin>
            </div> : <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'>Add Stock</p>
                    <Form
                        name='form'
                        onFinish={onFinish}
                        layout={'vertical'}
                    >
                        <Form.Item
                            name='card'
                            label='Design Name'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Design Name')
                                }
                            ]}
                        >
                            <Select
                                showArrow
                                allowClear
                                className='form-input'
                                placeholder={`Select Design`}
                                optionFilterProp='children'
                            >
                                {cards?.map((v, i) => <Option value={v?._id} key={i}><Avatar src={v?.imageUrl} style={{ marginRight: 10, width: 34, height: 24 }} />{v?.designName}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name='stock'
                            label='Stock'
                            hasFeedback
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                showArrow
                                allowClear
                                className='form-input'
                                placeholder={'Select Stock'}
                                optionFilterProp='children'
                                filterOption={(input, option) =>
                                    option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {arr?.map((v, i) => <Option value={v + 1} key={i}>{v + 1}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type='primary'
                                className='form-button'
                                block
                                htmlType='submit'
                                loading={loading}
                            >
                                Add Stock
                            </Button>
                            <Button
                                style={{ borderRadius: 4, marginTop: 6 }}
                                block
                                onClick={() => setAddStock(false)}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>}
        </div>
    )
}

export default StockReport