import { DatePicker, Select, Button, Table, Modal } from 'antd'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { convertTitle, convertMessage, createLotteryCard, getAllData } from '../../../utils/helpers'
import ViewCard from '../Cards/ViewCard'
import axios from 'axios'
import { ADMIN } from '../../../utils/apis'
const { RangePicker } = DatePicker
const { Option } = Select

const LotteryCards = (props) => {
    const { user, adminActions, setLotteryCardssss } = props
    const buUsers = useSelector(state => state?.adminReducer?.buUsers || [])
    const [card, setCard] = useState({})
    const [userData, setUserData] = useState({})
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({})
    const [guest, setGuest] = useState({})
    const [dateRange, setDateRange] = useState({
        date1: moment.utc().format('YYYY-MM-DD'),
        date2: moment.utc().add(7, 'days').format('YYYY-MM-DD')
    })
    const [showMessageData, setShowMessageData] = useState(false)
    const [showData, setShowData] = useState(false)
    const [selectedUser, setSelectedUser] = useState(buUsers?.[0]?._id || null)
    const [lotteryCards, setLotteryCards] = useState([])
    const [loyaltyAdvertisment, setLoyaltyAdvertisment] = useState({})

    useEffect(() => {
        getAllData(user?._id, adminActions)

        if (buUsers?.length) {
            getLotteryCards()
        }
    }, [])

    useEffect(() => {
        if (buUsers?.length) {
            getLotteryCards()
        }
    }, [selectedUser, dateRange])

    const getLotteryCards = () => {
        setLoading(true)
        let obj = {
            ...dateRange,
            userId: selectedUser
        }
        axios.post(`${ADMIN.GET_LOTTERY_CARDS}`, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                let tableData = Object.entries(data?.data)
                tableData = tableData?.map((v) => {
                    return {
                        id: v[0],
                        value: v[1]
                    }
                })
                console.log('TABLE DATA', tableData)
                setLotteryCards([...tableData])
                setLoyaltyAdvertisment(data?.loyaltyAdvertisment)
            })
    }

    const getLatestData = (d) => {
        let obj = {
            date1: d[0].format('YYYY-MM-DD'),
            date2: d[1].format('YYYY-MM-DD')
        }

        setDateRange(obj)
    }

    const onCancel = () => {
        setCard({})
        setShowData(false)
    }

    let columns = [
        {
            title: 'User Invoice Id',
            key: 'id',
            dataIndex: 'id',
            ellipsis: true,
            sorter: true
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (e) => <div style={{ paddingTop: 15, marginTop: -5, display: 'flex', flexDirection: 'column' }}>
                <a onClick={() => createLotteryCard(e?.value, loyaltyAdvertisment)}>Show Total Pdfs</a>
            </div>
        }
    ]

    return (
        <div className='flex-column menu-main'>
            <div className='button-end button-end-scroll'>
                <Button
                    type='primary'
                    className='btn-end invite-guest invite-guest3'
                    onClick={() => {
                        setLotteryCardssss()
                    }}

                >
                    Back
                </Button>
            </div>
            <div className='user-table'>
                <h1 className='text-center'>Lottery Cards</h1>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20, width: 300 }}>
                <h3>Date Range</h3>
                <RangePicker
                    defaultValue={[moment(), moment().add(7, 'days')]}
                    onChange={getLatestData}
                    allowClear={false}
                />
                <br />
                <Select
                    showSearch
                    showArrow
                    allowClear
                    defaultValue={buUsers[0]?._id}
                    onChange={(e) => setSelectedUser(e)}
                    className='form-input'
                    placeholder={'Select User'}
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                        option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {buUsers?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
                </Select>
            </div>
            <div className='user-table'>
                <Table
                    columns={columns}
                    dataSource={lotteryCards}
                    loading={loading}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div>
            {showData ? <ViewCard card={card} onCancel={onCancel} showData={showData} /> : null}
            {/* 
            <Modal
                title='Dates And Messages'
                visible={showMessageData}
                onCancel={() => setShowMessageData(false)}
                className='modal-view'
                footer={[
                    <Button
                        key='back'
                        onClick={() => setShowMessageData(false)}
                        className='form-input'
                    >
                        Return
                    </Button>
                ]}
            >
                <div>
                    <h1>Message: </h1>
                    <h3>{convertMessage(message, guest, userData)}</h3>
                </div>
            </Modal> */}
        </div>
    )
}

export default LotteryCards