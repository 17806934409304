import React, { useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, Upload } from 'antd'
import { inputPlace, requiredMessage } from '../../../utils/helpers'
import axios from 'axios'
import { POST, GET, ADMIN } from '../../../utils/apis'
import { convertTitle, successNotification, errorMessage, warningMessage } from '../../../utils/helpers'

const AddGiftCards = (props) => {
    const { onCancel, edit, user , getCards } = props
    const [loading, setLoading] = useState(false)
    const [quantity, setQuantity] = useState(null)
    const [amount, setAmount] = useState(0)
    const [fileList, setFileList] = useState([])

    const onFinish = (values) => {
        // console.log('values',values)
        values.file = fileList
        edit?._id && (values._id = edit?._id)
        values.adminId = user?._id

        if (!values._id && !values?.file?.length) {
            return warningMessage('Please Upload Image')
        }
        setLoading(true)

        let formData = new FormData()
        if (values?.file?.length) {
            formData.append('file', values?.file[0])
        }
        if (values?._id) {
            formData.append('_id', values?._id)
        }
        formData.append('seriesName', values?.seriesName)
        formData.append('quantity', Number(values?.quantity))
        formData.append('amount', Number(values?.amount))
        formData.append('adminId', values?.adminId)

        console.log('values', values)
        axios.post(ADMIN.CREATE_ADMIN_GIFT_CARDS, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(data?.message)
                    console.log('data',data)
                    onCancel()
                    getCards()
                }
                // errorMessage(data.message)
            })
            .catch((e) => {
                setLoading(false)
                errorMessage()
                console.log('e',e)
            })
    }

    
    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage('You can only upload Images!')
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'> Add Gift Cards</p>
                    <Form
                        name='form'
                        onFinish={onFinish}
                        layout={'vertical'}
                        requiredMark={false}
                    >
                        <Form.Item
                            name='seriesName'
                            label='Series Name'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Input Series Name'
                                }
                            ]}
                        >
                            <Input
                                className='form-input'
                                placeholder='Please Input Series Name'
                            />
                        </Form.Item>
                        <Form.Item
                            name='quantity'
                            label='Quantity'
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Quantity')
                                }
                            ]}
                        >
                            <div>
                                <Input
                                    className='form-input'
                                    placeholder={inputPlace('Quantity')}
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value?.replace(/[^0-9]/g, ''))}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item
                            name='amount'
                            label='Amount'
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Amount')
                                }
                            ]}
                            initialValue={0}
                        >
                            <div>
                                <Input
                                    className='form-input'
                                    placeholder={inputPlace('Amount')}
                                    type='number'
                                    max={1000}
                                    min={0}
                                    step={0}
                                    maxLength={4}
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value?.replace(/[^0-9]/g, ''))}
                                    // onChange={(e) => setAmount(e.target.value?.replace(/[^0-9.]/g, ''))}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item
                            name='file'
                            label='Image'
                        >
                            <Upload
                                name='file'
                                multiple={false}
                                beforeUpload={() => false}
                                accept='image/png, image/jpeg'
                                onChange={normFile}
                                fileList={fileList}
                            >
                                <Button icon={<UploadOutlined />}>Click to upload</Button>
                            </Upload>
                            {/* {edit?.fileName ? <span><FileOutlined />&nbsp;{edit?.fileName}</span> : null} */}
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type='primary'
                                className='form-button'
                                block
                                htmlType='submit'
                                loading={loading}
                            >
                                Create
                            </Button>
                            <Button
                                style={{ borderRadius: 4, marginTop: 6 }}
                                block
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AddGiftCards