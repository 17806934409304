import { ALL_GUESTS, SEGMENT_MESSAGES, SUBSCRIPTIONS, POSTAL_CARD, INVOICES, USER_SEGMENTS, ALL_SUBSCRIBE_COUPONS, SAVINGS, LOTTERIES_SAVINGS, FORMS, FORM_REPORTS, GET_COUPONS, GET_NOTIFICATIONS, GET_CARDS, GET_ADVERTISEMENT, WALLET, ALL_SUBSCRIBE_LOTTERY, USER_PENDING_GROUPS, USER_JOINED_GROUPS } from '../types'

const addAllGuests = (guests) => {
    return {
        type: ALL_GUESTS,
        guests
    }
}

const addAllSegmentMessage = (segmentMessages) => {
    return {
        type: SEGMENT_MESSAGES,
        segmentMessages
    }
}

const addAllSubscriptions = (subscriptions) => {
    return {
        type: SUBSCRIPTIONS,
        subscriptions
    }
}

const addPostalCardPrice = (postalCard) => {
    return {
        type: POSTAL_CARD,
        postalCard
    }
}

const addAllInvoices = (invoices) => {
    return {
        type: INVOICES,
        invoices
    }
}

const addAllUserSegments = (segments) => {
    return {
        type: USER_SEGMENTS,
        segments
    }
}

const addAllCouponsSubscriptionDetail = (couponsDetail) => {
    return {
        type: ALL_SUBSCRIBE_COUPONS,
        couponsDetail
    }
}
const addAllLotterySubscriptionDetail = (lotteryDetail) => {
    return {
        type: ALL_SUBSCRIBE_LOTTERY,
        lotteryDetail
    }
}

const addAllSavings = (savings) => {
    return {
        type: SAVINGS,
        savings
    }
}

const addAllLotterySavings = (lotteries) => {
    return {
        type: LOTTERIES_SAVINGS,
        lotteries
    }
}

const addAllForms = (forms) => {
    return {
        type: FORMS,
        forms
    }
}

const addAllCoupons = (coupons) => {
    return {
        type: GET_COUPONS,
        coupons
    }
}
const addUserJoinedGroups = (joined) => {
    return {
        type: USER_JOINED_GROUPS,
        joined
    }
}
const addUserPendingGroups = (pending) => {
    return {
        type: USER_PENDING_GROUPS,
        pending
    }
}

const addNotifictaion = (notification) => {
    return {
        type: GET_NOTIFICATIONS,
        notification
    }
}

const addAllFormReports = (formReports) => {
    return {
        type: FORM_REPORTS,
        formReports
    }
}

const addAllCard = (cards) => {
    return {
        type: GET_CARDS,
        cards
    }
}

const addAllAdvertisement = (advertisements) => {
    return {
        type: GET_ADVERTISEMENT,
        advertisements
    }
}
const addWallet = (wallet) => {
    return {
        type: WALLET,
        wallet
    }
}

export {
    addAllGuests,
    addAllSegmentMessage,
    addAllSubscriptions,
    addPostalCardPrice,
    addAllInvoices,
    addAllUserSegments,
    addUserPendingGroups,
    addUserJoinedGroups,
    addAllCouponsSubscriptionDetail,
    addAllSavings,
    addAllLotterySavings,
    addAllForms,
    addAllFormReports,
    addAllCoupons,
    addNotifictaion,
    addAllCard,
    addAllAdvertisement,
    addWallet,
    addAllLotterySubscriptionDetail
}