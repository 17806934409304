import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import moment from 'moment-timezone'
import { Table, Button, DatePicker, Modal, Tooltip, Avatar } from 'antd'
import { getAllData, dateFormater, convertMessage, createPostalCard, splitDate, createFrontSide } from '../../../utils/helpers'
import { ADMIN } from '../../../utils/apis'
import ViewCard from '../Cards/ViewCard'

const { RangePicker } = DatePicker

const SendMessagesList = (props) => {
    const { user, adminActions } = props
    const [card, setCard] = useState({})
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({})
    const [guest, setGuest] = useState({})
    const [userData, setUserData] = useState({})
    const [showData, setShowData] = useState(false)
    const [showMessageData, setShowMessageData] = useState(false)
    const messagesList = useSelector(state => state?.adminReducer?.messagesList || [])

    useEffect(() => {
        getAllData(user?._id, adminActions)
    }, [])

    const showTooltip = (data) => (
        <Tooltip placement='topLeft' title={data}>
            {data}
        </Tooltip>
    )

    const getData = (obj) => {
        setLoading(true)
        axios.post(`${ADMIN.GET_MESSAGES_LIST}`, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                adminActions?.addAllMessagesList(data?.data || [])
            })
    }

    const createBackSide = (obj) => {
        let cardUrl = obj?.cardUrl1 || obj?.cardUrl2 || obj?.cardUrl3 || obj?.cardUrl4 || obj?.cardUrl5

        if (cardUrl) {
            window.open(cardUrl, '_blank')
        }
    }

    const createFullSide = (obj) => {
        let fullCardUrl = obj?.fullCardUrl1 || obj?.fullCardUrl2 || obj?.fullCardUrl3 || obj?.fullCardUrl4 || obj?.fullCardUrl5

        if (fullCardUrl) {
            window.open(fullCardUrl, '_blank')
        }
    }

    const getLatestData = (d) => {
        let obj = {
            date1: d[0].format('YYYY-MM-DD'),
            date2: d[1].format('YYYY-MM-DD')
        }

        getData(obj)
    }

    const onCancel = () => {
        setCard({})
        setShowData(false)
    }

    const showMessage = (obj) => {
        let msg = obj?.message1 || obj?.message2 || obj?.message3 || obj?.message4 || obj?.message5

        setGuest(obj?.guest)
        setUserData(obj?.userId)
        setMessage(msg)
        setShowMessageData(true)
    }

    let columns = [
        {
            title: 'User Name',
            dataIndex: ['userId', 'fullName'],
            key: 'fullName',
            ellipsis: true,
            sorter: true,
            render: (e) => showTooltip(e)
        },
        {
            title: 'User Email',
            dataIndex: ['userId', 'email'],
            key: 'email',
            ellipsis: true,
            sorter: true,
            render: (e) => showTooltip(e)
        },
        {
            title: 'Guest Name',
            dataIndex: ['guest', 'name'],
            key: 'email',
            ellipsis: true,
            sorter: true,
            render: (e) => showTooltip(e)
        },
        {
            title: 'Fixed Day',
            dataIndex: 'fixedDay',
            key: 'fixedDay',
            render: (fixedDay) => <p style={{ paddingTop: 15, marginTop: -5 }}>{fixedDay ? 'True' : 'False'}</p>
        },
        {
            title: 'Sending Date',
            key: 'sendingDate',
            ellipsis: true,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{showTooltip(dateFormater(splitDate(e?.sendingDate1 || e?.sendingDate2 || e?.sendingDate3 || e?.sendingDate4 || e?.sendingDate5)))}</p>
        },
        {
            title: 'Sending Type',
            key: 'postalCard',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e?.postalCard ? 'Postal Card' : 'Email'}</p>
        },
        {
            title: 'Card Design',
            key: 'card',
            render: (e) => <Avatar src={e?.card1?.imageUrl || e?.card2?.imageUrl || e?.card3?.imageUrl || e?.card4?.imageUrl || e?.card5?.imageUrl} style={{ marginRight: 10, width: 86, height: 60 }} shape='square' />
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (e) => <div style={{ paddingTop: 15, marginTop: -5, display: 'flex', flexDirection: 'column' }}>
                <a onClick={() => showMessage(e)}>View Message</a>
                {e?.postalCard ? <div>
                    <a onClick={() => createFrontSide(e)}>Front Side</a><br />
                    <a onClick={() => createBackSide(e)}>Back Side</a><br />
                    <a onClick={() => createFullSide(e)}>Front + Back</a>
                </div> : null}
            </div>
        }
    ]

    return (
        <div className='flex-column menu-main'>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20, width: 300 }}>
                <h3>Date Range</h3>
                <RangePicker
                    defaultValue={[moment(), moment().add(7, 'days')]}
                    onChange={getLatestData}
                    allowClear={false}
                />
            </div>
            <div className='user-table'>
                <Table
                    columns={columns}
                    dataSource={messagesList}
                    loading={loading}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div>
            {showData ? <ViewCard card={card} onCancel={onCancel} showData={showData} /> : null}

            <Modal
                title='Dates And Messages'
                visible={showMessageData}
                onCancel={() => setShowMessageData(false)}
                className='modal-view'
                footer={[
                    <Button
                        key='back'
                        onClick={() => setShowMessageData(false)}
                        className='form-input'
                    >
                        Return
                    </Button>
                ]}
            >
                <div>
                    <h1>Message: </h1>
                    <h3>{convertMessage(message, guest, userData)}</h3>
                </div>
            </Modal>
        </div>
    )
}

export default SendMessagesList