import React, { useState, useEffect } from 'react'
import AddAdminAd from './AddAds'
import { DeleteOutlined } from '@ant-design/icons'
import axios from 'axios'
import _ from 'lodash'
import { ADMIN } from '../../../utils/apis'
import { convertTitle, successNotification } from '../../../utils/helpers'
import { Button, Image, Popconfirm, Spin, Table } from 'antd'
import GroupAds from './GroupAds'
import AddAdminGroupAd from './AddGroupAds'

const AdminAd = (props) => {
  const { user } = props
  const [advertisementData, setAdvertisementData] = useState([])
  const [form, setForm] = useState(false)
  const [edit, setEdit] = useState({})
  const [loading, setLoading] = useState(false)
  const [showGroupAd, setshowGroupAd] = useState(false)
  const [spin, setSpin] = useState({})
  const [selectionType, setSelectionType] = useState([])

  useEffect(() => {
    getAllData()
  }, [form])

  useEffect(() => {
    getKey()
  }, [advertisementData])

  const getAllData = () => {
    axios.get(`${ADMIN?.GET_ADMIN_ADVERTISEMENT}/${user?._id}`)
      .then(
        (res) => {
          setAdvertisementData(res?.data?.data)
        }
      )
      .catch(e => {
        setLoading(false)
      })
  }

  const deleteAdvertisement = (_id) => {
    setSpin({ [_id]: true })

    let valObj = { _id, userId: `${user?._id}` }
    axios.post(`${ADMIN.DELETE_ADMIN_ADVERTISEMENT}`, valObj)
      .then((res) => {
        const { data } = res
        setSpin({})
        if (data?.success) {
          successNotification('Successfully Deleted!')
          getAllData()
        }
      })
      .catch((e) => {
        setSpin({})
      })
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Image',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (e) => <Image src={`${e}`} style={{ marginRight: 0, width: 100, height: 60 }} shape='square' />
    },
    {
      title: 'Actions',
      key: 'action',
      width: 100,
      render: (e) => (
        <Popconfirm
          title={`Delete ${convertTitle('this Advertisement')}?`}
          okText='Delete'
          cancelText='Cancel'
          okButtonProps={{
            type: 'primary',
            className: 'form-button'
          }}
          cancelButtonProps={{ style: { borderRadius: 4 } }}
          onConfirm={() => deleteAdvertisement(e?._id)}
        >{!e?.activeAdd ?
          <Button href='#'><DeleteOutlined style={{ color: 'red' }} /> Delete</Button> : ''}
        </Popconfirm>
      )
    }
  ]

  const dataAdv = advertisementData.map((v, i) => {
    return {
      name: v?.name,
      key: v?._id,
      imageUrl: v?.imageUrl,
      _id: v?._id,
      activeAdd: v?.active,
      enabled: false
    }
  })

  let idArray = []

  dataAdv?.filter((a, i) => {
    let condForActiveAdd = a.activeAdd == true ? a?.key : 0
    idArray.push(condForActiveAdd)
  })


  const getKey = () => {
    setSelectionType(idArray)
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let active = true

      let diff = _.differenceWith(selectionType, selectedRowKeys, _.isEqual)

      diff = diff?.filter((a) => a)

      let getActiveId = selectedRowKeys[selectedRowKeys?.length - 1]
      setLoading(true)

      let val = { _id: getActiveId, userId: `${user?._id}`, active: active }

      if (diff?.length) {
        val._id = diff[0]
        val.active = false
      }
      axios.post(`${ADMIN?.SET_ACTIVE_ADMIN_ADVERTISEMENT}`, val)
        .then((res) => {
          const { data } = res
          setSpin({})
          if (data?.success) {
            setLoading(false)
            if (diff?.length) {
              successNotification('De activated Successfully!')
            } else {
              successNotification('Activated Successfully!')
            }
          }
        })
        .catch((e) => {
          console.log(
            'error', e
          )
          setSpin({})
          setLoading(false)
        })
      getAllData()
    }
  }

  return (
    <div className='flex-column menu-main'>
      {form && !showGroupAd ? <AddAdminAd
        // edit={edit}
        onCancel={() => (setForm(false))}
        {...props}
      /> :
        form && showGroupAd ?
          <AddAdminGroupAd
            edit={edit}
            setEdit={setEdit}
            onCancel={() => { setForm(false); setEdit({}) }}
            {...props}
          />
          : <div>
            <div className='button-end button-end-scroll'>
              {showGroupAd ?
                <Button
                  type='primary'
                  className='btn-end invite-guest'
                  onClick={() => setshowGroupAd(false)}
                >
                  Advertisements
                </Button> :
                <Button
                  type='primary'
                  className='btn-end invite-guest'
                  onClick={() => setshowGroupAd(true)}
                >
                  Group Advertisements
                </Button>
              }
              {showGroupAd ?
                <Button
                  type='primary'
                  className='btn-end invite-guest invite-guest3'
                  onClick={() => setForm(true)}
                >
                  Add Group Advertisement
                </Button> :
                <Button
                  type='primary'
                  className='btn-end '
                  onClick={() => setForm(true)}
                >
                  Add Advertisement
                </Button>
              }
            </div>
            <div className='user-table'>
              {!showGroupAd ? <Table
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: selectionType,
                  ...rowSelection
                }}

                columns={columns}
                dataSource={dataAdv}
                pagination={{ hideOnSinglePage: true, pageSize: 5 }}
              /> :
                <GroupAds form={form} setForm={setForm} setEdit={setEdit} {...props} />
              }
            </div>
          </div>}
    </div>
  )
}

export default AdminAd