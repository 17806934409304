import moment from 'moment-timezone'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { errorMessage, getAllUserData, successMessage } from '../../../utils/helpers'
import { GET, POST, ADMIN } from '../../../utils/apis'
import axios from 'axios';
import { Table, Button, DatePicker, Modal, Tooltip, Select, Image, Popconfirm, Input, Space } from 'antd'
import _ from 'lodash'
import AddGiftCards from './AddGiftCards'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'

const GiftCardUsers = (props) => {
    const { user, userActions } = props
    const [requests, setRequests] = useState([])
    const [cities, setCities] = useState([])
    const [loading, setLoading] = useState(false)
    const [detail, setDetail] = useState({})
    const [showDetails, setShowDetails] = useState(false)

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [filteredInfo, setFilteredInfo] = useState({})
    const [sortedInfo, setSortedInfo] = useState({})

    const handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };
    const clearFilters = () => {
        setFilteredInfo({});
    };
    const clearAll = () => {
        setFilteredInfo({});
        setSortedInfo({});
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    useEffect(() => {
        getRequests()
    }, [])

    const getRequests = () => {
        setLoading(true)
        axios.get(`${GET.GET_GIFT_CARD_USERS}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setCities(data?.data.map((v, i) => { return { text: v?.city?.toLowerCase(), value: v?.city?.toLowerCase() } }) || [])
                setRequests(data?.data)
                console.log('dara', data?.data)
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    console.log('requests', requests)

    const handleOk = () => {
        setShowDetails(false)
    }

    const columns = [
        {
            title: 'Full Name',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'Business Name',
            dataIndex: 'businessName',
            key: 'businessName',
            ...getColumnSearchProps('businessName')
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email')
        },
        {
            title: 'Postal Code',
            dataIndex: 'postalCodeCity',
            key: 'postalCodeCity',
            ...getColumnSearchProps('postalCodeCity'),
        },
        {
            title: 'Phone',
            dataIndex: 'telNo',
            key: 'telNo',
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            render: (e) => `${e?.toLowerCase()}`,
            filters: _.uniqWith(cities, _.isEqual),
            filteredValue: filteredInfo?.city || null,
            onFilter: (value, record) => {
                console.log('VALUE *****', value, record)
                return record.city.toLowerCase().includes(value?.toLowerCase())
            },
            sorter: (a, b) => a?.city?.length - b?.city?.length,
            sortOrder: sortedInfo.columnKey === 'city' ? sortedInfo.order : null,
            ellipsis: true,
        },
    ]
    return (
        <Table
            loading={loading}
            dataSource={requests}
            columns={columns}
            pagination={{ hideOnSinglePage: true }}
            scroll={{ x: 800 }}
            onChange={handleChange}
        />
    )
}

export default GiftCardUsers