import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { Table, Input, Button, Space, Popconfirm, Spin, Modal } from 'antd'
import { convertTitle, successNotification, getAllSegments, getAllUserData, convertDate } from '../../utils/helpers'
import { POST, GET } from '../../utils/apis'
import { errorMessage } from '../../utils/helpers'
import AddGuest from './AddGuest'
import ViewGuestInvites from './ViewGuestInvites'
import axios from 'axios'

const WithdrawalRequests = (props) => {
    const { user } = props
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getWithdrawalRequests()
    }, [])

    const getWithdrawalRequests = () => {
        setLoading(true)
        axios.get(`${GET.GET_ALL_LOYALTY_WITHDRAW_REQUEST_FOR_BU_USER}/${user?._id}`)
            .then((res) => {
                const { data } = res
                console.log('data', data?.data)
                setLoading(false)

                if (data.success) {
                    setData(data?.data)
                    // dispatch(loginUser(data.admin))
                }
                else {
                    // dispatch(removeUser())
                }
            })
            .catch((e) => {
                // dispatch(removeUser())
                setLoading(false)
            })
    }


    const ApproveRequest = (e) => {
        console.log('e', e)
        let coupons = e?.coupons?.map((v, i) => v?._id)
        let obj = {
            userId: user?._id,
            _id: e?._id,
            coupons: coupons,
            withdrawAmount: e?.withdrawAmount,
            guestId: e?.guestId?._id

        }
        // console.log('obj', obj)
        setLoading(true)
        axios.post(`${POST.ACCEPT_LOYALTY_WITH_DRAW_REQUEST}`, obj)
            .then((res) => {
                const { data } = res
                // console.log('data', data)
                setLoading(false)

                if (data.success) {
                    // setData(data?.data)
                    successNotification(data?.message)
                    getWithdrawalRequests()
                    // dispatch(loginUser(data.admin))
                }
            })
            .catch((e) => {
                // dispatch(removeUser())
                setLoading(false)
            })
    }

    let columns = [
        {
            title: 'Guest Name',
            dataIndex: ['guestId', 'fullName'],
            key: 'businessName',
            // sorter: true,
            sorter: (a, b) => a.businessName - b.businessName
        },
        {
            title: 'Guest Email',
            dataIndex: ['guestId', 'email'],
            // dataIndex: 'guestEmail',
            key: 'guestEmail',
            sorter: (a, b) => a.guestEmail - b.guestEmail
        },
        {
            title: 'Date',
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{convertDate(e)}</p>
        },
        {
            title: 'Amount Requested',
            dataIndex: 'withdrawAmount',
            key: 'withdrawAmount',
            sorter: (a, b) => a.withdrawAmount - b.withdrawAmount
        },
        {
            title: 'No. of Coupons',
            key: 'coupons',
            sorter: (a, b) => a.coupons - b.coupons,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e?.coupons?.length}</p>
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (e) =>
                <Popconfirm
                    disabled={e?.isApproved}
                    title={`Approve this Request?`}
                    okText='yes'
                    cancelText='Cancel'
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => ApproveRequest(e)}
                >
                    <Button disabled={e?.isApproved}>Approve</Button>
                </Popconfirm>
        }
    ]


    return (
        <Table
            columns={columns}
            loading={loading}
            dataSource={data}
            pagination={{ hideOnSinglePage: true }}
        // scroll={{ x: 1000 }}
        />
    )
}

export default WithdrawalRequests