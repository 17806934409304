import { Table } from 'antd'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getAllUserData } from '../../utils/helpers'



// joined
const JoinedTable = (props) => {

    const { user, userActions, history } = props

    const joined = useSelector(state => state?.userReducer?.joined || [])

    // console.log('joined', joined)
    useEffect(() => {
        getAllUserData(user?._id, userActions)
    }, [])

    let columns = [
        {
            title: 'Group Name',
            dataIndex: 'groupName',
            key: 'name',
            sorter: true,
            ellipsis: true,
        },
        {
            title: 'Private',
            dataIndex: 'privateGroup',
            key: 'privateGroup',
            sorter: true,
            ellipsis: true,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> {e ? 'TRUE' : 'FALSE'}</p>

        },
        {
            title: 'Postal Code Range',
            dataIndex: 'postalCode',
            key: 'postalCode',
            // sorter: true,
            ellipsis: true,
            render: (e) => {
                return (<>
                    <p>{e[0]?.postalcodeStart}-{e[0]?.postalcodeEnd}</p>
                    {e[1] ? <> <br />
                        <p>{e[1]?.postalcodeStart}-{e[1]?.postalcodeEnd}</p> </> : null}
                </>)
            }
        },
        {
            title: 'Total Group Members',
            dataIndex: 'totalMember',
            key: 'totalMember',
            sorter: true,
            ellipsis: true,
            // render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> {e ? e?.userId?.length : 0}</p>
            // render: (e) => console.log(e, 'LENGTH')
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            sorter: true,
            ellipsis: true,
            render: (e) => <span>{moment(e).utcOffset(0, true).format('DD-MM-YYYY')}</span>

        },
        // {
        //     title: 'Card Design',
        //     key: 'design',
        //     sorter: true,
        //     ellipsis: true,
        //     render: (e) => <Image src={e?.imageUrl} style={{ marginRight: 10, width: 86, height: 60 }} shape='square' />
        // },
    ]

    const data = joined.map((v, i) => {
        return {
            _id: v?._id,
            groupId: v?.groupId?._id,
            groupName: v?.groupId?.name,
            totalMember: v?.groupId?.userId?.length,
            userId: v?.userId?._id,
            privateGroup: v?.privateGroup,
            created: v?.created,
            postalCode: v?.groupId?.postalCode

        }
    })

    return (
        <Table
            columns={columns}
            dataSource={data}
            // loading={loading}
            pagination={{ hideOnSinglePage: true }}
            scroll={{ x: 800 }}
        />
    )
}

export default JoinedTable