import { Table } from 'antd'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllUserData } from '../../utils/helpers'

const Notification = (props) => {
    const { user, userActions } = props
    const [loading, setLoading] = useState(false)
    const notification = useSelector(state => state?.userReducer?.notification || [])

    useEffect(() => {
        getAllUserData(user?._id, userActions)
    }, [])

    const columns = [
        {
            title: 'Message',
            dataIndex: 'notificationMessage',
            key: 'notificationMessage',
        },
        {
            title: 'Created At',
            dataIndex: 'created',
            key: 'created'
        }
    ]

    const notificationData = notification.map((v, i) => {
        return {
            notificationMessage: v?.notificationMessage,
            created: moment(v?.created).utcOffset(0, true).format('DD/MM/YYYY'),
        }
    })

    return (
        <div className='flex-column menu-main'>
            <div className='user-table'>
                <Table
                    loading={loading}
                    // loading={true}
                    dataSource={notificationData}
                    columns={columns}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div>
        </div>
    )
}

export default Notification