import React, { useState } from 'react'
import { Form, Button, Input, Select, Upload } from 'antd'
import { UploadOutlined, FileOutlined } from '@ant-design/icons'
import axios from 'axios'
import PropTypes from 'prop-types'
import { requiredMessage, successNotification, errorMessage, warningMessage, inputPlace } from '../../../utils/helpers'
import { ADMIN } from '../../../utils/apis'

const { Option } = Select

const AddCards = (props) => {
    const { onCancel, edit } = props
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([])

    const onFinish = (values) => {
        values.file = fileList
        edit?._id && (values._id = edit?._id)

        if (!values._id && !values?.file?.length) {
            return warningMessage('Please Upload Image')
        }
        setLoading(true)

        let formData = new FormData()
        if (values?.file?.length) {
            formData.append('file', values?.file[0])
        }
        if (values?._id) {
            formData.append('_id', values?._id)
        }
        formData.append('designName', values?.designName)
        axios.post(ADMIN.CREATE_CARD, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(data?.message)
                    return setTimeout(() => {
                        onCancel()
                    }, 300)
                }
                errorMessage(data.message)
            })
            .catch((e) => {
                setLoading(false)
                errorMessage()
            })
    }

    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage('You can only upload Images!')
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'>{edit?._id ? 'Update' : 'Create'} Card</p>
                    <Form
                        name='form'
                        onFinish={onFinish}
                        layout={'vertical'}
                    >
                        <Form.Item
                            name='designName'
                            label='Design Name'
                            hasFeedback
                            initialValue={edit?.designName}
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Design Name')
                                }
                            ]}
                        >
                            <Input
                                className='form-input'
                                placeholder={inputPlace('Design Name')}
                            />
                        </Form.Item>
                        <Form.Item
                            name='file'
                            label='File'
                        >
                            <Upload
                                name='file'
                                multiple={false}
                                beforeUpload={() => false}
                                accept='image/png, image/jpeg'
                                onChange={normFile}
                                fileList={fileList}
                            >
                                <Button icon={<UploadOutlined />}>Click to upload</Button>
                            </Upload>
                            {edit?.fileName ? <span><FileOutlined />&nbsp;{edit?.fileName}</span> : null}
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type='primary'
                                className='form-button'
                                block
                                htmlType='submit'
                                loading={loading}
                            >
                                {edit?._id ? 'Update' : 'Create'}
                            </Button>
                            <Button
                                style={{ borderRadius: 4, marginTop: 6 }}
                                block
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

AddCards.propTypes = {
    onCancel: PropTypes.func.isRequired
}

export default AddCards