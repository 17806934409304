import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import moment from 'moment-timezone'
import { Table, Button, DatePicker, Spin, Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { getAllData, convertTitle, successNotification, errorNotification } from '../../../utils/helpers'
import { apiUrl } from '../../../utils/constants'
import { ADMIN } from '../../../utils/apis'

const { RangePicker } = DatePicker

const MessageReport = (props) => {
    const { user, adminActions } = props
    const [spin, setSpin] = useState({})
    const [spinLoad, setSpinLoad] = useState(false)
    const [dateRange, setDateRange] = useState({
        date1: moment.utc().format('YYYY-MM-DD'),
        date2: moment.utc().add(7, 'days').format('YYYY-MM-DD')
    })
    const reports = useSelector(state => state?.adminReducer?.reports || [])

    useEffect(() => {
        getAllData(user?._id, adminActions)
    }, [])

    const createReport = () => {
        setSpinLoad(true)
        axios.post(`${apiUrl}${ADMIN.CREATE_MESSAGES_REPORT}/${user?._id}`, dateRange)
            .then((res) => {
                const { data } = res
                setSpinLoad(false)
                if (data?.success) {
                    successNotification('Report Successfully Created!')
                    adminActions?.addAllReports(data?.data || [])
                }
                else {
                    errorNotification(data?.message)
                }
            })
            .catch((e) => {
                errorNotification()
                setSpinLoad(false)
                console.log('e', e)
            })
    }

    const createReportRange = (d) => {
        let obj = {
            date1: moment.utc(d[0]).format('YYYY-MM-DD'),
            date2: moment.utc(d[1]).format('YYYY-MM-DD')
        }

        setDateRange(obj)
    }

    const deleteReport = (_id) => {
        setSpin({ [_id]: true })
        axios.post(`${ADMIN.DELETE_MESSAGES_REPORT}/${user?._id}`, { _id })
            .then((res) => {
                const { data } = res
                // console.log('data', data)
                setSpin({})
                if (data?.success) {
                    successNotification('Successfully Deleted!')
                    adminActions?.addAllReports(data?.data || [])
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }

    let columns = [
        {
            title: 'Report Range',
            key: 'sendingDate',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e?.fileName}</p>
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (e) => <div style={{ paddingTop: 15, marginTop: -5, display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <a onClick={() => window.open(e?.reportUrl, '_blank')}>View</a>
                <Popconfirm
                    title={`Delete ${convertTitle(e?.fileName)}?`}
                    okText='Delete'
                    cancelText='Cancel'
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteReport(e?._id)}
                >
                    <a href="#"><DeleteOutlined style={{ color: 'red' }} /> Delete</a>
                    {spin[e?._id] ? <span className='demo-loading-container'>
                        &nbsp;<Spin />
                    </span> : null}
                </Popconfirm>
            </div>
        }
    ]

    return (
        <div className='flex-column menu-main'>
            <Spin spinning={spinLoad}>
                <div className='flex-mh' style={{ display: 'flex', flexDirection: 'column', marginBottom: 20, width: 400 }}>
                    <h3>Message Report</h3>
                    <div className='flex-mobile' style={{ display: 'flex' }}>
                        <RangePicker
                            defaultValue={[moment(), moment().add(7, 'days')]}
                            onChange={createReportRange}
                            style={{ marginBottom: 10 }}
                        />
                        <Button className='form-input report-button' onClick={createReport}>Create Report</Button>
                    </div>
                </div>
                <div className='user-table'>
                    <Table
                        columns={columns}
                        dataSource={reports}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 800 }}
                    />
                </div>
            </Spin>
        </div>
    )
}

export default MessageReport