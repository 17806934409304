import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { Table, Input, Button, Space, Popconfirm, Spin, Modal } from 'antd'
import { convertTitle, successNotification, getAllSegments, getAllUserData, convertDate } from '../../utils/helpers'
import { POST } from '../../utils/apis'
import { errorMessage } from '../../utils/helpers'
import AddGuest from './AddGuest'
import ViewGuestInvites from './ViewGuestInvites'
import WithdrawalRequests from './WithdrawalRequests'

const Guests = (props) => {
    const { user, adminActions, userActions } = props
    const [spin, setSpin] = useState({})
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const [showForm, setShowForm] = useState(false)
    const [showSpin, setShowSpin] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showInvites, setShowInvites] = useState(false)
    const [showRequests, setShowRequests] = useState(false)
    const [guestName, setGuestName] = useState(null)
    const [guestEmail, setGuestEmail] = useState(null)
    const [edit, setEdit] = useState({})
    const searchInput = useRef(null)
    const guests = useSelector(state => state?.userReducer?.guests || [])

    useEffect(() => {
        getAllSegments(adminActions, user?._id)
        getAllUserData(user?._id, userActions)
    }, [showForm])

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => {
                            confirm({ closeDropdown: false })
                            setSearchText(selectedKeys[0])
                            setSearchedColumn(dataIndex)
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100)
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const deleteGuest = (_id) => {
        setSpin({ [_id]: true })
        axios.post(`${POST.DELETE_GUEST}/${user?._id}`, { _id })
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successNotification('Successfully Deleted!')
                    userActions?.addAllGuests(data?.data || [])
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }

    const showEdit = (e) => {
        setEdit(e)
        setShowForm(true)
    }

    const cancelModal = () => {
        setShowModal(false)
        setGuestName(false)
        setGuestEmail(false)
    }

    const inviteGuest = () => {
        if (!guestName) {
            return errorMessage('Please Enter Guest Name')
        } else if (!guestEmail) {
            return errorMessage('Please Enter Guest Email')
        }

        setShowSpin(true)

        let obj = {
            userId: user?._id,
            name: guestName,
            email: guestEmail
        }

        axios.post(`${POST.INVITE_GUEST}`, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    successNotification('Successfully Invited!')
                }
                else {
                    errorMessage('Unable to invite guest!')
                }

                setShowSpin(false)
                cancelModal()
            })
            .catch((e) => {
                setShowSpin(false)
                cancelModal()
            })
    }

    let columns = [
        {
            title: 'Bu Name',
            dataIndex: 'businessName',
            key: 'businessName',
            sorter: true,
            ...getColumnSearchProps('businessName')
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            ...getColumnSearchProps('name')
        },
        {
            title: 'Segment',
            dataIndex: ['segment', 'name'],
            key: 'segment',
            sorter: true
        },
        {
            title: 'Starting Date',
            dataIndex: 'startingDate',
            key: 'startingDate',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{convertDate(e)}</p>
        },
        {
            title: 'Street and House no',
            dataIndex: 'streetHouse',
            key: 'streetHouse',
            sorter: true,
            ...getColumnSearchProps('streetHouse')
        },
        {
            title: 'Postal Code and City',
            dataIndex: 'postalCodeCity',
            key: 'postalCodeCity',
            sorter: true,
            ...getColumnSearchProps('postalCodeCity')
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
            ...getColumnSearchProps('email')
        },
        {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
            sorter: true,
            ...getColumnSearchProps('note')
        },
        {
            title: 'Invited',
            dataIndex: 'isInvited',
            key: 'isInvited',
            render: (e) => <span>{e ? 'True' : 'False'}</span>
        },
        {
            title: 'Created At',
            dataIndex: 'created',
            key: 'created',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (e) => <span>
                <a onClick={() => showEdit(e)}>Edit</a> &nbsp;&nbsp;&nbsp;
                <Popconfirm
                    title={`Delete ${convertTitle(e?.name)}?`}
                    okText='Delete'
                    cancelText='Cancel'
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteGuest(e?._id)}
                >
                    <a href="#"><DeleteOutlined style={{ color: 'red' }} /> Delete</a>
                    {spin[e?._id] ? <span className='demo-loading-container'>
                        &nbsp;<Spin />
                    </span> : null}
                </Popconfirm>
            </span>
        }
    ]

    if (showInvites) {
        return (
            <ViewGuestInvites {...props} cancelShow={setShowInvites} />
        )
    }

    return (
        <div className='flex-column menu-main'>
            {showForm ? <AddGuest edit={edit} onCancel={() => (setShowForm(false), setEdit(null))} {...props} /> : <div>
                <div className='button-end button-end-scroll'>
                    {!showRequests ? <>
                        <Button
                            type='primary'
                            className='btn-end invite-guest invite-guest3'
                            onClick={() => setShowRequests(true)}
                        >
                            Loyalty Withdraw Requests
                        </Button>
                        <Button
                            type='primary'
                            className='btn-end invite-guest invite-guest3'
                            onClick={() => setShowModal(true)}
                        >
                            Invite Guest
                        </Button>
                        <Button
                            type='primary'
                            className='btn-end invite-guest invite-guest2'
                            onClick={() => setShowInvites(true)}
                        >
                            View Guest Invites
                        </Button>
                        <Button
                            type='primary'
                            className='btn-end'
                            onClick={() => setShowForm(true)}
                        >
                            Create Guest
                        </Button></> :
                        <Button
                            type='primary'
                            className='btn-end'
                            onClick={() => setShowRequests(false)}
                        >
                            Back
                        </Button>
                    }
                </div>

                <div className='user-table'>
                    {!showRequests ? <Table
                        columns={columns}
                        dataSource={guests}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 1000 }}
                    /> :
                        <WithdrawalRequests {...props} />}
                </div>
            </div>}

            {showModal ? <Modal
                title='Invite Guest'
                visible={showModal}
                onCancel={cancelModal}
                onOk={inviteGuest}
            >
                <Spin spinning={showSpin}>
                    <Input
                        className='form-input'
                        placeholder={`Enter Guest Name`}
                        onChange={e => setGuestName(e.target.value)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Input
                        className='form-input'
                        placeholder={`Enter Guest Email`}
                        onChange={e => setGuestEmail(e.target.value)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                </Spin>
            </Modal> : null}
        </div>
    )
}

export default Guests