import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input, Radio, AutoComplete, Checkbox, InputNumber, Switch } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ADMIN } from '../../utils/apis'
import { errorMessage, requiredMessage, successMessage } from '../../utils/helpers'
import { message, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { FileOutlined } from '@ant-design/icons'
import _, { includes } from 'lodash'
const { TextArea } = Input

const AddQuestions = (props) => {
    const { onCancel, user, copyQuestion, setCopyQuestion ,openQuestions} = props

    const [form] = Form.useForm()
    const [ansType, setAnsType] = useState(copyQuestion?.answerType || 'input')
    const [ansList, setansList] = useState(copyQuestion?.answerList || '')
    const [loading, setLoading] = useState(false)
  
    var error1;
    var error2;

    const onChange = e => {
        setAnsType(e.target.value)
    }

    const onFinish = (values) => {

        setLoading(true)

        if (ansType === 'input') {
            values.answerList = values.answerList1
        }
        else if (ansType === 'radio') {
            values.answerList = values.answerList2
        }
        else if (ansType === 'multiple') {
            values.answerList = values.answerList3
        }
        else {
            values.answerList = values.answerList4
        }
        values.userId = user?._id

        values.answerType = ansType


        delete values?.answerList1
        delete values?.answerList2
        delete values?.answerList3
        delete values?.answerList4
        console.log('values', values)
        // return true

        axios.post(ADMIN.CREATE_QUESTION, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successMessage('Question Created Successfully!')
                    form.resetFields()
                    openQuestions()
                    setCopyQuestion({})
                    onCancel()
                } else {
                    errorMessage(data.message)
                }
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
                // errorMessage()
            })

    }

    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'>Create Questions</p>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        name='QuestionsForm'
                        layout='vertical'
                        requiredMark={true}>
                        <Form.Item
                            name='title'
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Title'),
                                }
                            ]}
                            label='Title'
                            required
                            initialValue={copyQuestion?.title}
                        >
                            <Input placeholder='Title' />
                        </Form.Item>
                        <Form.Item
                            name='question'
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Question'),
                                }
                            ]}
                            label='Question'
                            required
                            initialValue={copyQuestion?.question}
                        >
                            <Input placeholder='e.g What is capital of Neatherland?' />
                        </Form.Item>

                        <Form.Item label='Select type of answer you want from the guest'>
                            <Radio.Group onChange={onChange} value={ansType}>
                                <Radio value={'input'}>Input</Radio>
                                <Radio value={'radio'}>Radio options</Radio>
                                <Radio value={'multiple'}>Select Multiple options</Radio>
                                <Radio value={'single'}>Select single option</Radio>
                            </Radio.Group>
                        </Form.Item>

                        {ansType === 'input' ? "" : ansType === 'radio' ?
                            <Form.List
                                name='answerList2'
                                rules={[
                                    {
                                        validator: async (_, names) => {
                                            if (!names || names.length < 2) {
                                                return Promise.reject(new Error('At least 2 options'));
                                            }
                                        },
                                    },
                                ]}
                            initialValue={ansList}
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, name, index) => (
                                            <Form.Item
                                                // label={index === 0 ? 'options' : null}
                                                required={false}
                                                key={field.key}
                                            >
                                                <Form.Item
                                                    {...field}
                                                    name={[name, 'option']}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: 'Please input options or delete this field.',
                                                        },
                                                    ]}
                                                    noStyle
                                                >
                                                    <Input placeholder='Option' style={{ width: '90%' }} />
                                                </Form.Item> &nbsp;
                                                {fields.length > 1 ? (
                                                    <MinusCircleOutlined
                                                        className='dynamic-delete-button'
                                                        onClick={() => remove(field.name)}
                                                    />
                                                ) : null}
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type='dashed'
                                                onClick={() => add()}
                                                style={{ width: '100%' }}
                                                icon={<PlusOutlined />}
                                            >
                                                Add field
                                            </Button>

                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List> : ansType === 'multiple' ?
                                <Form.List
                                    name='answerList3'
                                    rules={[
                                        {
                                            validator: async (_, names) => {
                                                if (!names || names.length < 2) {
                                                    return Promise.reject(new Error('At least 2 options'));
                                                }
                                            },
                                        },
                                    ]}
                                initialValue={ansList}
                                >
                                    {(fields, { add, remove }, { errors = error1 }) => (
                                        <>
                                            {fields.map((field, name, index) => (
                                                <Form.Item
                                                    required={false}
                                                    key={field.key}
                                                >
                                                    <Form.Item
                                                        {...field}
                                                        name={[name, 'option']}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                                message: 'Please input options or delete this field.',
                                                            },
                                                        ]}
                                                        noStyle
                                                    >
                                                        <Input placeholder='Option' style={{ width: '90%' }} />
                                                    </Form.Item> &nbsp;
                                                    {fields.length > 1 ? (
                                                        <MinusCircleOutlined
                                                            className='dynamic-delete-button'
                                                            onClick={() => remove(field.name)}
                                                        />
                                                    ) : null}
                                                </Form.Item>
                                            ))}
                                            <Form.Item>
                                                <Button
                                                    type='dashed'
                                                    onClick={() => add()}
                                                    style={{ width: '100%' }}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Add field
                                                </Button>

                                                <Form.ErrorList errors={error1} />
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List> :
                                <Form.List
                                    name='answerList4'
                                    rules={[
                                        {
                                            validator: async (_, names) => {
                                                if (!names || names.length < 2) {
                                                    return Promise.reject(new Error('At least 2 options'));
                                                }
                                            },
                                        },
                                    ]}
                                initialValue={ansList}
                                >
                                    {(fields, { add, remove }, { errors = error2 }) => (
                                        <>
                                            {fields.map((field, name, index) => (
                                                <Form.Item
                                                    // label={index === 0 ? 'options' : ''}
                                                    required={false}
                                                    key={field.key}
                                                >
                                                    <Form.Item
                                                        {...field}
                                                        name={[name, 'option']}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                                message: 'Please input options or delete this field.',
                                                            },
                                                        ]}
                                                        noStyle
                                                    >
                                                        <Input placeholder='Option' style={{ width: '90%' }} />
                                                    </Form.Item> &nbsp;
                                                    {fields.length > 1 ? (
                                                        <MinusCircleOutlined
                                                            className='dynamic-delete-button'
                                                            onClick={() => remove(field.name)}
                                                        />
                                                    ) : null}
                                                </Form.Item>
                                            ))}
                                            <Form.Item>
                                                <Button
                                                    type='dashed'
                                                    onClick={() => add()}
                                                    style={{ width: '100%' }}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Add field
                                                </Button>

                                                <Form.ErrorList errors={error2} />
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                        }
                        <br />
                        <Form.Item >
                            <Button
                                htmlType='submit'
                                style={{ borderRadius: 4, width: '100%' }}
                                type='primary'
                                loading={loading}
                            >
                                Submit
                            </Button>
                            <Button
                                style={{ borderRadius: 4, marginTop: 6 }}
                                block
                                onClick={() => { onCancel(); setCopyQuestion({}) }}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div >
    )
}

export default AddQuestions