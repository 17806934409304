import { Image, Select, Table } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN } from '../../../utils/apis'
import { convertTitle } from '../../../utils/helpers'
const { Option } = Select

const BuGiftCards = (props) => {
    const { user, userActions } = props
    const buUsers = useSelector(state => state?.adminReducer?.buUsers || [])
    const [selectedUser, setSelectedUser] = useState(buUsers?.[0]?._id || null)
    const [requests, setRequests] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (buUsers?.length) {
            getRequests()
        }
    }, [selectedUser])

    const getRequests = () => {
        setLoading(true)
        axios.get(`${ADMIN.GET_ALL_GIFT_CARDS_FOR_ADMIN}/${selectedUser}`)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                setLoading(false)
                setRequests(data?.data)
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    const columns = [

        {
            title: 'User Name',
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: 'Gift Card Code',
            dataIndex: 'giftCardCode',
            key: 'giftCardCode',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount - b.amount,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>
        },
        {
            title: 'Image',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: (e) => <Image src={e} style={{ marginRight: 10, width: 86, height: 60 }} />
        },
        {
            title: 'Code',
            dataIndex: 'giftCardCode',
            key: 'giftCardCode',
        },
        {
            title: 'IsUsed',
            dataIndex: 'isUsed',
            key: 'isUsed',
            sorter: (a, b) => a.isUsed - b.isUsed,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? 'True' : 'False'}</p>
        },
        {
            title: 'isAdmin',
            dataIndex: 'isAdmin',
            key: 'isAdmin',
            sorter: (a, b) => a.isAdmin - b.isAdmin,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? 'True' : 'False'}</p>
        },
        {
            title: 'created',
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p>{moment(e).utc().format('YYYY-MM-DD')}</p>
        },
    ]

    let mapdata = requests?.map((v, i) => {
        console.log('userId:', v)
        return {
            userId: v?.isAdmin ? v?.adminId?.userName : v?.userId.fullName,
            giftCardCode: v?.giftCardCode,
            imageUrl: v?.imageUrl,
            isUsed: v?.isUsed,
            amount: v?.amount,
            isAdmin: v?.isAdmin,
            created: v?.created,
        }
    })

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20, width: 300 }}>
                <Select
                    showSearch
                    showArrow
                    allowClear
                    defaultValue={buUsers[0]?._id}
                    onChange={(e) => setSelectedUser(e)}
                    className='form-input'
                    placeholder={'Select User'}
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                        option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {buUsers?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
                </Select>
            </div>
            <Table
                loading={loading}
                dataSource={mapdata}
                columns={columns}
                pagination={{ hideOnSinglePage: true }}
                scroll={{ x: 800 }}
            />
        </>
    )
}

export default BuGiftCards