import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { errorMessage, getAllUserData, successMessage } from '../../utils/helpers'
import { GET, POST, ADMIN } from '../../utils/apis'
import axios from 'axios';
import { Table, Button, DatePicker, Modal, Tooltip, Select, Image, Popconfirm } from 'antd'
import _ from 'lodash'

const Requests = (props) => {
    const { user, userActions } = props
    const [requests, setRequests] = useState([])
    const [loading, setLoading] = useState(false)
    const [detail, setDetail] = useState({})
    const [showDetails, setShowDetails] = useState(false)
    const [giftCardDetail, setGiftCardDetail] = useState({})

    useEffect(() => {
        getRequests()
    }, [])

    const getRequests = () => {
        setLoading(true)
        axios.get(`${GET.GET_USER_GIFT_CARD_CLAIM_REQUEST}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setRequests(data?.data)
                console.log('DATA****', data?.data)
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    const handleOk = () => {
        setShowDetails(false)
    }

    const columns = [
        {
            title: 'Gift Card Code',
            dataIndex: 'giftCardCode',
            key: 'giftCardCode',
        },
        {
            title: 'Requester Email',
            dataIndex: ['requesterId', 'email'],
            key: 'UserEmail',
        },
        {
            title: 'Requester Name',
            dataIndex: ['requesterId', 'fullName'],
            key: 'fullName',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (e) => (
                <>
                    <Button onClick={() => {
                        setGiftCardDetail(e?.giftCard)
                        setShowDetails(true)
                    }} > View Detail</Button >
                    &nbsp; &nbsp; &nbsp;
                    {
                        e?.isApproved == true ?
                            <Button disabled> Accepted</Button>
                            :
                            <Button disabled>pending</Button>
                    }
                </>
            )
        }
    ]

    return (
        <>
            <Table
                loading={loading}
                dataSource={requests}
                columns={columns}
                pagination={{ hideOnSinglePage: true }}
                scroll={{ x: 800 }}
            />
            <Modal title='Gift Card Details' visible={showDetails} onOk={handleOk} onCancel={handleOk} footer={null}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <ul>
                        <li><b>Series Name:</b> {giftCardDetail?.seriesName ? giftCardDetail?.seriesName : '-------'}</li>
                        <li><b>Code: </b> {giftCardDetail?.giftCardCode ? giftCardDetail?.giftCardCode : '-------'}</li>
                        <li><b>Amount: </b> {giftCardDetail?.amount ? giftCardDetail?.amount : '-------'} </li>
                        <li><b>Admin: </b> {giftCardDetail?.giftCardCode ? `${giftCardDetail?.isAdmin}` : '-------'}</li>
                        <li><b>Created At: </b>{giftCardDetail?.created ? moment(giftCardDetail?.created)?.format('YYYY-MM-DD') : '-------'} </li>
                    </ul>
                </div>
            </Modal>
        </>
    )
}

export default Requests