import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Form, Input, Button } from 'antd'
import { requiredMessage, inputPlace, successNotification, errorMessage } from '../../utils/helpers'
import { allPaths } from '../../utils/constants'
import { ADMIN } from '../../utils/apis'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import axios from 'axios'

const Login = (props) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const onFinish = (values) => {
        const { history } = props
        setLoading(true)
        axios.post(ADMIN.LOGIN, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification('Successfully Logged In!')
                    dispatch(loginUser(data.admin))
                    return setTimeout(() => {
                        history.replace(allPaths.ADMIN_SEGMENTS)
                    }, 300)
                }
                errorMessage(data.message)
            })
            .catch((e) => {
                setLoading(false)
                errorMessage()
            })
    }

    useEffect(() => {
        dispatch(removeUser())
    }, [])

    return (
        <div className='card-parent'>
            <div className='card1' style={{ marginTop: -100 }}>
                <p className='heading head-center'>Admin Portal</p>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    requiredMark={false}
                >
                    <Form.Item
                        name='userName'
                        label='User Name'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('User Name')
                            }
                        ]}
                    >
                        <Input
                            className='form-input'
                            placeholder={inputPlace('User Name')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='password'
                        label='Password'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('Password')
                            },
                            {
                                min: 6
                            }
                        ]}
                    >
                        <Input.Password
                            className='form-input'
                            placeholder={inputPlace('Password')}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type='primary'
                            className='form-button'
                            block
                            htmlType='submit'
                            loading={loading}
                        >
                            Login
                        </Button>
                    </Form.Item>
                </Form>
                {/* <p>
                    <Link to={allPaths.FORGOT}>Forgot Password</Link>
                </p> */}
            </div>
        </div>
    )
}

export default Login