import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { getAllUserData } from '../../utils/helpers'
import carousel1 from '../../assets/carousel1.jpg'
import carousel2 from '../../assets/carousel2.jpg'
import carousel3 from '../../assets/carousel3.jpg'
import NoAdvertisement from "../../assets/NoAdvertisement.jpg"
import { ADMIN, POST } from '../../utils/apis'
import axios from 'axios';
import { requiredMessage, inputPlace, successNotification, successMessage, errorMessage } from '../../utils/helpers'

const Home = (props) => {
    const { user, userActions } = props
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState([])


    let userType = `${user?.userType}`
    let userId = `${user?._id}`
    useEffect(() => {
        getAllUserData(user?._id, userActions)
        axios.get(`${ADMIN.GET_ALL_ACTIVE_ADMIN_ADVERTISEMENT}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setImage(data?.data || [])
            })
            .catch((e) => {
                setLoading(false)
            })
    }, [])

    return (
        <div className='card-form'>
            <div className='card2' style={{ minHeight: 'calc(100vh - 200px)', padding: 6 }}>
                <h2>Connecting people</h2>
                {userType ?
                    <Carousel
                        autoPlay
                        infiniteLoop
                        emulateTouch
                    >
                        {
                            image.length != 0 ? image.map((v, i) => {
                                return (
                                    <div key={i}>
                                        <img src={v?.imageUrl} alt='advertisement' />
                                    </div>
                                )
                            }) :
                                ''
                        }

                    </Carousel> :
                    <Carousel
                        autoPlay
                        infiniteLoop
                        emulateTouch
                    >
                        <div>
                            <img src={carousel1} />
                        </div>
                        <div>
                            <img src={carousel2} />
                        </div>
                        <div>
                            <img src={carousel3} />
                        </div>
                    </Carousel>
                }
            </div>
        </div>
    )
}

export default Home