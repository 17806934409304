import { Button, Descriptions, Modal, Popconfirm, Table } from 'antd';
import axios from 'axios';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { POST } from '../../utils/apis';
import { convertTitle, errorMessage, successMessage } from '../../utils/helpers';

const Draws = (props) => {
    const { user, userActions, drawData, loading1 } = props
    const [loading, setLoading] = useState(false)
    const [lotteryInfo, setLotteryInfo] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false)

    const showModal = () => {
        setIsModalVisible(true);
    };

    // useEffect(() => {
    //     // getAllUserData(user?._id, userActions)
    //     setfilteredArray(_.uniqBy(makArray, 'text'))

    // }, [drawData])

    const request = (e) => {
        setLoading(true)
        let valObj = { guestId: `${user?._id}`, userId: e?.userId?._id, lotteryCode: e?.lotteryCode, }

        axios.post(`${POST.WITH_DRAW_REQUEST}`, valObj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setLoading(false)
                    successMessage(data?.message)
                    // console.log('dataaaaget', data)
                } else {
                    setLoading(false)
                    errorMessage(data.message)
                }
            })
            .catch((e) => {
                setLoading(false)
            })
    }


    const columns = [
        // {
        //     title: 'Lottery Name',
        //     dataIndex: 'name',
        //     key: 'name',
        // },
        {
            title: 'Prize',
            dataIndex: 'prize',
            key: 'prize',
            // sorter: (a, b) => a.prize - b.prize,
            render: (e) => <img src={`${e}`} style={{ marginRight: 10, width: 86, height: 60 }}/>
        },
        {
            title: 'Lottery Code',
            dataIndex: 'lotteryCode',
            key: 'lotteryCode'
        },
        {
            title: 'Lottery type',
            dataIndex: 'lotteryType',
            key: 'lotteryType',
            sorter: (a, b) => a.lotteryType - b.lotteryType,
        },
        {
            title: 'WithDraw',
            dataIndex: 'withDraw',
            key: 'withDraw',
            sorter: (a, b) => a.withDraw - b.withDraw,
            render: (e) => <a>{e ? 'true' : 'false'}</a>

            // filters: filteredArray,
            // onFilter: (value, record) => record.date.indexOf(value) === 0
        }
        ,
        {
            title: 'Action',
            // dataIndex: 'ac',
            key: 'userId',
            render: (e) => (
                <>
                    <Button onClick={() => {
                        showModal()
                        // console.log(e, 'ar')
                        setLotteryInfo(e)
                    }}>
                        Detail
                    </Button>
                    &nbsp;&nbsp;&nbsp;

                    {
                        e?.withDraw ? 
                        <Button onClick={() => {
                        }}>
                            Claimed
                        </Button> :
                            <Popconfirm
                                title={`WithDraw ${convertTitle('this Lottery')}?`}
                                okText='Draw Request'
                                cancelText='Cancel'
                                // okButtonProps={{
                                //     type: 'primary',
                                //     className: 'form-button'
                                // }}
                                cancelButtonProps={{ style: { borderRadius: 4 } }}
                                onConfirm={() => { request(e) }}
                            >
                                <a > WithDraw</a>
                            </Popconfirm>
                    }


                </>
            )
        }
    ]
    const dataSource = drawData.map((info, i) => {
        return {
            userId: info.userId,
            name: info?.name,
            isClaimed: info?.isClaimed,
            lotteryCode: info?.lotteryCode,
            prize: info?.prize ,
            withDraw: info?.withDraw,
            lotteryType: info?.lotteryType === 'physicalLottery	' ? 'Physical Lottery' : 'Online Lottery',
            date: moment(info?.date).utcOffset(0, true).format('DD-MM-YYYY'),
        }
    })

    // const makArray = dataSource.map((v, i) => {
    //     return {
    //         text: v?.date,
    //         value: v?.date,
    //     }
    // })
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    // console.log('drawData',drawData)
    return (
        <>
            <Table
                loading={loading1}
                dataSource={dataSource}
                columns={columns}
                pagination={{ hideOnSinglePage: true }}
                scroll={{ x: 800 }}
            />
            {
                isModalVisible ?
                    < Modal title='Lottery Information' visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <Descriptions title=''>
                            <div key={123}>
                                <Descriptions.Item label='LotteryCode'>{`LotteryCode : ${lotteryInfo.lotteryCode}`}</Descriptions.Item><br />
                                <Descriptions.Item label='UserName'>{`UserName : ${lotteryInfo.userId?.fullName}`}</Descriptions.Item><br />
                                <Descriptions.Item label='StreetNumber'>{`StreetNumber : ${lotteryInfo.userId?.streetNumber}`}</Descriptions.Item><br />
                                <Descriptions.Item label='PostalCodeCity'>{`PostalCodeCity : ${lotteryInfo.userId?.postalCodeCity}`}</Descriptions.Item><br />
                                <Descriptions.Item label='country'>{`Country : ${lotteryInfo.userId?.country}`}</Descriptions.Item><br />
                                <Descriptions.Item label='TelNo'>{`TelNo : ${lotteryInfo.userId?.telNo}`}</Descriptions.Item><br />
                                {/* <Descriptions.Item label='Email'>{`Email : ${lotteryInfo.userId?.email}`}</Descriptions.Item><br /> */}
                            </div>

                        </Descriptions>
                    </Modal> : null}
        </>
    )
}

export default Draws