import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GET, POST, ADMIN } from '../../utils/apis'
import axios from 'axios';
import { Table, Button, DatePicker, Modal, Tooltip, Select, Image, Popconfirm } from 'antd'
import _ from 'lodash'
import AddGiftCards from './AddGiftCards'
import { createGiftCard } from '../../utils/helpers'
import AcceptGiftCards from './AcceptGiftCards';
import Requests from './Requests';
import MyGiftCards from './MyGiftCards';
// import GiftNotifications from './GiftNotifications'
// import GiftRequests from './GiftRequests'

const GiftCards = (props) => {
    const { user, userActions } = props
    const [showCreate, setShowCreate] = useState(false)
    const [loading, setLoading] = useState(false)
    const [cards, setCards] = useState([])
    const [showAcceptGiftCards, setShowAcceptGiftCards] = useState(false)
    const [remainingCards, setRemainingCards] = useState(0)
    const [showRequests, setShowRequests] = useState(false)
    const [showMyGiftCards, setShowMyGiftCards] = useState(false)
    console.log('user', user)
    useEffect(() => {
        getCards()
    }, [])
    const allStatesFalse = () => {
        setShowCreate(false)
        setShowAcceptGiftCards(false)
        setShowRequests(false)
        setShowMyGiftCards(false)
    }
    const getCards = () => {
        setLoading(true)
        axios.get(`${GET.GET_ALL_USER_GIFT_CARDS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                setLoading(false)
                setRemainingCards(data?.remainingCards)
                let tableData = Object.entries(data?.data)
                tableData = tableData?.map((v) => {
                    return {
                        id: v[0],
                        value: v[1]
                    }
                })
                setCards([...tableData])
            })
            .catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    let columns = [
        {
            title: 'Series Name',
            key: 'id',
            dataIndex: 'id',
            ellipsis: true,
            sorter: (a, b) => a.id.length - b.id.length,
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (e) => <div style={{ paddingTop: 15, marginTop: -5, display: 'flex', flexDirection: 'column' }}>
                <a onClick={() => createGiftCard(e?.value)}>Show Total Pdfs</a>
            </div>
        }
    ]

    return (
        <>
            <div className='flex-column menu-main'>
                {showCreate ?
                    // null
                    <AddGiftCards getCards={getCards} remainingCards={remainingCards} onCancel={() => setShowCreate(false)} {...props} />
                    :
                    <>
                        {!showAcceptGiftCards && !showRequests && !showMyGiftCards ?
                            <div className='button-end button-end-scroll'>
                                <Button
                                    type='primary'
                                    className='btn-end invite-guest invite-guest3'
                                    onClick={() => setShowRequests(true)}
                                >
                                    Request
                                </Button>
                                <Button
                                    type='primary'
                                    className='btn-end invite-guest invite-guest3'
                                    onClick={() => setShowMyGiftCards(true)}
                                >
                                    My Gift Cards
                                </Button>
                                < AcceptGiftCards {...props} />
                                <Button
                                    type='primary'
                                    className='btn-end invite-guest'
                                    onClick={() => setShowCreate(true)}
                                >
                                    Create Gift Cards
                                </Button>
                            </div>
                            :
                            <div className='button-end button-end-scroll'>
                                <Button
                                    type='primary'
                                    className='btn-end invite-guest invite-guest3'
                                    onClick={allStatesFalse}
                                >
                                    Back
                                </Button>
                            </div>
                        }
                        <div className='user-table'>

                            {
                            showRequests ?
                                <Requests {...props} />
                                :
                            showMyGiftCards ?
                                <MyGiftCards {...props} />
                                :
                                <Table
                                    loading={loading}
                                    dataSource={cards}
                                    columns={columns}
                                    pagination={{ hideOnSinglePage: true }}
                                    scroll={{ x: 800 }}
                                />
                            }
                        </div >
                    </>
                }
            </div >
        </>
    )
}

export default GiftCards