import { Button, Select, Table } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { ADMIN } from '../../../utils/apis';
import { convertTitle } from '../../../utils/helpers';

// const data = [
//   {
//     key: '1',
//     name: 'John Brown',
//     age: 32,
//     address: 'New York No. 1 Lake Park'
//   },
//   {
//     key: '2',
//     name: 'Jim Green',
//     age: 42,
//     address: 'London No. 1 Lake Park'
//   },
//   {
//     key: '3',
//     name: 'Joe Black',
//     age: 32,
//     address: 'Sidney No. 1 Lake Park'
//   },
//   {
//     key: '4',
//     name: 'Disabled User',
//     age: 99,
//     address: 'Sidney No. 1 Lake Park'
//   }
// ]
const { Option } = Select

export const Feedback = (props) => {

  const { user, onCancel } = props

  const forms = useSelector(state => state?.userReducer?.forms || [])
  const [loading, setLoading] = useState(false)
  const [feedbackData, setFeedbackData] = useState([])
  const [selectedForm, setSelectedForm] = useState(forms[0]?._id || null)
  const [formId, setFormId] = useState([])
  const [selectionType, setSelectionType] = useState('4');

  console.log('forms', forms)

  console.log('selectedForm', selectedForm)
  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {

  //     setSelectionType(`${selectedRowKeys}`);
  //   },
  //   getCheckboxProps: (record) => ({
  //     name: record.name
  //   })
  // };

  const getData = () => {
    setLoading(true)
    axios.get(`${ADMIN.GET_FEEDBACKS}/${selectedForm}`)
      .then((res) => {
        const { data } = res
        console.log('All feedback data', data)
        setFeedbackData(data?.data || [])
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }
  const columns = [
    {
      title: 'Form Type',
      dataIndex: ['formId', 'formType'],
    },
    {
      title: 'Form Type',
      dataIndex: ['formId', 'questionsType'],
    },
    {
      title: 'Age',
      dataIndex: 'age'
    },
    {
      title: 'Address',
      dataIndex: 'address'
    }
  ]
  useEffect(() => {
    getData()
  }, [selectedForm])

  console.log('feedbackData', feedbackData)
  return (
    <div className='flex-column menu-main'>
      <div className='user-table' style={{ marginLeft: 20 }}>
        <div className='flex-row' style={{ marginBottom: -30 }}>
          <Select
            className='btn-end'
            showSearch
            showArrow
            allowClear
            defaultValue={forms[0]?._id}
            onChange={(e) => setSelectedForm(e)}
            // className='form-input'
            placeholder={'Select User'}
            optionFilterProp='children'
            filterOption={(input, option) =>
              option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
            }
          >
            {forms?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.formName)}`}</Option>)}
          </Select>
          <Button
            type='primary'
            className='btn-end'
            onClick={() => onCancel()}
          >
            Back
          </Button>
        </div>
        <br />
        <br />
        <Table
          loading={loading}
          columns={columns}
          dataSource={feedbackData}
          pagination={{ hideOnSinglePage: true }}
          scroll={{ x: 800 }}
        />
      </div>
    </div>
  );
};


export default Feedback