import React, { useEffect, useState } from 'react'
import { Table, Button, Modal, DatePicker } from 'antd'
import moment from 'moment-timezone'
import _ from 'lodash'
import QRCode from 'qrcode.react'
import HTML2Canvas from 'html2canvas'
import { POST } from '../../utils/apis'
import axios from 'axios'

const { RangePicker } = DatePicker

const LotteryCodes = (props) => {
    const { user, onCancel } = props
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [download, setDownload] = useState(false)
    const [lotteryCode, setLotteryCode] = useState('')
    const [code, setCodes] = useState([])
    const [filteredArray, setfilteredArray] = useState([])
    const [dateRange, setDateRange] = useState({
        date1: moment().add(-1, 'months').format('YYYY-MM-DD'),
        date2: moment().format('YYYY-MM-DD')
    })

    useEffect(() => {
        getAllLotteries()
    }, [dateRange])

    useEffect(() => {
        if (download) {
            downloadQR()
        }
    }, [download])

    const getAllLotteries = () => {
        setLoading(true)
        let valObj = { userId: `${user?._id}`, date1: dateRange?.date1, date2: dateRange?.date2 }
        axios.post(`${POST.GET_USER_ALL_LOTTERIES}`, valObj)
            .then((res) => {
                const { data } = res
                setCodes(data?.data || [])
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
            })
    }

    const disableModal = () => setVisible(false)

    const downloadQR = async () => {
        let id = download ? 'qrCodeDownload' : 'qrCode'
        const canvas = document.getElementById(id)

        let html2Canvas = await HTML2Canvas(canvas, { allowTaint: true, useCORS: true })

        const pngUrl = html2Canvas.toDataURL()
        let downloadLink = document.createElement('a')
        downloadLink.href = pngUrl
        downloadLink.download = `lottery-code.png`
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)

        setDownload(false)
    }

    const showQR = () => {
        return (
            <Modal
                visible={visible}
                title='Lottery Code'
                onCancel={disableModal}
                footer={[
                    <Button key='back' onClick={disableModal}>
                        Close
                    </Button>
                ]}
            >
                <div
                    style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}
                >
                    <div id='qrCode' style={{ textAlign: 'center' }}>
                        <QRCode
                            value={lotteryCode}
                            size={250}
                            level={'H'}
                            includeMargin={true}
                        />
                        <p>{lotteryCode}</p>
                    </div>
                    <a onClick={downloadQR}> Download QR </a>
                </div>
            </Modal>
        )
    }

    const columns = [
        {
            title: 'Lottery Type',
            dataIndex: 'lotteryType',
            key: 'lotteryType',
            sorter: (a, b) => a.lotteryType - b.lotteryType,

        },
        {
            title: 'isClaimed',
            dataIndex: 'isClaimed',
            key: 'isClaimed',
            sorter: (a, b) => a.isClaimed - b.isClaimed,
            render: (e) => <span>{e ? 'true' : 'false'}</span>

        },
        {
            title: 'Lottery Code',
            dataIndex: 'lotteryCode',
            key: 'lotteryCode'
        },
        {
            title: 'Lottery QR Code',
            dataIndex: 'lotteryCode',
            key: 'lotteryQRCode',
            render: (e) => (
                <span>
                    <Button type='link' onClick={() => (setLotteryCode(e), setVisible(true))}>View</Button>
                    <Button type='link' onClick={() => (setLotteryCode(e), setDownload(true))}>Download</Button>
                </span>
            )
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created'
        }
    ]

    const createReportRange = (d) => {
        setDateRange({
            date1: d[0].format('YYYY-MM-DD'),
            date2: d[1].format('YYYY-MM-DD')
        })
        getAllLotteries()
    }

    const disabledDate = (current) => {
        let customDate = moment().format('YYYY-MM-DD')
        return current && current > moment(customDate, 'YYYY-MM-DD').add(1, 'day')
    }

    return (
        <div>
            {showQR()}
            <div
                style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}
            >
                <div
                    style={{ textAlign: 'center' }}
                    id='qrCodeDownload'
                    className='fixed-qr'
                >
                    <QRCode
                        value={lotteryCode}
                        size={250}
                        level={'H'}
                        includeMargin={true}
                    />
                    <p>{lotteryCode}</p>
                </div>
            </div>
            <div className='flex-mobile' style={{ display: 'flex' }}>
                <RangePicker
                    defaultValue={[moment().add(-1, 'months'), moment()]}
                    onChange={createReportRange}
                    style={{ marginBottom: 10 }}
                // disabledDate={disabledDate}
                />
            </div>
            <div className='button-end button-end-scroll'>
                <Button
                    type='primary'
                    className='btn-end invite-guest invite-guest3'
                    onClick={onCancel}
                >
                    Back
                </Button>

            </div>

            <div className='user-table'>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={code}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div>
        </div>
    )
}

export default LotteryCodes