import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import LotteryCodes from '../AdminLottery/LotteryCodes'
import { GET, POST, ADMIN } from '../../../utils/apis'
import axios from 'axios'
import { errorMessage, successMessage, getAllUserData } from '../../../utils/helpers'
import { Table, Button, DatePicker, Modal, Tooltip, Select } from 'antd'
import IconButton from '@mui/material/IconButton'
import _ from 'lodash'

import DoneAllIcon from '@mui/icons-material/DoneAll'
import CloseIcon from '@mui/icons-material/Close';

const { RangePicker } = DatePicker
const AdminCashback = (props) => {
    const { user, userActions } = props
    const [loading, setLoading] = useState(false)
    const [showDraw, setShowDraw] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [bankDetails, setBankDetails] = useState({})
    const [forms, setForms] = useState([])
    const [filteredArray, setfilteredArray] = useState([])

    const [dateRange, setDateRange] = useState({
        date1: moment().add(-1, 'months').format('YYYY-MM-DD'),
        date2: moment().format('YYYY-MM-DD')
    })
    useEffect(() => {
        getAllForms()
    }, [dateRange])
    useEffect(() => {
        setfilteredArray(_.uniqBy(buemailArray, 'text'))
    }, [forms])



    const getAllForms = () => {
        setLoading(true)
        let obj = { date1: dateRange?.date1, date2: dateRange?.date2 }
        axios.post(`${ADMIN.GET_ALL_CASH_BACKS}`, obj)
            .then((res) => {
                const { data } = res
                setForms(data?.data || [])
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
            })
    }
    const approveCashBackStatus = (e) => {
        let obj = {
            _id: e?._id,
            isPaid: true,
            adminId: user?._id
        }
        setLoading(true)
        axios.post(ADMIN.CONFIRM_CASH_BACK, obj)
            .then((res) => {
                const { data } = res
                // setForms(data?.data || [])
                setLoading(false)
                if (data.success) {
                    getAllForms()
                    successMessage(data?.message)
                }
                else {
                    errorMessage(data?.message)
                }
            })
            .catch((e) => {
                setLoading(false)
            })
    }
    const columns = [
        {
            title: 'Guest Name',
            dataIndex: 'guestId',
            key: 'guestId',
            render: (e) => <span>{e?.fullName}</span>
            // render: (e) => console.log(e?.guestId?.fullName)
        },
        {
            title: 'Guest Email',
            dataIndex: 'guestId',
            key: 'guestId',
            filters: filteredArray,
            onFilter: (value, record) => record.guestId?.email.indexOf(value) === 0,
            render: (e) => <span>{e?.email}</span>
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount - b.amount,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> € {e}</p>

        },
        {
            title: 'Service Charge',
            dataIndex: 'serviceCharge',
            key: 'serviceCharge',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> € {e}</p>

        },
        {
            title: 'Create Date',
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> {moment(e).utcOffset(0, true).format('DD-MM-YYYY')}</p>

        },
        {
            title: 'Status',
            key: 'action',
            sorter: (a, b) => a.action - b.action,
            render: (e) => (
                <>
                    <Button onClick={()=>{setBankDetails(e?.guestId);setShowDetails(true)}}>Details</Button>{
                        e?.isPaid === true ?
                            < IconButton style={{ color: 'green' }} disabled >
                                <DoneAllIcon />
                            </IconButton > :
                            < IconButton style={{ color: 'red' }} onClick={() => { approveCashBackStatus(e) }} >
                                <CloseIcon />
                            </IconButton >}
                </>
            )
        }
    ]

    const createReportRange = (d) => {
        setDateRange({
            date1: d[0].format('YYYY-MM-DD'),
            date2: d[1].format('YYYY-MM-DD')
        })
        getAllForms()
    }

    const buemailArray = forms.map((v, i) => {
        return {
            text: v?.guestId?.email,
            value: v?.guestId?.email
        }
    })

    const handleOk = ()=>{
        setShowDetails(false)
    }

    return (
        <>
            <div className='flex-column menu-main'>
                <div className='flex-mobile' style={{ display: 'flex' }}>
                    <RangePicker
                        defaultValue={[moment().add(-1, 'months'), moment()]}
                        onChange={createReportRange}
                        style={{ marginBottom: 10 }}
                    // disabledDate={disabledDate}
                    />
                </div>
                <div className='button-end button-end-scroll'>
                    {!showDraw ? <Button
                        type='primary'
                        className='btn-end invite-guest invite-guest3'
                        onClick={() => setShowDraw(true)}
                    >
                        {/* All Lottery Codes */}
                    </Button>
                        :
                        <Button
                            type='primary'
                            className='btn-end invite-guest invite-guest3'
                            onClick={() => setShowDraw(false)}

                        >
                            Back
                        </Button>}
                </div>
                <div className='user-table'>

                    <Table
                        loading={loading}
                        // loading={true}
                        dataSource={forms}
                        columns={columns}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 800 }}
                    />
                    <Modal title='Bank Details' visible={showDetails} onOk={handleOk}  onCancel={handleOk} footer={null}>
                        <ul>
                            <li>fullName: {bankDetails?.fullName} </li>
                            <li>bankAccount: {bankDetails?.bankAccount}</li>
                            <li>IBAN: {bankDetails?.IBAN}</li>
                        </ul>
                    </Modal>
                </div>
            </div>
        </>
    )
}

export default AdminCashback