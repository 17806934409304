import { Button, Popconfirm, Table, Tooltip } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GET, POST } from '../../utils/apis'
import { getAllUserData, successNotification, errorMessage } from '../../utils/helpers'
import AddCashback from './AddCashback'
import moment from 'moment'

const Cashback = (props) => {
  const { user, userActions } = props
  const [spin, setSpin] = useState({})
  const [form, setForm] = useState([])
  const [cashBackAmount, setCashBackAmount] = useState()
  const [loading, setLoading] = useState(false)
  const wallet = useSelector(state => state?.userReducer?.wallet || [])
  // console.log(wallet[0]?.amount)
  useEffect(() => {
    getAllUserData(user?._id, userActions)
    getCashBackForm()
  }, [])

  const getCashBackForm = () => {
    setLoading(true)
    axios.get(`${GET.GET_USER_CASH_BACK}/${user?._id}`)
      .then((res) => {
        const { data } = res
        setForm(data?.data || [])
        console.log('data?.data',data?.data)
        // console.log(data?.data, 'form')
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })

    axios.get(`${GET.GET_USER_CASH_BACK_AMOUNT}/${user?._id}`)
      .then((res) => {
        const { data } = res
        setCashBackAmount(data?.data || 0)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  const columns = [
    {
      title: 'Form Id',
      dataIndex: '_id',
      // sorter: true,
      ellipsis: true
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      // sorter: true,
      sorter: (a, b) => a.amount - b.amount,
      ellipsis: true,
      render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> € {e}</p>

      // render: (e) => <span>`${e }`</span>
    },
    {
      title: 'Status',
      key: 'isPaid',
      dataIndex: 'isPaid',
      // sorter: true,
      sorter: (a, b) => a.isPaid - b.isPaid,
      ellipsis: true,
      render: (e) => <span>{e ? 'Approved' : 'Pending'}</span>
    },
    {
      title: 'Created_At',
      key: 'created',
      dataIndex: 'created',
      // sorter: true,
      ellipsis: true,
      render: (e) => <span>{moment(e).utcOffset(0, true).format('DD-MM-YYYY')}</span>
    },

  ]

  // console.log('cashBackAmount', cashBackAmount)
  return (
    <div className='flex-column menu-main'>
      <div className='button-end button-end-scroll'>
        {wallet.length >= 1 && wallet[0]?.amount >= cashBackAmount ?
          <AddCashback getCashBackForm={getCashBackForm} reRender={() => { getAllUserData(user?._id, userActions) }} {...props} />

          :
          <Button type='primary' className='btn-end' onClick={
            () => errorMessage('Insufficient Balacnce in your Wallet')
          }>
            Cashback Request
          </Button>
        }</div>
      <div className='user-table'>
        <Table
          loading={loading}
          dataSource={form}
          columns={columns}
          pagination={{ hideOnSinglePage: true }}
          scroll={{ x: 800 }}
        />
      </div>
    </div>
  )
}

export default Cashback