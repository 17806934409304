import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { GET, POST } from '../../utils/apis'
import { Button, Popconfirm, Spin, Table, Tabs, DatePicker } from 'antd'
import { convertTitle, errorMessage, successMessage } from '../../utils/helpers'
import UpdateLottery from './UpdateLottery'
import moment from 'moment'
import _ from 'lodash'

const { RangePicker } = DatePicker


const LotteryWinners = (props) => {

    var dateObj = new Date()
    const todayDate = dateObj.toLocaleDateString('en-CA').slice(0, 7)

    const { user, onCancel } = props
    const [showCreateLottery, setShowCreateLottery] = useState(false)
    const [edit, setEdit] = useState({})
    const [loading, setLoading] = useState(false)
    const [lotteryWinner, setlotteryWinner] = useState([])
    const [filteredArray, setfilteredArray] = useState([])
    // const [dateRange, setDateRange] = useState({
    //     date1: moment().add(-1, 'months').format('YYYY-MM-DD'),
    //     date2: moment().format('YYYY-MM-DD')
    // })
    const [dateRange, setDateRange] = useState({
        date1: moment().format('YYYY-MM')
    })


    useEffect(() => {
        setfilteredArray(_.uniqBy(makArray, 'text'))
    }, [lotteryWinner])

    useEffect(() => {
        getLottery()
    }, [dateRange])

    const makArray = lotteryWinner.map((v, i) => {
        return {
            text: moment(v?.date).format('DD-MM-YYYY'),
            value: moment(v?.date).format('DD-MM-YYYY'),
        }
    })

    const getLottery = () => {
        setLoading(true)
        let valObj = { userId: `${user?._id}`, date1: dateRange?.date1 }
        // console.log('valObj in LW', valObj)
        axios.post(`${POST.GET_LOTTERY_PRIZE_WINNERS}`, valObj)
            .then((res) => {
                const { data } = res
                // console.log('data',data?.data)
                setlotteryWinner(data?.data)
                setLoading(false)
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
            })
    }

    const columns = [
        {
            title: 'Lottey Winner',
            dataIndex: ['guest', 'fullName'],
            key: 'fullName',
            sorter: (a, b) => a.fullName - b.fullName,

            // render: text => <a>{text}</a>
        },
        {
            title: 'Winner Email',
            dataIndex: ['guest', 'email'],
            key: 'Email',
            // sorter: (a, b) => a.fullName - b.fullName,

            // render: text => <a>{text}</a>
        },
        {
            title: 'Lottey Code',
            dataIndex: 'lotteryCode',
            key: 'lotteryCode',
            // render: text => <a>{text}</a>
        },
        {
            title: 'Prize',
            dataIndex: 'prize',
            key: 'prize',
            // sorter: (a, b) => a.prize - b.prize,
            render: (e) => <img src={`${e}`} style={{ marginRight: 10, width: 86, height: 60 }} />
        },
        {
            title: 'Double Chance',
            dataIndex: ['guest','doubleChances'],
            key: 'doubleChances',
            sorter: (a, b) => a.doubleChances - b.doubleChances,
            render: (e) => <a>{e ? 'true' : 'false'}</a>
        },
        {
            title: 'withDraw Date',
            dataIndex: 'date',
            key: 'date',
            // filters: filteredArray,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{moment(e).utcOffset(0, true).format('DD-MM-YYYY')}</p> ,
            // onFilter: (value, record) => record.date.indexOf(value) === 0,

            // render: text => <a>{text}</a>
        },

    ]

    const showEdit = (e) => {
        setEdit(e)
        setShowCreateLottery(true)
    }
    // console.log('lotteryWinner', lotteryWinner)

    const dataSource = lotteryWinner.map((info, i) => {

        return {
            key: info?._id,
            fullName: info?.guest?.fullName,
            guestEmail: info?.guest?.email,
            doubleChances: info?.guest?.doubleChances,
            name: info?.name,
            prize: info?.prize,
            lotteryCode: info?.lotteryCode,
            isClaimed: info?.isClaimed,
            lotteryType: info?.lotteryType,
            date: moment(info?.date).utcOffset(0, true).format('DD-MM-YYYY'),
        }
    })
    // console.log('dataSource', dataSource)

    const createReportRange = (d) => {
        setDateRange({
            date1: d?.format('YYYY-MM')
        })
    }
    let currentDate = moment().format('YYYY-MM')

    return (
        <>
            <div>
                <div className='flex-mobile' style={{ display: 'flex' }}>
                    {/* <RangePicker
                        defaultValue={[moment().add(-1, 'months'), moment()]}
                        onChange={createReportRange}
                        style={{ marginBottom: 10 }}
                    // disabledDate={disabledDate}
                    /> */}
                    <DatePicker
                        defaultValue={moment(currentDate, 'YYYY-MM')}
                        style={{ marginBottom: 10, width: '25%' }}
                        picker={'month'}
                        onChange={createReportRange}
                        allowClear={false}
                    />
                </div>
                <div className='button-end button-end-scroll'>
                    <Button
                        type='primary'
                        className='btn-end invite-guest invite-guest3'
                        onClick={onCancel}
                    >
                        Back
                    </Button>

                </div>

                <div className='user-table'>
                    <Table
                        loading={loading ? <Spin /> : ""}
                        columns={columns}
                        dataSource={lotteryWinner}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 800 }}
                    />
                </div>
            </div>
        </>
    )
}

export default LotteryWinners