import { Avatar, Button, Form, Input, Select } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ADMIN, GET, POST } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { errorMessage, inputPlace, requiredMessage, successNotification } from '../../utils/helpers'

const { Option } = Select

const AddGiftCards = (props) => {
    const { onCancel, edit, user, remainingCards, getCards,getUser,history,location } = props
    const [loading, setLoading] = useState(false)
    const [quantity, setQuantity] = useState(null)
    const [cardImages, setCardImages] = useState([])
    const [giftCardSubscription, setGiftCardSubscription] = useState({})
    const userId = new URLSearchParams(location.search).get('userId') || null


    const selectAmount = [5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100]

    const getGiftCardImages = () => {
        axios.get(`${ADMIN?.GET_GIFT_CARD_IMAGES}`)
            .then((res) => {
                const { data } = res
                setCardImages(data?.data?.filter((v) => v.active === true))
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    const getGiftCardsSubscription = () => {
        axios.get(GET.GIFT_CARD_SUBSCRIPTION)
            .then((res) => {
                const { data } = res
                let giftCards = data?.data[0]
                setGiftCardSubscription(giftCards || {})
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    const getPayementStatus = () => {
        if (userId) {
            setLoading(true)
            axios.get(`${GET.PAYMENT_STATUS}/${userId}`)
                .then((res) => {
                    const { data } = res
                    getUser()
                    setLoading(false)
                    history?.replace(allPaths.GIFT_CARD)
                    if (data.success) {
                        // getCards()
                        return successNotification(data.message)
                    }
                    errorMessage(data.message)
                })
                .catch((e) => {
                    setLoading(false)
                    errorMessage()
                })
        }

    }
    const onFinish = (values) => {
        setLoading(true)
        values.userId = user?._id
        values.imageUrl = cardImages[0]?.imageUrl
        values.giftCardsRate = giftCardSubscription?.amount
        values.giftCardsPrice = Number(values?.cardAmount)
        values.giftCardsQuantity = Number(values?.giftCardsQuantity)
        values.subscriptionName = giftCardSubscription?.subscriptionName
        values._id = giftCardSubscription?._id
        values.subscriptionTypes = giftCardSubscription?.subscriptionTypes
        axios.post(POST.GUEST_PURCHASE_GIFT_CARDS, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    window.location.href = data?.redirectUrl
                    return
                }
                errorMessage(data.message)
            })
            .catch((e) => {
                setLoading(false)
                errorMessage()
                console.log('e', e)
            })
    }

    useEffect(() => {
        getGiftCardImages()
        getGiftCardsSubscription()
        getPayementStatus()
        // getCards()
    }, [])

    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'> Create Gift Cards</p>
                    <Form
                        name='form'
                        onFinish={onFinish}
                        layout={'vertical'}
                        requiredMark={false}
                    >
                        <Form.Item
                            name='seriesName'
                            label='Series Name'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Input Series Name'
                                }
                            ]}
                        >
                            <Input
                                className='form-input'
                                placeholder='Please Input Series Name'
                            />
                        </Form.Item>
                        <Form.Item
                            name='giftCardsQuantity'
                            label='Quantity'
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Quantity')
                                }
                            ]}
                        >
                            <div>
                                <Input
                                    className='form-input'
                                    placeholder={inputPlace('Quantity')}
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value?.replace(/[^0-9]/g, ''))}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item
                            name='cardAmount'
                            label='Amount'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Amount'
                                }
                            ]}
                        >
                            <Select
                                showArrow
                                allowClear
                                className='form-input'
                                placeholder={`Select Amount`}
                                optionFilterProp='children'
                            >
                                {selectAmount?.map((v, i) => <Option value={v} key={i}>{v}</Option>)}
                            </Select>
                        </Form.Item>
                        {/* <Form.Item
                            name='imageUrl'
                            label='Image'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Image'
                                }
                            ]}
                        >
                            <Select
                                showArrow
                                allowClear
                                className='form-input'
                                placeholder={`Select Image`}
                                optionFilterProp='children'
                            >
                                {cardImages?.map((v, i) => <Option value={v?.imageUrl} key={i}><Avatar src={v?.imageUrl} style={{ marginRight: 10, width: 34, height: 24 }} />{v?.name}</Option>)}
                            </Select>
                        </Form.Item> */}
                        <Form.Item>
                            <Button
                                type='primary'
                                className='form-button'
                                block
                                htmlType='submit'
                                loading={loading}
                            >
                                Create
                            </Button>
                            <Button
                                style={{ borderRadius: 4, marginTop: 6 }}
                                block
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AddGiftCards