import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Spin, Table, DatePicker } from 'antd'
import { useEffect, useState } from 'react'
import { GET, POST } from '../../utils/apis'
import { convertTitle, errorMessage, successMessage } from '../../utils/helpers'
import LotteryWinners from './LotteryWinners'
import UpdateLottery from './UpdateLottery'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment-timezone'
import LotteryCodes from './LotterCodes'
import WithdrawRequest from './WithdrawRequest'

const { RangePicker } = DatePicker


const BusinessLottery = (props) => {
    var dateObj = new Date()
    // const todayDate = dateObj.toLocaleDateString('en-CA')
    const { user } = props
    const [showCreateLottery, setShowCreateLottery] = useState(false)
    const [showWithDrawForm, setShowWithDrawForm] = useState(false)
    const [showLotteryWinners, setShowLotteryWinners] = useState(false)
    const [showLotteryCodes, setShowLotteryCodes] = useState(false)
    const [edit, setEdit] = useState({})
    const [loading, setLoading] = useState(false)
    const [lottery, setlottery] = useState([])
    const [filteredArray, setfilteredArray] = useState([])
    const [dateRange, setDateRange] = useState({
        date1: moment().format('YYYY-MM')
    })

    const makArray = lottery?.map((v, i) => {
        return {
            text: v?.date,
            value: v?.date,
        }
    })

    useEffect(() => {
        setfilteredArray(_.uniqBy(makArray, 'text'))
    }, [lottery])

    useEffect(() => {
        getLottery()
    }, [dateRange])

    const getLottery = () => {
        setLoading(true)
        let valObj = { userId: `${user?._id}`, date1: dateRange?.date1 }
        axios.post(`${GET.GET_USER_LOTTERIES}`, valObj)
            .then((res) => {
                const { data } = res
                setlottery(data?.data)
                // console.log('dataaaaget', data)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
            })
    }

    const deleteLottery = (e) => {
        let getDays = getDaysDiff(moment(e.date).utcOffset(0, true).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')) >= 1 ? false : true

        if (!getDays) {
            errorMessage('Cannot Delete Lottery of Current Month')
        } else {
            // console.log('else')

            setLoading(true)
            let valObj = {
                _id: e?._id, userId: `${user?._id}`
            }
            axios.post(`${POST.DELETE_LOTTERY_PRIZE} `, valObj)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data.success) {
                        successMessage('Lottery Deleted Successfully!')
                        getLottery()
                    }
                })
                .catch((e) => {
                    setLoading(false)
                })
        }
    }

    const getDaysDiff = (start_date, end_date, date_format = 'YYYY-MM-DD') => {
        const getDateAsArray = (date) => {
            return moment(date.split(/\D+/), date_format);
        }
        return getDateAsArray(end_date).diff(getDateAsArray(start_date), 'days') + 1;
    }


    const columns = [
        {
            title: 'Lottey Prize',
            dataIndex: 'prize',
            key: 'prize',
            // sorter: (a, b) => a.prize - b.prize,
            render: (e) => <img src={`${e}`} style={{ marginRight: 10, width: 86, height: 60 }} />

        },
        {
            title: 'Prize Name',
            dataIndex: 'name',
            key: 'name',
            // filters: filteredArray,
            // onFilter: (value, record) => record.date.indexOf(value) === 0,
            // render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{moment(e).utcOffset(0, true).format('DD/MM/YYYY')}</p>
        },
        {
            title: 'Lottey Month',
            dataIndex: 'date',
            key: 'date',
            // filters: filteredArray,
            // onFilter: (value, record) => record.date.indexOf(value) === 0,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{moment(e).utcOffset(0, true).format('YYYY/MM')}</p>
        },
        {
            title: 'Private Lottery',
            dataIndex: 'isPublic',
            key: 'isPublic',
            sorter: (a, b) => a.isPublic - b.isPublic,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? 'True' : 'False'}</p>
            // filters: filteredArray,
            // onFilter: (value, record) => record.date.indexOf(value) === 0,
            // render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{moment(e).utcOffset(0, true).format('DD/MM/YYYY')}</p>
        },
        {
            title: 'Action',
            key: 'action',
            render: (e) => (
                <>
                    <Popconfirm
                        title={`Delete ${convertTitle("this Lottery")}?`}
                        okText='Delete'
                        cancelText='Cancel'
                        okButtonProps={{
                            type: 'primary',
                            className: 'form-button'
                        }}
                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                        onConfirm={() => { deleteLottery(e) }}
                    >
                        <a ><DeleteOutlined style={{ color: 'red' }} /> Delete</a>
                    </Popconfirm>

                    <a onClick={() => { showEdit(e) }}><EditOutlined style={{ marginLeft: 10 }} /> Edit</a>
                </>
            )
        }
    ]

    const showEdit = (e) => {
        let getDays = getDaysDiff(moment(e.date).utcOffset(0, true).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')) >= 1 ? false : true

        if (!getDays) {
            errorMessage('Cannot Edit Lottery of Current Month')
        } else {
            setEdit(e)
            setShowCreateLottery(true)
            getLottery()
        }
    }
    const createReportRange = (d) => {
        setDateRange({
            date1: d?.format('YYYY-MM')
        })
    }
    let currentDate = moment().format('YYYY-MM')
    return (
        <>
            <div className='flex-column menu-main'>
                {showCreateLottery ? <UpdateLottery getLottery={() => getLottery()} edit={edit} user={user} onCancel={() => (setShowCreateLottery(false), setEdit(null))} {...props} />

                    : showLotteryWinners ? <LotteryWinners user={user} onCancel={() => (setShowLotteryWinners(false), setEdit(null))} {...props} />
                        : showLotteryCodes ? <LotteryCodes user={user} onCancel={() => (setShowLotteryCodes(false), setEdit(null))} {...props} />
                            : showWithDrawForm ? <WithdrawRequest user={user} onCancel={() => (setShowWithDrawForm(false), setEdit(null))} {...props} />
                                :
                                <div>
                                    <div className='flex-mobile' style={{ display: 'flex' }}>
                                        {/* <RangePicker
                                            defaultValue={[moment().add(-1, 'months'), moment()]}
                                            onChange={createReportRange}
                                            style={{ marginBottom: 10 }}
                                            allowClear={false}
                                        /> */}
                                        <DatePicker
                                            defaultValue={moment(currentDate, 'YYYY-MM')}
                                            style={{ marginBottom: 10, width: '25%' }}
                                            picker={'month'}
                                            onChange={createReportRange}
                                            allowClear={false}
                                        />
                                    </div>
                                    <div className='button-end button-end-scroll'>
                                        <Button
                                            type='primary'
                                            className='btn-end invite-guest invite-guest3'
                                            onClick={() => setShowLotteryWinners(true)}
                                        >
                                            Lottery Winners
                                        </Button>
                                        <Button
                                            type='primary'
                                            className='btn-end invite-guest invite-guest3'
                                            onClick={() => setShowLotteryCodes(true)}
                                        >
                                            Lottery Codes
                                        </Button>
                                        <Button
                                            type='primary'
                                            className='btn-end invite-guest invite-guest3'
                                            onClick={() => setShowCreateLottery(true)}
                                        >
                                            Create Lottery
                                        </Button>
                                        <Button
                                            type='primary'
                                            className='btn-end invite-guest invite-guest3'
                                            onClick={() => setShowWithDrawForm(true)}
                                        >
                                            WithDraw Request
                                        </Button>
                                    </div>
                                    <div className='user-table'>
                                        <Table
                                            loading={loading}
                                            // loading={true}
                                            columns={columns}
                                            dataSource={lottery}
                                            pagination={{ hideOnSinglePage: true }}
                                            scroll={{ x: 800 }}
                                        />
                                    </div>
                                </div>}
            </div>
        </>
    )
}

export default BusinessLottery