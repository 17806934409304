import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import moment from 'moment-timezone'
import chunk from 'lodash/chunk'
import { Table, Button, DatePicker, Modal, Select } from 'antd'
import { getAllData, convertMessage, createLoyaltyCard, convertTitle } from '../../../utils/helpers'
import { ADMIN } from '../../../utils/apis'
import ViewCard from '../Cards/ViewCard'

const { RangePicker } = DatePicker
const { Option } = Select

const LoyaltyCards = (props) => {
    const { user, adminActions } = props
    const postalCards = chunk(useSelector(state => state?.adminReducer?.postalCards || []), 8)
    const buUsers = useSelector(state => state?.adminReducer?.buUsers || [])
    const [card, setCard] = useState({})
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({})
    const [guest, setGuest] = useState({})
    const [dateRange, setDateRange] = useState({
        // date1: moment.utc().format('YYYY-MM-DD'),
        date1: moment('2020-01-01').format('YYYY-MM-DD'),
        date2: moment.utc().add(7, 'days').format('YYYY-MM-DD')
    })
    const [userData, setUserData] = useState({})
    const [showData, setShowData] = useState(false)
    const [showMessageData, setShowMessageData] = useState(false)
    const [selectedUser, setSelectedUser] = useState(buUsers?.[0]?._id || null)
    const [loyaltyCards, setLoyaltyCards] = useState([])
    const [loyaltyAdvertisment, setLoyaltyAdvertisment] = useState({})

    useEffect(() => {
        getAllData(user?._id, adminActions)

        if (buUsers?.length) {
            getLoyaltyCards()
        }
    }, [])

    useEffect(() => {
        if (buUsers?.length) {
            getLoyaltyCards()
        }
    }, [selectedUser, dateRange])

    const getLoyaltyCards = () => {
        setLoading(true)
        let obj = {
            ...dateRange,
            userId: selectedUser
        }
        axios.post(`${ADMIN.GET_LOYALTY_CARDS}`, obj)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                setLoading(false)
                let tableData = Object.entries(data?.data)
                tableData = tableData?.map((v) => {
                    return {
                        id: v[0],
                        value: v[1]
                    }
                })
                setLoyaltyCards([...tableData])
                setLoyaltyAdvertisment(data?.loyaltyAdvertisment)
            })
    }

    const getLatestData = (d) => {
        let obj = {
            date1: d[0].format('YYYY-MM-DD'),
            date2: d[1].format('YYYY-MM-DD')
        }

        setDateRange(obj)
    }

    const onCancel = () => {
        setCard({})
        setShowData(false)
    }

    let columns = [
        {
            title: 'User Invoice Id',
            key: 'id',
            dataIndex: 'id',
            ellipsis: true,
            sorter: true
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (e) => <div style={{ paddingTop: 15, marginTop: -5, display: 'flex', flexDirection: 'column' }}>
                {/* <a onClick={() => createLoyaltyCard(e?.value, loyaltyAdvertisment)}>Show Total Pdfssss</a> */}
                <Button onClick={() => createLoyaltyCard(e?.value, loyaltyAdvertisment)}>Show Total Pdfssss</Button>
            </div>
        }
    ]

    return (
        <div className='flex-column menu-main'>
            <div className='user-table'>
                <h1 className='text-center'>Loyalty Cards</h1>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20, width: 300 }}>
                <h3>Date Range</h3>
                <RangePicker
                    defaultValue={[moment('2020-01-01','YYYY-MM-DD'), moment()]}
                    onChange={getLatestData}
                    allowClear={false}
                />
                <br />
                <Select
                    showSearch
                    showArrow
                    allowClear
                    defaultValue={buUsers[0]?._id}
                    onChange={(e) => setSelectedUser(e)}
                    className='form-input'
                    placeholder={'Select User'}
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                        option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {buUsers?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
                </Select>
            </div>
            <div className='user-table'>
                <Table
                    columns={columns}
                    dataSource={loyaltyCards}
                    loading={loading}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div>
            {showData ? <ViewCard card={card} onCancel={onCancel} showData={showData} /> : null}

            <Modal
                title='Dates And Messages'
                visible={showMessageData}
                onCancel={() => setShowMessageData(false)}
                className='modal-view'
                footer={[
                    <Button
                        key='back'
                        onClick={() => setShowMessageData(false)}
                        className='form-input'
                    >
                        Return
                    </Button>
                ]}
            >
                <div>
                    <h1>Message: </h1>
                    <h3>{convertMessage(message, guest, userData)}</h3>
                </div>
            </Modal>
        </div>
    )
}

export default LoyaltyCards