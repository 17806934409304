import { Button, Image, Table } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { ADMIN } from '../../../utils/apis'
import { successMessage, successNotification } from '../../../utils/helpers'

const GiftCardImages = (props) => {
    const { user, userActions } = props
    const [requests, setRequests] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectionType, setSelectionType] = useState([])
    const [spin, setSpin] = useState({})

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'image',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: (e) => <Image src={e} style={{ marginRight: 10, width: 86, height: 60 }} />
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p>{moment(e).utc().format('YYYY-MM-DD')}</p>
        }
        ,
        {
            title: 'Action',
            key: 'action',
            render: (e) => (<Button onClick={() => deleteImages(e?._id)}>Delete </Button>)
        }
    ]

    const requestsFilter = requests.map((v, i) => {
        return {
            name: v?.name,
            key: v?._id,
            imageUrl: v?.imageUrl,
            _id: v?._id,
            active: v?.active,
            enabled: false
        }
    })

    let idArray = []

    requestsFilter?.filter((a, i) => {
        if (a.active == true) {
            idArray.push(a.key)
        }
    })
    const getKey = () => {
        setSelectionType(idArray)
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectionType(`${selectedRowKeys[0]}`)

            // let getActiveId = selectedRowKeys[selectedRowKeys?.length - 1]
            // setLoading(true)

            let updateAddId = selectedRows[0]._id

            setLoading(true)

            let val = { _id: updateAddId, adminId: `${user?._id}` }
           
            axios.post(`${ADMIN?.SET_ACTIVE_GIFT_CARD_IMAGE}`, val)
                .then((res) => {
                    const { data } = res
                    setSpin({})
                    if (data?.success) {
                        successNotification(data?.message)
                        getRequests()

                        // if (diff?.length) {
                        //     successNotification('De activated Successfully!')
                        //     getRequests()
                        // } else {
                        //     successNotification('Activated Successfully!')
                        //     getRequests()
                        // }
                    }
                    setLoading(false)
                })
                .catch((e) => {
                    console.log(
                        'error', e
                    )
                    setSpin({})
                    setLoading(false)
                })
        }
    }
    useEffect(() => {
        getKey()
    }, [requests])

    useEffect(() => {
        getRequests()
    }, [])

    const getRequests = () => {
        setLoading(true)
        axios.get(`${ADMIN?.GET_GIFT_CARD_IMAGES}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setRequests(data?.data)
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    const deleteImages = (e) => {
        let obj = { _id: e, adminId: user?._id }
        console.log('OBJ', obj
        )
        axios.post(`${ADMIN.DELETE_GIFT_CARD_IMAGES}`, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                successMessage(data?.message)
                getRequests()
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    return (
        <Table
            rowSelection={{
                type: 'radio',
                selectedRowKeys: selectionType,
                ...rowSelection
            }}
            loading={loading}
            dataSource={requestsFilter}
            columns={columns}
            pagination={{ hideOnSinglePage: true }}
            scroll={{ x: 800 }}
        />
    )
}

export default GiftCardImages