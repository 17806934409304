import { ClassNames } from '@emotion/react'
import { Upload, Modal, Button } from 'antd'
import React, { useState } from 'react'
import { UploadOutlined, FileOutlined } from '@ant-design/icons'

const UploadFile = (props) => {
    const { field, fields, key, myObj, setfinalObj ,setFormObj} = props
    const file_type = ['.jpg', 'jpeg', '.png', '.svg', '.mp4']
    const [previewTitle, setPreviewTitle] = useState('')
    const [previewImage, setPreviewImage] = useState('')

    const [previewVisible, setPreviewVisible] = useState(false)

    const [fileList, setFileList] = useState([])

    const handleCancel = () => setPreviewVisible(false)



    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)

            reader.onload = () => resolve(reader.result)

            reader.onerror = (error) => reject(error)
        })


    const onChange = (info) => {
        var abc = [field].toString()
        setFileList(info?.fileList)
        myObj[abc] = { 'prizeLength': info?.fileList?.length, 'prizes': info?.fileList }
        console.log('myobj upload',myObj)
        setFormObj(myObj)
    }

    const handlePreview = async (file) => {

        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }

        setPreviewImage(file.url || file.preview)
        setPreviewVisible(true)
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        )
    }
    return (
        // <ImgCrop rotate>
        <div style={{ textAlign: 'start' }}>
            <Upload
                key={key}
                accept='image/*'
                beforeUpload={(file) => {
                    // conosle.log({ file })
                    return false
                }}
                multiple="true"
                // action='http://localhost:3000/'
                // listType='picture-card'
                fileList={fileList}
                onChange={onChange}
                // onPreview={handlePreview}
            >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
                {/* {fileList.length < 8 && '+ Upload'} */}
            </Upload>
            <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </div>
        // </ImgCrop>
    )
}

export default UploadFile