import { Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { GET } from '../../utils/apis'
import { convertDate } from '../../utils/helpers'

const WithdrawalRequests = (props) => {
    const { user } = props
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        getWithdrawalRequests()
    }, [])
    const getWithdrawalRequests = () => {
        setLoading(true)
        axios.get(`${GET.GET_ALL_LOYALTY_WITHDRAW_REQUEST}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    setData(data?.data)
                }
            })
            .catch((e) => {
                setLoading(false)
            })
    }
    let columns = [
        {
            title: 'BU Name',
            dataIndex: ['userId', 'businessName'],
            key: 'fullName',
            sorter: (a, b) => a.fullName - b.fullName,
        },
        {
            title: 'Date',
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{convertDate(e)}</p>
        },
        {
            title: 'Amount Requested',
            dataIndex: 'withdrawAmount',
            key: 'withdrawAmount',
            sorter: (a, b) => a.withdrawAmount - b.withdrawAmount,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>
        },
        {
            title: 'Number of Coupons',
            dataIndex: 'coupons',
            key: 'coupons',
            sorter: (a, b) => a.coupons - b.coupons,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e?.length}</p>
        },
        {
            title: 'Status',
            dataIndex: 'isApproved',
            key: 'isApproved',
            sorter: (a, b) => a.isApproved - b.isApproved,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? 'Approved' : 'Pending'}</p>
        }
    ]
    return (
        <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={{ hideOnSinglePage: true }}
        // scroll={{ x: 1000 }}
        />
    )
}

export default WithdrawalRequests