import { DeleteOutlined, EditTwoTone } from '@ant-design/icons'
import { Button, Image, Popconfirm, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { GET, POST } from '../../utils/apis'
import { convertTitle, successNotification, getAllUserData } from '../../utils/helpers'
import AddAdvertisement from './AddAdvertisement'
import LoyaltyAdvertisement from './LoyaltyAdvertisement'
import { useSelector } from 'react-redux'

const Advertisement = (props) => {
  const { user, userActions } = props
  const [loading, setLoading] = useState(false)
  const [advertisementData, setAdvertisementData] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [lCard, setlCard] = useState(false)
  const [edit, setEdit] = useState({})

  const [spin, setSpin] = useState({})
  const [selectionType, setSelectionType] = useState('')
  const advertisements = useSelector(state => state?.userReducer?.advertisements || [])

  useEffect(() => {
    getAllUserData(user?._id, userActions)
    getAllData()
    getKey()
  }, [])

  const getAllData = () => {
    setLoading(true)
    axios.get(`${GET?.ADVERTISEMENT}/${user?._id}`)
      .then((res) => {

        setAdvertisementData(res?.data?.data)

        setSelectionType(res?.data?.data?.map((v, i) => {
          return {
            name: v?.name,
            key: v?._id,
            imageUrl: v?.imageUrl,
            _id: v?._id,
            activeAdd: v?.active,
            enabled: false
          }
        }).filter((a, i) => {
          return a.activeAdd === true
        })[0].key)

        setLoading(false)
      }
      )

      .catch(e => {
        setLoading(false)
      })
  }
  const deleteAdvertisement = (_id) => {
    setSpin({ [_id]: true })

    let valObj = { _id, userId: `${user?._id}` }
    axios.post(`${POST.DELETE_ADVERTISEMENT}`, valObj)
      .then((res) => {
        const { data } = res
        setSpin({})
        if (data?.success) {
          successNotification('Successfully Deleted!')
          getAllData()
        }
      })
      .catch((e) => {
        setSpin({})
      })
  }

  const showEdit = (e) => {
    setEdit(e)
    setShowForm(true)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Image',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (e) => <Image src={`${e}`} style={{ marginRight: 0, width: 100, height: 60 }} shape='square' />
    },
    {
      title: 'Actions',
      key: 'action',
      width: 100,
      render: (e) => (
        <span>
         { !e.activeAdd ? <a onClick={() => showEdit(e)}><EditTwoTone /></a>:null}&nbsp;
          <Popconfirm
            title={`Delete ${convertTitle("this Advertisement")}?`}
            okText='Delete' 
            cancelText='Cancel'
            okButtonProps={{
              type: 'primary',
              className: 'form-button'
            }}
            cancelButtonProps={{ style: { borderRadius: 4 } }}
            onConfirm={() => deleteAdvertisement(e?._id)}
          >{!e.activeAdd ?
            <DeleteOutlined style={{ color: 'red' }} /> : ''}
          </Popconfirm>
        </span>)
    }
  ]



  const data1 = advertisementData.map((v, i) => {
    return {
      name: v?.name,
      key: v?._id,
      imageUrl: v?.imageUrl,
      _id: v?._id,
      activeAdd: v?.active,
      enabled: false
    }
  })

  const getActiveAddID = data1?.filter((a, i) => {
    return a.activeAdd == true;
  })
  const getKey = () => {

    const key = getActiveAddID[0]?.key
    setSelectionType(key)

    // console.log(`key`, key)
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {

      setSelectionType(`${selectedRowKeys[0]}`)

      let updateAddId = selectedRows[0]._id

      setLoading(true)

      let val = { _id: updateAddId, userId: `${user?._id}` }
      axios.post(`${POST.ACTIVE_ADVERTISEMENT}`, val)
        .then((res) => {
          const { data } = res
          setSpin({})
          if (data?.success) {
            setLoading(false)
            // console.log("data ==>", data)
            successNotification('Successfully Updated!')
            setSpin({})
            getAllData()
          }
        })
        .catch((e) => {
          setSpin({})
          setLoading(false)
        })
    },
  };

  return (
    <div className='flex-column menu-main'>
      {showForm ? (
        <AddAdvertisement
          edit={edit}
          onCancel={() => (setShowForm(false), setEdit(null))}
          getAllData={() => getAllData()}
          {...props}
        />)
        : !showForm && !lCard ? (
          <div>
            <div className='button-end button-end-scroll'>
              <Button
                type='primary'
                className='btn-end invite-guest invite-guest2'
                onClick={() => setShowForm(true)}
              >
                Create Advertisement
              </Button>
              <Button
                type='primary'
                className='btn-end'
                onClick={() => setlCard(true)}
              >
                Loyalty Card Advertisement
              </Button>
            </div>

            <div className='user-table'>
              <Table
                columns={columns}
                loading={loading}
                dataSource={advertisementData ? data1 : []}
                rowSelection={{
                  type: "radio",
                  selectedRowKeys: [selectionType],
                  ...rowSelection
                }}
                pagination={{ hideOnSinglePage: true, pageSize: 5 }}
                scroll={{ x: 800 }} />
            </div>
          </div>

        ) : <LoyaltyAdvertisement {...props} setlCard={() => setlCard(false)} />}
    </div>
  )
}

export default Advertisement