import React, { useState, useRef } from 'react'
import { Form, Input, Button, Select, Upload, Image } from 'antd'
import { UploadOutlined, FileOutlined } from '@ant-design/icons'
import { requiredMessage, inputPlace, successMessage, errorMessage, warningMessage } from '../../utils/helpers'
import axios from 'axios'
import { AUTH } from '../../utils/apis'
import countryCodes from '../../utils/countryCodes'

const { Option } = Select

const UserSettings = (props) => {
    const { user, authActions } = props
    const [loading, setLoading] = useState(false)
    const [btnText, setBtnText] = useState('Update Profile')
    const [fileList, setFileList] = useState([])
    const [country, setCountry] = useState(user?.country || 'Netherlands')
    const [countryCode, setCountryCode] = useState(countryCodes.filter(v => v?.code === user?.country)?.[0]?.dial_code || '+31')
    const formRef = useRef(null)

    const onFinish = (values) => {
        values.file = fileList
        values._id = user._id

        if (!values._id && !values?.file?.length) {
            return warningMessage('Please Upload Logo!')
        }

        let formData = new FormData()

        if (values?.file?.length) {
            formData.append('logo', values?.file[0])
        }

        for (var [k, v] of Object.entries(values)) {
            if (k && v) {
                formData.append(k, v)
            }
        }

        setLoading(true)
        setBtnText('Updating...')
        axios.post(AUTH.UPDATE_SETTINGS, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                // console.log('data', data)
                setBtnText('Update Profile')
                if (data.success) {
                    successMessage('Profile Updated Successfully!')
                    authActions.loginUser(data.user)
                    return window.location.reload()
                }
                errorMessage(data.message)
            })
            .catch((e) => {
                setLoading(false)
                setBtnText('Update Profile')
                errorMessage()
            })
    }

    const updateCountry = (e) => {
        let selectedCode = countryCodes.filter(v => v?.code === e)[0].dial_code

        setCountryCode(selectedCode)

        formRef.current.setFieldsValue({ prefix: selectedCode })
    }

    const prefixSelector = () => {
        return (
            <Form.Item name='prefix' noStyle>
                <Select
                    showSearch
                    disabled
                    style={{
                        minWidth: 80,
                        maxWidth: window.innerWidth > 500 ? 'auto' : 120
                    }}
                    defaultValue={countryCode}
                    value={countryCode}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {countryCodes?.map((v, i) => <Option value={v?.dial_code} key={i}>{`${v?.name} ${v?.dial_code}`}</Option>)}
                </Select>
            </Form.Item >
        )
    }

    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage('You can only upload Images!')
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    return (
        <div>
            <p className='setting-heading'>Profile Settings</p>
            <Form
                name='form'
                onFinish={onFinish}
                layout={'vertical'}
                requiredMark={false}
                ref={formRef}
            >
                <Form.Item
                    name='email'
                    label='Email'
                    initialValue={user?.email}
                >
                    <Input
                        className='form-input'
                        placeholder={inputPlace('Email')}
                        disabled
                    />
                </Form.Item>
                <Form.Item
                    name='file'
                    label='Logo'
                >
                    <Upload
                        name='file'
                        multiple={false}
                        beforeUpload={() => false}
                        accept='image/png, image/jpeg'
                        onChange={normFile}
                        fileList={fileList}
                    >
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                    {user?.logo ? <div style={{ display: 'flex', marginTop: 10, alignItems: 'center' }}><Image src={user?.logoUrl} height={80} />&nbsp;&nbsp;<FileOutlined />&nbsp;{user?.logo}</div> : null}
                </Form.Item>
                <Form.Item
                    name='fullName'
                    label='Full Name'
                    initialValue={user?.fullName}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: requiredMessage('Full Name')
                        }
                    ]}
                >
                    <Input
                        className='form-input'
                        placeholder={inputPlace('Full Name')}
                    />
                </Form.Item>
                <Form.Item
                    name='businessName'
                    label='Business Name'
                    initialValue={user?.businessName}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: requiredMessage('Business Name')
                        }
                    ]}
                >
                    <Input
                        className='form-input'
                        placeholder={inputPlace('Business name')}
                    />
                </Form.Item>
                <Form.Item
                    name='streetNumber'
                    label='Street + Number'
                    initialValue={user?.streetNumber}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: requiredMessage('Street + Number')
                        }
                    ]}
                >
                    <Input
                        className='form-input'
                        placeholder={inputPlace('Street + Number')}
                    />
                </Form.Item>
                <Form.Item
                    name='postalCodeCity'
                    label='Postal Code'
                    initialValue={user?.postalCodeCity}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: requiredMessage('Postal Code')
                        }
                    ]}
                >
                    <Input
                        className='form-input'
                        placeholder={inputPlace('Postal Code')}
                    />
                </Form.Item>
                <Form.Item
                    name='city'
                    label='City'
                    initialValue={user?.city ? user?.city : ''}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: requiredMessage('City')
                        }
                    ]}
                >
                    <Input
                        className='form-input'
                        placeholder={inputPlace('City')}
                    />
                </Form.Item>
                <Form.Item
                    name='country'
                    label='Country'
                    initialValue={user?.country}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: requiredMessage('Country')
                        }
                    ]}
                >
                    <Select
                        showSearch
                        showArrow
                        allowClear
                        className='form-input'
                        placeholder={`Select Country`}
                        optionFilterProp='children'
                        onChange={updateCountry}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {countryCodes?.map((v, i) => <Option value={v?.code} key={i}>{v?.name}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    name='telNo'
                    label='Tel No'
                    initialValue={user?.telNo}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: requiredMessage('Tel No')
                        }
                    ]}
                >
                    <Input
                        addonBefore={prefixSelector()}
                        className='form-input'
                        placeholder={`Tel No e.g 612345678`}
                    />
                </Form.Item>
                <Form.Item
                    name='remark'
                    label='Remark'
                    initialValue={user?.remark}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: requiredMessage('Remark')
                        }
                    ]}
                >
                    <Input
                        className='form-input'
                        placeholder={inputPlace('Remark')}
                    />
                </Form.Item>

                <Form.Item
                    name='bankAccount'
                    label='Name on Bank Account'
                    initialValue={user?.bankAccount ? user?.bankAccount : ''}
                    hasFeedback
                    rules={[
                        {
                            required: false,
                            message: requiredMessage('Name on your Bank Account')
                        }
                    ]}
                >
                    <Input
                        className='form-input'
                        placeholder={'What is name on your Bankaccount'}
                    />
                </Form.Item>
                <Form.Item
                    name='IBAN'
                    label='IBAN Number'
                    initialValue={user?.IBAN}
                    hasFeedback
                    rules={[
                        {
                            required: false,
                            message: requiredMessage('IBAN Number')
                        }
                    ]}
                >
                    <Input
                        className='form-input'
                        placeholder={inputPlace('IBAN Number')}
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        type='primary'
                        className='form-button'
                        block
                        htmlType='submit'
                        loading={loading}
                    >
                        {btnText}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default UserSettings