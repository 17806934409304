import React from 'react'
import BusinessLottery from './BusinessLottery'
import GuestLottery from './GuestLottery'

const Lottery = (props) => {
    const { user } = props

    return (
        <>
            {user?.userType === 'business' ? <BusinessLottery {...props} /> : <GuestLottery {...props} />}
        </>
    )
}

export default Lottery