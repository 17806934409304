import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { errorMessage, getAllUserData, successMessage, successNotification } from '../../utils/helpers'
import { GET, POST, ADMIN } from '../../utils/apis'
import axios from 'axios';
import { Table, Button, DatePicker, Modal, Tooltip, Select, Image, Popconfirm } from 'antd'
import _ from 'lodash'
import { allPaths } from '../../utils/constants'

const MyGiftCards = (props) => {
    const { user, userActions, history } = props
    const [requests, setRequests] = useState([])
    const [loading, setLoading] = useState(false)
    const [detail, setDetail] = useState({})
    const [showDetails, setShowDetails] = useState(false)
    const [giftCardDetail, setGiftCardDetail] = useState({})

    console.log('requests',requests)

    useEffect(() => {
        getRequests()
    }, [])

    const getRequests = () => {
        setLoading(true)
        axios.get(`${GET.GET_ALL_BUUSER_GIFT_CARDS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setRequests(data?.data)
                console.log('DATA****', data?.data)
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    const columns = [
        {
            title: 'Image',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: (e) => <Image src={e} style={{ marginRight: 10, width: 86, height: 60 }} />
        },
        {
            title: 'Code',
            dataIndex: 'giftCardCode',
            key: 'giftCardCode',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount - b.amount,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>
        },
        {
            title: 'Claimed',
            dataIndex: 'isClaimed',
            key: 'isClaimed',
            sorter: (a, b) => a.isClaimed - b.isClaimed,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? 'True' : 'False'}</p>
        },
        // {
        //     title: 'isAdmin',
        //     dataIndex: 'isAdmin',
        //     key: 'isAdmin',
        //     render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? 'True' : 'False'}</p>
        // },
        {
            title: 'Series',
            dataIndex: 'seriesName',
            key: 'seriesName',
            sorter: (a, b) => a.seriesName.length - b.seriesName.length,
        },
        {
            title: 'created',
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p>{moment(e).utc().format('YYYY-MM-DD')}</p>
        }
    ]

    return (
        <Table
            loading={loading}
            dataSource={requests}
            columns={columns}
            pagination={{ hideOnSinglePage: true }}
            scroll={{ x: 800 }}
        />
    )
}

export default MyGiftCards