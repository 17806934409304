import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Table, Button, Spin, Popconfirm, Modal, Space } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import QRCode from 'qrcode.react'
import AddQuestions from './AddQuestions'
import CreateForm from './CreateForm'
import ViewAllQuestions from './ViewAllQuestions'
import Feedback from './Feedback'
import { DeleteOutlined } from '@ant-design/icons'
import { POST } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { getAllUserData, successNotification, successMessage, convertTitle } from '../../utils/helpers'
import axios from 'axios'
import Reports from './Reports'

let origin = `https://2keepintouch.com`

if (process.env.NODE_ENV === 'development') {
  origin = `http://localhost:3000`
}

const Questions = (props) => {
  const { user, userActions } = props
  const [showQuestion, setShowQuestion] = useState(false)
  const [allQuestion, setAllQuestion] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [showFeedback, setShowFeedback] = useState(false)
  const [visible, setVisible] = useState(false)
  const [link, setLink] = useState(null)
  const [edit, setEdit] = useState({})
  const [copyQuestion, setCopyQuestion] = useState({})
  const [copyForm, setCopyForm] = useState({})
  const [loading, setLoading] = useState(false)
  const [reports, setReports] = useState(false)
  const [spin, setSpin] = useState({})
  const forms = useSelector(state => state?.userReducer?.forms || [])

  useEffect(() => {
    getAllUserData(user?._id, userActions)
  }, [showForm])

  const deleteForm = (_id) => {
    setSpin({ [_id]: true })
    setLoading(true)
    let valObj = { _id, userId: `${user?._id}` }
    axios.post(`${POST.DELETE_FORM}`, valObj)
      .then((res) => {
        const { data } = res
        setSpin({})
        if (data?.success) {
          getAllUserData(user?._id, userActions)
          successNotification('Successfully Deleted!')
          setLoading(false)
        }
      })
      .catch((e) => {
        setSpin({})
        setLoading(false)
      })
  }

  const disableModal = () => {
    setLink(null)
    setVisible(false)
  }

  const showQR = (url) => {
    setLink(url)
    setVisible(true)
  }

  const downloadQR = () => {
    let id = 'qrCodeQuestion'
    const canvas = document.getElementById(id)
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    let downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = `from.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const columns = [
    {
      title: 'Form Name',
      dataIndex: 'formName',
      key: 'formName',
      // render: text => <a>{text}</a>
    },
    {
      title: 'Form URL',
      dataIndex: '_id',
      key: '_id',
      render: (e) => {
        var url = `${origin}${allPaths.FORM}/${e}`
        return (
          <Space>
            <Button target={'_blank'} href={url} >Open URL</Button> &nbsp;&nbsp;
            <CopyToClipboard text={url}
              onCopy={() => successMessage('Successfully Copied to Clipboard!')}>
              <Button>Copy URL</Button>
            </CopyToClipboard>
            &nbsp;&nbsp;
            <Button onClick={() => showQR(url)}>QR Code</Button>
          </Space>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (e) => (
        <>
          <Space>

            <Popconfirm
              title={`Delete ${convertTitle('this Form')}?`}
              okText='Delete'
              cancelText='Cancel'
              okButtonProps={{
                type: 'primary',
                className: 'form-button'
              }}
              cancelButtonProps={{ style: { borderRadius: 4 } }}
              onConfirm={() => deleteForm(e?._id)}
            >
              <Button icon={<DeleteOutlined style={{ color: 'red' }} />}>
                Delete</Button>
            </Popconfirm>
            <Button onClick={() => { setCopyForm(e); setShowForm(true) }} >Copy</Button>
          </Space>
        </>
      )
    }
  ]

  return (
    <div className='flex-column menu-main'>
      {showQuestion && !showForm ?
        <AddQuestions openQuestions={()=>{setShowQuestion(false);setShowForm(false);setAllQuestion(true)}} copyQuestion={copyQuestion} setCopyQuestion={setCopyQuestion} onCancel={() => (setShowQuestion(false), setEdit(null))} {...props} /> :
        !showQuestion && showForm ? (
          <CreateForm openForm={()=>{setShowFeedback(false);setAllQuestion(false);setShowForm(false)}} copyForm={copyForm} setCopyForm={setCopyForm} createQuestion={() => (setShowQuestion(true), setShowForm(false))} onCancel={() => (setShowForm(false))} {...props} />) :
          !showQuestion && !showForm && allQuestion ?
            <ViewAllQuestions copyQuestion={copyQuestion} setCopyQuestion={setCopyQuestion} createForm={() => (setShowForm(true))} createQuestion={() => (setShowQuestion(true), setShowForm(false))} setAllQuestion={() => setAllQuestion(false)} {...props} />
            : !showQuestion && !showForm && !allQuestion && showFeedback ?
              <Feedback setShowFeedback={() => setShowFeedback(false)} {...props} />
              : reports ?
                <Reports onCancel={() => (setReports(false))} {...props} /> : <div>
                  <div className='button-end button-end-scroll'>
                    <Button
                      type='primary'
                      className='btn-end invite-guest invite-guest3'
                      onClick={() => setAllQuestion(true)}
                    >
                      View all Questions
                    </Button>
                    <Button
                      type='primary'
                      className='btn-end invite-guest'
                      onClick={() => setShowForm(true)}>
                      Create form
                    </Button>
                    <Button
                      type='primary'
                      className='btn-end invite-guest invite-guest3'
                      onClick={() => setShowQuestion(true)}
                    >
                      Create Question
                    </Button>
                    <Button
                      type='primary'
                      className='btn-end invite-guest invite-guest3'
                      onClick={() => setReports(true)}
                    >
                      Reports
                    </Button>
                  </div>

                  <div className='user-table'>
                    <Table
                      loading={loading}
                      columns={columns}
                      dataSource={forms}
                      pagination={{ hideOnSinglePage: true }}
                      scroll={{ x: 800 }}
                    />
                  </div>
                </div>
      }
      <Modal
        visible={visible}
        title='Form QR'
        onCancel={disableModal}
        footer={[
          <Button key='back' onClick={disableModal}>
            Close
          </Button>
        ]}
      >
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}>
          <QRCode
            value={link}
            size={250}
            id='qrCodeQuestion'
            level={'H'}
            includeMargin={true}
          />
          <a onClick={downloadQR}> Download QR </a>
        </div>
      </Modal>
    </div>
  )
}

export default Questions