import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Typography, Menu, Dropdown, Carousel, Select } from 'antd'
import axios from 'axios'
import { QrReader } from 'react-qr-reader'
import React, { useEffect, useState } from 'react'
import { POST, GET } from '../../utils/apis'
import { constraints } from '../../utils/constants'
import { errorMessage, getAllUserData, successMessage, provisionCalculate, successNotification } from '../../utils/helpers'
import moment from 'moment'
import { allPaths } from '../../utils/constants'

// import { Carousel } from 'react-responsive-carousel'
const { Option } = Select

const { Title } = Typography
const AcceptGiftCards = (props) => {
    const { location } = props
    const userId = new URLSearchParams(location.search).get('userId') || null
    const { user, userActions, getAllSavings, getUser, history } = props
    const [visible, setVisible] = useState(false)
    const [visibleQR, setVisibleQR] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState([])
    const [lottery, setLottery] = useState('')
    const [QRCode, setQRCode] = useState(null)
    const [giftCardDetail, setGiftCardDetail] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isPrizeInput, setPrizeInput] = useState(false)
    const [updatedPrize, setUpdatedPrize] = useState(0)

    const selectAmount = [5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100]


    useEffect(() => {
        getAllUserData(user?._id, userActions)
        getPayementStatus()

    }, [])
    const getPayementStatus = () => {
        if (userId) {
            setLoading(true)
            axios.get(`${GET.PAYMENT_STATUS}/${userId}`)
                .then((res) => {
                    const { data } = res
                    getUser()
                    setLoading(false)
                    history?.replace(allPaths.GIFT_CARD)
                    if (data.success) {
                        return successNotification(data.message)
                    }
                    errorMessage(data.message)
                })
                .catch((e) => {
                    setLoading(false)
                    errorMessage()
                })
        }

    }
    useEffect(() => {
        if (QRCode && !loading) {
            handleOk(QRCode)
        }
    }, [QRCode])

    const menu = (
        <Menu>
            <Menu.Item onClick={() => setVisible(true)} key='1'>Verify By Code</Menu.Item>
            <Menu.Item onClick={() => setVisibleQR(true)} key='2'>Verify By QR Code</Menu.Item>
        </Menu>
    )

    const handleOk = (code = null) => {
        setConfirmLoading(true)
        setLoading(true)

        axios.post(POST.VERIFY_GIFT_CARDS, { giftCardCode: lottery || code, userId: user?._id, userType: user?.userType })
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setGiftCardDetail(data?.data)
                    handleCancel()
                    setVisible(false)
                    setQRCode(null)
                    setVisibleQR(false)
                    setConfirmLoading(false)
                }
                else {
                    errorMessage(data?.message)
                }
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            })
            .catch((e) => {
                console.log('e ', e)
                setVisible(false)
                setConfirmLoading(false)
                setLoading(false)
                errorMessage()
            })

    }

    const handleCancel = () => {
        setVisible(false)
        setVisibleQR(false)
        showModal1()
        setLottery('')
    }
    const handleCancel2 = () => {
        setVisible(false)
        setVisibleQR(false)
        setLottery('')
    }

    const showModal1 = () => {
        setIsModalVisible(true)
    }

    const handlelotteryInput = (e) => {

        let value = e?.target?.value?.replace(/ /g, '')
        let event = e?.nativeEvent?.data

        if (event && ((value?.length === 3 || value?.length === 7) || (lottery?.length === 3 || lottery.length === 7))) {
            if (lottery?.length === 3 || lottery.length === 7) {
                value = `${lottery}-${event}`
            }
            else {
                value = `${value}-`
            }
        }

        setLottery(value)
    }
    const updateGiftCardPrize = () => {
        setLoading(true)
        let obj = {
            amount: Number(updatedPrize) + Number(provisionCalculate(Number(updatedPrize))) || 0,
            isAdmin: giftCardDetail.isAdmin,
            guestId: user?._id,
            giftCardCode: giftCardDetail?.giftCardCode,
            _id: giftCardDetail._id,
            totalProvision: Number(provisionCalculate(Number(updatedPrize)))
        }
        console.log('TEST PROVISION TOTAL', obj)

        // return true

        axios.post(POST?.CHANGE_PRIZE_OF_GIFT_CARDS, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    window.location.href = data?.redirectUrl
                    setIsModalVisible(false)
                    setGiftCardDetail({})
                    getPayementStatus()
                    return
                }
                else {
                    errorMessage(data?.message)
                }
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage()
            })
    }
    const scanQr = async qr => {
        let code = qr?.text

        if (code) {
            setQRCode(code)
        }
    }

    return (
        <div>
            <Dropdown overlay={menu} placement='bottomLeft'>
                <Button type='primary' className='btn-end invite-guest invite-guest3'>
                    Verify Gift Cards
                </Button>
            </Dropdown>
            <Modal
                title={<center>
                    <Title level={4}>Gift Card</Title>
                </center>}
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel2}
                footer={[
                    <Button key='back' onClick={handleCancel2} className='form-input'>
                        Cancel
                    </Button>,
                    <Button
                        key='ok'
                        onClick={handleOk}
                        disabled={lottery?.length !== 11}
                        className='form-input'
                        type='primary'
                        loading={loading}
                    >
                        Verify Gift Card
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4>Enter your Gift Card number</h4>
                    <Input
                        className='form-input'
                        autoFocus
                        required
                        maxLength={11}
                        value={lottery}
                        onChange={handlelotteryInput}
                        placeholder='e.g: 456-786-ab6'
                    />
                </div>
            </Modal>

            <Modal
                title={<center>
                    <Title level={4}>Activate QR</Title>
                </center>}
                visible={visibleQR}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel2}
                footer={[
                    <Button key='back' onClick={handleCancel2} className='form-input'>
                        Cancel
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4>Scan your QR</h4>
                    <QrReader
                        delay={1000}
                        onError={e => errorMessage('WebCam Not Found!')}
                        constraints={constraints}
                        // constraints={{facingMode: 'environment'}}
                        onResult={scanQr}
                        style={{ width: '100%' }}
                    />
                </div>
            </Modal>
            <Modal
                title={<center>
                    <Title level={4}>Gift Card Details</Title>
                </center>}
                // visible={true}
                visible={isModalVisible}
                // onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={() => { setPrizeInput(false); setIsModalVisible(false) }}
                footer={[
                    <>
                        {
                            giftCardDetail?.amount <= 0 && giftCardDetail?.isAdmin == true === !giftCardDetail?.guestId
                                ?
                                <>
                                    <Button key='back' onClick={() => setIsModalVisible(false)} className='form-input'>
                                        Cancel
                                    </Button>
                                    {
                                        !isPrizeInput ?
                                            <Button loading={loading} key='backs' onClick={() => {
                                                setPrizeInput(true)
                                            }} className='form-input'>
                                                Update Gift Card Prize?
                                            </Button>
                                            :
                                            <Button loading={loading} key='backs' onClick={() => {
                                                updateGiftCardPrize(giftCardDetail)
                                            }} className='form-input'>
                                                Update
                                            </Button>
                                    }
                                </>
                                :
                                <>
                                    <Button key='back' onClick={() => setIsModalVisible(false)} className='form-input'>
                                        Cancel
                                    </Button>
                                </>
                        }
                    </>
                ]}
            >
                {
                    !isPrizeInput ?
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <ul>
                                <li><b>Amount: </b>{giftCardDetail?.amount ? giftCardDetail?.amount : '0'} </li>
                                <li><b>Created At: </b>{giftCardDetail?.created ? moment(giftCardDetail?.created)?.format('YYYY-MM-DD') : '-------'} </li>
                                <li><b>Code: </b>{giftCardDetail?.giftCardCode ? giftCardDetail?.giftCardCode : '-------'}</li>
                                <li><b>Admin: </b>{giftCardDetail?.giftCardCode ? `${giftCardDetail?.isAdmin}` : '-------'}</li>
                                <li><b>Series Name:</b> {giftCardDetail?.seriesName ? giftCardDetail?.seriesName : '-------'}</li>
                            </ul>
                        </div> :
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <ul>
                                <li><b>Created At: </b>{giftCardDetail?.created ? moment(giftCardDetail?.created)?.format('YYYY-MM-DD') : '-------'} </li>
                                <li><b>Code: </b>{giftCardDetail?.giftCardCode ? giftCardDetail?.giftCardCode : '-------'}</li>
                                <li><b>Admin: </b>{giftCardDetail?.giftCardCode ? `${giftCardDetail?.isAdmin}` : '-------'}</li>
                                <li><b>Series Name:</b> {giftCardDetail?.seriesName ? giftCardDetail?.seriesName : '-------'}</li>
                                Amount:
                                <Select
                                    style={{ width: '100%' }}
                                    showArrow
                                    allowClear
                                    className='form-input'
                                    placeholder={`Select Amount`}
                                    optionFilterProp='children'
                                    // onChange={(e)=>console.log('selected',e)}
                                    onSelect={(e) => setUpdatedPrize(e)}

                                >
                                    {selectAmount?.map((v, i) => <Option value={v} key={i}>{v}</Option>)}
                                </Select>
                            </ul>
                        </div>
                }

            </Modal>
        </div >
    )
}

export default AcceptGiftCards