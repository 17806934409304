import { MinusCircleOutlined, PlusCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Form, Input, Row } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { ADMIN } from '../../../utils/apis'
import { errorMessage, inputPlace, requiredMessage, successNotification } from '../../../utils/helpers'

const AddGroup = (props) => {
    const { onCancel, edit, user } = props
    const [loading, setLoading] = useState(false)
    const [formComplete, setformComplete] = useState(false)
    const [secondRange, setsecondRange] = useState(edit?.postalCode?.length > 1 ? true : false)
    const [startPostal, setstartPostal] = useState(edit?._id ? edit?.postalCode[0]?.postalcodeStart : '')
    const [endPostal, setendPostal] = useState(edit?._id ? edit?.postalCode[0]?.postalcodeEnd : '')
    const [startPostal2, setstartPostal2] = useState(edit?.postalCode?.length > 1 ? edit?.postalCode[1]?.postalcodeStart : '')
    const [endPostal2, setendPostal2] = useState(edit?.postalCode?.length > 1 ? edit?.postalCode[1]?.postalcodeEnd : '')
    const [groupPassword, setGroupPassword] = useState(edit?.private ? edit?.private : false)

    // console.log('invitedUsersId', edit?.invitedUsersId?.length)
    const onChange = (e) => {
        setGroupPassword(e.target.checked)
    }

    const onFinish = (values) => {
        setLoading(true)
        values.adminId = user?._id
        delete values?.postalCode
        delete values?.postalCode2

        if (secondRange) {
            values.postalCode = [{ postalcodeStart: startPostal, postalcodeEnd: endPostal }, { postalcodeStart: startPostal2, postalcodeEnd: endPostal2 }]
        } else {
            values.postalCode = [{ postalcodeStart: startPostal, postalcodeEnd: endPostal }]
        }

        if (_.isEmpty(edit)) {
            axios.post(ADMIN.CREATE_GROUP, values)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data.success) {
                        successNotification(data?.message)
                        setLoading(false)
                        return setTimeout(() => {
                            onCancel()
                        }, 300)
                    }
                    errorMessage(data.message)
                })
                .catch((e) => {
                    setLoading(false)
                    errorMessage()
                })
        } else {
            values._id = edit?._id
            axios.post(ADMIN.EDIT_GROUP, values)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data.success) {
                        successNotification(data?.message)
                        return setTimeout(() => {
                            onCancel()
                        }, 300)
                    }
                    errorMessage(data.message)
                })
                .catch((e) => {
                    setLoading(false)
                    errorMessage()
                })
        }

    }

    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'>{edit?._id ? 'Update' : 'Create'} Group</p>
                    <Form
                        name='form'
                        onFinish={onFinish}
                        layout={'vertical'}
                    >
                        <Form.Item
                            name='name'
                            label='Group Name'
                            hasFeedback
                            initialValue={edit?.name}
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Group Name')
                                }
                            ]}
                        >
                            <Input
                                className='form-input'
                                placeholder={inputPlace('Group Name')}
                            />
                        </Form.Item>
                        <label>Postal Code</label>
                        <Form.Item
                            name='postalCode'
                            label='First Range'
                            hasFeedback
                            initialValue={groupPassword}
                            rules={[
                                {
                                    required: true,
                                    validator: async (_, names) => {
                                        if (formComplete && startPostal.length != 6) {
                                            return Promise.reject(new Error('Length of Postal Code must be of 6 digits'));
                                        }
                                        if (formComplete && endPostal.length != 6) {
                                            return Promise.reject(new Error('Length of Postal Code must be of 6 digits'));
                                        }
                                        if (formComplete && startPostal.localeCompare(endPostal, undefined, { numeric: true, sensitivity: 'base' }) != -1) {
                                            return Promise.reject(new Error('Start of the Range must be smaller'));
                                        }
                                    },
                                },
                            ]}

                        >
                            <Row gutter={24}>

                                <Col className='gutter-row' span={8}>
                                    <center><label >Start</label></center>
                                    <Input
                                        disabled={edit?.invitedUsersId?.length ? true : false}
                                        value={startPostal}
                                        onChange={(e) => setstartPostal(e.target.value.toUpperCase().replace(/[^a-z0-9]/gi, ''))}
                                        maxLength={6}
                                        // pattern= 
                                        className='form-input gutter-box'
                                        placeholder={'Start range'}
                                    // style='text-transform:uppercase'
                                    />
                                </Col>
                                <Col className='gutter-row' span={8}>
                                    <center><label >End</label></center>
                                    <Input
                                        disabled={edit?.invitedUsersId?.length ? true : false}
                                        onChange={(e) => setendPostal(e.target.value.toUpperCase().replace(/[^a-z0-9]/gi, ''))}
                                        value={endPostal}
                                        maxLength={6}
                                        className='form-input gutter-box'
                                        placeholder={'End range'}
                                    />
                                </Col>
                                {!secondRange && !edit?.invitedUsersId?.length ? <PlusCircleOutlined
                                    style={{ marginTop: 35 }}
                                    className='dynamic-delete-button'
                                    onClick={() => setsecondRange(true)}
                                /> : null}

                            </Row>
                        </Form.Item>

                        {secondRange ?
                            <Form.Item
                                name='postalCode2'
                                label='Second Range'
                                hasFeedback
                                initialValue={groupPassword}
                                rules={[
                                    {
                                        required: true,
                                        validator: async (_, names) => {

                                            let checkStart = (startPostal2.localeCompare(startPostal, undefined, { numeric: true, sensitivity: 'base' }) == 1 && startPostal2.localeCompare(endPostal, undefined, { numeric: true, sensitivity: 'base' }) == -1) || (startPostal.localeCompare(startPostal2, undefined, { numeric: true, sensitivity: 'base' }) == 1 && startPostal.localeCompare(endPostal2, undefined, { numeric: true, sensitivity: 'base' }) == -1);

                                            let checkEnd = (endPostal2.localeCompare(startPostal, undefined, { numeric: true, sensitivity: 'base' }) == 1 && endPostal2.localeCompare(endPostal, undefined, { numeric: true, sensitivity: 'base' }) == -1) || (endPostal.localeCompare(startPostal2, undefined, { numeric: true, sensitivity: 'base' }) == 1 && endPostal.localeCompare(endPostal2, undefined, { numeric: true, sensitivity: 'base' }) == -1);

                                            if (formComplete && (startPostal2 == startPostal || startPostal2 == endPostal || checkStart || checkEnd)) {
                                                return Promise.reject(new Error('Both Ranges must not intersect'));
                                            }
                                            if (formComplete && (endPostal2 == startPostal || endPostal2 == endPostal)) {
                                                return Promise.reject(new Error('Both Ranges must not intersect'));
                                            }
                                            if (formComplete && startPostal2.length != 6) {
                                                return Promise.reject(new Error('Length of Postal Code must be of 6 digits'));
                                            }
                                            if (formComplete && endPostal2.length != 6) {
                                                return Promise.reject(new Error('Length of Postal Code must be of 6 digits'));
                                            }
                                            if (formComplete && startPostal2.localeCompare(endPostal2, undefined, { numeric: true, sensitivity: 'base' }) != -1) {
                                                return Promise.reject(new Error('Start of the Range must be smaller'));
                                            }
                                        }
                                    }
                                ]}
                            >
                                <Row gutter={24}>
                                    <Col className='gutter-row' span={8}>
                                        <center><label >Start</label></center>
                                        <Input
                                            disabled={edit?.invitedUsersId?.length ? true : false}
                                            value={startPostal2}
                                            onChange={(e) => setstartPostal2(e.target.value.toUpperCase().replace(/[^a-z0-9]/gi, ''))}
                                            maxLength={6}
                                            // pattern= 
                                            className='form-input gutter-box'
                                            placeholder={'Start range'}
                                        // style='text-transform:uppercase'
                                        />
                                    </Col>
                                    <Col className='gutter-row' span={8}>
                                        <center><label >End</label></center>
                                        <Input
                                            disabled={edit?.invitedUsersId?.length ? true : false}
                                            onChange={(e) => setendPostal2(e.target.value.toUpperCase().replace(/[^a-z0-9]/gi, ''))}
                                            value={endPostal2}
                                            maxLength={6}
                                            className='form-input gutter-box'
                                            placeholder={'End range'}
                                        />
                                    </Col>
                                    {secondRange && !edit?.invitedUsersId?.length ?
                                        <MinusCircleOutlined
                                            // disabled={true}
                                            style={{ marginTop: 35 }}
                                            className='dynamic-delete-button'
                                            onClick={() => {
                                                setsecondRange(false);
                                                setstartPostal2();
                                                setendPostal2()
                                            }}
                                        /> : null}
                                </Row>
                            </Form.Item>
                            : null
                        }

                        {edit && edit?.invitedUsersId?.length ?
                            <Row><p> <InfoCircleOutlined /> The Range is uneditable as this Group contains Invited Users</p></Row>
                            : null
                        }
                        <Form.Item
                            label='Private?'
                            name='private'
                            onChange={onChange}
                            initialValue={groupPassword}
                            valuePropName='checked'>

                            <Checkbox>Private</Checkbox>

                        </Form.Item>

                        {groupPassword ?
                            <Form.Item
                                name='password'
                                label='Password'
                                hasFeedback
                                initialValue={edit?.password}
                                rules={[
                                    {
                                        required: true,
                                        message: requiredMessage('Password')
                                    }
                                ]}
                            >
                                <Input.Password
                                    autoComplete='new-password'
                                    className='form-input'
                                    placeholder={inputPlace('Password')}
                                />
                            </Form.Item> : null
                        }
                        <Form.Item>
                            <Button
                                type='primary'
                                className='form-button'
                                onClick={() => setformComplete(true)}
                                block
                                htmlType='submit'
                                loading={loading}
                            >
                                {edit?._id ? 'Update' : 'Create'}
                            </Button>
                            <Button
                                style={{ borderRadius: 4, marginTop: 6 }}
                                block
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

AddGroup.propTypes = {
    onCancel: PropTypes.func.isRequired
}

export default AddGroup