import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import axios from 'axios'
import { Form, Button, Input, message } from 'antd'
import { AUTH, GET } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { Page404 } from '../../Config/routes'

const UpdatePassword = (props) => {
    const { history } = props
    const path = history?.location?.pathname
    const [state, setState] = useState({
        loading: true,
        success: false,
        buttonLoading: false,
        user: null
    })

    // console.log('path === allPaths?.BU_UPDATE_PASSWORD ? allPaths?.BU_LOGIN : allPaths?.LOGIN', path?.indexOf(allPaths?.BU_UPDATE_PASSWORD) !== -1 ? allPaths?.BU_LOGIN : allPaths?.LOGIN)

    useEffect(() => {
        const { match } = props
        const { token } = match.params
        axios.get(`${GET.VERIFY_TOKEN}/${token}`)
            .then((result) => {
                const { data } = result
                if (data.success) {
                    updateState({ message: data.message, success: data.success, loading: false, user: data.user })
                }
                else {
                    updateState({ message: data.message, success: data.success, loading: false })
                }
            })
            .catch(e => {
                updateState({ message: 'Something Went Wrong Please Refresh this page', success: false, loading: false })
            })
    }, [])

    const updateState = (obj) => {
        setState({ ...state, ...obj })
    }

    const onFinish = (values) => {
        const { user } = state
        let redirectRoute = path?.indexOf(allPaths?.BU_UPDATE_PASSWORD) !== -1 ? allPaths?.BU_LOGIN : allPaths?.LOGIN

        if (user) {

            values.email = user?.email
            values.userType = path?.indexOf(allPaths?.BU_UPDATE_PASSWORD) !== -1 ? 'business' : 'guest'

            axios.post(AUTH.UPDATE_PASSWORD, values)
                .then((result) => {
                    const { data } = result
                    if (data.success) {
                        message.success('Password Successfully Changed')
                        setTimeout(() => {
                            history.push(redirectRoute)
                        }, 500)
                    }
                    else {
                        message.error(data.message)
                    }
                })
                .catch((err) => {
                    message.error('Something went wrong!')
                })
        }
        else {
            message.error('Something went wrong!')
        }
    }

    const onFinishFailed = (err) => {

    }

    return (
        <div>
            {!state.loading ? <div className='background-layout'>
                {state.success ? <div className='card-parent'>
                    <div className='card1'>
                        <p className='heading head-center'>Update Password</p>
                        <Form
                            name='basic'
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                name='password'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your new password!',
                                    },
                                ]}
                            >
                                <Input.Password className='form-input' placeholder='Enter your New Password!' />
                            </Form.Item>
                            <Form.Item
                                name='confirmPassword'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('The two passwords that you entered do not match!');
                                        },
                                    })
                                ]}
                            >
                                <Input.Password className='form-input' placeholder='Confirm your Password!' />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type='primary'
                                    htmlType='submit'
                                    block loading={state.buttonLoading}
                                    className='form-button'
                                >
                                    Update
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div> : <Page404 {...props} />}
            </div> : <Skeleton paragraph={{ rows: 22 }} />}
        </div>
    )
}

export default UpdatePassword