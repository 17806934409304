import React, { useState, useEffect } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, Upload, Select } from 'antd'
import { inputPlace } from '../../../utils/helpers'
import axios from 'axios'
import { POST, GET, ADMIN } from '../../../utils/apis'
import { convertTitle, successNotification, errorMessage, warningMessage, requiredMessage } from '../../../utils/helpers'

const { Option } = Select;


const AddAdminGroupAd = (props) => {
    const { onCancel, edit, user, setEdit } = props
    const [loading, setLoading] = useState(false)
    const [groups, setGroups] = useState(null)
    const [fileList, setFileList] = useState([])
    // console.log('edit', edit)


    useEffect(() => {
        getGroups()
    }, [])


    const getGroups = () => {
        setLoading(true)

        axios.get(`${ADMIN?.GET_GROUPS_FOR_ADMIN_ADV}/${user?._id}`)
            .then(
                (res) => {
                    setGroups(res?.data?.data)
                    setLoading(false)
                }
            )
            .catch(e => {
                setLoading(false)
            })
    }

    const onFinish = (values) => {
        values.file = fileList
        edit?._id && (values._id = edit?._id)
        values.adminId = user?._id

        if (!values._id && !values?.file?.length && !edit?._id) {
            return warningMessage('Please Upload Image')
        }
        setLoading(true)

        let formData = new FormData()
        if (values?.file?.length && !edit?._id) {
            formData.append('file', values?.file[0])
        }
        if (values?._id) {
            formData.append('_id', values?._id)
        }

        formData.append('name', values?.name)
        formData.append('adminId', values?.adminId)
        formData.append('groupsName', values?.groupsName)

        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        if (!edit?._id) {
            console.log('create API')
            axios.post(ADMIN.CREATE_ADMIN_GROUP_ADVERTISEMENT, formData)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data.success) {
                        successNotification(data?.message)
                        // console.log('data',data)
                        onCancel()
                    }
                    // errorMessage(data.message)
                })
                .catch((e) => {
                    setLoading(false)
                    errorMessage()
                    // console.log('e',e)
                })
        } else {
            let obj = {
                adminId: values?.adminId,
                groups: values?.groupsName,
                name: values?.name,
                _id: values?._id,
            }
            console.log('obj', obj)
            axios.post(ADMIN.EDIT_ADMIN_GROUP_ADVERTISEMENT, obj)
                .then((res) => {
                    const { data } = res
                    console.log('data', data)
                    setLoading(false)
                    if (data.success) {
                        successNotification(data?.message)
                        // console.log('data',data)
                        onCancel()
                    }
                    // errorMessage(data.message)
                })
                .catch((e) => {
                    setLoading(false)
                    errorMessage()
                    // console.log('e',e)
                })
        }
    }

    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage('You can only upload Images!')
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    const handleChange = (value) => {
        console.log(`groupsName `, value);
    }

    const initialGroups = edit?.groups?.map((v) => v?._id)
    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'> {edit?._id ? 'Edit' : 'Add'} Group Advertisement</p>
                    <Form
                        name='form'
                        onFinish={onFinish}
                        layout={'vertical'}
                    >
                        <Form.Item
                            name='name'
                            label='Name'
                            // hasFeedback
                            initialValue={edit?.name}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Input Advertisement Name'
                                }
                            ]}
                        >
                            <Input
                                className='form-input'
                                placeholder={inputPlace('Name')}
                            />
                        </Form.Item>
                        <Form.Item
                            name='groupsName'
                            label='Select Group Names'
                            // hasFeedback
                            initialValue={initialGroups}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Input Advertisement Name'
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Select Groups"
                                value={edit?.groups}
                                optionFilterProp="children"
                                onChange={handleChange}
                            // optionLabelProp="label"
                            // onSearch={onSearch}
                            // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            >
                                {groups?.map((v) => (<Option value={v?._id} label={v?.name}>{v?.name}</Option>))}
                            </Select>
                        </Form.Item>
                        {!edit?._id ? <Form.Item
                            name='file'
                            label='File'
                        >
                            <Upload
                                name='file'
                                multiple={false}
                                beforeUpload={() => false}
                                accept='image/png, image/jpeg'
                                onChange={normFile}
                                fileList={fileList}
                            >
                                <Button icon={<UploadOutlined />}>Click to upload</Button>
                            </Upload>
                            {/* {edit?.fileName ? <span><FileOutlined />&nbsp;{edit?.fileName}</span> : null} */}
                        </Form.Item> : null}
                        <Form.Item>
                            <Button
                                type='primary'
                                className='form-button'
                                block
                                htmlType='submit'
                                loading={loading}
                            >
                                {edit?._id ? 'Update' : 'Create'}
                            </Button>
                            <Button
                                style={{ borderRadius: 4, marginTop: 6 }}
                                block
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AddAdminGroupAd