import { DeleteOutlined, EditTwoTone } from '@ant-design/icons'
import { Button, Image, Popconfirm, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { GET, POST } from '../../utils/apis'
import { convertTitle, successNotification } from '../../utils/helpers'
import AddLoyaltyAdvertisement from './AddLoyaltyAd'

const LoyaltyAdvertisement = (props) => {
    const { user } = props
    const { setlCard } = props
    const [lForm, setLform] = useState(false)
    const [loading, setLoading] = useState(false)
    const [edit, setEdit] = useState({})
    const [spin, setSpin] = useState({})
    const [selectionType, setSelectionType] = useState('')
    const [lAdvertisementData, setLAdvertisementData] = useState([])

    useEffect(() => {
        getAllData()
        getKey()
    }, [])

    const getAllData = () => {
        setLoading(true)
        axios.get(`${GET?.GET_LOYALTY_ADVERTISEMENT}/${user?._id}`)
            // .then((res) => setLAdvertisementData(res?.data?.data))
            .then((result) => {
                const { data } = result
                if (data.success) {
                    setLAdvertisementData(data?.data)

                    setSelectionType(data?.data?.map((v, i) => {
                        return {
                            name: v?.name,
                            key: v?._id,
                            imageUrl: v?.imageUrl,
                            _id: v?._id,
                            activeAdd: v?.active,
                            enabled: false
                        }
                    }).filter((a, i) => {
                        return a.activeAdd === true
                    })[0].key)

                    setLoading(false)
                }
            })
            .catch(e => {
                setLoading(false)
            })

    }

    const deleteAdvertisement = (_id) => {
        setSpin({ [_id]: true })

        let valObj = { _id, userId: `${user?._id}` }
        axios.post(`${POST.DELETE_LOYALTY_ADVERTISEMENT}`, valObj)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successNotification('Successfully Deleted!')
                    getAllData()
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }

    const showEdit = (e) => {
        console.log('e', e)
        setEdit(e)
        setLform(true)
    }

    const updateLoyaltyAdvertisment = (val) => {
        setLoading(true)
        axios.post(`${POST.ACTIVE_LOYALTY_ADVERTISEMENT}`, val)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    setLoading(false)
                    successNotification('Successfully Updated!')
                    getAllData()
                }
            })
            .catch((e) => {
                setSpin({})
                console.log('e', e)
                setLoading(false)
            })
    }

    const data1 = lAdvertisementData.map((v, i) => {
        return {
            name: v?.name,
            key: v?._id,
            imageUrl: v?.imageUrl,
            _id: v?._id,
            activeAdd: v?.active,
            enabled: false
        }
    })

    const columns1 = [
        {
            title: 'Loyalty Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Loyalty Card Image',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: (e) => <Image src={`${e}`} style={{ marginRight: 10, width: 86, height: 60 }} shape='square' />
        },
        {
            title: 'Actions',
            key: 'action',
            width: 100,
            render: (e) => (
                <span>
                    {!e.activeAdd ? <a onClick={() => showEdit(e)}><EditTwoTone /></a> : null}&nbsp;
                    <Popconfirm
                        title={`Delete ${convertTitle("this Loyalty Advertisement")}?`}
                        okText='Delete'
                        cancelText='Cancel'
                        okButtonProps={{
                            type: 'primary',
                            className: 'form-button'
                        }}
                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                        onConfirm={() => deleteAdvertisement(e?._id)}

                    >{!e.activeAdd ?
                        <DeleteOutlined style={{ color: 'red' }} /> : ''}
                    </Popconfirm>
                </span>)
        }
    ]

    const getActiveAddID = data1?.filter((a, i) => {
        return a.activeAdd == true
    })

    const getKey = () => {
        const key = getActiveAddID[0]?.key
        setSelectionType(key)
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectionType(`${selectedRowKeys}`)
            let updateAddId = selectedRows[0]._id
            // setLoading(true)
            let val = { _id: updateAddId, userId: `${user?._id}` }

            updateLoyaltyAdvertisment(val)
        }
    }


    return (
        <div>
            {lForm ? (
                <AddLoyaltyAdvertisement
                    edit={edit}
                    onCancel={() => (setLform(false), setEdit(null))}
                    getAllData={() => getAllData()}
                    {...props}
                />
            ) : (
                <div>
                    <div className='button-end button-end-scroll'>
                        <Button
                            type='primary'
                            className='btn-end invite-guest invite-guest2'
                            onClick={() => setLform(true)}
                        >
                            Create Loyalty Advertisement
                        </Button>
                        <Button
                            type='primary'
                            className='btn-end'
                            onClick={setlCard}
                        >
                            Advertisement
                        </Button>
                    </div>
                    <div className='user-table'>
                        <Table
                            columns={columns1}
                            loading={loading}
                            dataSource={lAdvertisementData ? data1 : ""}
                            rowSelection={{
                                type: "radio",
                                selectedRowKeys: [selectionType],
                                ...rowSelection
                            }}
                            pagination={{ hideOnSinglePage: true, pageSize: 5 }}
                            scroll={{ x: 800 }}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default LoyaltyAdvertisement
