import { Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { GET } from '../../utils/apis'

const CouponInfo = (props) => {
    const { user, userActions } = props
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    console.log('data',data)

    useEffect(() => {
        getCouponInfo()
    }, [])

    const getCouponInfo = () => {
        setLoading(true)
        axios.get(`${GET.BU_USER_COUPON_INFO}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    setData(data?.data)
                    // dispatch(loginUser(data.admin))
                }
                else {
                    // dispatch(removeUser())
                }
            })
            .catch((e) => {
                // dispatch(removeUser())
                setLoading(false)
            })
    }

    const columns = [
        {
            title: 'User Name',
            dataIndex: ['userId', 'fullName'],
            key: 'Uname',
            sorter: (a, b) => a.Uname - b.Uname
        },
        {
            title: 'User Email',
            dataIndex: ['userId', 'email'],
            key: 'Uemail',
            sorter: (a, b) => a.Uemail - b.Uemail,
        },
        {
            title: 'No. of Coupons',
            dataIndex: 'coupons',
            key: 'coupons',
            sorter: (a, b) => a.coupons - b.coupons,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e?.length}</p>
        },
        {
            title: 'Total Amount of Coupons',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            sorter: (a, b) => a.totalAmount - b.totalAmount,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>

        },
        {
            title: 'Total Claimed Amount',
            dataIndex: 'usedAmount',
            key: 'usedAmount',
            sorter: (a, b) => a.usedAmount - b.usedAmount,

            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>
        },
        {
            title: 'Remaining Coupon Amount',
            dataIndex: 'netAmount',
            key: 'netAmount',
            sorter: (a, b) => a.netAmount - b.netAmount,

            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>
        }
    ]

    return (
        <Table
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={{ hideOnSinglePage: true }}
            scroll={{ x: 800 }}
        />
    )
}

export default CouponInfo
