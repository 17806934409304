import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { errorMessage, getAllUserData, successMessage, createGiftCard } from '../../../utils/helpers'
import { GET, POST, ADMIN } from '../../../utils/apis'
import axios from 'axios';
import { Table, Button, DatePicker, Modal, Tooltip, Select, Image, Popconfirm } from 'antd'
import _ from 'lodash'
import AddGiftCards from './AddGiftCards'

const GiftNotifications = (props) => {
    const { user, userActions } = props
    const [notifications, setNotifications] = useState([])
    const [loading, setLoading] = useState(false)
    const [detail, setDetail] = useState({})
    const [showDetails, setShowDetails] = useState(false)
    const [cards, setCards] = useState([])

    useEffect(() => {
        getNotification()
    }, [])

    const getNotification = () => {
        setLoading(true)
        axios.get(`${ADMIN.GET_ADMIN_NOTIFICATIONS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setNotifications(data?.data)
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    const columns = [
        {
            title: 'Bu User',
            dataIndex: ["userId", "email"],
            key: 'email',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a?.email?.length - b?.email?.length,
        },
        {
            title: 'Notification',
            dataIndex: 'notificationMessage',
            key: 'notificationMessage'
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p>{moment(e?.created).utc().format('YYYY-MM-DD')}</p>
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (e) => (
                <>
                    <Button onClick={() => {
                        setDetail(e?.userId)
                        setShowDetails(true)
                    }} > View Detail</Button >
                    &nbsp; &nbsp; &nbsp;
                    <Button onClick={() => createGiftCard(e?.giftCards)} > Generate PDF</Button>
                </>
            )
        }
    ]
    const handleOk = () => {
        setShowDetails(false)
    }
    return (
        <>
            <Table
                loading={loading}
                dataSource={notifications}
                columns={columns}
                pagination={{ hideOnSinglePage: true }}
                scroll={{ x: 800 }}
            />
            <Modal title='User Details' visible={showDetails} onOk={handleOk} onCancel={handleOk} footer={null}>
                <ul>
                    <li>fullName: {detail?.fullName ? detail?.fullName : '-------'} </li>
                    <li>email: {detail?.email ? detail?.email : '-------'} </li>
                    <li>bankAccount: {detail?.bankAccount ? detail?.bankAccount : '-------'}</li>
                    <li>IBAN: {detail?.IBAN ? detail?.IBAN : '-------'}</li>
                    <li>Address: {detail?.streetNumber ? detail?.streetNumber : '-------'}</li>
                </ul>
            </Modal>
        </>
    )
}

export default GiftNotifications