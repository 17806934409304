import React, { useEffect, useState } from 'react'
import { Table, Input, Space, Spin } from 'antd'
import SelectCountry from './SelectCountry'
import axios from 'axios'
import { POST } from '../../utils/apis'
import countryCodes from '../../utils/countryCodes'

const BuUsers = (props) => {
  const { user } = props
  const [businessUsers, setbusinessUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [userSelectedCountry, setUserSelectedCountry] = useState(user?.country)
  const userCountry = { country: userSelectedCountry }


  const { Search } = Input;
  const [searchValue, setSearchValue ] = useState()
  useEffect(() => {
    axios.post(POST.GET_ALL_BUSINESS_USER, userCountry)
      .then((res) => {
        const { data } = res
        setLoading(false)
        setbusinessUsers(data?.data || [])
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [userSelectedCountry])

  const columns = [
    {
      title: 'Bu Name',
      dataIndex: 'BuName',
      key: 'BuName',
      ellipsis: true
    },
    {
      title: 'country',
      dataIndex: 'country',
      key: 'country',
      sorter: true,
      ellipsis: true
    },
    {
      title: 'city',
      dataIndex: 'city',
      key: 'city',
      sorter: true,
      ellipsis: true
    },
  ]

  const data = businessUsers.map((v, i) => {
    return {
      id: v?._id,
      country: countryCodes?.find((code) => {
        if (code?.code === v?.country)
          return code?.name;
      }).name,
      city: v?.postalCodeCity,
      BuName: v?.businessName,
    }
  })

  return (
    <div className='flex-column menu-main'>
      <div className='Buselect'>
        <Space >
          <SelectCountry setUserSelectedCountry={setUserSelectedCountry} user={user} />
        </Space>
      </div>
      <br />
      <div className='user-table'>
        <Table
          loading={loading ? <Spin /> : ""}
          class
          columns={columns}
          dataSource={businessUsers ? data : ""}
          pagination={{ hideOnSinglePage: true }}
          scroll={{ x: 800 }}
        />
      </div>
    </div>
  )
}

export default BuUsers