import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { errorMessage, getAllUserData, successMessage } from '../../../utils/helpers'
import LotteryCodes from './LotteryCodes'
import { GET, POST, ADMIN } from '../../../utils/apis'
import axios from 'axios';
import { Table, Button, DatePicker, Modal, Tooltip, Select, Image, Popconfirm } from 'antd'
import _ from 'lodash'
import DoubleChance from './DoubleChance'
import LotteryCards from './LotteryCards'
const { RangePicker } = DatePicker

const Lottery = (props) => {
    const { user, userActions } = props
    const [loading, setLoading] = useState(false)
    const [showDraw, setShowDraw] = useState(false)
    const [showDOC, setshowDOC] = useState(false)
    const [lotteryCards, setLotteryCards] = useState(false)
    const [tempLoading, setTempLoading] = useState(false)
    const [lotteryPrize, setLotteryPrize] = useState([])
    const [filteredArray, setfilteredArray] = useState([])
    const [filteredArray2, setfilteredArray2] = useState([])
    const [filteredArray3, setfilteredArray3] = useState([])
    const [filteredArray4, setfilteredArray4] = useState([])
    const [dateRange, setDateRange] = useState({
        date1: moment().add(-1, 'months').format('YYYY-MM-DD'),
        date2: moment().format('YYYY-MM-DD')
    })



    useEffect(() => {
        setfilteredArray(_.uniqBy(buNameArray, 'text'))
        setfilteredArray2(_.uniqBy(buemailArray, 'text'))
        setfilteredArray3(_.uniqBy(winnerArray, 'text'))
        setfilteredArray4(_.uniqBy(withDrawArray, 'text'))
        // console.log('makArray2',makArray2 )
    }, [lotteryPrize])


    const getLotteriesData = (e) => {
        setLoading(true)
        let obj = { date1: dateRange?.date1, date2: dateRange?.date2 }

        axios.post(ADMIN.EACH_MONTH_LOTTETY_PRIZE, obj)
            .then((res) => {
                const { data } = res
                setLotteryPrize(data?.data || [])
                // console.log('Admin', data?.data)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getLotteriesData()
    }, [dateRange])

    const columns = [
        {
            title: 'Prize',
            dataIndex: 'prize',
            key: 'prize',
            defaultSortOrder: 'descend',
            // sorter: (a, b) => a.prize - b.prize,
            render: (e) => {
                return <Image src={`${e}`} style={{ marginRight: 10, width: 86, height: 60 }} />
            }
        },
        {
            title: 'Business Name',
            dataIndex: 'businessName',
            key: 'businessName',
            filters: filteredArray,
            onFilter: (value, record) => record.businessName.indexOf(value) === 0
        },
        {
            title: 'User Email',
            dataIndex: 'UserEmail',
            key: 'UserEmail',
            filters: filteredArray2,
            onFilter: (value, record) => record.UserEmail.indexOf(value) === 0
        },
        {
            title: 'Lottery Winner',
            dataIndex: 'guestName',
            key: 'guestName',
            filters: filteredArray3,
            onFilter: (value, record) => record.guestName.indexOf(value) === 0
        },
        // {
        //     title: 'Name',
        //     dataIndex: 'name',
        //     key: 'name',
        // },

        {
            title: 'Lottery Type',
            dataIndex: 'lotteryType',
            key: 'lotteryType',
            defaultSortOrder: 'descend',
            // sorter: (a, b) => a.prize - b.prize
        },
        {
            title: 'Lottery Code',
            dataIndex: 'lotteryCode',
            key: 'lotteryCode'
        },
        {
            title: 'Withdraw Date',
            dataIndex: 'date',
            key: 'date',
        }
    ]

    const dataSource2 = lotteryPrize.map((info, i) => {
        return {
            key: info?._id,
            businessName: info?.userId?.businessName,
            UserEmail: info?.userId?.email,
            guestName: info?.guest?.fullName ? info?.guest?.fullName : "Waiting...",
            name: info?.name,
            lotteryCode: info?.lotteryCode,
            prize: info?.withDraw ? info?.prize : info?.prize,
            lotteryType: info?.lotteryType === 'physicalLottery	' ? 'Physical Lottery' : 'Online Lottery',
            date: moment(info?.date).utcOffset(0, true).format('DD-MM-YYYY'),
        }
    })

    const buNameArray = lotteryPrize.map((v, i) => {
        return {
            text: v?.userId?.businessName,
            value: v?.userId?.businessName
        }
    })
    const buemailArray = lotteryPrize.map((v, i) => {
        return {
            text: v?.userId?.email,
            value: v?.userId?.email
        }
    })
    const winnerArray = lotteryPrize.map((v, i) => {
        return {
            text: v?.guest?.fullName ? v?.guest?.fullName : "Waiting...",
            value: v?.guest?.fullName ? v?.guest?.fullName : "Waiting..."
        }
    })
    const withDrawArray = lotteryPrize.map((v, i) => {
        return {
            text: moment(v?.date).utcOffset(0, true).format('DD-MM-YYYY'),
            value: moment(v?.date).utcOffset(0, true).format('DD-MM-YYYY')
        }
    })

    const createReportRange = (d) => {
        setDateRange({
            date1: d[0].format('YYYY-MM-DD'),
            date2: d[1].format('YYYY-MM-DD')
        })
        getLotteriesData()
    }


    const disabledDate = (current) => {

        let customDate = moment().format('YYYY-MM-DD')
        return current && current > moment(customDate, 'YYYY-MM-DD').add(1, 'day')
    }

    const updateLotterydate = () => {
        setTempLoading(true)
        let obj = { adminId: user?._id }
        axios.post(ADMIN.UPDATE_LOTTERY_WITHDRAW_DATE, obj)
            .then((res) => {
                const { data } = res
                setTempLoading(false)
                if (data.success) {
                    successMessage(data?.message)
                } else {
                    errorMessage(data?.message)
                }
            })
            .catch((e) => {
                setTempLoading(false)
            })
    }
    return (
        <>
            {lotteryCards ? < LotteryCards {...props} setLotteryCardssss={() => setLotteryCards(false)} />
                : <div className='flex-column menu-main'>

                    {!showDOC && !lotteryCards ? <div className='flex-mobile' style={{ display: 'flex' }}>
                        <RangePicker
                            defaultValue={[moment().add(-1, 'months'), moment()]}
                            onChange={createReportRange}
                            style={{ marginBottom: 10 }}
                        // disabledDate={disabledDate}
                        />
                    </div> : null}
                    <div className='button-end button-end-scroll'>
                        {!showDraw && !showDOC && !lotteryCards ? <>
                            <Button
                                type='primary'
                                className='btn-end invite-guest invite-guest3'
                                onClick={() => setShowDraw(true)}
                            >
                                All Lottery Codes
                            </Button>
                            <Button
                                type='primary'
                                className='btn-end invite-guest invite-guest3'
                                onClick={() => setshowDOC(true)}
                            >
                                Double of Chances
                            </Button>
                            <Button
                                type='primary'
                                className='btn-end invite-guest invite-guest3'
                                onClick={() => setLotteryCards(true)}
                            >
                                Lottery Cards
                            </Button>
                            {/* <Popconfirm
                                loading={tempLoading}
                                placement="leftBottom"
                                title={<div style={{ maxWidth: 250 }}> Are you sure you want to change the <b>All Prizes Withdraw Date</b>?</div>}
                                okText="Yes" cancelText="No" onConfirm={updateLotterydate}>
                                <Button
                                    type='primary'
                                    className='btn-end invite-guest invite-guest3'
                                    loading={tempLoading} >
                                    UPDATE LOTTERY WITHDRAW DATE
                                </Button>
                            </Popconfirm> */}

                        </>
                            :
                            <Button
                                type='primary'
                                className='btn-end invite-guest invite-guest3'
                                onClick={() => {
                                    setShowDraw(false)
                                    setshowDOC(false)
                                    setLotteryCards(false)
                                }}

                            >
                                Back
                            </Button>
                        }
                    </div>
                    <div className='user-table'>

                        {showDraw ?
                            <LotteryCodes {...props} dateRange={dateRange} />
                            : showDOC ? <DoubleChance {...props} dateRange={dateRange} />
                                :
                                <>
                                    <div className='user-table'>
                                        <h1 className='text-center'>Lottery Winners</h1>
                                    </div>
                                    <div className='user-table'>
                                        <Table
                                            loading={loading}
                                            // loading={true}
                                            dataSource={dataSource2}
                                            columns={columns}
                                            pagination={{ hideOnSinglePage: true }}
                                            scroll={{ x: 800 }}
                                        />
                                    </div>
                                </>
                        }
                    </div>
                </div>}
        </>
    )
}

export default Lottery