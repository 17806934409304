import React, { useState } from 'react'
import { Modal, Button, Descriptions, Avatar } from 'antd'
import moment from 'moment-timezone'
import Lottie from 'react-lottie'
import { segmentForm } from '../../../utils/constants'
import { convertMessage } from '../../../utils/helpers'
import ViewCard from '../Cards/ViewCard'
import NotFound from '../../../assets/not-found.json'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: NotFound,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
}

const ViewSegmentData = (props) => {
    const { showData, onCancel, segment, user } = props
    const [card, setCard] = useState({})
    const [showCardData, setShowCardData] = useState(false)

    const cancelCard = () => {
        setCard({})
        setShowCardData(false)
    }

    const momentTime = (e) => {
        var date = moment.utc(e).format('YYYY-MM-DD HH:mm:ss')

        var stillUtc = moment.utc(date).toDate()

        return moment(stillUtc).format('YYYY-MM-DD HH:mm:ss')
    }

    const momentTimeFrom = (e) => {
        var date = moment.utc(e).format('YYYY-MM-DD HH:mm:ss')

        var stillUtc = moment.utc(date).toDate()

        return moment(stillUtc).fromNow()
    }

    return (
        <div style={{ paddingBottom: 40 }}>
            {showCardData ? <ViewCard card={card} onCancel={cancelCard} showData={showCardData} /> :
                <Modal
                    title='Dates And Messages'
                    visible={showData}
                    onCancel={onCancel}
                    className='modal-view'
                    footer={[
                        <Button
                            key='back'
                            onClick={onCancel}
                            className='form-input'
                        >
                            Return
                        </Button>
                    ]}
                >
                    <div>
                        <Descriptions bordered column={1}>
                            {segmentForm?.map((v, i) => {
                                return v?.map((y, j) => {
                                    if (y?.draft && segment[y?.name]) {
                                        return <Descriptions.Item label={y?.label} key={`${i}-${j}`}>{convertMessage(segment[y?.name], segment?.guest, user) || 'Not Found!'}</Descriptions.Item>
                                    }
                                    else if (y?.name?.startsWith('card')) {
                                        return <Descriptions.Item label={y?.label} key={`${i}-${j}`}>
                                            {segment[y?.name]?.imageUrl ? <Avatar src={segment[y?.name]?.imageUrl} style={{ marginRight: 10, width: 86, height: 60 }} shape='square' /> : <div style={{ width: 86, height: 60 }}>
                                                <Lottie options={defaultOptions}
                                                    height={60}
                                                    width={86}
                                                />
                                            </div>}
                                        </Descriptions.Item>
                                    }
                                    else if (y?.name?.startsWith('mail') || y?.name?.startsWith('postalCard') || y?.name?.startsWith('whatsapp') || y?.name?.startsWith('fullCardUrl') || y?.name?.startsWith('sendingDate') || y?.name?.startsWith('sendingType') || y?.name?.startsWith('absolute') || y?.name?.startsWith('date') || y?.name?.startsWith('hour')) {
                                        return null
                                    }
                                    return <Descriptions.Item label={y?.label} key={`${i}-${j}`}>{segment[y?.name] || 'Not Found!'}</Descriptions.Item>
                                })
                            })}
                        </Descriptions>
                    </div>
                </Modal>}
        </div>
    )
}

export default ViewSegmentData