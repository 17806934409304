import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { DeleteOutlined, ShareAltOutlined, EditTwoTone } from '@ant-design/icons'
import { Table, Input, Button, Space, Popconfirm, Spin, Avatar, Modal, Select, Tooltip } from 'antd'
import { getAllData, convertTitle, successNotification, successMessage, errorMessage } from '../../../utils/helpers'
import { ADMIN } from '../../../utils/apis'
import AddGroup from './AddGroup'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const { Option } = Select


const AdminGroup = (props) => {
    const { user, adminActions } = props
    // const buUsers = useSelector(state => state?.adminReducer?.buUsers || [])
    const [buUsers, setBuUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [spin, setSpin] = useState({})
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const [invitedUsers, setInvitedUsers] = useState(false)
    const [invitedUserData, setInvitedUserData] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [invitationObj, setInvitationObj] = useState({})
    const [loading, setLoading] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [edit, setEdit] = useState({})
    const searchInput = useRef(null)
    const groups = useSelector(state => state?.adminReducer?.groups || [])
    useEffect(() => {
        getAllData(user?._id, adminActions)
    }, [showForm, spin])


    const showModal = (e) => {
        setIsModalVisible(true)

        let obj = {
            userEmail: null,
            adminId: e && e?.adminId,
            groupId: e && e?._id,
            privateGroup: e && e?.private
        }

        setInvitationObj(obj)
    }

    const handleOk = () => {
        setLoading(true)
        const new_obj = { ...invitationObj, userEmail: selectedUser }
        axios.post(`${ADMIN.INVITE_USER_IN_GROUP}`, new_obj)
            .then((res) => {
                const { data } = res
                // console.log('DATA', data)
                if (data?.success) {
                    successNotification(data?.message ? data?.message : 'Successfully Invited!')
                    setIsModalVisible(false)
                    setLoading(false)
                    setSelectedUser(null)
                }
                else {
                    setLoading(false)
                    errorMessage(data?.message)
                }
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
                errorMessage(e?.message)
                setIsModalVisible(false)
            })
    }

    const handleCancel = () => {
        setIsModalVisible(false)
        setSelectedUser(null)
    }

    const getInvitedUsers = (e) => {
        axios.get(`${ADMIN.GET_INVITED_USERS}/${e}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setInvitedUsers(true)
                    setInvitedUserData(data?.data || [])
                }
            })
            .catch((e) => {
                console.log('e..', e)
                errorMessage(e?.message)
            })
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => {
                            confirm({ closeDropdown: false })
                            setSearchText(selectedKeys[0])
                            setSearchedColumn(dataIndex)
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100)
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const deleteCard = (_id) => {
        setSpin({ [_id]: true })
        axios.post(`${ADMIN.DELETE_GROUP}`, { adminId: user?._id, _id: _id })
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successNotification('Successfully Deleted!')
                    adminActions?.addAllGroups(data?.data || [])
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }
    const showEdit = (e) => {
        console.log('e', e)
        setEdit(e)
        setShowForm(true)
    }

    const getAllBuUsers = (e) => {
        setLoading(true)
        let obj = {
            postalCode: e?.postalCode,
            groupId: e?._id,
            adminId: user?._id
        }

        axios.post(ADMIN?.GET_BU_USERS, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setBuUsers(data?.data)
                    setLoading(false)
                    showModal(e)
                }
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
                errorMessage()
            })
    }

    const deleteInvite = (e) => {
        setLoading(true)
        let obj = {
            _id: e?._id,
            groupId: e?.groupId,
            userId: e?.userId?._id
        }
        axios.post(ADMIN?.DELETE_INVITED_USER, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setLoading(false)
                    getInvitedUsers(e?.groupId)
                    successMessage(data?.message || 'Successfully Deleted')
                }
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
                errorMessage()
            })
    }


    let columns1 = [

        {
            title: 'User Name',
            dataIndex: 'userId',
            key: 'userId',
            sorter: true,
            ellipsis: true,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e?.fullName}</p>
        },
        {
            title: 'User Email',
            dataIndex: 'userId',
            key: 'userId',
            sorter: true,
            // ellipsis: true,
            render: (e) => <p>{e?.email}</p>
        },

        {
            title: 'Invited',
            dataIndex: 'inviteSent',
            key: 'inviteSent',
            sorter: true,
            ellipsis: true,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? 'True' : 'False'}</p>
        },
        {
            title: 'Invite Accepted',
            dataIndex: 'inviteAccepted',
            key: 'inviteAccepted',
            sorter: true,
            ellipsis: true,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? 'True' : 'False'}</p>
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (e) => <span>
                {e?.inviteAccepted ? <Tooltip title='User has Joined the Group,So it cannot be deleted'><p>can't delete</p></Tooltip> : <Popconfirm
                    title={`Delete ?`}
                    okText='Delete'
                    cancelText='Cancel'
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteInvite(e)}
                >
                    <a href='#'><DeleteOutlined style={{ color: 'red' }} /> Delete</a>
                    {spin[e?._id] ? <span className='demo-loading-container'>
                        &nbsp;<Spin />
                    </span> : null}
                </Popconfirm>}
            </span>
        }
    ]

    let columns = [
        {
            title: 'Group Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            // ellipsis: true,
            ...getColumnSearchProps('name')
        },
        {
            title: 'Postal Code Range',
            // dataIndex: 'postalCode',
            key: 'postalCode',
            ellipsis: true,
            render: (e) => {
                return (<>
                    <p>{e?.postalCode[0]?.postalcodeStart}-{e?.postalCode[0]?.postalcodeEnd}</p>
                    {e?.postalCode[1] ? <> <br />
                        <p>{e?.postalCode[1]?.postalcodeStart}-{e?.postalCode[1]?.postalcodeEnd}</p> </> : null}
                </>)
            }

        },
        {
            title: 'Private',
            dataIndex: 'private',
            key: 'private',
            ellipsis: true,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? 'True' : 'False'}</p>

        },
        {
            title: 'Password',
            dataIndex: 'password',
            key: 'password',
            render: (e) => {
                var pass = e ? e : null
                return (
                    <span>
                        {e ? <CopyToClipboard text={pass}
                            onCopy={() => successMessage('Successfully Copied to Clipboard!')}>
                            <a>Copy Password</a>
                        </CopyToClipboard> : 'null'}

                    </span>
                )
            }

        },
        {
            title: 'Total Users',
            dataIndex: 'userId',
            key: 'userId',
            ellipsis: true,
            render: (e) => {
                let uniqueChars = [...new Set(e)]
                return <p>{uniqueChars && uniqueChars ? uniqueChars?.length : 0}</p>
            }
        },
        {
            title: 'Created At',
            dataIndex: 'created',
            key: 'created',
            ellipsis: true
        },
        {
            title: 'View Invited Users',
            dataIndex: '_id',
            key: '_id',
            render: (e) => <Button onClick={() => getInvitedUsers(e)}>Show</Button>
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (e) => <span>
                <a onClick={() => showEdit(e)}><EditTwoTone /></a>&nbsp;
                <Popconfirm
                    title={`Delete ${convertTitle(e?.name)}?`}
                    okText='Delete'
                    cancelText='Cancel'
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteCard(e?._id)}
                >
                    <a href='#'><DeleteOutlined style={{ color: 'red' }} /></a>
                    {spin[e?._id] ? <span className='demo-loading-container'>
                        &nbsp;<Spin />
                    </span> : null}
                </Popconfirm>
                &nbsp;
                <a onClick={() => {
                    getAllBuUsers(e)
                }
                }><ShareAltOutlined /></a>

            </span>
        }
    ]


    return (
        <div className='flex-column menu-main'>
            {showForm ? <AddGroup edit={edit} onCancel={() => (setShowForm(false), setEdit(null))} {...props} /> : <div>
                <div className='button-end button-end-scroll'>
                    {invitedUsers ? <Button
                        type='primary'
                        className='btn-end'
                        onClick={() => setInvitedUsers(false)}
                    >
                        Back
                    </Button> :
                        <Button
                            type='primary'
                            className='btn-end'
                            onClick={() => setShowForm(true)}
                        >
                            Add Group
                        </Button>}
                </div>
                <div className='user-table'>
                    {invitedUsers ?
                        <Table
                            columns={columns1}
                            dataSource={invitedUserData}
                            // dataSource={groups}
                            pagination={{ hideOnSinglePage: true }}
                            scroll={{ x: 800 }} />
                        :
                        <>
                            <Table
                                columns={columns}
                                dataSource={groups}
                                pagination={{ hideOnSinglePage: true }}
                                scroll={{ x: 1100 }}
                            />
                            <Modal title='Invite User by Email'
                                visible={isModalVisible}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                okText='Invite'
                                footer={[
                                    <Button key='back' onClick={handleCancel} className='form-input'>
                                        Cancel
                                    </Button>,
                                    <Button
                                        key='ok'
                                        onClick={handleOk}
                                        className='form-input'
                                        type='primary'
                                        loading={loading}
                                    >
                                        Invite User
                                    </Button>
                                ]}>

                                <Select
                                    style={{ width: '100%' }}
                                    showSearch
                                    showArrow
                                    allowClear
                                    value={selectedUser}
                                    onChange={(e) => setSelectedUser(e)}
                                    className='form-input'
                                    placeholder={'Select User'}
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {buUsers?.map((v, i) => <Option value={v?.email} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
                                </Select>
                                <br />
                                <br />
                                <p>{!buUsers.length ? ' No Users exist in this range of Postal Code' : 'Invite User '}</p>
                            </Modal>
                        </>
                    }
                </div>
            </div>}
        </div>
    )
}

export default AdminGroup