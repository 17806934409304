import React, { useState } from 'react'
import { Select } from 'antd'
import countryCodes from '../../utils/countryCodes'

const SelectCountry = (props) => {
  const { user, setUserSelectedCountry } = props

  const userCountry = user?.country
  const { Option } = Select;
  const [country, setCountry] = useState(userCountry)

  function onChange(value) {
    setUserSelectedCountry(value)
  }

  return (
    <Select
      size='large'
      style={{ width: 250 }}
      defaultValue={country}
      showSearch
      showArrow
      allowClear
      placeholder="Select Country"
      optionFilterProp="children"
      onChange={onChange}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {countryCodes?.map((v, i) => <Option value={v?.code} key={i}>{v?.name}</Option>)}
    </Select>
  )
}

export default SelectCountry;
