import React, { useState, useRef } from 'react'
import { Form, Button, Input, Checkbox, Select, Avatar } from 'antd'
import { Editor } from 'react-draft-wysiwyg'
import axios from 'axios'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { inputPlace, requiredMessage, successNotification, errorMessage } from '../../../utils/helpers'
import { segmentForm } from '../../../utils/constants'
import { ADMIN } from '../../../utils/apis'

const { Option } = Select

var arr = Array.from(Array(365).keys())

const AddSegment = (props) => {
    const { onCancel, edit, user } = props
    const [loading, setLoading] = useState(false)
    const [fixedDay, setFixedDay] = useState(edit?.fixedDay || false)
    const [switches, setSwitches] = useState({ ...edit })
    const cards = useSelector(state => state?.adminReducer?.cards || [])
    const formRef = useRef(null)

    const onFinish = async (values) => {
        values.fixedDay = fixedDay
        edit?._id && (values._id = edit?._id)

        let currentDate = moment()

        values = { ...switches, ...values }

        // values.date1 = values.date1 || switches.date1 || null
        // values.date2 = values.date2 || switches.date2 || null
        // values.date3 = values.date3 || switches.date3 || null
        // values.date4 = values.date4 || switches.date4 || null
        // values.date5 = values.date5 || switches.date5 || null

        // if (values?.absolute1) {
        //     let date1 = absoluteLocalDate(values?.date1, values?.hour1)

        //     if (date1 < currentDate) {
        //         return errorMessage('Please Select Future date and Time in Date 1')
        //     }

        //     values.sendingDate1 = await absoluteDate(values?.date1, values?.hour1)
        //     values.date1 = values?.date1?.toString()
        // }
        // else {
        //     values.sendingDate1 = await relativeDate(values?.date1, values?.hour1)
        // }

        // if (values?.date2) {
        //     if (values?.absolute2) {
        //         let date2 = absoluteLocalDate(values?.date2, values?.hour2)

        //         if (date2 < currentDate) {
        //             return errorMessage('Please Select Future date and Time in Date 2')
        //         }

        //         values.sendingDate2 = await absoluteDate(values?.date2, values?.hour2)
        //         values.date2 = values?.date2?.toString()
        //     }
        //     else {
        //         values.sendingDate2 = await relativeDate(values?.date2, values?.hour2)
        //     }
        // }
        // else {
        //     values.sendingDate2 = null
        // }

        // if (values?.date3) {
        //     if (values?.absolute3) {
        //         let date3 = absoluteLocalDate(values?.date3, values?.hour3)

        //         if (date3 < currentDate) {
        //             return errorMessage('Please Select Future date and Time in Date 3')
        //         }

        //         values.sendingDate3 = await absoluteDate(values?.date3, values?.hour3)
        //         values.date3 = values?.date3?.toString()
        //     }
        //     else {
        //         values.sendingDate3 = await relativeDate(values?.date3, values?.hour3)
        //     }
        // }
        // else {
        //     values.sendingDate3 = null
        // }

        // if (values?.date4) {
        //     if (values?.absolute4) {
        //         let date4 = absoluteLocalDate(values?.date4, values?.hour4)

        //         if (date4 < currentDate) {
        //             return errorMessage('Please Select Future date and Time in Date 4')
        //         }

        //         values.sendingDate4 = await absoluteDate(values?.date4, values?.hour4)
        //         values.date4 = values?.date4?.toString()
        //     }
        //     else {
        //         values.sendingDate4 = await relativeDate(values?.date4, values?.hour4)
        //     }
        // }
        // else {
        //     values.sendingDate4 = null
        // }

        // if (values?.date5) {
        //     if (values?.absolute5) {
        //         let date5 = absoluteLocalDate(values?.date5, values?.hour5)

        //         if (date5 < currentDate) {
        //             return errorMessage('Please Select Future date and Time in Date 5')
        //         }

        //         values.sendingDate5 = await absoluteDate(values?.date5, values?.hour5)
        //         values.date5 = values?.date5?.toString()
        //     }
        //     else {
        //         values.sendingDate5 = await relativeDate(values?.date5, values?.hour5)
        //     }
        // }
        // else {
        //     values.sendingDate5 = null
        // }

        setLoading(true)

        axios.post(ADMIN.CREATE_SEGMENT, values)
            .then((res) => {
                const { data } = res
                // console.log('data', data)
                setLoading(false)
                if (data.success) {
                    successNotification(data?.message)
                    return setTimeout(() => {
                        onCancel()
                    }, 300)
                }
                errorMessage(data.message)
            })
            .catch((e) => {
                setLoading(false)
                errorMessage()
            })
    }

    const createEditor = (val) => {
        // console.log('val', val)
        val.entityMap = {}
        // let editorState = EditorState.createWithContent(
        //     ContentState.createFromBlockArray(
        //         convertFromHTML(draftToHtml(val))
        //     )
        // )

        // console.log('***', draftToHtml(val))
        return val
    }

    const changeFormat = (e, v, dataArr, reset = false) => {
        reset && !edit?._id && formRef.current.resetFields([dataArr[1]?.name])
        setSwitches({ ...switches, [v]: e })
    }

    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'>{edit?._id ? 'Update' : 'Create'} Segment</p>
                    <Form
                        name='form'
                        onFinish={onFinish}
                        layout={'vertical'}
                        ref={formRef}
                    >
                        <div className='flex-row flex-mobile'>
                            <Form.Item
                                name='name'
                                label='Name'
                                className='form-layout left-layout'
                                style={{ flex: 3 }}
                                hasFeedback
                                initialValue={edit?.name}
                                rules={[
                                    {
                                        required: true,
                                        message: requiredMessage('Segment Name')
                                    },
                                    {
                                        max: 50
                                    }
                                ]}
                            >
                                <Input
                                    className='form-input'
                                    placeholder={inputPlace('Segment Name')}
                                />
                            </Form.Item>
                            <Form.Item
                                name='fixedDay'
                                label='&nbsp;'
                                className='form-layout right-layout'
                                style={{ flex: 1 }}
                                hasFeedback
                                initialValue={edit?.fixedDay}
                            >
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Checkbox
                                    className='form-input'
                                    checked={fixedDay}
                                    onClick={() => setFixedDay(!fixedDay)}
                                /> &nbsp; Fixed Day
                            </Form.Item>
                        </div>
                        {segmentForm?.map((v, i) => {
                            return (
                                <div key={i}>
                                    <Form.Item
                                        name={v[0]?.name}
                                        label={v[0]?.label}
                                        hasFeedback
                                        initialValue={edit?.[v[0]?.name]}
                                        rules={[
                                            {
                                                required: v[0]?.required,
                                                message: requiredMessage(v[0]?.label)
                                            }
                                        ]}
                                    >
                                        <Editor
                                            initialContentState={edit?.[v[0]?.name] && createEditor(edit?.[v[0]?.name])}
                                            toolbarClassName='toolbarClassName'
                                            wrapperClassName='wrapperClassName'
                                            editorClassName='editorClassName'
                                            placeholder={inputPlace(v[0]?.label)}
                                            toolbar={{
                                                options: ['inline', 'colorPicker', 'emoji', 'link', 'blockType', 'fontSize', 'fontFamily', 'embedded', 'list', 'textAlign', 'history'],
                                            }}
                                        />
                                    </Form.Item>
                                    {/* <div className='flex-row flex-mobile'>
                                        {switches?.[v[9]?.name] ?
                                            <Form.Item
                                                name={v[1]?.name}
                                                label={`${v[1]?.label} `}
                                                className='form-layout left-layout'
                                                style={{ flex: 3, marginTop: 24 }}
                                                hasFeedback
                                                rules={[
                                                    {
                                                        required: edit?._id ? false : v[1]?.required,
                                                        message: requiredMessage(v[1]?.label)
                                                    }
                                                ]}
                                            >
                                                <DatePicker
                                                    style={{ width: '100%' }}
                                                    disabledDate={disabledDate}
                                                    defaultValue={edit?.[v[9]?.name] && switches?.[v[9]?.name] ? moment(edit?.[v[1]?.name]) : moment()}
                                                    onChange={(e) => changeFormat(e, v[1]?.name, v)}
                                                />
                                                {console.log('***', edit?.[v[9]?.name] && switches?.[v[9]?.name], edit?.[v[1]?.name])}
                                            </Form.Item> : <Form.Item
                                                name={v[1]?.name}
                                                label={`${v[1]?.label} (This date reflects the number of days the first message will be send after initial date)`}
                                                className='form-layout left-layout'
                                                style={{ flex: 3, marginTop: 3 }}
                                                hasFeedback
                                                rules={[
                                                    {
                                                        required: edit?._id ? false : v[1]?.required,
                                                        message: requiredMessage(v[1]?.label)
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    showArrow
                                                    allowClear
                                                    defaultValue={!edit?.[v[9]?.name] ? Number(edit?.[v[1]?.name]) || 1 : 1}
                                                    onSelect={(e) => changeFormat(e, v[1]?.name, v)}
                                                    className='form-input'
                                                    placeholder={'Select number of days after initial date to send this messages'}
                                                    optionFilterProp='children'
                                                    filterOption={(input, option) =>
                                                        option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {arr?.map((v, i) => <Option value={v + 1} key={i}>{v + 1}</Option>)}
                                                </Select>
                                            </Form.Item>}

                                        <div className='form-layout right-layout'>
                                            <Form
                                                name={v[9]?.name}
                                                label={`${v[9]?.label}`}
                                                hasFeedback
                                                initialValues={switches?.[v[9]?.name] || false}
                                                rules={[
                                                    {
                                                        required: v[9]?.required,
                                                        message: requiredMessage(v[9]?.label)
                                                    }
                                                ]}
                                            >
                                                <Switch
                                                    unCheckedChildren='Relative'
                                                    checkedChildren='Absolute'
                                                    checked={switches?.[v[9]?.name] || false}
                                                    onChange={(e) => changeFormat(e, v[9]?.name, v, true)}
                                                />
                                            </Form>
                                            <Form.Item
                                                name={v[8]?.name}
                                                label={`${v[8]?.label}`}
                                                initialValue={edit?.[v[8]?.name] || '00'}
                                                hasFeedback
                                                rules={[
                                                    {
                                                        required: v[8]?.required,
                                                        message: requiredMessage(v[8]?.label)
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    showArrow
                                                    allowClear
                                                    className='form-input'
                                                    placeholder={'Select Time'}
                                                    optionFilterProp='children'
                                                    filterOption={(input, option) =>
                                                        option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {timeArray?.map((v, i) => <Option value={v} key={i}>{v}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div> */}
                                    <Form.Item
                                        name={v[2]?.name}
                                        label={v[2]?.label}
                                        initialValue={edit?.[v[2]?.name]?._id}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: v[2]?.required,
                                                message: requiredMessage(v[2]?.label)
                                            }
                                        ]}
                                    >
                                        <Select
                                            showArrow
                                            allowClear
                                            className='form-input'
                                            placeholder={`Select ${v[2]?.label}`}
                                            optionFilterProp='children'
                                        >
                                            {cards?.map((v, i) => <Option value={v?._id} key={i}><Avatar src={v?.imageUrl} style={{ marginRight: 10, width: 34, height: 24 }} />{v?.designName}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </div>
                            )
                        })}
                        <Form.Item>
                            <Button
                                type='primary'
                                className='form-button'
                                block
                                htmlType='submit'
                                loading={loading}
                            >
                                {edit?._id ? 'Update' : 'Create'}
                            </Button>
                            <Button
                                style={{ borderRadius: 4, marginTop: 6 }}
                                block
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div >
        </div >
    )
}

AddSegment.propTypes = {
    onCancel: PropTypes.func.isRequired
}

export default AddSegment