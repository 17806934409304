import React, { useState, useEffect } from 'react'
import { Input, Row, Col, Checkbox, Button, Tabs, InputNumber } from 'antd'
import axios from 'axios'
import { POST, GET, ADMIN } from '../../utils/apis'
import { successNotification } from '../../utils/helpers'



const { TabPane } = Tabs;

// const AllTabs = () => {
// };

const PriceSettings = (props) => {
    const [subscriptions, setSubscriptions] = useState([])
    const [couponDiscount, setCouponDiscount] = useState({})
    const [loading, setLoading] = useState(false)
    const [cardCouponDiscountAmount, setCardCouponDiscountAmount] = useState({})
    const [onlineCouponDiscountAmount, setOnlineCouponDiscountAmount] = useState()

    useEffect(() => {
        getAllSubscription()
        getCouponDiscount()
    }, [])

    const getAllSubscription = () => {
        axios.get(GET.SUBSCRIPTION)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setSubscriptions(data?.data)
                }
            })
    }

    const updateCouponDiscount = (type, updatedValue) => {

        setLoading(true)
        let obj = {
            couponType: type,
            couponDiscountValue: updatedValue
        }
        axios.post(ADMIN.UPDATE_COUPON_DISCOUNT, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    // console.log(data?.data)
                }
                setLoading(false)

            })
    }

    const getCouponDiscount = async () => {
        setLoading(true)
        axios.get(ADMIN.GET_COUPON_DISCOUNT)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setCardCouponDiscountAmount(data?.data[0]?.couponDiscountValue)
                    setOnlineCouponDiscountAmount(data?.data[1]?.couponDiscountValue)
                    setLoading(false)
                }
            })
    }
    // console.log(couponDiscount)
    const updateSubscription = async () => {
        setLoading(true)
        let allPromise = []
        for (var v of subscriptions) {
            allPromise.push(Promise.resolve(axios.post(POST.UPDATE_SUBSCRIPTION, v)))
        }
        await Promise.all(allPromise)
            .then(() => {
                setLoading(false)
                successNotification('Successfully Updated!')
                getAllSubscription()
            })
    }

    const updateState = (i, k, v) => {
        if (v < 0) {
            return
        }
        subscriptions[i][k] = v

        setSubscriptions([...subscriptions])
    }

    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <Tabs defaultActiveKey="1" centered>

                        <TabPane tab="Subscription Price" key="1">
                            <p className='heading head-center'>Prices Settings</p>
                            {subscriptions?.map((v, i) => {
                                return (
                                    <Row gutter={16} key={i} style={{ marginBottom: 20 }}>
                                        <Col span={24}>
                                            {v?.subscription ? <spna>
                                                <Checkbox checked={v?.active} onChange={e => updateState(i, 'active', e?.target?.checked)} />
                                                &nbsp;{v?.subscriptionName}&nbsp;&nbsp;
                                            </spna> : <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{v?.subscriptionName}&nbsp;&nbsp;</span>}
                                            <Input
                                                type='number'
                                                className='subs-input'
                                                value={v?.amount}
                                                min={0}
                                                onChange={e => updateState(i, 'amount', e?.target?.value)}
                                            />
                                        </Col>
                                    </Row>
                                )
                            })}
                            {subscriptions?.length ? <Button
                                onClick={updateSubscription}
                                className='form-button'
                                type='primary'
                                loading={loading}
                            >
                                Update
                            </Button> : null}
                        </TabPane>


                        <TabPane tab="Coupon Discount" key="2">
                            <Row style={{ marginBottom: 20 }}>
                                <span>
                                    Enter amount(€) of card coupon.
                                </span>
                                <div>
                                    <InputNumber
                                        placeholder='Enter Coupon amount!'
                                        style={{
                                            marginLeft: 15,
                                            marginRight: 15,
                                            width: 'calc(100% - 28px)',
                                        }}
                                        type='text'
                                        max={1000}
                                        min={0.1}
                                        step={0.1}
                                        maxLength={4}
                                        value={cardCouponDiscountAmount}
                                        onChange={(e) => setCardCouponDiscountAmount(e)}
                                    />
                                </div>
                                <Button
                                    onClick={() => updateCouponDiscount('physicalCoupon', cardCouponDiscountAmount)}
                                    className='form-button'
                                    type='primary'
                                    loading={loading}
                                >
                                    Update
                                </Button>
                            </Row>
                            <Row style={{ marginBottom: 20 }}>
                                <span>
                                    Enter amount(€) of online coupon.
                                </span>
                                <div>
                                    <InputNumber
                                        placeholder='Enter Coupon amount!'
                                        style={{
                                            marginLeft: 15,
                                            marginRight: 15,
                                            width: 'calc(100% - 28px)',
                                        }}
                                        type='text'
                                        max={1000}
                                        min={0.1}
                                        step={0.1}
                                        maxLength={4}
                                        value={onlineCouponDiscountAmount}
                                        onChange={(e) => setOnlineCouponDiscountAmount(e)}
                                    />
                                </div>
                                <Button
                                    onClick={() => updateCouponDiscount('onlineCoupon', onlineCouponDiscountAmount)}
                                    className='form-button'
                                    type='primary'
                                    loading={loading}
                                >
                                    Update
                                </Button>
                            </Row>


                        </TabPane>
                    </Tabs>

                </div>
            </div>
        </div>
    )
}

export default PriceSettings