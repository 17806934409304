import React, { useEffect, useState } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import axios from 'axios'
import { Button, Spin, Table, Modal, Typography, Select, DatePicker, Popconfirm } from 'antd'
import { getAllUserData, successNotification, errorMessage, convertTitle } from '../../../utils/helpers'
import { apiUrl } from '../../../utils/constants'
import { POST } from '../../../utils/apis'

const { Title } = Typography
const { RangePicker } = DatePicker
const { Option } = Select

const Reports = (props) => {
  const { user, userActions, onCancel } = props
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [spin, setSpin] = useState({})
  const [spinLoad, setSpinLoad] = useState(false)
  const [formId, setFormId] = useState(null)
  const [dateRange, setDateRange] = useState({
    date1: moment().add(-7, 'days').format('YYYY-MM-DD'),
    date2: moment().format('YYYY-MM-DD')
  })
  const forms = useSelector(state => state?.userReducer?.forms || [])
  const formReports = useSelector(state => state?.userReducer?.formReports || [])

  useEffect(() => {
    getAllUserData(user?._id, userActions)
  }, [formId])

  const columns = [
    {
      title: 'File Name',
      key: 'fileName',
      render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e?.fileName}</p>
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (e) => <div style={{ paddingTop: 15, marginTop: -5, display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        <a onClick={() => window.open(e?.reportUrl, '_blank')}>View</a>
        <Popconfirm
          title={`Delete ${convertTitle(e?.fileName)}?`}
          okText='Delete'
          cancelText='Cancel'
          okButtonProps={{
            type: 'primary',
            className: 'form-button'
          }}
          cancelButtonProps={{ style: { borderRadius: 4 } }}
          onConfirm={() => deleteReport(e?._id)}
        >
          <a href="#"><DeleteOutlined style={{ color: 'red' }} /> Delete</a>
          {spin[e?._id] ? <span className='demo-loading-container'>
            &nbsp;<Spin />
          </span> : null}
        </Popconfirm>
      </div>
    }
  ]

  const createReportRange = (d) => {
    let obj = {
      date1: d[0].format('YYYY-MM-DD'),
      date2: d[1].format('YYYY-MM-DD')
    }

    setDateRange(obj)
  }

  const disabledDate = (current) => {
    let customDate = moment().format('YYYY-MM-DD')
    return current && current > moment(customDate, 'YYYY-MM-DD').add(1, 'day')
  }

  const handleOk = () => {
    setLoading(true)
    let obj = {
      ...dateRange,
      formId,
      userId: user?._id
    }
    axios.post(`${apiUrl}${POST.CREATE_FORM_REPORT}/${user?._id}`, obj)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data?.success) {
          successNotification('Report Successfully Created!')
          userActions?.addAllFormReports(data?.data || [])
          setVisible(false)
        }
        else {
          errorMessage(data?.message)
        }
        setVisible(false)
      })
      .catch((e) => {
        errorMessage()
        console.log('e', e)
        setLoading(false)
      })
  }

  const deleteReport = (_id) => {
    setSpin({ [_id]: true })
    axios.post(`${POST.DELETE_FORM_REPORT}/${user?._id}`, { _id })
      .then((res) => {
        const { data } = res
        setSpin({})
        if (data?.success) {
          successNotification('Successfully Deleted!')
          userActions?.addAllFormReports(data?.data || [])
        }
      })
      .catch((e) => {
        setSpin({})
      })
  }

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <div className='flex-column menu-main'>
      <div className='user-table' style={{ marginLeft: 20 }}>
        <div className='flex-row' style={{ marginBottom: -30 }}>
          <Button
            type='primary'
            className='btn-end'
            onClick={onCancel}
          >
            Back
          </Button>
          <Button
            type='primary'
            className='btn-end'
            onClick={() => setVisible(true)}
          >
            Create Report
          </Button>
        </div>
        <br />
        <br />
        <Table
          loading={loading ? <Spin /> : ''}
          columns={columns}
          dataSource={formReports}
          pagination={{ hideOnSinglePage: true }}
          scroll={{ x: 800 }}
        />
      </div>
      <Modal
        title={<center>
          <Title level={4}>Create Form Report</Title>
        </center>}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        footer={[
          <Button key='back' onClick={handleCancel} className='form-input'>
            Cancel
          </Button>,
          <Button
            key='ok'
            onClick={handleOk}
            // disabled={coupon?.length !== 11}
            className='form-input'
            type='primary'
            loading={loading}
          >
            Create
          </Button>
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h3>Questionnaire Report</h3>
          <div className='flex-mobile' style={{ display: 'flex' }}>
            <RangePicker
              defaultValue={[moment().add(-7, 'days'), moment()]}
              onChange={createReportRange}
              style={{ marginBottom: 10, width: 300 }}
              disabledDate={disabledDate}
            />
          </div>
          <Select
            showSearch
            showArrow
            allowClear
            onSelect={(e) => setFormId(e)}
            placeholder={'Select Form'}
            style={{ width: 300 }}
            optionFilterProp='children'
            filterOption={(input, option) =>
              option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
            }
          >
            {forms?.map((v, i) => <Option value={v?._id} key={i}>{v?.formName}</Option>)}
          </Select>
        </div>
      </Modal>
    </div>
  )
}

export default Reports