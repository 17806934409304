import React, { useState, useRef } from 'react'
import { Form, Input, Button } from 'antd'
import { requiredMessage, inputPlace, successMessage, errorMessage } from '../../utils/helpers'
import { AUTH } from '../../utils/apis'
import axios from 'axios'

const ChangePassword = (props) => {
    const { user, authActions } = props
    const [loading, setLoading] = useState(false)
    const [btnText, setBtnText] = useState('Update Password')
    const antdForm = useRef(null)

    const onFinish = (values) => {
        values = { ...values, ...user }
        setLoading(true)
        setBtnText('Updating...')
        axios.post(AUTH.CHANGE_PASSWORD, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setBtnText('Update Password')
                if (data.success) {
                    successMessage('Password Updated Successfully!')
                    antdForm.current.resetFields(['password', 'newPassword', 'confirm'])
                    return authActions.loginUser(data.user)
                }
                errorMessage(data.message)
            })
            .catch((e) => {
                setLoading(false)
                setBtnText('Update Password')
                errorMessage()
            })
    }

    return (
        <div>
            <p className='setting-heading'>Change Password</p>
            <Form
                name='form'
                onFinish={onFinish}
                layout={'vertical'}
                requiredMark={false}
                ref={antdForm}
            >
                <Form.Item
                    name='password'
                    label='Current Password'
                    rules={[
                        {
                            required: true,
                            message: requiredMessage('Current Password')
                        },
                        {
                            min: 6
                        }
                    ]}
                >
                    <Input.Password
                        className='form-input'
                        placeholder={inputPlace('Current Password')}
                    />
                </Form.Item>
                <Form.Item
                    name='newPassword'
                    label='New Password'
                    rules={[
                        {
                            required: true,
                            message: requiredMessage('New Password')
                        },
                        {
                            min: 6
                        }
                    ]}
                >
                    <Input.Password
                        className='form-input'
                        placeholder={inputPlace('New Password')}
                    />
                </Form.Item>
                <Form.Item
                    name='confirm'
                    label='Confirm Password'
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: requiredMessage('confirm password'),
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve()
                                }
                                return Promise.reject('The two passwords that you entered do not match!')
                            },
                        })
                    ]}
                >
                    <Input.Password
                        placeholder={inputPlace('Confirm Password')}
                        className='form-input'
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        type='primary'
                        className='form-button'
                        block
                        htmlType='submit'
                        loading={loading}
                    >
                        {btnText}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default ChangePassword