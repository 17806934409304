import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Form, Button, Input, Select, DatePicker } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { inputPlace, requiredMessage, successNotification, warningMessage, errorMessage, getAllSegments, disabledDate } from '../../utils/helpers'
import { POST } from '../../utils/apis'
import countryCodes from '../../utils/countryCodes'

const { TextArea } = Input
const { Option } = Select

const AddGuest = (props) => {
    const { onCancel, edit, adminActions, user } = props
    const [loading, setLoading] = useState(false)
    const [country, setCountry] = useState(edit?.country || 'Netherlands')
    const [countryCode, setCountryCode] = useState(countryCodes.filter(v => v?.name === edit?.country)?.[0]?.dial_code || '+31')
    const segments = useSelector(state => state?.adminReducer?.segments || [])
    const formRef = useRef(null)

    useEffect(() => {
        getAllSegments(adminActions, user?._id)

        if (!user?.subscription?._id) {
            warningMessage('Please Select Subscription to send Messages!')
        }
    }, [])

    const onFinish = (values) => {
        setLoading(true)
        edit?._id && (values._id = edit?._id)
        values.userId = user?._id
        axios.post(POST.CREATE_GUEST, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(data?.message)
                    return setTimeout(() => {
                        onCancel()
                    }, 300)
                }
                errorMessage(data.message)
            })
            .catch((e) => {
                setLoading(false)
                errorMessage()
            })
    }

    const updateCountry = (e) => {
        let selectedCode = countryCodes.filter(v => v?.name === e)[0].dial_code

        setCountryCode(selectedCode)

        formRef.current.setFieldsValue({ prefix: selectedCode })
    }

    const prefixSelector = () => {
        return (
            <Form.Item name='prefix' noStyle>
                <Select
                    showSearch
                    disabled
                    style={{
                        minWidth: 80,
                        maxWidth: window.innerWidth > 500 ? 'auto' : 120
                    }}
                    defaultValue={countryCode}
                    value={countryCode}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {countryCodes?.map((v, i) => <Option value={v?.dial_code} key={i}>{`${v?.name} ${v?.dial_code}`}</Option>)}
                </Select>
            </Form.Item >
        )
    }

    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'>{edit?._id ? 'Update' : 'Create'} Guest</p>
                    <Form
                        name='form'
                        onFinish={onFinish}
                        layout={'vertical'}
                        ref={formRef}
                    >
                        <Form.Item
                            name='businessName'
                            label='Business Name'
                            hasFeedback
                            initialValue={edit?.businessName}
                        >
                            <Input
                                className='form-input'
                                placeholder={inputPlace('Business Name')}
                            />
                        </Form.Item>
                        <Form.Item
                            name='segment'
                            label='Segment'
                            hasFeedback
                            initialValue={edit?.segment?._id}
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Segment')
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                showArrow
                                allowClear
                                className='form-input'
                                placeholder={`Select Segment`}
                                optionFilterProp='children'
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {segments?.map((v, i) => <Option value={v?._id} key={i}>{v?.name}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name='startingDate'
                            label='Starting Date'
                            hasFeedback
                            initialValue={edit?.startingDate ? moment(edit?.startingDate) : null}
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Starting Date')
                                }
                            ]}
                        >
                            <DatePicker
                                style={{ width: '100%' }}
                                disabledDate={disabledDate}
                            />
                        </Form.Item>
                        <Form.Item
                            name='name'
                            label='Guest Name'
                            hasFeedback
                            initialValue={edit?.name}
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Guest Name')
                                },
                                {
                                    max: 30,
                                    message: 'Maximum 30 Characters Allowed!'
                                }
                            ]}
                        >
                            <Input
                                className='form-input'
                                placeholder={inputPlace('Guest Name')}
                                maxLength={30}
                            />
                        </Form.Item>
                        <Form.Item
                            name='streetHouse'
                            label='Street and House No'
                            initialValue={edit?.streetHouse}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Street and House No')
                                },
                                {
                                    max: 30,
                                    message: 'Maximum 30 Characters Allowed!'
                                }
                            ]}
                        >
                            <Input
                                className='form-input'
                                placeholder={inputPlace('Street and House No')}
                                maxLength={30}
                            />
                        </Form.Item>
                        <Form.Item
                            name='postalCodeCity'
                            label='Postal Code And City'
                            initialValue={edit?.postalCodeCity}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Postal Code And City')
                                },
                                {
                                    max: 30,
                                    message: 'Maximum 30 Characters Allowed!'
                                }
                            ]}
                        >
                            <Input
                                className='form-input'
                                placeholder={inputPlace('Postal Code And City')}
                                maxLength={30}
                            />
                        </Form.Item>
                        <Form.Item
                            name='country'
                            label='Country'
                            hasFeedback
                            initialValue={edit?.country ? edit?.country : country}
                        >
                            <Select
                                showSearch
                                showArrow
                                allowClear
                                className='form-input'
                                placeholder={`Select Country`}
                                optionFilterProp='children'
                                onChange={updateCountry}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {countryCodes?.map((v, i) => <Option value={v?.name} key={i}>{v?.name}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name='telNo'
                            label='Tel No'
                            initialValue={edit?.telNo}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Tel No')
                                },
                                {
                                    max: 30,
                                    message: 'Maximum 30 Characters Allowed!'
                                }
                            ]}
                        >
                            <Input
                                addonBefore={prefixSelector()}
                                className='form-input'
                                placeholder={'Tel No e.g 612345678'}
                                type='tel'
                                maxLength={30}
                            />
                        </Form.Item>
                        <Form.Item
                            name='email'
                            label='Guest Email'
                            initialValue={edit?.email}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Guest Email')
                                }
                            ]}
                        >
                            <Input
                                className='form-input'
                                placeholder={inputPlace('Guest Email')}
                            />
                        </Form.Item>
                        <Form.Item
                            name='note'
                            label='Note'
                            initialValue={edit?.note}
                            hasFeedback
                        >
                            <TextArea
                                className='form-input'
                                placeholder={inputPlace('Note')}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type='primary'
                                className='form-button'
                                block
                                htmlType='submit'
                                loading={loading}
                            >
                                {edit?._id ? 'Update' : 'Create'}
                            </Button>
                            <Button
                                style={{ borderRadius: 4, marginTop: 6 }}
                                block
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

AddGuest.propTypes = {
    onCancel: PropTypes.func.isRequired
}

export default AddGuest