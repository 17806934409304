import { ALL_USERS, ALL_SEGMENTS, ALL_CARDS, USERS_INVOICES, MESSAGES_LIST, REPORTS, FINANCIAL_REPORTS, STOCK_REPORTS, ALL_PUBLIC_CARDS, POSTAL_CARDS, ALL_BUSINESS_USERS, ALL_GROUPS, ADMIN_FORM_REPORTS ,PROVISION_REPORTS } from '../types'

const reducer = (state = {}, action) => {
    switch (action.type) {
        case ALL_USERS: {
            return { ...state, users: action.users }
        }
        case ALL_SEGMENTS: {
            return { ...state, segments: action.segments }
        }
        case ALL_CARDS: {
            return { ...state, cards: action.cards }
        }
        case ALL_GROUPS: {
            return { ...state, groups: action.groups }
        }
        case ALL_PUBLIC_CARDS: {
            return { ...state, publicCards: action.publicCards }
        }
        case USERS_INVOICES: {
            return { ...state, usersInvoices: action.usersInvoices }
        }
        case MESSAGES_LIST: {
            return { ...state, messagesList: action.messagesList }
        }
        case REPORTS: {
            return { ...state, reports: action.reports }
        }
        case FINANCIAL_REPORTS: {
            return { ...state, financialReports: action.financialReports }
        }
        case PROVISION_REPORTS: {
            return { ...state, provisionReport: action.provisionReport }
        }
        case STOCK_REPORTS: {
            return { ...state, stockReports: action.stockReports }
        }
        case POSTAL_CARDS: {
            return { ...state, postalCards: action.postalCards }
        }
        case ALL_BUSINESS_USERS: {
            return { ...state, buUsers: action.buUsers }
        }
        case ADMIN_FORM_REPORTS: {
            return { ...state, adminFormReports: action.adminFormReports }
        }
        default: {
            return state
        }
    }
}

export default reducer