import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Form, Input, Button } from 'antd'
import { requiredMessage, inputPlace, successNotification, errorMessage } from '../../utils/helpers'
import { allPaths } from '../../utils/constants'
import { AUTH } from '../../utils/apis'
import { SocialLoginButtons } from '../../Components'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import axios from 'axios'

const Login = (props) => {
  const { history } = props
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const path = history?.location?.pathname

  const onFinish = (values) => {
    values.userType = path === allPaths?.BU_LOGIN ? 'business' : 'guest'
    const { history } = props
    setLoading(true)
    axios.post(AUTH.LOGIN, values)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data.success) {
          successNotification('Successfully Logged In!')
          dispatch(loginUser(data.user))
          return setTimeout(() => {
            history?.replace(allPaths?.HOME)
          }, 300)
        }
        errorMessage(data.message)
      })
      .catch((e) => {
        setLoading(false)
        errorMessage()
      })
  }

  useEffect(() => {
    dispatch(removeUser())
  }, [])

  return (
    <div className='card-parent background-layout'>
      <div className='card1'>
        <p className='heading head-center'>
          {path === allPaths?.BU_LOGIN ? 'Business Login' : 'Login'}
        </p>
        <Form
          name='form'
          onFinish={onFinish}
          layout={'vertical'}
          requiredMark={false}
        >
          <Form.Item
            name='email'
            label='Email'
            hasFeedback
            rules={[
              {
                required: true,
                message: requiredMessage('Email'),
              },
              {
                type: 'email',
              },
            ]}
          >
            <Input className='form-input' placeholder={inputPlace('Email')} />
          </Form.Item>
          <Form.Item
            name='password'
            label='Password'
            hasFeedback
            rules={[
              {
                required: true,
                message: requiredMessage('Password'),
              },
              {
                min: 6,
              },
            ]}
          >
            <Input.Password
              className='form-input'
              placeholder={inputPlace('Password')}
            />
          </Form.Item>

          {path === allPaths?.BU_LOGIN ? (
            <Form.Item>
              {/* <Link to='/login/bu'> */}
              <Button
                type='primary'
                className='form-button'
                block
                htmlType='submit'
                loading={loading}
              >
                Business Login
              </Button>
              {/* </Link> */}
            </Form.Item>
          ) : (
            <Form.Item>
              <Button
                type='primary'
                className='form-button'
                block
                htmlType='submit'
                loading={loading}
              >
                Login
              </Button>
            </Form.Item>
          )}

          <center>
            <p>or</p>
          </center>

          {path === allPaths?.BU_LOGIN ? (
            <Form.Item>
              <Link to='/login'>
                <Button
                  type='primary'
                  className='form-button'
                  block
                  htmlType='submit'
                >
                  Login as guest
                </Button>
              </Link>
            </Form.Item>
          ) : (
            <Form.Item>
              <Link to={allPaths?.BU_LOGIN}>
                <Button
                  type='primary'
                  className='form-button'
                  block
                  htmlType='submit'
                >
                  Business Login
                </Button>
              </Link>
            </Form.Item>
          )}
        </Form>
        {/* <SocialLoginButtons {...props} loginUser={(e) => dispatch(loginUser(e))} /> */}
        <p>
          Don't have an account?&nbsp;
          {path === allPaths?.BU_LOGIN ? <span>
            <Link to={allPaths?.BU_SIGNUP}>Business Sign up</Link>
            <br />
            <Link to={allPaths?.BU_FORGOT}>Forgot Password</Link>
          </span> : <span>
            <Link to={allPaths.SIGNUP}>Sign up</Link>
            <br />
            <Link to={allPaths?.FORGOT}>Forgot Password</Link>
          </span>}
        </p>
      </div>
    </div>
  )
}

export default Login
