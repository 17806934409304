import React, { useState } from 'react'
import { Tabs } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { ChangePassword, UserSettings } from '../../Components'

const { TabPane } = Tabs

const Settings = (props) => {
    const user = useSelector(state => state.authReducer.user)
    const dispatch = useDispatch()
    const [state, updateState] = useState({
    })
    // const dispatch = useDispatch()

    // const reduxActions = {
    //     loginUser: (u) => dispatch(loginUser(u)),
    //     updateRoute: (selectedKey) => dispatch(updateRoute(selectedKey)),
    //     removeUser: () => dispatch(removeUser()),
    //     setArtifactData: (artifact) => dispatch(setArtifactData(artifact)),
    // }

    const onFinish = (value) => {

    }

    return (
        <div className='card-form'>
            <div className='card2'>
                <Tabs defaultActiveKey='1' centered>
                    <TabPane tab={'Profile'} key='1'>
                        <UserSettings {...props} />
                    </TabPane>
                    <TabPane tab='Security' key='2'>
                        <ChangePassword {...props} />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default Settings