import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input, Radio, AutoComplete, Checkbox, InputNumber, Switch } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ADMIN } from '../../../utils/apis'
import { errorMessage, requiredMessage, successMessage } from '../../../utils/helpers'
import { message, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { FileOutlined } from '@ant-design/icons'
import _, { includes } from 'lodash'
const { TextArea } = Input

const AddQuestions = (props) => {
    const { onCancel, user, copyQuestion, setCopyQuestion, openQuestions } = props

    const [form] = Form.useForm()
    const [ansType, setAnsType] = useState(copyQuestion?.answerType || 'input')
    const [ansList, setansList] = useState(copyQuestion?.answerList || '')
    const [loading, setLoading] = useState(false)
    const [allChapters, setAllChapters] = useState([])
    const [chapterValue, setchapterValue] = useState(copyQuestion.chapter || {})
    const [paragraphValue, setParagraphValue] = useState(copyQuestion.paragraph || {})
    const [fileList, setFileList] = useState(copyQuestion?.file?.url ? [copyQuestion?.file] : [])
    const [questionType, setQuestionType] = useState(copyQuestion?.questionType || 'questionnaire')
    useEffect(() => {
        getAllChapters()
    }, [])
    const getAllChapters = () => {
        axios.get(`${ADMIN.GET_ALL_CHAPTERS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setAllChapters(data?.data || [])
                }
            })
    }
    var error1
    var error2
    // const normFile = (e) => {
    //     setFileList([])

    //     if (Array.isArray(e)) {
    //         return e
    //     }
    //     e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
    //     return e && [e.file]
    // }
    const normFile = (e) => {
        return e?.fileList[0];
    }
    const normFile2 = (e) => {
        return setFileList(e?.fileList);
    }

    const onChange = e => {
        setAnsType(e.target.value)
    }

    const getTopicId = (value) => {
        return allChapters?.filter((y) => y?.chapterName === chapterValue?.chapterName)?.map((v, i) => v?.paragraph)?.flat()?.filter((x) => x?._id === paragraphValue?.paragraphId)[0]?.topics?.filter((x) => x?.topicName?.toLowerCase() === value?.toLowerCase())[0]?._id
    }
    const getChapterId = (value) => {
        return allChapters?.filter((v, i) => v?.chapterName?.toLowerCase() === value?.toLowerCase())[0]?._id
    }
    const getParagraphId = (value) => {
        // return allChapters?.filter((v, i) => v?.paragraph?.paragraphName?.toLowerCase() === value?.toLowerCase())[0]?.paragraph?._id
        return allChapters?.filter((v, i) => v?._id === chapterValue?.chapterId)[0]?.paragraph?.filter((x) => x?.paragraphName?.toLowerCase() === value?.toLowerCase())[0]?._id
    }
    const getChapterName = (value) => {
        return allChapters?.filter((v, i) => v?.chapterName?.toLowerCase() === value?.toLowerCase())[0]?.chapterName
    }
    const getParagraphName = (value) => {
        return allChapters?.filter((v, i) => v?._id === chapterValue?.chapterId)[0]?.paragraph?.filter((x) => x?.paragraphName?.toLowerCase() === value?.toLowerCase())[0]?.paragraphName
    }
    const onFinish = async (values) => {


        // values.chapter = chapterValue
        // values.paragraph = paragraphValue

        // console.log('values', values)
        // return true

        let tempArr = values?.topics?.map(element => element?.toLowerCase())
        let cond = _.uniq(tempArr)?.length !== tempArr?.length

        if (cond && !user?.userType) {
            return errorMessage('Topics must be unique')
        }
        setLoading(true)
        values.answerType = ansType
        if (ansType === 'input') {
            values.answerList = values?.answerList1
        }
        else if (ansType === 'radio') {
            values.answerList = values?.answerList2
        }
        else if (ansType === 'multiple') {
            values.answerList = values?.answerList3
        }
        else {
            values.answerList = values?.answerList4
        }
        if (ansType === 'input') {
            values.answerList = values?.answerList5
        }
        let checkCorrectAnsCond = _.find(values?.answerList, { 'isTrue': true })
        if (values?.questionType === 'test' && values?.answerType !== 'input' && values?.answerType !== 'upload' && !checkCorrectAnsCond) {
            setLoading(false)
            return errorMessage('Please Select a Correct Answer')
        }
        values.userId = user?._id
        values.questionType = questionType
        values.point = Number(values?.point || 0)
        values.chapter = chapterValue
        values.paragraph = paragraphValue

        if (values?.topics?.length > 0) {
            values.topic1 = { topicName: values?.topics[0], topicId: getTopicId(values?.topics[0]) || null }
        }
        if (values?.topics?.length > 1) {
            values.topic2 = { topicName: values?.topics[1], topicId: getTopicId(values?.topics[1]) || null }
        }
        if (values?.topics?.length > 2) {
            values.topic3 = { topicName: values?.topics[2], topicId: getTopicId(values?.topics[2]) || null }
        }

        delete values?.answerList1
        delete values?.answerList2
        delete values?.answerList3
        delete values?.answerList4

        if (fileList[0]?.originFileObj) {
            let formData = new FormData()
            formData.append('file', fileList[0]?.originFileObj)
            await axios.post(ADMIN.GET_FILE_URL, formData)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        values.file = data?.data
                    } else {
                        return errorMessage('File Upload Error')
                    }
                })
                .catch((e) => {
                    console.log(e)
                    return errorMessage()

                })
        } else if (fileList[0]?.url) {
            values.file = fileList[0]?.url
        }
        for (var i = 0; i < values?.answerList?.length; i++) {
            if (values?.answerList[i]?.file?.originFileObj) {
                let fileData = new FormData()
                fileData.append('file', values?.answerList[i]?.file?.originFileObj)
                await axios.post(ADMIN.GET_FILE_URL, fileData)
                    .then((res) => {
                        const { data } = res
                        if (data.success) {
                            values.answerList[i].file = data?.data
                        } else {
                            return errorMessage('File Upload Error')
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                        return errorMessage()

                    })
            }
        }
        delete values?.topics
        console.log('values', values)
        // return true

        axios.post(ADMIN.CREATE_QUESTION, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successMessage('Question Created Successfully!')
                    form.resetFields()
                    openQuestions()
                    setCopyQuestion({})
                    openQuestions()
                    onCancel()
                } else {
                    errorMessage(data.message)
                }
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
                errorMessage()
            })
    }
    const optionsTopics = allChapters?.filter((y) => y?.chapterName === chapterValue?.chapterName)?.map((v, i) => v?.paragraph)?.flat()?.filter((x) => x?._id === paragraphValue?.paragraphId)?.map((x) => x?.topics)?.flat()?.map((z) => {
        return {
            value: z?.topicName,
            id: z?._id
        }
    })

    const optionsParagraph = allChapters?.filter((y) => y?.chapterName === chapterValue?.chapterName)?.map((v, i) => v?.paragraph).flat().map((x) => {
        return {
            value: x?.paragraphName,
            id: x?._id,
        }
    })
    const optionsChapter = allChapters?.map((v, i) => {
        return {
            value: v?.chapterName
        }
    })
    const onSearchChapter = (searchText) => {
        // setchapterValue({ chapterName: searchText, chapterId:null })
        if (getChapterId(searchText)) {
            setchapterValue({ chapterName: getChapterName(searchText), chapterId: getChapterId(searchText) })
        } else {
            setchapterValue({ chapterName: searchText, chapterId: getChapterId(searchText) || null })
        }
    }
    const onSelectChapter = (data) => {
        setchapterValue({
            chapterName: allChapters?.filter((v, i) => v?.chapterName === data)[0]?.chapterName,
            chapterId: allChapters?.filter((v, i) => v?.chapterName === data)[0]?._id,
        })
    }
    const onSearchParagraph = (searchText) => {
        setParagraphValue({ paragraphName: searchText, paragraphId: null })
        if (getParagraphId(searchText)) {
            setParagraphValue({ paragraphName: getParagraphName(searchText), paragraphId: getParagraphId(searchText) || null })
        } else {
            setParagraphValue({ paragraphName: searchText, paragraphId: null })
        }
    }

    const onSelectParagraph = (data) => {
        setParagraphValue({
            paragraphName: data,
            paragraphId: optionsParagraph?.filter((v) => v?.value === data)[0]?.id,
        })
    }
    const onChangeQuestionType = (e) => {
        setQuestionType(e?.target?.value)
    }
    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'>Create Questions</p>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        name='QuestionsForm'
                        layout='vertical'
                        requiredMark={true}>
                        <Form.Item
                            name='title'
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Title'),
                                }
                            ]}
                            label='Title'
                            required
                            initialValue={copyQuestion?.title}
                        >
                            <Input.TextArea rows={2} placeholder='Title' />
                        </Form.Item>
                        {user?.userType != 'guest' && user?.userType != 'business' ?
                            <>
                                <Form.Item
                                    name='point'
                                    rules={[
                                        {
                                            required: true,
                                            message: requiredMessage('Points'),
                                        }
                                    ]}
                                    label='Points'
                                    required
                                    initialValue={copyQuestion?.point}
                                >
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        placeholder='2'
                                        type='number'
                                        max={1000}
                                        min={0}
                                        step={1}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='questionType'
                                    label='Question Type'
                                    required
                                    initialValue={copyQuestion?.questionType}
                                >
                                    <Radio.Group disabled={copyQuestion?.questionType} value={questionType} onChange={onChangeQuestionType} defaultValue='questionnaire'>
                                        <Radio.Button value='questionnaire'>Questionnaire</Radio.Button>
                                        <Radio.Button value='test'>Test</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </>
                            : null
                        }
                        <Form.Item
                            name='question'
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Question'),
                                }
                            ]}
                            label='Question'
                            required
                            initialValue={copyQuestion?.question}
                        >
                            <Input.TextArea rows={2} placeholder='e.g What is capital of Neatherland?' />
                        </Form.Item>
                        <Form.Item
                            label='File'
                            name='file'
                            style={{ width: '100%' }}
                            valuePropName='file'
                            getValueFromEvent={normFile2}
                        >
                            <Upload
                                className='questionFileUpload'
                                multiple={false}
                                maxCount={1}
                                beforeUpload={() => false}
                                fileList={fileList}
                                accept='image/*, video/*'
                                action='/upload.do'
                                style={{ width: '100%' }}
                            >
                                <Button style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>Click to upload</Button>
                                <br />
                            </Upload>
                        </Form.Item>

                        <Form.Item label='Select type of answer you want from the guest'>
                            <Radio.Group onChange={onChange} value={ansType}>
                                <Radio value={'input'}>Input</Radio>
                                <Radio value={'radio'}>Radio options</Radio>
                                <Radio value={'multiple'}>Select Multiple options</Radio>
                                <Radio value={'single'}>Select single option</Radio>
                                <Radio value={'upload'}>Upload option</Radio>
                            </Radio.Group>
                        </Form.Item>

                        {questionType === 'test' ?
                            (
                                ansType === 'input' ? null :
                                    ansType === 'upload' ? null :
                                        ansType === 'radio' ?
                                            <Form.List
                                                name='answerList2'
                                                rules={[
                                                    {
                                                        validator: async (_, names) => {
                                                            if (!names || names.length < 2) {
                                                                return Promise.reject(new Error('At least 2 options'))
                                                            }
                                                        }
                                                    }
                                                ]}
                                                initialValue={ansList}
                                            >
                                                {(fields, { add, remove }, { errors }) => (
                                                    <>
                                                        {fields.map((field, name, index) => (
                                                            <Form.Item
                                                                required={false}
                                                                key={field.key}
                                                            >
                                                                <div style={{ display: 'flex' }}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[name, 'isTrue']}
                                                                        valuePropName='checked'
                                                                        noStyle
                                                                        initialValue={false}
                                                                    >
                                                                        <Switch style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' />
                                                                    </Form.Item>

                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[name, 'option']}
                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                whitespace: true,
                                                                                message: 'Please input options or delete this field.',
                                                                            },
                                                                        ]}
                                                                        noStyle
                                                                    >
                                                                        <Input.TextArea rows={2} placeholder='Option' style={{ width: '100%' }} />
                                                                    </Form.Item>
                                                                </div>

                                                                <Form.Item
                                                                    {...field}
                                                                    name={[name, 'explaination']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            whitespace: true,
                                                                            message: 'Please input Explaination.',
                                                                        },
                                                                    ]}
                                                                    noStyle
                                                                >
                                                                    <Input.TextArea rows={2} placeholder='explanation' style={{ width: '100%', marginTop: 5 }} />
                                                                </Form.Item>
                                                                <div style={{ display: 'flex' }}>
                                                                    <div style={{ width: '100%' }}>
                                                                        <Form.Item
                                                                            {...field}
                                                                            name={[name, 'file']}
                                                                            style={{ width: '100%' }}
                                                                            valuePropName='file'
                                                                            getValueFromEvent={normFile}

                                                                        >
                                                                            <Upload
                                                                                className='questionFileUpload'
                                                                                multiple={false}
                                                                                maxCount={1}
                                                                                beforeUpload={() => false}
                                                                                accept='image/*, video/*'
                                                                                action='/upload.do'
                                                                                style={{ width: '100%' }}
                                                                            >
                                                                                <Button disabled={ansList[name]?.file?.url} style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>Click to upload</Button>
                                                                                <br />
                                                                            </Upload>
                                                                        </Form.Item>
                                                                        {ansList[name]?.file?.url ? <a target='blank' href={ansList[name]?.file?.url}><FileOutlined />&nbsp;{ansList[name]?.file?.name}</a> : null}
                                                                    </div>
                                                                    &nbsp;
                                                                    {fields.length > 1 ? (
                                                                        <MinusCircleOutlined
                                                                            style={{ marginTop: 8 }}
                                                                            className='dynamic-delete-button'
                                                                            onClick={() => remove(field.name)}
                                                                        />
                                                                    ) : null}
                                                                </div>
                                                            </Form.Item>
                                                        ))}
                                                        <Form.Item>
                                                            <Button
                                                                type='dashed'
                                                                onClick={() => add()}
                                                                style={{ width: '100%' }}
                                                                icon={<PlusOutlined />}
                                                            >
                                                                Add field
                                                            </Button>
                                                            <Form.ErrorList errors={errors} />
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List> :
                                            ansType === 'multiple' ?
                                                <Form.List
                                                    name='answerList3'
                                                    rules={[
                                                        {
                                                            validator: async (_, names) => {
                                                                if (!names || names.length < 2) {
                                                                    return Promise.reject(new Error('At least 2 options'))
                                                                }
                                                            }
                                                        }
                                                    ]}
                                                    initialValue={ansList}
                                                >
                                                    {(fields, { add, remove }, { errors }) => (
                                                        <>
                                                            {fields.map((field, name, index) => (
                                                                <Form.Item
                                                                    required={false}
                                                                    key={field.key}
                                                                >
                                                                    <div style={{ display: 'flex' }}>
                                                                        <Form.Item
                                                                            {...field}
                                                                            name={[name, 'isTrue']}
                                                                            valuePropName='checked'
                                                                            noStyle
                                                                            initialValue={false}
                                                                        >
                                                                            <Switch style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' />
                                                                        </Form.Item>

                                                                        <Form.Item
                                                                            {...field}
                                                                            name={[name, 'option']}
                                                                            validateTrigger={['onChange', 'onBlur']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    whitespace: true,
                                                                                    message: 'Please input options or delete this field.',
                                                                                },
                                                                            ]}
                                                                            noStyle
                                                                        >
                                                                            <Input.TextArea rows={2} placeholder='Option' style={{ width: '100%' }} />
                                                                        </Form.Item>
                                                                    </div>

                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[name, 'explaination']}
                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                whitespace: true,
                                                                                message: 'Please input Explaination.',
                                                                            },
                                                                        ]}
                                                                        noStyle
                                                                    >
                                                                        <Input.TextArea rows={2} placeholder='explanation' style={{ width: '100%', marginTop: 5 }} />
                                                                    </Form.Item>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <div style={{ width: '100%' }}>
                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[name, 'file']}
                                                                                style={{ width: '100%' }}
                                                                                valuePropName='file'
                                                                                getValueFromEvent={normFile}

                                                                            >
                                                                                <Upload
                                                                                    className='questionFileUpload'
                                                                                    multiple={false}
                                                                                    maxCount={1}
                                                                                    beforeUpload={() => false}
                                                                                    accept='image/*, video/*'
                                                                                    action='/upload.do'
                                                                                    style={{ width: '100%' }}
                                                                                >
                                                                                    <Button disabled={ansList[name]?.file?.url} style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>Click to upload</Button>
                                                                                    <br />
                                                                                </Upload>
                                                                            </Form.Item>
                                                                            {ansList[name]?.file?.url ? <a target='blank' href={ansList[name]?.file?.url}><FileOutlined />&nbsp;{ansList[name]?.file?.name}</a> : null}
                                                                        </div>
                                                                        &nbsp;
                                                                        {fields.length > 1 ? (
                                                                            <MinusCircleOutlined
                                                                                style={{ marginTop: 8 }}
                                                                                className='dynamic-delete-button'
                                                                                onClick={() => remove(field.name)}
                                                                            />
                                                                        ) : null}
                                                                    </div>
                                                                </Form.Item>
                                                            ))}
                                                            <Form.Item>
                                                                <Button
                                                                    type='dashed'
                                                                    onClick={() => add()}
                                                                    style={{ width: '100%' }}
                                                                    icon={<PlusOutlined />}
                                                                >
                                                                    Add field
                                                                </Button>
                                                                <Form.ErrorList errors={errors} />
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List> :
                                                <Form.List
                                                    name='answerList4'
                                                    rules={[
                                                        {
                                                            validator: async (_, names) => {
                                                                if (!names || names.length < 2) {
                                                                    return Promise.reject(new Error('At least 2 options'))
                                                                }
                                                            }
                                                        }
                                                    ]}
                                                    initialValue={ansList}
                                                >
                                                    {(fields, { add, remove }, { errors }) => (
                                                        <>
                                                            {fields.map((field, name, index) => (
                                                                <Form.Item
                                                                    required={false}
                                                                    key={field.key}
                                                                >
                                                                    <div style={{ display: 'flex' }}>
                                                                        <Form.Item
                                                                            {...field}
                                                                            name={[name, 'isTrue']}
                                                                            valuePropName='checked'
                                                                            noStyle
                                                                            initialValue={false}
                                                                        >
                                                                            <Switch style={{ marginRight: 5, marginTop: 5 }} checkedChildren='T' unCheckedChildren='F' />
                                                                        </Form.Item>

                                                                        <Form.Item
                                                                            {...field}
                                                                            name={[name, 'option']}
                                                                            validateTrigger={['onChange', 'onBlur']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    whitespace: true,
                                                                                    message: 'Please input options or delete this field.',
                                                                                },
                                                                            ]}
                                                                            noStyle
                                                                        >
                                                                            <Input.TextArea rows={2} placeholder='Option' style={{ width: '100%' }} />
                                                                        </Form.Item>
                                                                    </div>

                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[name, 'explaination']}
                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                whitespace: true,
                                                                                message: 'Please input Explaination.',
                                                                            },
                                                                        ]}
                                                                        noStyle
                                                                    >
                                                                        <Input.TextArea rows={2} placeholder='explanation' style={{ width: '100%', marginTop: 5 }} />
                                                                    </Form.Item>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <div style={{ width: '100%' }}>
                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[name, 'file']}
                                                                                style={{ width: '100%' }}
                                                                                valuePropName='file'
                                                                                getValueFromEvent={normFile}

                                                                            >
                                                                                <Upload
                                                                                    className='questionFileUpload'
                                                                                    multiple={false}
                                                                                    maxCount={1}
                                                                                    beforeUpload={() => false}
                                                                                    accept='image/*, video/*'
                                                                                    action='/upload.do'
                                                                                    style={{ width: '100%' }}
                                                                                >
                                                                                    <Button disabled={ansList[name]?.file?.url} style={{ width: '100%', marginTop: 5 }} icon={<UploadOutlined />}>Click to upload</Button>
                                                                                    <br />
                                                                                </Upload>
                                                                            </Form.Item>
                                                                            {ansList[name]?.file?.url ? <a target='blank' href={ansList[name]?.file?.url}><FileOutlined />&nbsp;{ansList[name]?.file?.name}</a> : null}
                                                                        </div>
                                                                        &nbsp;
                                                                        {fields.length > 1 ? (
                                                                            <MinusCircleOutlined
                                                                                style={{ marginTop: 8 }}
                                                                                className='dynamic-delete-button'
                                                                                onClick={() => remove(field.name)}
                                                                            />
                                                                        ) : null}
                                                                    </div>
                                                                </Form.Item>
                                                            ))}
                                                            <Form.Item>
                                                                <Button
                                                                    type='dashed'
                                                                    onClick={() => add()}
                                                                    style={{ width: '100%' }}
                                                                    icon={<PlusOutlined />}
                                                                >
                                                                    Add field
                                                                </Button>
                                                                <Form.ErrorList errors={errors} />
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>) :
                            (
                                ansType === 'input' ? '' :
                                    ansType === 'upload' ? ''
                                        : ansType === 'radio' ?
                                            <Form.List
                                                name='answerList2'
                                                rules={[
                                                    {
                                                        validator: async (_, names) => {
                                                            if (!names || names.length < 2) {
                                                                return Promise.reject(new Error('At least 2 options'));
                                                            }
                                                        },
                                                    },
                                                ]}
                                                initialValue={ansList}
                                            >
                                                {(fields, { add, remove }, { errors }) => (
                                                    <>
                                                        {fields.map((field, name, index) => (
                                                            <Form.Item
                                                                // label={index === 0 ? 'options' : null}
                                                                required={false}
                                                                key={field.key}
                                                            >
                                                                <Form.Item
                                                                    {...field}
                                                                    name={[name, 'option']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            whitespace: true,
                                                                            message: 'Please input options or delete this field.',
                                                                        },
                                                                    ]}
                                                                    noStyle
                                                                >
                                                                    <Input.TextArea rows={2} placeholder='Option' style={{ width: '90%' }} />
                                                                </Form.Item> &nbsp;
                                                                {fields.length > 1 ? (
                                                                    <MinusCircleOutlined
                                                                        className='dynamic-delete-button'
                                                                        onClick={() => remove(field.name)}
                                                                    />
                                                                ) : null}
                                                            </Form.Item>
                                                        ))}
                                                        <Form.Item>
                                                            <Button
                                                                type='dashed'
                                                                onClick={() => add()}
                                                                style={{ width: '100%' }}
                                                                icon={<PlusOutlined />}
                                                            >
                                                                Add field
                                                            </Button>

                                                            <Form.ErrorList errors={errors} />
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List> : ansType === 'multiple' ?
                                                <Form.List
                                                    name='answerList3'
                                                    rules={[
                                                        {
                                                            validator: async (_, names) => {
                                                                if (!names || names.length < 2) {
                                                                    return Promise.reject(new Error('At least 2 options'));
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                    initialValue={ansList}
                                                >
                                                    {(fields, { add, remove }, { errors = error1 }) => (
                                                        <>
                                                            {fields.map((field, name, index) => (
                                                                <Form.Item
                                                                    required={false}
                                                                    key={field.key}
                                                                >
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[name, 'option']}
                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                whitespace: true,
                                                                                message: 'Please input options or delete this field.',
                                                                            },
                                                                        ]}
                                                                        noStyle
                                                                    >
                                                                        <Input.TextArea rows={2} placeholder='Option' style={{ width: '90%' }} />
                                                                    </Form.Item> &nbsp;
                                                                    {fields.length > 1 ? (
                                                                        <MinusCircleOutlined
                                                                            className='dynamic-delete-button'
                                                                            onClick={() => remove(field.name)}
                                                                        />
                                                                    ) : null}
                                                                </Form.Item>
                                                            ))}
                                                            <Form.Item>
                                                                <Button
                                                                    type='dashed'
                                                                    onClick={() => add()}
                                                                    style={{ width: '100%' }}
                                                                    icon={<PlusOutlined />}
                                                                >
                                                                    Add field
                                                                </Button>

                                                                <Form.ErrorList errors={error1} />
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List> :
                                                <Form.List
                                                    name='answerList4'
                                                    rules={[
                                                        {
                                                            validator: async (_, names) => {
                                                                if (!names || names.length < 2) {
                                                                    return Promise.reject(new Error('At least 2 options'));
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                    initialValue={ansList}
                                                >
                                                    {(fields, { add, remove }, { errors = error2 }) => (
                                                        <>
                                                            {fields.map((field, name, index) => (
                                                                <Form.Item
                                                                    // label={index === 0 ? 'options' : ''}
                                                                    required={false}
                                                                    key={field.key}
                                                                >
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[name, 'option']}
                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                whitespace: true,
                                                                                message: 'Please input options or delete this field.',
                                                                            },
                                                                        ]}
                                                                        noStyle
                                                                    >
                                                                        <Input.TextArea rows={2} placeholder='Option' style={{ width: '90%' }} />
                                                                    </Form.Item> &nbsp;
                                                                    {fields.length > 1 ? (
                                                                        <MinusCircleOutlined
                                                                            className='dynamic-delete-button'
                                                                            onClick={() => remove(field.name)}
                                                                        />
                                                                    ) : null}
                                                                </Form.Item>
                                                            ))}
                                                            <Form.Item>
                                                                <Button
                                                                    type='dashed'
                                                                    onClick={() => add()}
                                                                    style={{ width: '100%' }}
                                                                    icon={<PlusOutlined />}
                                                                >
                                                                    Add field
                                                                </Button>

                                                                <Form.ErrorList errors={error2} />
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>)

                        }
                        <Form.Item
                            name='chapter'
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('chapter'),
                                }
                            ]}
                            required
                            label='Chapters'
                            initialValue={copyQuestion?.chapter?.chapterName}
                        >
                            <AutoComplete
                                // options={_.uniqBy(optionsChapter, 'value')}
                                options={optionsChapter}
                                placeholder='Enter Chapter'
                                onSearch={onSearchChapter}
                                onSelect={onSelectChapter}
                                filterOption={(inputValue, option) =>
                                    option.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            name='paragraph'
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('paragraph'),
                                }
                            ]}
                            label='Paragraph'
                            required
                            initialValue={copyQuestion?.paragraph?.paragraphName}
                        >
                            <AutoComplete
                                disabled={!chapterValue?.chapterName?.length}
                                // options={_.uniqBy(optionsParagraph, 'value')}
                                options={optionsParagraph}
                                placeholder='Enter Paragraph'
                                onSearch={onSearchParagraph}
                                onSelect={onSelectParagraph}
                                filterOption={(inputValue, option) =>
                                    option.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
                                }
                            />
                        </Form.Item>
                        <Form.List
                            name='topics'
                            initialValue={copyQuestion?.topics?.map((v) => v?.topicName) || ['']}
                           
                        >
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            label={index === 0 ? 'Topics' : ''}
                                            required
                                            key={field.key}
                                        >
                                            <Form.Item
                                                {...field}
                                                validateTrigger={['onChange', 'onBlur']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        whitespace: true,
                                                        message: 'Please input Topic',
                                                    },
                                                ]}
                                                noStyle
                                            // initialValue={copyQuestion?.topics[0]?.topicName}
                                            >
                                                <AutoComplete

                                                    // options={_.uniqBy(optionsTopics?.filter((v) => !form.getFieldValue('topics')?.includes(v?.value)), 'value')}
                                                    options={optionsTopics?.filter((v) => !form.getFieldValue('topics')?.includes(v?.value))}
                                                    placeholder='Enter Topic'
                                                    disabled={!paragraphValue?.paragraphName?.length}
                                                    style={{
                                                        width: fields.length > 1 ? '93%' : '100%',
                                                        marginRight: fields.length > 1 ? '1%' : 0
                                                    }}
                                                    filterOption={(inputValue, option) =>
                                                        option?.value?.toUpperCase()?.indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                />
                                            </Form.Item>
                                            {fields.length > 1 ? (
                                                <MinusCircleOutlined
                                                    className='dynamic-delete-button'
                                                    onClick={() => remove(field.name)}
                                                />
                                            ) : null}
                                        </Form.Item>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type='dashed'
                                            onClick={() => add()}
                                            style={{
                                                width: '100%',
                                            }}
                                            disabled={!paragraphValue?.paragraphName?.length && fields?.length > 2}

                                        >
                                            Add Topic ({fields?.length}/3)
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <br />
                        <Form.Item >
                            <Button
                                htmlType='submit'
                                style={{ borderRadius: 4, width: '100%' }}
                                type='primary'
                                loading={loading}
                            >
                                Submit
                            </Button>
                            <Button
                                style={{ borderRadius: 4, marginTop: 6 }}
                                block
                                onClick={() => { onCancel(); setCopyQuestion({}) }}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div >
    )
}

export default AddQuestions