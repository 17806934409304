import React, { useState, useEffect, useRef } from 'react'
import { Form, Button, Input, Select, DatePicker, Skeleton } from 'antd'
import axios from 'axios'
import { Page404 } from '../../Config/routes'
import { inputPlace, requiredMessage, successNotification, errorMessage, disabledDate, convertTitle } from '../../utils/helpers'
import { POST, GET } from '../../utils/apis'
import countryCodes from '../../utils/countryCodes'
import logo from '../../assets/invoice-icon.jpeg'
import { Result } from 'antd'

const { TextArea } = Input
const { Option } = Select

const InviteGuest = (props) => {
    const { history } = props
    const [loading, setLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)
    const [isValid, setValid] = useState(false)
    const [guest, setGuest] = useState({})
    const [country, setCountry] = useState('Netherlands')
    const [countryCode, setCountryCode] = useState(countryCodes.filter(v => v?.name === country)?.[0]?.dial_code || '+31')
    const [segments, setSegments] = useState([])
    const formRef = useRef(null)

    useEffect(() => {
        const { match } = props
        const { token } = match.params

        axios.get(`${GET.VERIFY_GUEST}/${token}`)
            .then((result) => {
                const { data } = result
                if (data.success) {
                    setGuest(data?.guest || {})
                    setSegments(data?.segments || [])
                    setValid(true)
                    formRef.current.setFieldsValue({ ...data?.guest })
                }
                setPageLoading(false)
            })
            .catch(e => {
                setPageLoading(false)
            })
    }, [])


    const onFinish = (values) => {
        setLoading(true)
        values.userId = guest?.userId
        values.isInvited = true
        values.linkId = guest?._id

        axios.post(POST.CREATE_GUEST, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(data?.message)
                    return setTimeout(() => {
                        history?.replace('/')
                    }, 300)
                }
                errorMessage(data.message)
            })
            .catch((e) => {
                setLoading(false)
                errorMessage()
            })
    }

    const updateCountry = (e) => {
        let selectedCode = countryCodes.filter(v => v?.name === e)[0].dial_code

        setCountryCode(selectedCode)

        formRef.current.setFieldsValue({ prefix: selectedCode })
    }

    const prefixSelector = () => {
        return (
            <Form.Item name='prefix' noStyle initialValue={countryCode}>
                <Select
                    showSearch
                    disabled
                    style={{
                        minWidth: 80,
                        maxWidth: window.innerWidth > 500 ? 'auto' : 120
                    }}
                    value={countryCode}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {countryCodes?.map((v, i) => <Option value={v?.dial_code} key={i}>{`${v?.name} ${v?.dial_code}`}</Option>)}
                </Select>
            </Form.Item >
        )
    }

    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'><img className='postal-logo' src={guest?.userId?.logoUrl || logo} /></p>
                    {pageLoading ? <Skeleton paragraph={{ rows: 12 }} active loading /> : isValid ?
                        <div>
                            <p className='heading head-center'>{convertTitle(guest?.userId?.businessName || guest?.userId?.fullName)}</p>
                            <p className='head-center' style={{ marginTop: -20 }}>(By submit this form, you agree to Marketing activities by www.2keepintouch.com)</p>
                            <Form
                                name='form'
                                onFinish={onFinish}
                                layout={'vertical'}
                                ref={formRef}
                            >
                                <Form.Item
                                    name='businessName'
                                    label='Business Name'
                                    hasFeedback
                                >
                                    <Input
                                        className='form-input'
                                        placeholder={inputPlace('Business Name')}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='segment'
                                    label='Segment'
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: requiredMessage('Segment')
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        showArrow
                                        allowClear
                                        className='form-input'
                                        placeholder={`Select Segment`}
                                        optionFilterProp='children'
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {segments?.map((v, i) => <Option value={v?._id} key={i}>{v?.name}</Option>)}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name='startingDate'
                                    label='Starting Date'
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: requiredMessage('Starting Date')
                                        }
                                    ]}
                                >
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        disabledDate={disabledDate}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='name'
                                    label='Guest Name'
                                    initialValue={guest?.name}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: requiredMessage('Guest Name')
                                        },
                                        {
                                            max: 30,
                                            message: 'Maximum 30 Characters Allowed!'
                                        }
                                    ]}
                                >
                                    <Input
                                        className='form-input'
                                        placeholder={inputPlace('Guest Name')}
                                        maxLength={30}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='streetHouse'
                                    label='Street and House No'
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: requiredMessage('Street and House No')
                                        },
                                        {
                                            max: 30,
                                            message: 'Maximum 30 Characters Allowed!'
                                        }
                                    ]}
                                >
                                    <Input
                                        className='form-input'
                                        placeholder={inputPlace('Street and House No')}
                                        maxLength={30}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='postalCodeCity'
                                    label='Postal Code And City'
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: requiredMessage('Postal Code And City')
                                        },
                                        {
                                            max: 30,
                                            message: 'Maximum 30 Characters Allowed!'
                                        }
                                    ]}
                                >
                                    <Input
                                        className='form-input'
                                        placeholder={inputPlace('Postal Code And City')}
                                        maxLength={30}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='country'
                                    label='Country'
                                    hasFeedback
                                    initialValue={country}
                                >
                                    <Select
                                        showSearch
                                        showArrow
                                        allowClear
                                        className='form-input'
                                        placeholder={`Select Country`}
                                        optionFilterProp='children'
                                        onChange={updateCountry}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {countryCodes?.map((v, i) => <Option value={v?.name} key={i}>{v?.name}</Option>)}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name='telNo'
                                    label='Tel No'
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: requiredMessage('Tel No')
                                        },
                                        {
                                            max: 30,
                                            message: 'Maximum 30 Characters Allowed!'
                                        }
                                    ]}
                                >
                                    <Input
                                        addonBefore={prefixSelector()}
                                        className='form-input'
                                        placeholder={'Tel No e.g 612345678'}
                                        type='tel'
                                        maxLength={30}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='email'
                                    label='Guest Email'
                                    initialValue={guest?.email}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: requiredMessage('Guest Email')
                                        }
                                    ]}
                                >
                                    <Input
                                        className='form-input'
                                        placeholder={inputPlace('Guest Email')}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='note'
                                    label='Note'
                                    hasFeedback
                                >
                                    <TextArea
                                        className='form-input'
                                        placeholder={inputPlace('Note')}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type='primary'
                                        className='form-button'
                                        block
                                        htmlType='submit'
                                        loading={loading}
                                    >
                                        Create
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div> : <Result
                            status="success"
                            title="Invite Accepted Successfully!" />}
                </div>
            </div>
        </div>
    )
}

export default InviteGuest