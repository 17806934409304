import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Popconfirm, Space, Spin, Table } from 'antd'
import axios from 'axios'
import QRCode from 'qrcode.react'
import React, { useEffect, useRef, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Highlighter from 'react-highlight-words'
import { GET, POST } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { convertTitle, errorMessage, getAllSegments, getAllUserData, successMessage, successNotification } from '../../utils/helpers'

let origin = `https://2keepintouch.com`

if (process.env.NODE_ENV === 'development') {
    origin = `http://localhost:3000`
}

const ViewGuestInvites = (props) => {
    const { user, adminActions, userActions, cancelShow } = props
    const [spin, setSpin] = useState({})
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const [showForm, setShowForm] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showInvites, setShowInvites] = useState(true)
    const [invites, setInvites] = useState([])
    const [showSpin, setShowSpin] = useState(false)
    const [guestName, setGuestName] = useState(null)
    const [guestEmail, setGuestEmail] = useState(null)
    const [inviteId, setInviteId] = useState(null)
    const [visible, setVisible] = useState(false)
    const [link, setLink] = useState(null)
    const searchInput = useRef(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getAllSegments(adminActions, user?._id)
        getAllUserData(user?._id, userActions)

        getInvites()
    }, [showForm])

    const getInvites = () => {
        axios.get(`${GET.ALL_INVITES}/${user?._id}`)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setLoading(false)
                    setInvites(data?.data || [])
                }
            })
            .catch((e) => {
                console.log("e", e)
                setLoading(false)
            })
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => {
                            confirm({ closeDropdown: false })
                            setSearchText(selectedKeys[0])
                            setSearchedColumn(dataIndex)
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100)
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const deleteInviteGuest = (_id) => {
        setSpin({ [_id]: true })
        axios.post(`${POST.DELETE_GUEST_LINK}/${user?._id}`, { _id })
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successNotification('Successfully Deleted!')
                    setInvites(data?.data || [])
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }

    const showEditLink = (e) => {
        setGuestName(e?.name)
        setGuestEmail(e?.email)
        setInviteId(e?._id)
        setShowModal(true)
    }

    const cancelModal = () => {
        setShowModal(false)
        setGuestName(false)
        setGuestEmail(false)
        setInviteId(null)
    }

    const inviteGuest = () => {
        if (!guestName) {
            return errorMessage('Please Enter Guest Name')
        } else if (!guestEmail) {
            return errorMessage('Please Enter Guest Email')
        }

        setShowSpin(true)

        let obj = {
            userId: user?._id,
            name: guestName,
            email: guestEmail
        }

        inviteId && (obj._id = inviteId)

        axios.post(`${POST.INVITE_GUEST}`, obj)
            .then((res) => {
                const { data } = res
                getInvites()
                if (data?.success) {
                    successNotification('Successfully Invited!')
                }
                else {
                    errorMessage('Unable to invite guest!')
                }
                setShowSpin(false)
                cancelModal()
            })
            .catch((e) => {
                getInvites()
                setShowSpin(false)
                cancelModal()
            })
    }

    const disableModal = () => {
        setLink(null)
        setVisible(false)
    }

    const showQR = (url) => {
        setLink(url)
        setVisible(true)
    }

    const downloadQR = () => {
        let id = 'qrCodeGuest'
        const canvas = document.getElementById(id)
        const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
        let downloadLink = document.createElement('a')
        downloadLink.href = pngUrl
        downloadLink.download = `lottery-code.png`
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
    }

    let columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            ...getColumnSearchProps('name')
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
            ...getColumnSearchProps('email')
        },
        {
            title: 'Status',
            dataIndex: 'isCompleted',
            key: 'isCompleted',
            render: (e) => <span>{e ? 'Completed' : 'Pending'}</span>
        },
        {
            title: 'Invite URL',
            dataIndex: '_id',
            key: '_id',
            render: (e) => {
                var url = `${origin}${allPaths.INVITE_GUEST}/${e}`
                return (
                    <span>
                        <Button target={'_blank'} href={url} >Open URL</Button> &nbsp;&nbsp;
                        <CopyToClipboard text={url}
                            onCopy={() => successMessage('Successfully Copied to Clipboard!')}>
                            <Button>Copy URL</Button>
                        </CopyToClipboard>
                        &nbsp;&nbsp;
                        <Button onClick={() => showQR(url)}>QR Code</Button>
                    </span>
                )
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (e) => <span>
                {!e?.isCompleted ? <span>
                    <a onClick={() => showEditLink(e)}>Edit</a> &nbsp;&nbsp;&nbsp;
                    <Popconfirm
                        title={`Delete ${convertTitle(e?.name)}?`}
                        okText='Delete'
                        cancelText='Cancel'
                        okButtonProps={{
                            type: 'primary',
                            className: 'form-button'
                        }}
                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                        onConfirm={() => deleteInviteGuest(e?._id)}
                    >
                        <a href="#"><DeleteOutlined style={{ color: 'red' }} /> Delete</a>
                        {spin[e?._id] ? <span className='demo-loading-container'>
                            &nbsp;<Spin />
                        </span> : null}
                    </Popconfirm>
                </span> : null}
            </span>
        }
    ]

    return (
        <div className='flex-column menu-main'>
            <div>
                <div className='button-end button-end-scroll'>
                    <Button
                        type='primary'
                        className='btn-end invite-guest'
                        onClick={() => cancelShow(false)}
                    >
                        View All Guest
                    </Button>
                    <Button
                        type='primary'
                        className='btn-end invite-guest'
                        onClick={() => setShowModal(true)}
                    >
                        Invite Guest
                    </Button>
                </div>

                <div className='user-table'>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={invites}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 1000 }}
                    />
                </div>
            </div>

            {showModal ? <Modal
                title='Invite Guest'
                visible={showModal}
                onCancel={cancelModal}
                onOk={inviteGuest}
            >
                <Spin spinning={showSpin}>
                    <Input
                        className='form-input'
                        placeholder={`Enter Guest Name`}
                        defaultValue={guestName}
                        onChange={e => setGuestName(e.target.value)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Input
                        className='form-input'
                        placeholder={`Enter Guest Email`}
                        defaultValue={guestEmail}
                        onChange={e => setGuestEmail(e.target.value)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                </Spin>
            </Modal> : null}
            <Modal
                visible={visible}
                title='Coupon Code'
                onCancel={disableModal}
                footer={[
                    <Button key='back' onClick={disableModal}>
                        Close
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}>
                    <QRCode
                        value={link}
                        size={250}
                        id='qrCodeGuest'
                        level={'H'}
                        includeMargin={true}
                    />
                    <a onClick={downloadQR}> Download QR </a>
                </div>
            </Modal>
        </div>
    )
}

export default ViewGuestInvites