import React, { useState, useEffect } from 'react'
import { Card, Button, Modal, Input, Spin, Select, Tooltip, Popconfirm, InputNumber } from 'antd'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { POST, GET, ADMIN } from '../../utils/apis'
import { errorMessage, warningMessage, successNotification, getAllUserData, convertNumber, setHoursMinutes, stringLimiter } from '../../utils/helpers'
import { allPaths } from '../../utils/constants'
import moment from 'moment-timezone'
const { Option } = Select

const Subscription = (props) => {
  const { user, userActions, location, history, getUser, setUpdateWallet } = props
  const subscriptions = useSelector((state) => state?.userReducer?.subscriptions || [])
  const [postalCard, setPostalCard] = useState({})
  const [coupon, setCoupon] = useState({})
  const [lottery, setLottery] = useState({})
  const [giftCardsQuantity, setGiftCardsQuantity] = useState(null)
  const [cardsQuantity, setCardQuantity] = useState(0)
  const [fixedCardsQuantity, setFixedCardQuantity] = useState(0)
  const [couponsQuantity, setCouponsQuantity] = useState(0)
  const [lotteriesQuantity, setLotteriesQuantity] = useState(0)
  const [onlineCouponAmount, setOnlineCouponAmount] = useState(1)
  const [cardCouponAmount, setCardCouponAmount] = useState(1)
  const [subscription, setSubscription] = useState({})
  const [whatsappSubscriptions, setWhatsappSubscriptions] = useState([])
  const [couponsCardSubscription, setCouponsSubscription] = useState(null)
  const [loyaltySubscription, setLoyaltySubscription] = useState({})
  const [loyaltyCardSubscriptions, setLoyaltyCardSubscriptions] = useState([])
  const [lotterySubscription, setLotterySubscription] = useState({})
  const [giftCardSubscription, setGiftCardSubscription] = useState({})
  const [lotteryCardSubscriptions, setLotteryCardSubscriptions] = useState([])
  const [cardSubscriptions, setCardSubscriptions] = useState([])
  const [whatsapp, setWhatsapp] = useState(null)
  const [loading, setLoading] = useState(false)
  const [buyCards, setBuyCards] = useState(false)
  const [buyWhatsapp, setBuyWhatsapp] = useState(false)
  const [buyCoupons, setBuyCoupons] = useState(false)
  const [buyCardCoupons, setBuyCardCoupons] = useState(false)
  const [buyCardLottery, setBuyCardLottery] = useState(false)
  const [buyGiftCards, setBuyGiftCards] = useState(false)
  const [buyOnlineLottery, setBuyOnlineLottery] = useState(false)
  const [visible, setVisible] = useState(false)
  const [visible2, setVisible2] = useState(false)
  const [upgrade, setUpgrade] = useState(false)
  const [extend, setExtend] = useState(false)
  const [upgradeSubscriptionList, setUpgradeSubscription] = useState([])
  const [couponsLoading, setCouponsLoading] = useState(false)
  const [lotteryLoading, setLotteryLoading] = useState(false)
  const couponsDetail = useSelector(state => state?.userReducer?.couponsDetail || {})
  const lotteryDetail = useSelector(state => state?.userReducer?.lotteryDetail || {})
  const selectAmount = [5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100]

  let [isExpired, setIsExpired] = useState(setHoursMinutes(user?.subscription?.expiry).diff(setHoursMinutes(moment()), 'days') > 0 ? false : true)
  const userId = new URLSearchParams(location.search).get('userId') || null
  const userType = user?.userType

  useEffect(() => {
    getAllUserData(user?._id, userActions)
    getWhatsAppSubscription()
    getCardSubscription()
    getLoyaltySubscription()
    getLotterySubscription()
    getGiftCardsSubscription()

    if (userType === 'business') {
      !couponsDetail?.onlineCoupons && setCouponsLoading(true)
      !lotteryDetail?.onlineLottery && setLotteryLoading(true)
      getAllCouponsDetail()
      getAllLotteryDetail()
    }

    if (userId) {
      setLoading(true)
      axios.get(`${GET.PAYMENT_STATUS}/${userId}`)
        .then((res) => {
          const { data } = res
          getUser()
          setLoading(false)
          history?.replace(allPaths.SUBSCRIPTION)
          if (data.success) {
            setUpdateWallet(true)
            return successNotification(data.message)
          }
          errorMessage(data.message)
        })
        .catch((e) => {
          setLoading(false)
          errorMessage()
        })
    }

    axios.get(ADMIN.GET_COUPON_DISCOUNT)
      .then((res) => {
        const { data } = res
        if (data.success) {
          setOnlineCouponAmount(data?.data[1]?.couponDiscountValue)
          setCardCouponAmount(data?.data[0]?.couponDiscountValue)
        }
      })
  }, [])

  useEffect(() => {
    for (var i in subscriptions) {
      if (subscriptions[i]?._id === user?.subscription?.subscriptionId) {
        setUpgradeSubscription(subscriptions?.slice(Number(i) + 1))
      }
    }
  }, [props?.location?.search])


  const extendSubscription = () => {
    setLoading(true)
    let obj = { userId: user?._id, subscriptionId: user?.subscription?._id }
    axios.post(POST.EXTEND_SUBSCRIPTION_EXPIRY, obj)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data.success) {
          window.location.href = data?.redirectUrl
          return
        }
        else {
          errorMessage(data?.message)
        }
      })
      .catch((e) => {
        setLoading(false)
        errorMessage()
      })
  }

  const getAllCouponsDetail = () => {
    axios.get(`${GET.ALL_COUPONS_DETAIL}/${user?._id}`)
      .then((res) => {
        const { data } = res
        setCouponsLoading(false)
        userActions.addAllCouponsSubscriptionDetail(data?.data || {})
      })
      .catch((e) => {
        setCouponsLoading(false)
        console.log('e', e)
      })
  }
  const getAllLotteryDetail = () => {
    axios.get(`${GET.ALL_LOTTERY_DETAIL}/${user?._id}`)
      .then((res) => {
        const { data } = res
        setLotteryLoading(false)
        userActions.addAllLotterySubscriptionDetail(data?.data || {})
      })
      .catch((e) => {
        setLotteryLoading(false)
        console.log('e', e)
      })
  }

  const getWhatsAppSubscription = () => {
    axios.get(GET.WHATSAPP_SUBSCRIPTION)
      .then((res) => {
        const { data } = res
        setWhatsappSubscriptions(data?.data || [])
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  const getCardSubscription = () => {
    axios.get(GET.CARD_SUBSCRIPTION)
      .then((res) => {
        const { data } = res
        setCardSubscriptions(data?.data || [])

      })
      .catch((e) => {
        console.log('e', e)
      })
  }
  const getLoyaltySubscription = () => {
    axios.get(GET.LOYALTY_SUBSCRIPTION)
      .then((res) => {
        const { data } = res
        let loyaltyCard = data?.data?.filter(v => v?.subscriptionTypes?.indexOf('physicalCoupon') === -1)[0]
        let loyaltyphysicalCards = data?.data?.filter(v => v?.subscriptionTypes?.indexOf('physicalCoupon') !== -1)
        setLoyaltySubscription(loyaltyCard || {})
        setLoyaltyCardSubscriptions(loyaltyphysicalCards || [])
      })
      .catch((e) => {
        console.log('e', e)
      })
  }
  const getLotterySubscription = () => {
    axios.get(GET.LOTTERY_SUBSCRIPTION)
      .then((res) => {
        const { data } = res
        let lotteryOnline = data?.data?.filter(v => v?.subscriptionTypes?.indexOf('physicalLottery') === -1)[0]
        let lotteryCards = data?.data?.filter(v => v?.subscriptionTypes?.indexOf('physicalLottery') !== -1)
        setLotterySubscription(lotteryOnline || {})
        setLotteryCardSubscriptions(lotteryCards || [])
      })
      .catch((e) => {
        console.log('e', e)
      })
  }
  const getGiftCardsSubscription = () => {
    axios.get(GET.GIFT_CARD_SUBSCRIPTION)
      .then((res) => {
        const { data } = res
        let giftCards = data?.data[0]
        setGiftCardSubscription(giftCards || {})
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  const createSubscription = (obj) => {
    setLoading(true)

    obj.userId = user?._id
    obj.postalCardRate = cardSubscriptions[0]?.amount || 1.5
    obj.postalCardQuantity = cardsQuantity || 0
    obj.fixedCardRate = cardSubscriptions[1]?.amount || 2.5
    obj.fixedCardQuantity = fixedCardsQuantity || 0
    obj.couponRate = loyaltySubscription?.amount || 0.01
    obj.couponsQuantity = couponsQuantity || 0
    obj.couponDiscount = onlineCouponAmount
    obj.couponCardDiscount = cardCouponAmount

    obj.giftCardsRate = giftCardSubscription?.amount || 1
    obj.giftCardsQuantity = Number(giftCardsQuantity || 0)

    setCardQuantity(0)
    setFixedCardQuantity(0)
    setCouponsQuantity(0)
    setGiftCardsQuantity(0)

    console.log('obj', obj)

    axios.post(POST.CREATE_SUBSCRIPTION, obj)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data.success) {
          window.location.href = data?.redirectUrl
          return
        }
        errorMessage(data.message)
      })
      .catch((e) => {
        setLoading(false)
        errorMessage()
      })
  }

  const addMoreCards = () => {
    setLoading(true)
    let obj = { ...postalCard }
    obj.userId = user?._id

    if (postalCard?.subscriptionTypes?.indexOf('fixed') !== -1) {
      obj.fixed = true
      obj.fixedCardRate = postalCard?.amount
      obj.fixedCardQuantity = cardsQuantity
    }
    else {
      obj.postalCardRate = postalCard?.amount
      obj.postalCardQuantity = cardsQuantity
    }
    setCardQuantity(0)

    axios.post(POST.ADD_MORE_CARDS, obj)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data.success) {
          window.location.href = data?.redirectUrl
          return
        }
        errorMessage(data.message)
      })
      .catch((e) => {
        setLoading(false)
        errorMessage()
      })
  }

  const addMoreWhatsappMessages = (w) => {
    setLoading(true)
    let obj = {}
    obj.userId = user?._id
    obj._id = w
    setWhatsapp(null)
    setBuyWhatsapp(false)
    axios.post(POST.ADD_MORE_WHATSAPP_MESSAGES, obj)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data.success) {
          window.location.href = data?.redirectUrl
          return
        }
        errorMessage(data.message)
      })
      .catch((e) => {
        setLoading(false)
        errorMessage()
      })
  }


  const addMoreCardCoupons = (c) => {
    setLoading(true)
    let obj = {}
    obj.userId = user?._id
    obj._id = c
    obj.couponCardDiscount = cardCouponAmount

    setCoupon(null)
    setBuyCardCoupons(false)

    axios.post(POST.ADD_MORE_CARD_COUPONS, obj)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data.success) {
          window.location.href = data?.redirectUrl
          return
        }
        errorMessage(data.message)
      })
      .catch((e) => {
        console.log('e', e)
        setLoading(false)
        errorMessage()
      })
  }

  const addMoreCardLotteries = (c) => {
    // setLoading(true)
    let obj = {}
    obj.userId = user?._id
    obj._id = c
    // obj.couponCardDiscount = cardCouponAmount
    // setCoupon(null)
    setBuyCardLottery(false)

    axios.post(POST.ADD_CARD_LOTTERIES, obj)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data.success) {
          window.location.href = data?.redirectUrl
          return
        }
        errorMessage(data.message)
      })
      .catch((e) => {
        console.log('e', e)
        setLoading(false)
        errorMessage()
      })
  }
  const addMoreCoupons = () => {
    setLoading(true)
    let obj = { ...loyaltySubscription }
    obj.userId = user?._id

    obj.couponRate = loyaltySubscription?.amount || 0.01
    obj.couponsQuantity = couponsQuantity
    obj.couponDiscount = onlineCouponAmount
    setCouponsQuantity(0)


    axios.post(POST.ADD_MORE_COUPONS, obj)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data.success) {
          window.location.href = data?.redirectUrl
          return
        }
        errorMessage(data.message)
      })
      .catch((e) => {
        setLoading(false)
        errorMessage()
      })
  }
  const addMoreGiftCards = () => {
    setLoading(true)
    let obj = { ...giftCardSubscription }
    obj.userId = user?._id

    obj.giftCardsRate = giftCardSubscription?.amount || 0.01
    obj.giftCardsQuantity = giftCardsQuantity

    console.log('obj===>', obj)
    setGiftCardsQuantity(null)


    axios.post(POST.ADD_MORE_GIFTCARDS, obj)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data.success) {
          window.location.href = data?.redirectUrl
          return
        }
        errorMessage(data.message)
      })
      .catch((e) => {
        setLoading(false)
        errorMessage()
      })
  }

  const addMoreOnlineLotteries = () => {
    setLoading(true)
    let obj = { ...lotterySubscription }
    obj.userId = user?._id
    obj.lotteryRate = lotterySubscription?.amount || 0.01
    obj.lotteryQuantity = lotteriesQuantity
    setLotteriesQuantity(0)


    axios.post(POST.ADD_LOTTERIES, obj)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data.success) {
          window.location.href = data?.redirectUrl
          return
        }
        errorMessage(data.message)
      })
      .catch((e) => {
        setLoading(false)
        errorMessage()
      })
  }

  const checkPostalCard = (obj) => {

    setSubscription(obj)
    if (obj?.subscriptionTypes?.indexOf('postalCard') !== -1) {
      return setVisible(true)
    } else if (obj?.subscriptionTypes?.indexOf('giftCard') !== -1) {
      return setVisible2(true)
    }
    createSubscription(obj)
  }

  const modalCancel = () => {
    setBuyCards(false)
    setVisible(false)
    setVisible2(false)
    setWhatsapp(null)
    setBuyWhatsapp(false)
    setBuyCoupons(false)
    setBuyCardCoupons(false)
    setBuyCardLottery(false)
    setBuyOnlineLottery(false)
    setBuyGiftCards(false)

  }
  const checkQuantity = () => {
    if (!cardsQuantity < 0) {
      return warningMessage('Please enter once a week cards Quantity!')
    }
    if (!fixedCardsQuantity < 0) {
      return warningMessage('Please enter fixed cards Quantity!')
    }
    if (!couponsQuantity < 0) {
      return warningMessage('Please enter Coupons Quantity!')
    }
    if (buyCards && !cardsQuantity) {
      return warningMessage('Please enter cards Quantity!')
    }
    if (buyWhatsapp && !whatsapp) {
      return warningMessage('Please Select Whatsapp Subscription!')
    }
    if (buyCoupons && !couponsQuantity) {
      return warningMessage('Please enter Coupons Quantity!')
    }
    if (buyCardCoupons && !coupon) {
      return warningMessage('Please Select Coupons Subscription!')
    }
    if (buyOnlineLottery && !lotteriesQuantity) {
      return warningMessage('Please enter Lotteries Quantity!')
    }
    if (buyCardLottery && !lottery) {
      return warningMessage('Please Select Lotteries Subscription!')
    }
    if (buyGiftCards && !giftCardsQuantity) {
      return warningMessage('Please Select number of GiftCards!')
    }

    modalCancel()

    if (buyCards) {
      return addMoreCards()
    }

    if (buyWhatsapp) {
      return addMoreWhatsappMessages(whatsapp)
    }

    if (buyCoupons) {
      return addMoreCoupons()
    }

    if (buyCardCoupons) {
      return addMoreCardCoupons(coupon)
    }
    if (buyOnlineLottery) {
      return addMoreOnlineLotteries()
    }

    if (buyCardLottery) {
      return addMoreCardLotteries(lottery)
    }

    if (buyGiftCards) {
      return addMoreGiftCards(giftCardsQuantity)
    }

    subscription.cardsQuantity = cardsQuantity
    subscription.fixedCardsQuantity = fixedCardsQuantity
    subscription.couponsQuantity = couponsQuantity

    if (subscription?.subscriptionTypes?.indexOf('whatsapp') !== -1 && whatsapp) {
      subscription.whatsapp = whatsapp
    }

    if (subscription?.subscriptionTypes?.indexOf('loyalty') !== -1 && couponsCardSubscription) {
      subscription.couponsCardSubscription = couponsCardSubscription
    }

    setSubscription({ ...subscription })
    createSubscription(subscription)
  }

  const convertDate = (val) => moment.utc(val).set('hour', 0).set('minute', 0).set('second', 0).toString()

  const easyLoad = (a) => {
    let obj = {
      userId: user?._id,
      amount: a,
      subscriptionName: 'Wallet Recharge subscription'
    }

    axios.post(POST.WALLET_RECHARGE, obj)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data.success) {
          window.location.href = data?.redirectUrl
          return
        }
        else {
          errorMessage(data?.message)
        }
      })
      .catch((e) => {
        setLoading(false)
        errorMessage()
      })
  }

  return (
    <div style={{ paddingBottom: 40 }}>
      {user?.subscription?.active && !upgrade && !isExpired ? (
        <Spin spinning={loading}>
          <div className='card-form'>
            <div
              className='user-card'
              style={{ minHeight: 'calc(100vh - 200px)' }}
            >
              <p className='heading head-center'>Your Current Subscription</p>
              <div className='container-fluid'>
                <div className='row'>
                  {userType === 'guest' ?
                    <>
                      <div className='col-lg-4 col-md-6 col-12-4'>
                        <Card className='subscription-card'>
                          <Tooltip placement='top' /* title={v?.subscriptionName} */ title='Easyload Card'>
                            <div className='subscription-title'>
                              Easyload Card
                            </div>
                          </Tooltip>
                          <div className='subscription-amount'>
                            Load your wallet with € 5
                          </div>
                          <Button
                            type='primary'
                            className='form-button subscription-btn'
                            block
                            htmlType='submit'
                            onClick={() => easyLoad(5)}
                          >
                            Purchase Now
                          </Button>
                        </Card>
                      </div>
                      <div className='col-lg-4 col-md-6 col-12-4'>
                        <Card className='subscription-card'>
                          <Tooltip placement='top' /* title={v?.subscriptionName} */ title='Easyload Card'>
                            <div className='subscription-title'>
                              Easyload Card
                            </div>
                          </Tooltip>
                          <div className='subscription-amount'>
                            Load your wallet with € 10
                          </div>
                          <Button
                            type='primary'
                            className='form-button subscription-btn'
                            block
                            htmlType='submit'
                            onClick={() => easyLoad(10)}
                          >
                            Purchase Now
                          </Button>
                        </Card>
                      </div></>
                    : null}</div></div>
              <div className='well'>{user?.subscription?.subscriptionName}</div>
              <div className='alert alert-success'>
                € {user?.subscription?.subscriptionAmount} / year (Your
                Subscription will Expire at{' '}
                {convertDate(user?.subscription?.expiry)})
                <Popconfirm
                  title={`Want to extend Subscription?`}
                  okText='Yes'
                  cancelText='Cancel'
                  okButtonProps={{
                    type: 'primary',
                    className: 'form-button'
                  }}
                  cancelButtonProps={{ style: { borderRadius: 4 } }}
                  onConfirm={() => extendSubscription()}
                >
                  <a>&nbsp; Extend subscription</a>
                </Popconfirm>

              </div>
              <hr />

              {user?.subscription?.subscriptionTypes?.indexOf('postalCard') !==
                -1 ? (
                <div>
                  <div className='well'>
                    Postal Cards (Once a week) Stock:{' '}
                    {user?.card?.remainingCards || 0}
                  </div>
                  <div className='well'>
                    Postal Cards (Fixed Day) Stock:{' '}
                    {user?.fixedCard?.remainingFixedCards || 0}
                  </div>
                  {user?.subscription?.subscriptionTypes?.indexOf(
                    'whatsapp'
                  ) !== -1 ? (
                    <div className='well'>
                      Whatsapp Messages Stock:{' '}
                      {user?.whatsapp?.remainingWhatsapp || 0}
                    </div>
                  ) : null}
                  {userType === 'business' && user?.subscription?.subscriptionTypes?.indexOf('loyalty') !== -1 ? <div>
                    <Spin spinning={couponsLoading}>
                      <div className='well'>
                        Total coupons purchased:{' '}
                        {couponsDetail?.onlineCoupons?.total || 0}
                      </div>
                      <div className='well'>
                        Total coupons used:{' '}
                        {couponsDetail?.onlineCoupons?.used || 0}
                      </div>
                      <div className='well'>
                        Total coupon claimed:{' '}
                        {couponsDetail?.onlineCoupons?.claimed || 0}
                      </div>
                      <div className='well'>
                        Total coupons stock:{' '}
                        {couponsDetail?.onlineCoupons?.stock || 0}
                      </div>
                      <div className='well'>
                        Total card coupons purchased:{' '}
                        {couponsDetail?.physicalCoupons?.total || 0}
                      </div>
                      <div className='well'>
                        Total card coupons used:{' '}
                        {couponsDetail?.physicalCoupons?.used || 0}
                      </div>
                      <div className='well'>
                        Total card coupon claimed:{' '}
                        {couponsDetail?.physicalCoupons?.claimed || 0}
                      </div>
                      <div className='well'>
                        Total card coupons stock:{' '}
                        {couponsDetail?.physicalCoupons?.stock || 0}
                      </div>
                    </Spin>
                  </div>
                    : null}
                  {userType === 'business' && user?.subscription?.subscriptionTypes?.indexOf('lottery') !== -1 ? <div>
                    <Spin spinning={lotteryLoading}>
                      <div className='well'>
                        Total Lottery purchased:{' '}
                        {lotteryDetail?.onlineLottery?.total || 0}
                      </div>
                      <div className='well'>
                        Total Lottery used:{' '}
                        {lotteryDetail?.onlineLottery?.used || 0}
                      </div>
                      <div className='well'>
                        Total Lottery claimed:{' '}
                        {lotteryDetail?.onlineLottery?.claimed || 0}
                      </div>
                      <div className='well'>
                        Total Lottery stock:{' '}
                        {lotteryDetail?.onlineLottery?.stock || 0}
                      </div>
                      <div className='well'>
                        Total card Lottery purchased:{' '}
                        {lotteryDetail?.physicalLottery?.total || 0}
                      </div>
                      <div className='well'>
                        Total card coupons used:{' '}
                        {lotteryDetail?.physicalLottery?.used || 0}
                      </div>
                      <div className='well'>
                        Total card Lottery claimed:{' '}
                        {lotteryDetail?.physicalLottery?.claimed || 0}
                      </div>
                      <div className='well'>
                        Total card Lottery stock:{' '}
                        {lotteryDetail?.physicalLottery?.stock || 0}
                      </div>
                    </Spin>
                  </div>
                    : null}
                  <div className='alert alert-info'>
                    Postal Card (Once a week) Price: €{' '}
                    {convertNumber(cardSubscriptions[0]?.amount || 0)} / piece
                  </div>
                  <div className='alert alert-info'>
                    Postal Card (Fixed Day) Price: €{' '}
                    {convertNumber(cardSubscriptions[1]?.amount || 0)} / piece
                  </div>
                  <Button
                    type='primary'
                    className='form-button'
                    onClick={() => {
                      setBuyCards(true)
                      setPostalCard(cardSubscriptions[0])
                    }}
                  >
                    Buy (Once a week) Cards
                  </Button>
                  <Button
                    type='primary'
                    className='form-button update-subscription'
                    onClick={() => {
                      setBuyCards(true)
                      setPostalCard(cardSubscriptions[1])
                    }}
                  >
                    Buy (Fixed Day) Cards
                  </Button>
                  {userType === 'business' ?
                    <>
                      <Button
                        type='primary'
                        className='form-button update-subscription'
                        onClick={() => setBuyCoupons(true)}
                      >
                        Buy Coupons
                      </Button>
                      <Button
                        type='primary'
                        className='form-button update-subscription'
                        onClick={() => {
                          setBuyCardCoupons(true)
                          // setCoupon(cardSubscriptions[1])
                        }}
                      >
                        Buy Coupons Cards
                      </Button>
                    </> : null}

                  {/* {user?.subscription?.subscriptionTypes?.indexOf(
                    'whatsapp'
                  ) !== -1 ? (
                    <Button
                      type='primary'
                      className='form-button update-subscription'
                      onClick={() => setBuyWhatsapp(true)}
                    >
                      Add More Whatsapp Messages
                    </Button>
                  ) : null} */}
                  {/* {upgradeSubscriptionList?.length ? (
                    <>
                      <Button
                        type='primary'
                        className='form-button update-subscription'
                        onClick={() => setUpgrade(true)}
                      >
                        Upgrade Subscription
                      </Button>
                    </>
                  ) : null} */}
                </div>
              ) : upgradeSubscriptionList?.length ? (
                <>
                  <Button
                    type='primary'
                    className='form-button update-subscription'
                    onClick={() => setUpgrade(true)}
                  >
                    Upgrade Subscription
                  </Button>
                </>
              ) : null}

              {
                userType === 'business' && user?.subscription?.subscriptionTypes?.includes('giftCard') ?
                  <Button
                    type='primary'
                    className='form-button update-subscription'
                    onClick={() => {
                      setBuyGiftCards(true)
                      // setCoupon(cardSubscriptions[1])
                    }}
                  >
                    Buy Gift Cards
                  </Button>
                  : null
              }

              {
                userType === 'business' && user?.subscription?.subscriptionTypes?.length ?
                  <><Button
                    type='primary'
                    className='form-button update-subscription'
                    onClick={() => setBuyOnlineLottery(true)}
                  >
                    Buy Online Lottery
                  </Button> <Button
                    type='primary'
                    className='form-button update-subscription'
                    onClick={() => {
                      setBuyCardLottery(true)
                      // setCoupon(cardSubscriptions[1])
                    }}
                  >
                      Buy Physical Lottery
                    </Button></> : null
              }
            </div>
          </div>
        </Spin>
      ) : (
        <Spin spinning={loading}>
          <div className='card-form'>
            <div
              className='user-card'
              style={{ minHeight: 'calc(100vh - 200px)' }}
            >
              {upgrade ? (<p className='heading head-center'>Upgrade Subscription</p>) : (<p className='heading head-center'>Subscription & Pricing</p>)}

              {cardSubscriptions?.length ? (<div>
                <div className='well postal-card'>
                  Postal Card (Once a week) Price: €{' '}
                  {convertNumber(cardSubscriptions[0]?.amount)} / piece
                </div>
                <div className='well postal-card'>
                  Postal Card (Fixed Day) Price: €{' '}
                  {convertNumber(cardSubscriptions[1]?.amount)} / piece
                </div>
              </div>
              ) : null}
              <div className='container-fluid'>
                <div className='row'>
                  {userType === 'guest' ?
                    <>
                      <div className='col-lg-4 col-md-6 col-12-4'>
                        <Card className='subscription-card'>
                          <Tooltip placement='top' /* title={v?.subscriptionName} */ title='Easyload Card'>
                            <div className='subscription-title'>
                              Easyload Card
                            </div>
                          </Tooltip>
                          <div className='subscription-amount'>
                            Load your wallet with € 5
                          </div>
                          <Button
                            type='primary'
                            className='form-button subscription-btn'
                            block
                            htmlType='submit'
                            onClick={() => easyLoad(5)}
                          >
                            Purchase Now
                          </Button>
                        </Card>
                      </div>
                      <div className='col-lg-4 col-md-6 col-12-4'>
                        <Card className='subscription-card'>
                          <Tooltip placement='top' /* title={v?.subscriptionName} */ title='Easyload Card'>
                            <div className='subscription-title'>
                              Easyload Card
                            </div>
                          </Tooltip>
                          <div className='subscription-amount'>
                            Load your wallet with € 10
                          </div>
                          <Button
                            type='primary'
                            className='form-button subscription-btn'
                            block
                            htmlType='submit'
                            onClick={() => easyLoad(10)}
                          >
                            Purchase Now
                          </Button>
                        </Card>
                      </div></>
                    : null}
                  {console.log('subscriptions===>', subscriptions)}
                  {(user?.subscription?.active && upgrade ? upgradeSubscriptionList : subscriptions)?.map((v, i) => {
                    if (userType === 'guest' && v?.subscriptionTypes?.indexOf('loyalty') !== -1) return null
                    if (userType === 'guest' && v?.subscriptionName === 'Subscription Lottery') return null
                    return (
                      <div className='col-lg-4 col-md-6 col-12-4' key={i}>
                        <Card className='subscription-card'>
                          <Tooltip placement='top' title={v?.subscriptionName}>
                            <div className='subscription-title'>
                              {stringLimiter(v?.subscriptionName)}
                            </div>
                          </Tooltip>
                          <div className='subscription-amount'>
                            Subscribe at € {v?.amount}/year
                          </div>
                          <Button
                            type='primary'
                            className='form-button subscription-btn'
                            block
                            htmlType='submit'
                            onClick={() => checkPostalCard(v)}
                          >
                            Subscribe Now
                          </Button>
                        </Card>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      )
      }
      {/* {console.log('postalCard********>', postalCard)} */}
      <Modal
        title={`Buy Postal Card ${visible ? '(Optional)' : null}`}
        visible={buyCards || visible}
        onCancel={modalCancel}
        style={{ width: 350 }}
        footer={[
          <Button
            key='back'
            // onClick={buyCards ? modalCancel : checkQuantity}
            onClick={modalCancel}
            className='form-input'
          >
            Cancel
          </Button>,
          <Button
            key='ok'
            onClick={checkQuantity}
            className='form-input'
            type='primary'
            disabled={!cardSubscriptions?.length}
          >
            Subscribe
          </Button>
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {postalCard?.amount ? (
            <div>
              <div className='well postal-card'>
                Postal Card Price: € {convertNumber(postalCard?.amount)} / piece
              </div>
              <div>
                <Input
                  placeholder='Enter Card pieces!'
                  style={{
                    marginLeft: 15,
                    marginRight: 15,
                    width: 'calc(100% - 28px)',
                  }}
                  type='number'
                  max={999}
                  maxLength={2}
                  min={0}
                  value={cardsQuantity}
                  onChange={(e) => setCardQuantity(e?.target?.value)}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className='well postal-card'>
                Postal Card (Once a week) Price: €{' '}
                {convertNumber(cardSubscriptions[0]?.amount)} / piece
              </div>
              <div>
                <Input
                  placeholder='Enter Card pieces!'
                  style={{
                    marginLeft: 15,
                    marginRight: 15,
                    width: 'calc(100% - 28px)',
                  }}
                  type='number'
                  max={999}
                  maxLength={2}
                  min={0}
                  value={cardsQuantity}
                  onChange={(e) => setCardQuantity(e?.target?.value)}
                />
              </div>
              <br />
              <div className='well postal-card'>
                Postal Card (Fixed Day) Price: €{' '}
                {convertNumber(cardSubscriptions[1]?.amount)} / piece
              </div>
              <div>
                <Input
                  placeholder='Enter Card pieces!'
                  style={{
                    marginLeft: 15,
                    marginRight: 15,
                    width: 'calc(100% - 28px)',
                  }}
                  min={0}
                  type='number'
                  max={999}
                  maxLength={2}
                  value={fixedCardsQuantity}
                  onChange={(e) => setFixedCardQuantity(e?.target?.value)}
                />
              </div>
            </div>
          )}
          <br />
          {subscription?.subscriptionTypes &&
            subscription?.subscriptionTypes?.indexOf('whatsapp') !== -1 ? (
            <div>
              <div className='well postal-card'>
                Whatsapp Subscription
              </div>
              <Select
                showSearch
                showArrow
                allowClear
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  width: 'calc(100% - 28px)',
                }}
                className='form-input'
                onSelect={(e) => setWhatsapp(e)}
                placeholder={'Select Whatsapp Subscription'}
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                }
              >
                {whatsappSubscriptions?.map((v, i) => (
                  <Option
                    value={v?._id}
                    key={i}
                  >{`${v?.subscriptionName} / € ${v?.amount}`}</Option>
                ))}
              </Select>
            </div>
          ) : null}
          <br />
          {subscription?.subscriptionTypes && subscription?.subscriptionTypes?.indexOf('loyalty') !== -1 ? (<div>
            <div className='well postal-card'>
              Loyalty Coupon Cards Subscription
            </div>
            <Select
              showSearch
              showArrow
              allowClear
              style={{
                marginLeft: 15,
                marginRight: 15,
                width: 'calc(100% - 28px)',
              }}
              className='form-input'
              onSelect={(e) => setCouponsSubscription(e)}
              placeholder={'Select Coupon Card Subscription'}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
              }
            >
              {loyaltyCardSubscriptions?.map((v, i) => (
                <Option
                  value={v?._id}
                  key={i}
                >{`${v?.subscriptionName} / € ${v?.amount}`}</Option>
              ))}
            </Select>
            <br />
            <div className='well postal-card'>
              Enter amount(€) of card coupon.
            </div>
            <div>
              <InputNumber
                placeholder='Enter Coupon amount!'
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  width: 'calc(100% - 28px)',
                }}
                type='text'
                max={1000}
                min={0.1}
                step={0.1}
                maxLength={4}
                value={cardCouponAmount}
                onChange={(e) => setCardCouponAmount(e)}
              />
            </div>
            <br />
            <div className='well postal-card'>
              Loyalty Coupons: €{' '}
              {convertNumber(loyaltySubscription?.amount)} / coupon
            </div>
            <div>
              <Input
                placeholder='Enter Card pieces!'
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  width: 'calc(100% - 28px)',
                }}
                type='number'
                min={0}
                max={999}
                maxLength={2}
                value={couponsQuantity}
                onChange={(e) => setCouponsQuantity(e?.target?.value)}
              />
            </div>
            <br />
            <div className='well postal-card'>
              Enter amount(€) of online coupons.
            </div>
            <div>
              <InputNumber
                placeholder='Enter Coupon amount ####!'
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  width: 'calc(100% - 28px)',
                }}
                type='text'
                max={1000}
                min={0.1}
                step={0.1}
                maxLength={4}
                // length={2}
                value={onlineCouponAmount}
                onChange={(e) => setOnlineCouponAmount(e)}
              />
            </div>
          </div>
          ) : null}
          {/* giftCards */}
          {/* <br /> */}
          {subscription?.subscriptionTypes && subscription?.subscriptionTypes?.indexOf('giftCard') !== -1 ? (
            <div>
              <div className='well postal-card'>
                Gift Card Price: € {convertNumber(giftCardSubscription?.amount)} / piece
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Input
                  placeholder='Enter Number of Gift Cards!'
                  style={{
                    marginLeft: 15,
                    marginRight: 15,
                    width: 'calc(100% - 28px)',
                  }}
                  type='number'
                  max={999}
                  min={0}
                  maxLength={2}
                  step={1}
                  value={giftCardsQuantity}
                  onChange={(e) => setGiftCardsQuantity(e?.target?.value)}
                />
                {/* <Select
                  showArrow
                  allowClear
                  className='form-input'
                  placeholder={`Select Amount`}
                  optionFilterProp='children'
                >
                  {selectAmount?.map((v, i) => <Option value={v} key={i}>{v}</Option>)}
                </Select> */}
              </div>
            </div>
          ) : null}
        </div>
      </Modal>
      <Modal
        title={`Buy online coupons ${visible ? '(Optional)' : ''}`}
        visible={buyCoupons}
        onCancel={modalCancel}
        style={{ width: 350 }}
        footer={[
          <Button
            key='back'
            // onClick={buyCards ? modalCancel : checkQuantity}
            onClick={modalCancel}
            className='form-input'
          >
            Cancel
          </Button>,
          <Button
            key='ok'
            onClick={checkQuantity}
            className='form-input'
            type='primary'
            disabled={!cardSubscriptions?.length}
          >
            Subscribe
          </Button>,
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {loyaltySubscription?.amount ? (
            <div>
              <div className='well postal-card'>
                Online Coupons Price: € {convertNumber(loyaltySubscription?.amount)} / piece
              </div>
              <div>
                <Input
                  placeholder='Enter Coupon pieces!'
                  style={{
                    marginLeft: 15,
                    marginRight: 15,
                    width: 'calc(100% - 28px)',
                  }}
                  type='number'
                  max={999}
                  min={0}
                  maxLength={2}
                  value={couponsQuantity}
                  onChange={(e) => setCouponsQuantity(e?.target?.value)}
                />
              </div>
              <br />
              <div className='well postal-card'>
                Enter amount(€) of online coupon.
              </div>
              <div>
                <InputNumber
                  placeholder='Enter Coupon amount!'
                  style={{
                    marginLeft: 15,
                    marginRight: 15,
                    width: 'calc(100% - 28px)',
                  }}
                  type='text'
                  max={1000}
                  min={0.1}
                  step={0.1}
                  maxLength={4}
                  value={onlineCouponAmount}
                  // onChange={(e) => setOnlineCouponAmount(+e?.target?.value)}
                  onChange={(e) => setOnlineCouponAmount(e)}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className='well postal-card'>
                Postal Card (Once a week) Price: €{' '}
                {convertNumber(cardSubscriptions[0]?.amount)} / piece
              </div>
              <div>
                <Input
                  placeholder='Enter Card pieces!'
                  style={{
                    marginLeft: 15,
                    marginRight: 15,
                    width: 'calc(100% - 28px)',
                  }}
                  type='number'
                  max={999}
                  min={0}
                  maxLength={2}
                  value={cardsQuantity}
                  onChange={(e) => setCardQuantity(e?.target?.value)}
                />
              </div>
              <br />
              <div className='well postal-card'>
                Postal Card (Fixed Day) Price: €{' '}
                {convertNumber(cardSubscriptions[1]?.amount)} / piece
              </div>
              <div>
                <Input
                  placeholder='Enter Card pieces!'
                  style={{
                    marginLeft: 15,
                    marginRight: 15,
                    width: 'calc(100% - 28px)',
                  }}
                  type='number'
                  max={999}
                  min={0}
                  maxLength={2}
                  value={fixedCardsQuantity}
                  onChange={(e) => setFixedCardQuantity(e?.target?.value)}
                />
              </div>
            </div>
          )}
          <br />
          {subscription?.subscriptionTypes &&
            subscription?.subscriptionTypes?.indexOf('whatsapp') !== -1 ? (
            <div>
              <div className='well postal-card'>
                Whatsapp Subscription
              </div>
              <Select
                showSearch
                showArrow
                allowClear
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  width: 'calc(100% - 28px)',
                }}
                className='form-input'
                onSelect={(e) => setWhatsapp(e)}
                placeholder={'Select Whatsapp Subscription'}
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                }
              >
                {whatsappSubscriptions?.map((v, i) => (
                  <Option
                    value={v?._id}
                    key={i}
                  >{`${v?.subscriptionName} / € ${v?.amount}`}</Option>
                ))}
              </Select>
            </div>
          ) : null}
          <br />
          {subscription?.subscriptionTypes && subscription?.subscriptionTypes?.indexOf('loyalty') !== -1 ? (<div>
            <div className='well postal-card'>
              Loyalty Coupon Cards Subscription
            </div>
            <Select
              showSearch
              showArrow
              allowClear
              style={{
                marginLeft: 15,
                marginRight: 15,
                width: 'calc(100% - 28px)',
              }}
              className='form-input'
              onSelect={(e) => setCouponsSubscription(e)}
              placeholder={'Select Coupon Card Subscription'}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
              }
            >
              {loyaltyCardSubscriptions?.map((v, i) => (
                <Option
                  value={v?._id}
                  key={i}
                >{`${v?.subscriptionName} / € ${v?.amount}`}</Option>
              ))}
            </Select>
            <br />
            <div className='well postal-card'>
              Loyalty Coupons: €{' '}
              {convertNumber(loyaltySubscription?.amount)} / coupon
            </div>
            <div>
              <Input
                placeholder='Enter Card pieces!'
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  width: 'calc(100% - 28px)',
                }}
                type='number'
                max={999}
                min={0}
                maxLength={2}
                value={couponsQuantity}
                onChange={(e) => setCouponsQuantity(e?.target?.value)}
              />
            </div>
            <br />
            <div className='well postal-card'>
              Enter amount(€) of online couponssss.
            </div>
            <div>
              <InputNumber
                placeholder='Enter Coupon amount ####!'
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  width: 'calc(100% - 28px)',
                }}
                type='text'
                max={1000}
                min={0.1}
                step={0.1}
                // maxLength={4}
                // length={2}
                value={onlineCouponAmount}
                onChange={(e) => setOnlineCouponAmount(e)}
              />
            </div>
          </div>
          ) : null}
        </div>
      </Modal>
      {/* Lottery Modal */}
      <Modal
        title={`Buy online Lotteries ${visible ? '(Optional)' : ''}`}
        visible={buyOnlineLottery}
        onCancel={modalCancel}
        style={{ width: 350 }}
        footer={[
          <Button
            key='back'
            // onClick={buyCards ? modalCancel : checkQuantity}
            onClick={modalCancel}
            className='form-input'
          >
            Cancel
          </Button>,
          <Button
            key='ok'
            onClick={checkQuantity}
            className='form-input'
            type='primary'
            disabled={!cardSubscriptions?.length}
          >
            Subscribe
          </Button>,
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {/* {loyaltySubscription?.amount ? ( */}
          <div>
            <div className='well postal-card'>
              Online Lotteries Price: € {convertNumber(lotterySubscription?.amount)} / piece
            </div>
            <div>
              <Input
                placeholder='Enter Lottery pieces!'
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  width: 'calc(100% - 28px)',
                }}
                type='number'
                max={999}
                min={0}
                maxLength={2}
                value={lotteriesQuantity}
                onChange={(e) => setLotteriesQuantity(e?.target?.value)}
              />
            </div>
            <br />
          </div>
          <br />
          {subscription?.subscriptionTypes &&
            subscription?.subscriptionTypes?.indexOf('whatsapp') !== -1 ? (
            <div>
              <div className='well postal-card'>
                Whatsapp Subscription
              </div>
              <Select
                showSearch
                showArrow
                allowClear
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  width: 'calc(100% - 28px)',
                }}
                className='form-input'
                onSelect={(e) => setWhatsapp(e)}
                placeholder={'Select Whatsapp Subscription'}
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
                }
              >
                {whatsappSubscriptions?.map((v, i) => (
                  <Option
                    value={v?._id}
                    key={i}
                  >{`${v?.subscriptionName} / € ${v?.amount}`}</Option>
                ))}
              </Select>
            </div>
          ) : null}
          <br />
          {subscription?.subscriptionTypes && subscription?.subscriptionTypes?.indexOf('loyalty') !== -1 ? (<div>
            <div className='well postal-card'>
              Loyalty Coupon Cards Subscription
            </div>
            <Select
              showSearch
              showArrow
              allowClear
              style={{
                marginLeft: 15,
                marginRight: 15,
                width: 'calc(100% - 28px)',
              }}
              className='form-input'
              onSelect={(e) => setCouponsSubscription(e)}
              placeholder={'Select Coupon Card Subscription'}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
              }
            >
              {lotteryCardSubscriptions?.map((v, i) => (
                <Option
                  value={v?._id}
                  key={i}
                >{`${v?.subscriptionName} / € ${v?.amount}`}</Option>
              ))}
            </Select>
            <br />
            <div className='well postal-card'>
              Loyalty Coupons: €{' '}
              {convertNumber(lotterySubscription?.amount)} / coupon
            </div>
            <div>
              <Input
                placeholder='Enter Card pieces!'
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  width: 'calc(100% - 28px)',
                }}
                type='number'
                max={999}
                min={0}
                maxLength={2}
                value={couponsQuantity}
                onChange={(e) => setCouponsQuantity(e?.target?.value)}
              />
            </div>
            <br />
            <div className='well postal-card'>
              Enter amount(€) of online couponssss.
            </div>
            <div>
              <InputNumber
                placeholder='Enter Coupon amount ####!'
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  width: 'calc(100% - 28px)',
                }}
                type='text'
                max={1000}
                min={0.1}
                step={0.1}
                // maxLength={4}
                // length={2}
                value={onlineCouponAmount}
                onChange={(e) => setOnlineCouponAmount(e)}
              />
            </div>
          </div>
          ) : null}
        </div>
      </Modal>
      <Modal
        title={`Buy Whatsapp Messages`}
        visible={buyWhatsapp}
        onCancel={modalCancel}
        style={{ width: 350 }}
        footer={[
          <Button key='back' onClick={modalCancel} className='form-input'>
            Cancel
          </Button>,
          <Button
            key='ok'
            onClick={checkQuantity}
            className='form-input'
            type='primary'
          >
            Subscribe
          </Button>
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Select
            showSearch
            showArrow
            allowClear
            style={{
              marginLeft: 15,
              marginRight: 15,
              width: 'calc(100% - 28px)',
            }}
            className='form-input'
            onSelect={(e) => setWhatsapp(e)}
            placeholder={'Select Whatsapp Subscription'}
            optionFilterProp='children'
            filterOption={(input, option) =>
              option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
            }
          >
            {whatsappSubscriptions?.map((v, i) => (
              <Option
                value={v?._id}
                key={i}
              >{`${v?.subscriptionName} / € ${v?.amount}`}</Option>
            ))}
          </Select>
        </div>
      </Modal>
      <Modal
        title={`Buy Coupon Cards`}
        visible={buyCardCoupons}
        onCancel={modalCancel}
        style={{ width: 350 }}
        footer={[
          <Button key='back' onClick={modalCancel} className='form-input'>
            Cancel
          </Button>,
          <Button
            key='ok'
            onClick={checkQuantity}
            className='form-input'
            type='primary'
          >
            Subscribe
          </Button>,
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Select
            showSearch
            showArrow
            allowClear
            style={{
              marginLeft: 15,
              marginRight: 15,
              width: 'calc(100% - 28px)',
            }}
            className='form-input'
            onSelect={(e) => setCoupon(e)}
            placeholder={'Select Card Coupon Subscription'}
            optionFilterProp='children'
            filterOption={(input, option) =>
              option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
            }
          >
            {loyaltyCardSubscriptions?.map((v, i) => (
              <Option
                value={v?._id}
                key={i}
              >{`${v?.subscriptionName} / € ${v?.amount}`}</Option>
            ))}
          </Select>
        </div>
        <br />
        <div className='well postal-card'>
          Enter amount(€) of card coupon.
        </div>
        <div>
          <InputNumber
            placeholder='Enter Coupon amount!'
            style={{
              marginLeft: 15,
              marginRight: 15,
              width: 'calc(100% - 28px)',
            }}
            type='text'
            max={1000}
            min={0.1}
            step={0.1}
            maxLength={4}
            value={cardCouponAmount}
            onChange={(e) => setCardCouponAmount(e)}
          />
        </div>
      </Modal>
      <Modal
        title={`Buy Lottery Cards`}
        visible={buyCardLottery}
        onCancel={modalCancel}
        style={{ width: 350 }}
        footer={[
          <Button key='back' onClick={modalCancel} className='form-input'>
            Cancel
          </Button>,
          <Button
            key='ok'
            onClick={checkQuantity}
            className='form-input'
            type='primary'
          >
            Subscribe
          </Button>,
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Select
            showSearch
            showArrow
            allowClear
            style={{
              marginLeft: 15,
              marginRight: 15,
              width: 'calc(100% - 28px)',
            }}
            className='form-input'
            onSelect={(e) => setLottery(e)}
            placeholder={'Select Card Lottery Subscription'}
            optionFilterProp='children'
            filterOption={(input, option) =>
              option?.value?.toString()?.indexOf(input.toLowerCase()) >= 0
            }
          >
            {lotteryCardSubscriptions?.map((v, i) => (
              <Option
                value={v?._id}
                key={i}
              >{`${v?.subscriptionName} / € ${v?.amount}`}</Option>
            ))}
          </Select>
        </div>
      </Modal>
      {/* giftCards */}
      <Modal
        title={`Buy Gift Cards`}
        visible={buyGiftCards || visible2}
        onCancel={modalCancel}
        style={{ width: 350 }}
        footer={[
          <Button key='back' onClick={modalCancel} className='form-input'>
            Cancel
          </Button>,
          <Button
            key='ok'
            onClick={checkQuantity}
            className='form-input'
            type='primary'
          >
            Subscribe
          </Button>
        ]}
      >
        <div className='well postal-card'>
          Gift Card Price: € {convertNumber(giftCardSubscription?.amount)} / piece
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Input
            placeholder='Enter Number of Gift Cards!'
            style={{
              marginLeft: 15,
              marginRight: 15,
              width: 'calc(100% - 28px)',
            }}
            type='number'
            max={999}
            min={0}
            maxLength={2}
            step={1}
            value={giftCardsQuantity}
            onChange={(e) => setGiftCardsQuantity(e?.target?.value)}
          />
        </div>
      </Modal>
    </div >
  )
}

export default Subscription