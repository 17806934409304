import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { DeleteOutlined,EditOutlined } from '@ant-design/icons'
import { Table, Input, Button, Space, Popconfirm, Spin, Image } from 'antd'
import { getAllUserData, convertTitle, successNotification } from '../../utils/helpers'
import { allPaths } from '../../utils/constants'
import { ADMIN, GET } from '../../utils/apis'
import AddCards from './AddCards'
import { useSelector } from 'react-redux'

const Cards = (props) => {
    const { user, userActions, history } = props
    const [spin, setSpin] = useState({})
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const [showForm, setShowForm] = useState(false)
    // const [cards, setCards] = useState([])
    const [loading, setLoading] = useState(true)
    const [edit, setEdit] = useState({})
    const searchInput = useRef(null)
    const cards = useSelector(state => state?.userReducer?.cards || [])

    useEffect(() => {
        if (user?.subscription?.subscriptionTypes?.indexOf('ownCard') === -1) {
            return history?.push(allPaths.HOME)
        }
        getAllUserData(user?._id, userActions)

        // getCards()
        if (!cards) {
            setLoading(true)
        }
    }, [showForm, cards])

    // const getCards = () => {
    //     setLoading(true)
    //     axios.get(`${GET.CARDS_BY_ID}/${user?._id}`)
            // .then((res) => {
            //     const { data } = res
            //     // setCards(data?.data || [])
            //     setLoading(false)
            // })
            // .catch(() => {
            //     setLoading(false)
            // })
    // }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => {
                            confirm({ closeDropdown: false })
                            setSearchText(selectedKeys[0])
                            setSearchedColumn(dataIndex)
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100)
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const deleteCard = (_id) => {
        setSpin({ [_id]: true })
        axios.post(`${ADMIN.DELETE_CARD}/${user?._id}`, { _id })
            .then((res) => {
                const { data } = res
                setSpin({})
                // getCards()
                if (data?.success) {
                    successNotification('Successfully Deleted!')
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }

    const showEdit = (e) => {
        setEdit(e)
        setShowForm(true)
    }

    let columns = [
        {
            title: 'Design Name',
            dataIndex: 'designName',
            key: 'designName',
            sorter: true,
            ellipsis: true,
            ...getColumnSearchProps('designName')
        },
        {
            title: 'Card Design',
            key: 'design',
            sorter: true,
            ellipsis: true,
            render: (e) => <Image src={e?.imageUrl} style={{ marginRight: 10, width: 86, height: 60 }} shape='square' />
        },
        {
            title: 'Created At',
            dataIndex: 'created',
            key: 'created',
            ellipsis: true
        },
        {
            title: 'Actions',
            key: 'actions',
            ellipsis: true,
            render: (e) =>
                <span>
                    <Button onClick={() => showEdit(e)}><EditOutlined />Edit</Button> &nbsp;&nbsp;&nbsp;
                    <Popconfirm
                        title={`Delete ${convertTitle(e?.designName)}?`}
                        okText='Delete'
                        cancelText='Cancel'
                        okButtonProps={{
                            type: 'primary',
                            className: 'form-button'
                        }}
                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                        onConfirm={() => deleteCard(e?._id)}
                    >
                        <Button href="#"><DeleteOutlined style={{ color: 'red' }} /> Delete</Button>
                        {spin[e?._id] ? <span className='demo-loading-container'>
                            &nbsp;<Spin />
                        </span> : null}
                    </Popconfirm>
                </span>
        }
    ]

    return (
        <div className='flex-column menu-main'>
            {showForm ? <AddCards edit={edit} onCancel={() => (setShowForm(false), setEdit(null))} {...props} /> : <div>
                <div className='button-end button-end-scroll'>
                    <Button
                        type='primary'
                        className='btn-end'
                        onClick={() => setShowForm(true)}
                    >
                        Create Card
                    </Button>
                </div>

                <div className='user-table'>
                    <Table
                        columns={columns}
                        dataSource={cards}
                        // loading={loading}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 800 }}
                    />
                </div>
            </div>}
        </div>
    )
}

export default Cards