import React, { useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, Upload ,Checkbox} from 'antd'
import { inputPlace, requiredMessage } from '../../../utils/helpers'
import axios from 'axios'
import { POST, GET, ADMIN } from '../../../utils/apis'
import { convertTitle, successNotification, errorMessage, warningMessage } from '../../../utils/helpers'

const AddGiftCardImages = (props) => {
    const { onCancel, edit, user } = props
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [checkboxValue, setCheckboxValue] = useState(false)

    const onFinish = (values) => {

        // console.log('values',values)
        values.file = fileList
        edit?._id && (values._id = edit?._id)
        values.adminId = user?._id
        // values.isAmountZero = checkboxValue

        if (!values._id && !values?.file?.length) {
            return warningMessage('Please Upload Image')
        }
        setLoading(true)

        let formData = new FormData()
        if (values?.file?.length) {
            formData.append('file', values?.file[0])
        }
        if (values?._id) {
            formData.append('_id', values?._id)
        }
        formData.append('name', values?.name)
        formData.append('adminId', values?.adminId)

        console.log('values', values)
        axios.post(ADMIN.CREATE_GIFT_CARD_IMAGES, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(data?.message)
                    console.log('data', data)
                    onCancel()
                    // getCards()
                }
                // errorMessage(data.message)
            })
            .catch((e) => {
                setLoading(false)
                errorMessage()
                console.log('e', e)
            })
    }
    const onChange = (e) => {
        setCheckboxValue(e?.target?.checked)
    }

    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage('You can only upload Images!')
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'> Add Gift Card Images</p>
                    <Form
                        name='form'
                        onFinish={onFinish}
                        layout={'vertical'}
                        requiredMark={false}
                    >
                        <Form.Item
                            name='name'
                            label='Image Name'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Input Image Name'
                                }
                            ]}
                        >
                            <Input
                                className='form-input'
                                placeholder='Please Input Image Name'
                            />
                        </Form.Item>
                        {/* <Form.Item
                            label='Amount Is greater than 0'
                            name='isAmountZero'
                            onChange={onChange}
                            initialValue={checkboxValue}
                            valuePropName='checked'>

                            <Checkbox>Amount Is greater than 0</Checkbox>

                        </Form.Item> */}

                        <Form.Item
                            name='file'
                            label='Image'
                        >
                            <Upload
                                name='file'
                                multiple={false}
                                beforeUpload={() => false}
                                accept='image/png, image/jpeg'
                                onChange={normFile}
                                fileList={fileList}
                            >
                                <Button icon={<UploadOutlined />}>Click to upload</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type='primary'
                                className='form-button'
                                block
                                htmlType='submit'
                                loading={loading}
                            >
                                Create
                            </Button>
                            <Button
                                style={{ borderRadius: 4, marginTop: 6 }}
                                block
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AddGiftCardImages