import React, { useState, useEffect } from 'react'
import { Table, Space, Button, Spin, Popconfirm, Modal, Image } from 'antd';
import { ADMIN } from '../../../utils/apis'
import { DeleteOutlined } from '@ant-design/icons'
import axios from 'axios';
import { successNotification, convertTitle } from '../../../utils/helpers'
// import ReactPlayer from 'react-player'

const ViewAllQuestions = (props) => {
    const { setAllQuestion, createQuestion, createForm, user, setCopyQuestion } = props

    const [loading, setLoading] = useState(false)
    const [questions, setQuestions] = useState([])
    const [spin, setSpin] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [viewSelectedData, setViewSelectedData] = useState('')
    const showModal = (e) => {
        setIsModalVisible(true)
        setViewSelectedData(e)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const getMesage = () => {
        setLoading(true)
        axios.get(`${ADMIN.GET_QUESTION}/${user?._id}`)
            .then((res) => {
                const { data } = res
                console.log('All questions', data)
                setQuestions(data?.data || [])
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getMesage()
    }, [])

    const deleteQuestion = (_id) => {
        setSpin({ [_id]: true })

        let valObj = { _id, userId: `${user?._id}` }
        axios.post(`${ADMIN.DELETE_QUESTION}`, valObj)
            .then((res) => {
                getMesage()
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successNotification('Successfully Deleted!')
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }

    const copyQues = (e) => {
        delete e?._id
        createQuestion()
        setCopyQuestion(e)

    }

    const editQues = (e) => {
        createQuestion()
        setCopyQuestion(e)

    }

    const columns = [
        {
            title: 'Questions',
            dataIndex: 'question',
            key: 'question',
            // render: text => <a>{text}</a>,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            // render: text => <a>{text}</a>,
        },
        {
            title: 'Point',
            dataIndex: 'point',
            key: 'point',
            // render: text => <a>{text}</a>,
        },
        {
            title: 'Type',
            dataIndex: 'questionType',
            render: text => text?.toUpperCase(),
        },
        {
            title: 'Action',
            key: 'action',
            render: (e) => <span>
                <a onClick={() => showModal(e)}>View</a> &nbsp;&nbsp;&nbsp;
                <a onClick={() => copyQues(e)}>Copy</a> &nbsp;&nbsp;&nbsp;
                {/* <a onClick={() => editQues(e)}>Edit</a> &nbsp;&nbsp;&nbsp; */}
                <Popconfirm
                    title={`Delete ${convertTitle("this question")}?`}
                    okText='Delete'
                    cancelText='Cancel'
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteQuestion(e?._id)}
                >
                    <a href="#"><DeleteOutlined style={{ color: 'red' }} /> Delete</a>
                </Popconfirm>
            </span>
        },
    ]
    return (
        <div>
            <div>
                <div className='button-end button-end-scroll'>
                    <Button
                        type='primary'
                        className='btn-end invite-guest'
                        onClick={setAllQuestion}
                    >
                        View All Forms
                    </Button>
                    <Button
                        type='primary'
                        className='btn-end invite-guest'
                        onClick={createForm}
                    >
                        Create form
                    </Button>
                    <Button
                        type='primary'
                        className='btn-end'
                        onClick={createQuestion}
                    >
                        Create Question
                    </Button>
                </div>
                <div className='user-table'>
                    <Modal title='Queston Detail' visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} cancelButtonProps={{ style: { display: 'none' } }}>
                        <strong>Title:</strong> {`${viewSelectedData?.title}`}<br />
                        <strong>Question:</strong> {`${viewSelectedData?.question}`}<br />
                        <strong>Question Type:</strong> {`${viewSelectedData?.questionType}`}<br />
                        <strong>Point:</strong> {`${viewSelectedData?.point}`}<br />
                        <strong>Answer Type:</strong> {`${viewSelectedData?.answerType}`}<br />
                        <strong>Answer:</strong>
                        <ul>
                            {viewSelectedData?.answerList?.map((v, i) => {
                                return (
                                    <>
                                        <li key={i}>{v ? v?.option : 'No Answer Found'}</li>
                                        <ul>
                                            {v?.explaination ? <li> <strong> Explaination: {v?.explaination}</strong></li> : null}
                                            {
                                                v?.file?.mediaType == 'video' ?
                                                    <>
                                                        <video width={200} height={'auto'} controls>
                                                            <source src={v?.file?.url} />
                                                        </video>
                                                    </>
                                                    : v?.file?.mediaType == 'image' ? <Image src={v?.file?.url} width={200} height={'auto'} /> : null
                                            }
                                        </ul>
                                    </>

                                )
                            })}
                        </ul>
                        {
                            viewSelectedData?.questionType == 'test' ?
                                <>
                                    <strong>Right Answer:</strong>
                                    <ul>
                                        {viewSelectedData?.answerList?.filter((v) => v?.isTrue).map((v, i) => {
                                            return (
                                                <>
                                                    <li key={i}>{v?.option}</li>
                                                </>
                                            )
                                        })}
                                    </ul>
                                </>
                                : null
                        }

                        <strong>Chapter:</strong> {`${viewSelectedData?.chapter?.chapterName}`}<br />
                        <strong>Paragraph:</strong> {`${viewSelectedData?.paragraph?.paragraphName}`}<br />
                        <strong>Topics:</strong> <br />
                        <ul>
                            {viewSelectedData?.topics?.map((v, i) => {
                                return (
                                    <li key={i}>{v ? v.topicName : 'No topic Found'}</li>
                                )
                            })}
                        </ul>
                    </Modal>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={questions}
                        pagination={false}
                        scroll={{ x: 800 }}
                    />
                </div>
            </div>
        </div>
    )
}

export default ViewAllQuestions;
