import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { GET, POST, ADMIN } from '../../utils/apis'
import { Button, Popconfirm, Spin, Table, Tabs, DatePicker } from 'antd'
import { convertTitle, errorMessage, successMessage } from '../../utils/helpers'
import UpdateLottery from './UpdateLottery'
import moment from 'moment'
import _ from 'lodash'
import IconButton from '@mui/material/IconButton'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import CloseIcon from '@mui/icons-material/Close';

const { RangePicker } = DatePicker


const WithDrawRequest = (props) => {

    var dateObj = new Date()
    const todayDate = dateObj.toLocaleDateString('en-CA').slice(0, 7)

    const { user, onCancel } = props
    const [showCreateLottery, setShowCreateLottery] = useState(false)
    const [edit, setEdit] = useState({})
    const [loading, setLoading] = useState(false)
    const [withDrawForm, setWithDrawForm] = useState([])
    const [filteredArray, setfilteredArray] = useState([])
    const [dateRange, setDateRange] = useState({
        date1: moment().add(-1, 'months').format('YYYY-MM-DD'),
        date2: moment().format('YYYY-MM-DD')
    })


    useEffect(() => {
        setfilteredArray(_.uniqBy(makArray, 'text'))
    }, [withDrawForm])

    useEffect(() => {
        getForms()
    }, [dateRange])

    const makArray = withDrawForm.map((v, i) => {
        return {
            text: moment(v?.date).format('DD-MM-YYYY'),
            value: moment(v?.date).format('DD-MM-YYYY'),
        }
    })
    const approveStatus = (e) => {
        let obj = {
            _id: e?.key,
            isPaid: true,
            userId  : user?._id,
            lotteryCode: e?.lotteryCode
        }
        setLoading(true)
        axios.post(POST.ACCEPT_WITH_DRAW_REQUEST, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    getForms()
                    successMessage(data?.message)
                }
                else {
                    errorMessage(data?.message)
                }
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
            })
    }

    const getForms = () => {
        setLoading(true)
        let valObj = { userId: `${user?._id}`, date1: dateRange?.date1, date2: dateRange?.date2 }

        axios.post(`${POST.GET_WITH_DRAW_REQUEST_USER}`, valObj)
            .then((res) => {
                const { data } = res
                setWithDrawForm(data?.data)
                setLoading(false)
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
            })
    }

    const columns = [
        {
            title: 'Guest Name',
            dataIndex: 'fullname',
            key: 'fullname',
            // render: (e) => console.log('a', e)
        },
        {
            title: 'Guest Email',
            dataIndex: 'email',
            key: 'email',
            // render: (e) => console.log('a', e)
        },
        {
            title: 'Lottey Code',
            dataIndex: 'lotteryCode',
            key: 'lotteryCode',
            // render: text => <a>{text}</a>
        },

        {
            title: 'Approved',
            dataIndex: 'isApproved',
            key: 'isApproved',
            render: e => <a>{e ? "true" : 'false'}</a>
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            filters: filteredArray,
        },
        {
            title: 'Status',
            key: 'action',
            render: (e) => (

                e?.isApproved === true ?
                    <> < IconButton style={{ color: 'green' }} disabled >
                        <DoneAllIcon />
                    </IconButton ></> :
                    <> < IconButton style={{ color: 'red' }} onClick={() => { approveStatus(e) }} >
                        <CloseIcon />
                    </IconButton >
                    </>

            )
        }

    ]

    const showEdit = (e) => {
        setEdit(e)
        setShowCreateLottery(true)
    }

    const dataSource = withDrawForm.map((info, i) => {
        return {
            key: info?._id,
            fullname: info?.guestId?.fullName,
            email: info?.guestId?.email,
            lotteryCode: info?.lotteryCode,
            isApproved: info?.isApproved,
            created: moment(info?.created).utcOffset(0, true).format('DD-MM-YYYY'),
        }
    })
    const createReportRange = (d) => {
        setDateRange({
            date1: d[0].format('YYYY-MM-DD'),
            date2: d[1].format('YYYY-MM-DD')
        })
        getForms()
    }
    const disabledDate = (current) => {
        let customDate = moment().format('YYYY-MM-DD')
        return current && current > moment(customDate, 'YYYY-MM-DD').add(1, 'day')
    }
    return (
        <>
            <div>
                <div className='flex-mobile' style={{ display: 'flex' }}>
                    <RangePicker
                        defaultValue={[moment().add(-1, 'months'), moment()]}
                        onChange={createReportRange}
                        style={{ marginBottom: 10 }}
                    // disabledDate={disabledDate}
                    />
                </div>
                <div className='button-end button-end-scroll'>
                    <Button
                        type='primary'
                        className='btn-end invite-guest invite-guest3'
                        onClick={onCancel}
                    >
                        Back
                    </Button>

                </div>

                <div className='user-table'>
                    <Table
                        loading={loading ? <Spin /> : ""}
                        columns={columns}
                        dataSource={dataSource}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 800 }}
                    />
                </div>
            </div>
        </>
    )
}

export default WithDrawRequest