import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Form, Input, Button, Select, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { requiredMessage, inputPlace, successNotification, errorMessage, warningMessage } from '../../utils/helpers'
import { AUTH } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import axios from 'axios'
import { removeUser } from '../../Redux/actions/authActions'
import countryCodes from '../../utils/countryCodes'
// import SIGNUP_BU from '../../Config/paths.jsx'

const { Option } = Select

const Signup = (props) => {
    const { history } = props
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [country, setCountry] = useState('NL')
    const [countryCode, setCountryCode] = useState('+31')
    const formRef = useRef(null)
    const dispatch = useDispatch()

    const path = history?.location?.pathname


    useEffect(() => {
        dispatch(removeUser())
    }, [])

    const onFinish = (values) => {

        values.userType = path === allPaths?.BU_SIGNUP ? 'business' : 'guest'

        values.file = fileList

        if (!values?.file?.length) {
            return warningMessage('Please Upload Logo!')
        }

        let formData = new FormData()

        if (values?.file?.length) {
            formData.append('file', values?.file[0])
        }

        for (var [k, v] of Object.entries(values)) {
            if (k && v) {
                formData.append(k, v)
            }
        }

        setLoading(true)
        axios.post(AUTH.SIGNUP, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(
                        'Successfully Signup we have sent you a verification link to your email, Please verify your email and login!'
                    )
                    return setTimeout(() => {
                        history.push('/login')
                    }, 300)
                }
                errorMessage(data.message)
            })
            .catch((e) => {
                setLoading(false)
                errorMessage()
            })
    }

    const updateCountry = (e) => {
        let selectedCode = countryCodes.filter((v) => v?.code === e)[0].dial_code

        setCountryCode(selectedCode)

        formRef.current.setFieldsValue({ prefix: selectedCode })
    }

    const prefixSelector = () => {
        return (
            <Form.Item name='prefix' noStyle>
                <Select
                    showSearch
                    disabled
                    style={{
                        minWidth: 80,
                        maxWidth: window.innerWidth > 500 ? 'auto' : 120,
                    }}
                    defaultValue={countryCode}
                    value={countryCode}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {countryCodes?.map((v, i) => (
                        <Option
                            value={v?.dial_code}
                            key={i}
                        >{`${v?.name} ${v?.dial_code}`}</Option>
                    ))}
                </Select>
            </Form.Item>
        )
    }

    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage('You can only upload Images!')
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    return (
        <div className='card-parent background-layout' style={{ height: '100%', paddingTop: 50, paddingBottom: 50 }}>
            <div className='card1'>
                <p className='heading head-center'>{path === allPaths?.BU_SIGNUP ? 'Business Register' : 'Register'}</p>
                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    requiredMark={false}
                    ref={formRef}
                >
                    <Form.Item
                        name='fullName'
                        label='Full Name'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('Full Name')
                            }
                        ]}
                    >
                        <Input
                            className='form-input'
                            placeholder={inputPlace('Full name')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='file'
                        label='Logo'
                    >
                        <Upload
                            name='file'
                            multiple={false}
                            beforeUpload={() => false}
                            accept='image/png, image/jpeg'
                            onChange={normFile}
                            fileList={fileList}
                        >
                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        name='businessName'
                        label='Business Name'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('Business Name')
                            }
                        ]}
                    >
                        <Input
                            className='form-input'
                            placeholder={inputPlace('Business name')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='email'
                        label='Email'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('Email')
                            },
                            {
                                type: 'email'
                            }
                        ]}
                    >
                        <Input
                            className='form-input'
                            placeholder={inputPlace('email')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='streetNumber'
                        label='Street + Number'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('Street + Number')
                            }
                        ]}
                    >
                        <Input
                            className='form-input'
                            placeholder={inputPlace('Street + Number')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='postalCodeCity'
                        label='Postal Code'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('Postal Code')
                            }
                        ]}
                    >
                        <Input
                            className='form-input'
                            placeholder={inputPlace('Postal Code')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='country'
                        label='Country'
                        hasFeedback
                        initialValue={country}
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('Country')
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            showArrow
                            allowClear
                            className='form-input'
                            placeholder={`Select Country`}
                            optionFilterProp='children'
                            onChange={updateCountry}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {countryCodes?.map((v, i) => <Option value={v?.code} key={i}>{v?.name}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='city'
                        label='City'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('City')
                            }
                        ]}
                    >
                        <Input
                            className='form-input'
                            placeholder={inputPlace('City')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='telNo'
                        label='Tel No'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('Tel No')
                            }
                        ]}
                    >
                        <Input
                            addonBefore={prefixSelector()}
                            className='form-input'
                            placeholder={`Tel No e.g 612345678`}
                        />
                    </Form.Item>
                    <Form.Item
                        name='remark'
                        label='Remark'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('Remark')
                            }
                        ]}
                    >
                        <Input
                            className='form-input'
                            placeholder={inputPlace('Remark')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='password'
                        label='Password'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('Password')
                            },
                            {
                                min: 6
                            }
                        ]}
                    >
                        <Input.Password
                            className='form-input'
                            placeholder={inputPlace('password')}
                        />
                    </Form.Item>
                    <Form.Item
                        label='Confirm Password'
                        name='confirm'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: requiredMessage('confirm password'),
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve()
                                    }

                                    return Promise.reject(
                                        'The two passwords that you entered do not match!'
                                    )
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            placeholder={inputPlace('confirm password')}
                            className='form-input'
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type='primary'
                            className='form-button'
                            block
                            htmlType='submit'
                            loading={loading}
                        >
                            Register
                        </Button>
                    </Form.Item>
                </Form>

                <p>
                    Already have account?&nbsp;
                    {path === allPaths?.BU_SIGNUP ? <span>
                        <Link to={allPaths?.BU_LOGIN}>Business Login</Link>
                        <br />
                        <Link to={allPaths?.BU_FORGOT}>Forgot Password</Link>
                    </span> : <span>
                        <Link to={allPaths.LOGIN}>Login</Link>
                        <br />
                        <Link to={allPaths?.FORGOT}>Forgot Password</Link>
                    </span>}
                </p>
            </div>
        </div>
    )
}

export default Signup