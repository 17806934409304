import React, { useState, useEffect } from 'react'
import AddAdminAd from './AddAds'
import { DeleteOutlined, EditTwoTone, EyeOutlined } from '@ant-design/icons'
import axios from 'axios'
import _ from 'lodash'
import { ADMIN } from '../../../utils/apis'
import { convertTitle, successNotification } from '../../../utils/helpers'
import { Button, Image, Popconfirm, Spin, Table, Modal } from 'antd'

const GroupAds = (props) => {
    const { user, setForm, setEdit, form } = props
    const [advertisementData, setAdvertisementData] = useState([])
    const [loading, setLoading] = useState(false)
    // const [showGroupAd, setshowGroupAd] = useState(false)
    const [showGroups, setshowGroups] = useState(false)
    const [adName, setadName] = useState('')
    const [GroupsNames, setGroupsNames] = useState([])
    const [spin, setSpin] = useState({})
    const [selectionType, setSelectionType] = useState([])

    useEffect(() => {
        getAllData()
    }, [form])

    useEffect(() => {
        getKey()
    }, [advertisementData])

    const getAllData = () => {
        setLoading(true)

        axios.get(`${ADMIN?.GET_ADMIN_GROUP_ADVERTISEMENT}/${user?._id}`)
            .then(
                (res) => {
                    setAdvertisementData(res?.data?.data)
                    setLoading(false)
                }
            )
            .catch(e => {
                setLoading(false)
            })
    }


    const deleteAdvertisement = (_id) => {
        // setLoading(true)
        setSpin({ [_id]: true })

        let valObj = { _id, adminId: `${user?._id}` }
        axios.post(`${ADMIN.DELETE_ADMIN_GROUP_ADVERTISEMENT}`, valObj)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successNotification('Successfully Deleted!')
                    getAllData()
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Image',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: (e) => <Image src={`${e}`} style={{ marginRight: 0, width: 100, height: 60 }} shape='square' />
        },
        {
            title: 'No. of Groups',
            dataIndex: 'groupsLen',
            key: 'groupsLen',
            // render: (e) =>{console.log('e',e)}
        },
        {
            title: 'Actions',
            key: 'action',
            width: 100,
            render: (e) => (
                <div style={{ display: 'flex' }} ><Popconfirm
                    title={`Delete ${convertTitle('this Advertisement')}?`}
                    okText='Delete'
                    cancelText='Cancel'
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteAdvertisement(e?._id)}
                >{!e?.activeAdd ?
                    <DeleteOutlined style={{ color: 'red' }} /> : ''}
                </Popconfirm>&nbsp;
                    {/* <Button onClick={() => { setshowGroups(true); setGroupsNames(e?.groups) }} >
                        show
                    </Button> */}
                    <EyeOutlined onClick={() => { setadName(e?.name);setshowGroups(true); setGroupsNames(e?.groups) }} />&nbsp;
                    {!e?.activeAdd ?
                        <EditTwoTone onClick={() => { setEdit(e);setForm(true) }} /> : ''}
                </div >
            )
        }
    ]

    const columns2 = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'private',
            dataIndex: 'private',
            key: 'private',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? 'True' : 'False'}</p>
        },
        {
            title: 'Postal Code',
            dataIndex: 'postalCode',
            key: 'postalCode',
            render: (e) => {
                return (<>
                    <p>{e[0]?.postalcodeStart}-{e[0]?.postalcodeEnd}</p>
                    {e[1] ? <> <br />
                        <p>{e[1]?.postalcodeStart}-{e[1]?.postalcodeEnd}</p> </> : null}
                </>)
            }
            // render: (e) => {
            //     return (console.log(e[1]?.postalcodeStart))
            // }
        }
    ]


    const dataAdv = advertisementData.map((v, i) => {
        // console.log('v',v?.groups?.length)
        return {
            name: v?.name,
            key: v?._id,
            groupsLen: v?.groups?.length,
            groups: v?.groups,
            imageUrl: v?.imageUrl,
            _id: v?._id,
            activeAdd: v?.active,
            enabled: false
        }
    })

    let idArray = []

    dataAdv?.filter((a, i) => {
        let condForActiveAdd = a.activeAdd == true ? a?.key : 0
        idArray.push(condForActiveAdd)
    })


    const getKey = () => {
        setSelectionType(idArray)
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            let active = true

            let diff = _.differenceWith(selectionType, selectedRowKeys, _.isEqual)

            diff = diff?.filter((a) => a)

            let getActiveId = selectedRowKeys[selectedRowKeys?.length - 1]
            // setLoading(true)

            let val = { _id: getActiveId, adminId: `${user?._id}`, active: active }

            if (diff?.length) {
                val._id = diff[0]
                val.active = false
            }
            axios.post(`${ADMIN?.SET_ACTIVE_ADMIN_GROUP_ADVERTISEMENT}`, val)
                .then((res) => {
                    const { data } = res
                    setSpin({})
                    if (data?.success) {
                        if (diff?.length) {
                            successNotification('De activated Successfully!')
                        } else {
                            successNotification('Activated Successfully!')
                        }
                    }
                    setLoading(false)
                    getAllData()


                })
                .catch((e) => {
                    console.log(
                        'error', e
                    )
                    setSpin({})
                    setLoading(false)
                })

        }
    }

    const handleCancel = () => {
        setshowGroups(false)
    }

    return (
        <>
            <Table
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: selectionType,
                    ...rowSelection
                }}

                columns={columns}
                dataSource={dataAdv}
                loading={loading}
                pagination={{ hideOnSinglePage: true, pageSize: 5 }}
            />
            <Modal title={adName} footer={null} visible={showGroups} onCancel={handleCancel}>
                <Table
                    // loading={loading}
                    columns={columns2}
                    dataSource={GroupsNames}
                    pagination={{ hideOnSinglePage: true, pageSize: 5 }}
                />
            </Modal>
        </>
    )
}

export default GroupAds