import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Table, Input, Button, Space, Popconfirm, Spin, Checkbox } from 'antd'
import Highlighter from 'react-highlight-words'
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { convertTitle, successNotification, getAllData } from '../../utils/helpers'
import { AUTH } from '../../utils/apis'
import axios from 'axios'


const Users = (props) => {
    const { user, adminActions } = props
    const [spin, setSpin] = useState({})
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const searchInput = useRef(null)
    const users = useSelector(state => state?.adminReducer?.users) || []

    useEffect(() => {
        getAllData(user?._id, adminActions)
    }, [])

    const deleteUser = (userId) => {
        setSpin({ [userId]: true })
        axios.post(`${AUTH.DELETE_ACCOUNT}/${user?._id}`, { userId })
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successNotification('Successfully Deleted!')
                    adminActions?.addAllUsers(data?.data || [])
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }

    const updateSettings = (userData) => {
        setSpin({ [userData?._id]: true })
        axios.post(`${AUTH.UPDATE_SETTINGS}`, userData)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    successNotification('Successfully Updated!')
                    getAllData(user?._id, adminActions)
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => {
                            confirm({ closeDropdown: false })
                            setSearchText(selectedKeys[0])
                            setSearchedColumn(dataIndex)
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100)
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const updateUserData = (userData, key, value) => {
        userData[key] = value

        updateSettings(userData)
    }

    let columns = [
        {
            title: 'Name',
            dataIndex: 'fullName',
            key: 'fullName',
            sorter: true,
            ellipsis: true,
            ...getColumnSearchProps('fullName')
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
            ellipsis: true,
            ...getColumnSearchProps('email')
        },
        {
            title: 'Deactive Email',
            key: 'deactivateEmail',
            ellipsis: true,
            render: (userData) => <Checkbox defaultChecked={userData?.deactivateEmail} onClick={e => updateUserData(userData, 'deactivateEmail', e?.target?.checked)} />
        },
        {
            title: 'Deactive Whatsapp',
            key: 'deactivateWhatsapp',
            ellipsis: true,
            render: (userData) => <Checkbox defaultChecked={userData?.deactivateWhatsapp} onClick={e => updateUserData(userData, 'deactivateWhatsapp', e?.target?.checked)} />
        },
        {
            title: 'Deactive Upload Card Design',
            key: 'deactivateCard',
            ellipsis: true,
            render: (userData) => <Checkbox defaultChecked={userData?.deactivateCard} onClick={e => updateUserData(userData, 'deactivateCard', e?.target?.checked)} />
        },
        {
            title: 'Created At',
            dataIndex: 'created',
            key: 'created',
            ellipsis: true
        },
        {
            title: 'Actions',
            key: 'actions',
            ellipsis: true,
            render: (e) => <span>
                <Popconfirm
                    title={`Delete ${convertTitle(e?.fullName)}?`}
                    okText='Delete'
                    cancelText='Cancel'
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => deleteUser(e?._id)}
                >
                    <a href="#"><DeleteOutlined style={{ color: 'red' }} /> Delete</a>
                    {spin[e?._id] ? <span className='demo-loading-container'>
                        &nbsp;<Spin />
                    </span> : null}
                </Popconfirm>
            </span>
        }
    ]

    return (
        <div className='flex-column menu-main'>
            <div className='user-table'>
                <Table
                    columns={columns}
                    dataSource={users}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div>
        </div>
    )
}

export default Users