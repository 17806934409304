import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Button, Popconfirm, Table, Tooltip, Modal, Select, Form } from 'antd'
import { useSelector } from 'react-redux'
import { POST } from '../../utils/apis'
import { errorNotification, getAllUserData, successNotification } from '../../utils/helpers'
import AddSavings from './AddSavings'
import WithdrawalRequests from './WithdrawalRequests'

const { Option } = Select;


const Savings = (props) => {
  const { user, userActions } = props
  const savings = useSelector(state => state?.userReducer?.savings || [])
  const [spin, setSpin] = useState({})
  const [showRequests, setshowRequests] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [amountToWithdraw, setamountToWithdraw] = useState(0)
  const [savingInfo, setSavingInfo] = useState({})
  const [form] = Form.useForm();

  // console.log('user', user)
  // console.log('savings', savings)
  useEffect(() => {
    getAllUserData(user?._id, userActions)
  }, [])

  const withdrawController = (e) => {
    let obj = {
      userId: e?.businessUser,
      guestId: `${user?._id}`,
      notificationMessage: `${user?.fullName} claimed coupons of amount €${e?.netAmount} `,
      claimAmount: e?.netAmount,
      savingId: e._id
    }

    axios.post(`${POST.CREATE_NOTIFICATION}`, obj)
      .then((res) => {
        const { data } = res
        setSpin({})
        if (data?.success) {
          successNotification('WithDrawal Successfully!')
          getAllUserData(user?._id, userActions)

        }
      })
      .catch((e) => {
        console.log('e', e)
        setSpin({})
      })
  }

  const columns = [
    {
      title: 'Business Name',
      dataIndex: ['businessUser', 'businessName'],
      key: 'fullName',
      sorter: (a, b) => a.fullName - b.fullName,
      ellipsis: true
    },
    {
      title: 'Coupon Quantity',
      dataIndex: 'coupons',
      key: 'coupons',
      sorter: (a, b) => a.coupons - b.coupons,
      ellipsis: true,
      render: (coupons) => <p style={{ paddingTop: 20, marginTop: -5 }}>{coupons?.length}</p>
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      ellipsis: true,
      render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>
    },
    {
      title: 'Used Amount',
      key: 'usedAmount',
      dataIndex: 'usedAmount',
      sorter: (a, b) => a.usedAmount - b.usedAmount,
      ellipsis: true,
      render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>
    },
    {
      title: 'Net Amount',
      key: 'netAmount',
      dataIndex: 'netAmount',
      sorter: (a, b) => a.netAmount - b.netAmount,
      ellipsis: true,
      render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>
    },
    {
      title: 'Action',
      key: 'Action',
      render: (e) => (
        <>
          < Button
            onClick={() => { setIsVisible(true); setSavingInfo(e) }}
          // disabled={e?.netAmount >= e?.businessUser?.minimumWithdrawalAmount ? false : true} 
          >
            Withdraw
          </Button>
        </>

      )
    }
  ]

  const onFinish = (values) => {

    setLoading(true)

    let obj = {
      userId: savingInfo?.businessUser?._id,
      guestId: user?._id,
      withdrawAmount: amountToWithdraw,
      coupons: values?.coupons
    }

    axios.post(`${POST.CREATE_LOYALTY_WITHDRAW_REQUEST}`, obj)
      .then((res) => {
        const { data } = res
        setSpin({})
        if (data?.success) {
          successNotification(data?.message)
          getAllUserData(user?._id, userActions)
          setLoading(false)
          setIsVisible(false)
          form.resetFields()
        }else{
          errorNotification(data?.message)
          setLoading(false)
        }
      })
      .catch((e) => {
        console.log('e', e)
        setSpin({})
      })

  };

  const onChange = (value) => {
    let sum = 0
    savingInfo?.coupons?.map((v) =>
      value.includes(v?._id) ?
        sum += v?.amount : sum
    )
    setamountToWithdraw(sum)
  }
  return (
    <div className='flex-column menu-main'>
      <div className='button-end button-end-scroll'>
        {!showRequests ? <><Button
          type='primary'
          className='btn-end invite-guest invite-guest2'
          onClick={() => setshowRequests(true)}
        >
          Withdraw Requests
        </Button>
          <AddSavings reRender={() => { getAllUserData(user?._id, userActions) }} {...props} /></> :
          <Button
            type='primary'
            className='btn-end invite-guest invite-guest2'
            onClick={() => setshowRequests(false)}
          >
            Back
          </Button>}
      </div>
      {!showRequests ? <div className='user-table'>
        <Table
          dataSource={savings}
          columns={columns}
          pagination={{ hideOnSinglePage: true }}
          scroll={{ x: 800 }}
        />

        <Modal title="Request for Withdrawal" onCancel={() => setIsVisible(false)} visible={isVisible} footer={null}>
          Minimum Amount You can Withdraw is € {savingInfo?.businessUser?.minimumWithdrawalAmount} <br />
          Maximum Amount You can Withdraw is € {savingInfo?.netAmount} <br />
          Amount to be withdrawn € {amountToWithdraw}
          <br /><br />

          <Form name="control-ref" form={form} layout="vertical" onFinish={onFinish}>

            <Form.Item
              name="coupons"
              label="Select Coupons to Withdraw"
              rules={[
                {
                  required: true,
                  message: 'Please select coupons!',
                },
              ]}
            >
              <Select
                placeholder="select your coupons"
                mode="multiple"
                optionFilterProp="children"
                onChange={onChange}
                // onSearch={onSearch}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >

                {savingInfo?.coupons?.map((v, i) => (
                  !v?.isWithDraw ?
                    <Option key={i} value={v?._id}>Code: {v?.couponCode} Amount:{v?.amount}</Option> : null)
                )}

              </Select>
            </Form.Item>

            {/* <Form.Item> */}
            <Button loading={loading} style={{ width: '100%' }} disabled={!(amountToWithdraw >= savingInfo?.businessUser?.minimumWithdrawalAmount) || !(amountToWithdraw <= savingInfo?.netAmount)} type="primary" htmlType="submit">
              Request
            </Button>
            <br />
            <Button style={{ width: '100%' }} onClick={() => {setIsVisible(false);form.resetFields()}}>
              cancel
            </Button>
          </Form>

          {/* {console.log('savingInfo', savingInfo)} */}
        </Modal>
      </div> :
        <div className='user-table'>
          <WithdrawalRequests {...props} />
        </div>

      }
    </div >
  )
}

export default Savings