import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { Table, Input, Button, Space, Modal, Descriptions } from 'antd'
import { createInvoicePdf, getAllData, dateFormater, convertNumber } from '../../../utils/helpers'
import ViewCard from '../Cards/ViewCard'

const Invoices = (props) => {
    const { user, adminActions } = props
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const [showInvoice, setShowInvoice] = useState(false)
    const [showInvoiceList, setShowInvoiceList] = useState(false)
    const [invoice, setInvoice] = useState({})
    const [userInvoices, setUserInvoice] = useState({})
    const searchInput = useRef(null)
    const usersInvoices = useSelector(state => state?.adminReducer?.usersInvoices || [])

    useEffect(() => {
        getAllData(user?._id, adminActions)
    }, [])

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => {
                            confirm({ closeDropdown: false })
                            setSearchText(selectedKeys[0])
                            setSearchedColumn(dataIndex)
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100)
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const viewInvoice = (fileUrl) => {
        setInvoice({
            designName: 'Invoice',
            fileUrl
        })

        setShowInvoice(true)
    }

    const onCancel = () => {
        setUserInvoice({})

        setShowInvoiceList(false)

        setInvoice({})
        setShowInvoice(false)
    }

    const showInvoices = (e) => {
        setUserInvoice(e)

        setShowInvoiceList(true)
    }

    let columns = [
        {
            title: 'Name',
            dataIndex: 'fullName',
            key: 'fullName',
            sorter: true,
            ...getColumnSearchProps('fullName')
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
            ...getColumnSearchProps('email')
        },
        {
            title: 'Registration Date',
            dataIndex: 'created',
            key: 'created',
            render: (created) => <p>{dateFormater(created)}</p>
        },
        {
            title: 'View Invoice',
            key: 'invoice',
            render: (e) =>
                <a onClick={() => {
                    // console.log(e)
                    showInvoices(e)
                }
                }> View</a >
        }
    ]

    return (
        <div className='flex-column menu-main'>
            <div className='user-table'>
                <Table
                    columns={columns}
                    dataSource={usersInvoices}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div>
            {showInvoice ? <ViewCard card={invoice} onCancel={() => setShowInvoice(false)} showData={showInvoice} /> : <Modal
                title='Invoices'
                visible={showInvoiceList}
                onCancel={onCancel}
                className='modal-view'
                footer={[
                    <Button
                        key='back'
                        onClick={onCancel}
                        className='form-input'
                    >
                        Return
                    </Button>
                ]}
            >
                <div>
                    {
                        userInvoices?.invoiceHistory?.length ?
                            <div>
                                {userInvoices?.invoiceHistory?.map((v, i) => {
                                    // console.log("v", v)
                                    return <div key={i}>
                                        <Descriptions bordered column={1} key={i}>
                                            <Descriptions.Item label={'Subscription Name'}>{v?.subscriptionName}</Descriptions.Item>
                                            {v?.subscriptionAmount && <Descriptions.Item label={'Subscription Amount'}>€ {convertNumber(v?.subscriptionAmount)}</Descriptions.Item>}
                                            {v?.postalCardQuantity && <Descriptions.Item label={'Postal Cards'}>{v?.postalCardQuantity}</Descriptions.Item>}
                                            {v?.postalCardRate && <Descriptions.Item label={'Postal Card Rate'}>€ {convertNumber(v?.postalCardRate)}</Descriptions.Item>}

                                            <Descriptions.Item label={'Subscription Types'}>{v?.subscriptionTypes ? v?.subscriptionTypes?.join(', ') : v?.subscriptionName}</Descriptions.Item>
                                            <Descriptions.Item label={'Total Amount'}>€ {convertNumber(v?.totalAmount)}</Descriptions.Item>
                                            <Descriptions.Item label={'Subscription Date'} >{dateFormater(v?.created)}</Descriptions.Item>
                                            <Descriptions.Item label={'View Invoice'} ><a onClick={() => createInvoicePdf(v, userInvoices, 'view', viewInvoice)}>View</a></Descriptions.Item>
                                            <Descriptions.Item label={'Download Invoice'}><a onClick={() => createInvoicePdf(v, userInvoices, 'download', viewInvoice)}>Download</a></Descriptions.Item>
                                        </Descriptions>
                                        <hr />
                                    </div>
                                })}
                            </div>
                            : <div>
                                <Descriptions bordered column={1} >
                                    <Descriptions.Item >No Invoice Found</Descriptions.Item>
                                </Descriptions>
                                <hr />
                            </div>
                    }

                </div>
            </Modal>}
        </div>
    )
}

export default Invoices