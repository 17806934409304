import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Typography, Menu, Dropdown, Carousel } from 'antd'
import axios from 'axios'
import { QrReader } from 'react-qr-reader'
import React, { useEffect, useState } from 'react'
import { POST } from '../../utils/apis'
import { constraints } from '../../utils/constants'
import { errorMessage, getAllUserData, successMessage } from '../../utils/helpers'
import moment from 'moment'
// import { Carousel } from 'react-responsive-carousel'

const { Title } = Typography
const AcceptGiftCards = (props) => {
    const { user, userActions, getAllSavings } = props
    const [visible, setVisible] = useState(false)
    const [visibleQR, setVisibleQR] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState([])
    const [lottery, setLottery] = useState('')
    const [QRCode, setQRCode] = useState(null)
    const [giftCardDetail, setGiftCardDetail] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false)

    useEffect(() => {
        getAllUserData(user?._id, userActions)
    }, [])
    useEffect(() => {
        if (QRCode && !loading) {
            console.log('QRCode', QRCode)
            handleOk(QRCode)
        }
    }, [QRCode])

    const menu = (
        <Menu>
            <Menu.Item onClick={() => setVisible(true)} key='1'>Claim By Code</Menu.Item>
            <Menu.Item onClick={() => setVisibleQR(true)} key='2'>Claim By QR Code</Menu.Item>
        </Menu>
    )

    const handleOk = (code = null) => {
        setConfirmLoading(true)
        setLoading(true)

        axios.post(POST.VERIFY_GIFT_CARDS, { giftCardCode: lottery || code, userId: user?._id, userType: user?.userType })
            .then((res) => {
                const { data } = res
                // console.log('data===>', data)
                if (data?.success) {
                    setGiftCardDetail(data?.data)
                    handleCancel()
                    setVisible(false)
                    setQRCode(null)
                    setVisibleQR(false)
                    setConfirmLoading(false)
                }
                else {
                    errorMessage(data?.message)
                }
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            })
            .catch((e) => {
                console.log('e ', e)
                setVisible(false)
                setConfirmLoading(false)
                setLoading(false)
                errorMessage()
            })

    }

    console.log('giftCardDetail',giftCardDetail)

    const handleCancel2 = () => {
        setVisible(false)
        setVisibleQR(false)
        setLottery('')

    }
    const handleCancel = () => {
        setVisible(false)
        setVisibleQR(false)
        showModal1()
        setLottery('')
    }

    const showModal1 = () => {
        setIsModalVisible(true)
    }

    const handlelotteryInput = (e) => {

        let value = e?.target?.value?.replace(/ /g, '')
        let event = e?.nativeEvent?.data

        if (event && ((value?.length === 3 || value?.length === 7) || (lottery?.length === 3 || lottery.length === 7))) {
            if (lottery?.length === 3 || lottery.length === 7) {
                value = `${lottery}-${event}`
            }
            else {
                value = `${value}-`
            }
        }

        setLottery(value)
    }

    const sendAcceptRequest = () => {
        setLoading(true)
        let obj = {
            giftCardCode: giftCardDetail?.giftCardCode,
            userId: user?._id,
            _id: giftCardDetail?._id
        }
        axios.post(POST.GIFT_CARDS_ACCEPT_REQUESTS, obj)
            .then((res) => {
                const { data } = res
                console.log('data===>', data)
                if (data?.success) {
                    successMessage(data?.message)
                    setIsModalVisible(false)
                    setGiftCardDetail({})
                }
                else {
                    errorMessage(data?.message)
                }
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage()
            })
    }
    const acceptCode = () => {
        setLoading(true)
        let obj = {
            giftCardCode: giftCardDetail?.giftCardCode,
            userId: user?._id,
            _id: giftCardDetail?._id
        }
        axios.post(POST.CLAIM_BU_GIFT_CARD, obj)
            .then((res) => {
                const { data } = res
                console.log('data===>', data)
                if (data?.success) {
                    successMessage(data?.message)
                    setIsModalVisible(false)
                    setGiftCardDetail({})
                }
                else {
                    errorMessage(data?.message)
                }
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage()
            })
    }

    const handleCancel1 = () => {
        setImage({})
    }

    const scanQr = async qr => {
        let code = qr?.text

        if (code) {
            setQRCode(code)
        }
    }

    return (
        <div>
            <Dropdown overlay={menu} placement='bottomLeft'>
                <Button type='primary' className='btn-end invite-guest invite-guest3'>
                    Accept Gift Cards
                </Button>
            </Dropdown>
            <Modal
                title={<center>
                    <Title level={4}>Gift Card</Title>
                </center>}
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel2}
                footer={[
                    <Button key='back' onClick={handleCancel2} className='form-input'>
                        Cancel
                    </Button>,
                    <Button
                        key='ok'
                        onClick={handleOk}
                        disabled={lottery?.length !== 11}
                        className='form-input'
                        type='primary'
                        loading={loading}
                    >
                        Accept Gift Card
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4>Enter your Gift Card number</h4>
                    <Input
                        className='form-input'
                        autoFocus
                        required
                        maxLength={11}
                        value={lottery}
                        onChange={handlelotteryInput}
                        placeholder='e.g: 456-786-ab6'
                    />
                </div>
            </Modal>

            <Modal
                title={<center>
                    <Title level={4}>Activate QR</Title>
                </center>}
                visible={visibleQR}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel2}
                footer={[
                    <Button key='back' onClick={handleCancel2} className='form-input'>
                        Cancel
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4>Scan your QR</h4>
                    <QrReader
                        delay={1000}
                        onError={e => errorMessage('WebCam Not Found!')}
                        constraints={constraints}
                        // constraints={{facingMode: 'environment'}}
                        onResult={scanQr}
                        style={{ width: '100%' }}
                    />
                </div>
            </Modal>
            <Modal
                title={<center>
                    <Title level={4}>Gift Card Details</Title>
                </center>}
                // visible={true}
                visible={isModalVisible}
                // onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    <>
                        <Button key='back' onClick={() => setIsModalVisible(false)} className='form-input'>
                            Cancel
                        </Button>
                        {giftCardDetail?.userId?._id !== user?._id ?
                            <Button loading={loading} key='backs' onClick={sendAcceptRequest} className='form-input'>
                                Claim Request To Admin?
                            </Button>
                            :
                            <Button loading={loading} key='backs' onClick={acceptCode} className='form-input'>
                                Accept
                            </Button>
                        }
                    </>
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <ul>
                        <li><b>Amount: </b>{giftCardDetail?.amount ? giftCardDetail?.amount : '-------'} </li>
                        <li><b>Created At: </b>{giftCardDetail?.created ? moment(giftCardDetail?.created)?.format('YYYY-MM-DD') : '-------'} </li>
                        <li><b>Code: </b>{giftCardDetail?.giftCardCode ? giftCardDetail?.giftCardCode : '-------'}</li>
                        <li><b>Admin: </b>{giftCardDetail?.giftCardCode ? `${giftCardDetail?.isAdmin}` : '-------'}</li>
                        <li><b>Series Name:</b> {giftCardDetail?.seriesName ? giftCardDetail?.seriesName : '-------'}</li>
                    </ul>
                </div>
            </Modal>
        </div >
    )
}

export default AcceptGiftCards