import React, { useEffect, useState } from 'react'
import { Table, Form, Input, Button, Popconfirm, Spin, Modal } from 'antd'
import { useSelector } from 'react-redux'
import { getAllUserData, convertTitle, successNotification, errorMessage } from '../../utils/helpers'
import { POST } from '../../utils/apis'
import axios from 'axios'


// joined
const PendingTable = (props) => {

    const { user, userActions, history } = props

    const pending = useSelector(state => state?.userReducer?.pending || [])

    const [spin, setSpin] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [gPassword, setgPassword] = useState('')
    const [newData, setnewData] = useState({})
    const [loading, setLoading] = useState(false)


    const showModal = () => {
        setIsModalVisible(true)
    }


    console.log('pending', pending)

    useEffect(() => {
        getAllUserData(user?._id, userActions)
    }, [spin])

    const joinGroup = (e) => {
        setSpin({ [e?._id]: true })
        console.log('e', e)

        let obj = {
            _id: e?._id,
            groupId: e?.groupId,
            userId: e?.userId,
            password: null,
            private: e?.privateGroup
        }
        console.log('obj', obj)
        setnewData(obj)
        e?.privateGroup ? showModal() : postFunction(obj)
    }

    const postFunction = (obj) => {
        console.log('obj', obj)
        setLoading(true)
        axios.post(`${POST.JOIN_GROUP}`, obj)
            .then((res) => {
                const { data } = res
                setSpin({})
                if (data?.success) {
                    setLoading(false)
                    console.log('data', data)
                    successNotification(data?.message)
                    setIsModalVisible(false)
                } else {
                    errorMessage(data?.message)
                    setLoading(false)
                }
            })
            .catch((e) => {
                setSpin({})
                setLoading(false)
                errorMessage(e?.message)
                console.log('error', e)
            })
    }

    let columns = [
        {
            title: 'Group Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            ellipsis: true,
        },
        {
            title: 'Private',
            dataIndex: 'privateGroup',
            key: 'privateGroup',
            sorter: true,
            ellipsis: true,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> {e ? 'TRUE' : 'FALSE'}</p>

        },
        {
            title: 'Postal Code Range',
            dataIndex: 'postalCode',
            key: 'postalCode',
            // sorter: true,
            ellipsis: true,
            // render: (e) => {console.log('e',e)}
            render: (e) => {
                return (<>
                    <p>{e[0]?.postalcodeStart}-{e[0]?.postalcodeEnd}</p>
                    {e[1] ? <> <br />
                        <p>{e[1]?.postalcodeStart}-{e[1]?.postalcodeEnd}</p> </> : null}
                </>)
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            ellipsis: true,
            render: (e) => <span>
                <Popconfirm
                    title={`Want to join ${convertTitle(e?.name)}?`}
                    okText='Yes'
                    cancelText='No'
                    okButtonProps={{
                        type: 'primary',
                        className: 'form-button'
                    }}
                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                    onConfirm={() => joinGroup(e)}
                >
                    <Button style={{ color: 'blue' }}> Join</Button>
                    {spin[e?._id] ? <span className='demo-loading-container'>
                        &nbsp;<Spin />
                    </span> : null}
                </Popconfirm>
            </span>
        }
        // {
        //     title: 'Card Design',
        //     key: 'design',
        //     sorter: true,
        //     ellipsis: true,
        //     render: (e) => <Image src={e?.imageUrl} style={{ marginRight: 10, width: 86, height: 60 }} shape='square' />
        // },
    ]

    const data = pending?.map((v, i) => {
        return {
            _id: v?._id,
            groupId: v?.groupId?._id,
            userId: v?.userId?._id,
            name: v?.groupId?.name,
            privateGroup: v?.privateGroup,
            postalCode: v?.groupId?.postalCode,
        }
    })


    const handleCancel = () => {
        setIsModalVisible(false)
        setSpin({})
    }

    const onFinished = (values) => {
        let obj = {
            _id: newData?._id,
            groupId: newData?.groupId,
            userId: newData?.userId,
            password: values?.password,
            private: newData?.private
        }
        postFunction(obj)
    }
    return (
        <><Table
            columns={columns}
            dataSource={data}
            // loading={loading}
            pagination={{ hideOnSinglePage: true }}
            scroll={{ x: 800 }}
        />
            <Modal title='Password' visible={isModalVisible} footer={null} onCancel={handleCancel}>

                <Form
                    name='basic'
                    onFinish={onFinished}
                    // onFinishFailed={onFinishFailed}
                    autoComplete='off'
                >
                    <Form.Item
                        label='Password'
                        name='password'
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            // offset: 8,
                            // span: 16,
                        }}
                    >
                        <Button loading={loading} type='primary' htmlType='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>

            </Modal>
        </>
    )
}

export default PendingTable