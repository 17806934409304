import { Table, Button, message, Popconfirm, DatePicker } from 'antd'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { errorMessage, getAllUserData, successMessage } from '../../utils/helpers'
import Draws from './Draws'
import { POST } from '../../utils/apis'
import axios from 'axios';
import AddLottery from './AddLottery'
import _ from 'lodash'
import DoubleChances from './DoubleChances'
import Invoice from './Invoice'
import GuestPrizes from './GuestPrizes'

const GuestLottery = (props) => {
    const { user, userActions } = props
    const [loading, setLoading] = useState(false)
    const [showDraw, setShowDraw] = useState(false)
    const [showInvoices, setshowInvoices] = useState(false)
    const [showGuestPrizes, setShowGuestPrizes] = useState(false)
    const [lotterySaving, setLotterySaving] = useState([])
    const [drawData, setDrawData] = useState([])
    const [businessUserID, setbusinessUserID] = useState(null)
    const [guestPrizesData, setGuestPrizesData] = useState([])
    const [dateRange, setDateRange] = useState({
        date1: moment().format('YYYY-MM')
    })

    useEffect(() => {
        getAllSavings()
        showMoreLotteryDetail()
        showGuestPrizesDetail()
    }, [dateRange])


    const getAllSavings = () => {
        let obj = {
            userId: user?._id,
            date1: dateRange?.date1,
        }

        console.log('obj', obj)
        setLoading(true)

        axios.post(`${POST.GET_LOTTERY_SAVINGS}`, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    // console.log('data', data?.data)
                    // successMessage(data?.message)
                    setLotterySaving(data?.data || {})
                    // console.log('data?.data',data?.data)
                }
            }).catch((e) => {
                console.log('e ', e)
                setLoading(false)
            })
    }

    const confirm = (e) => {
        setLoading(true)

        let obj = { guestId: user?._id }
        axios.post(POST?.REMOVE_DOUBLE_CHANCES_SUBSCRIPTION, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    successMessage(data?.message)
                    getAllUserData(user?._id, userActions)
                }
                else {
                    errorMessage(data?.message)
                }
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage()
            })

    };

    const cancel = (e) => {
        message.error('Click on No');
    };

    const showMoreLotteryDetail = (e) => {
        setLoading(true)
        let obj = {
            _id: user?._id,
            buId: e?.businessUser?._id ? e?.businessUser?._id : businessUserID,
        }

        axios.post(POST?.CLAIM_LOTTERY_DETAIL, obj)
            .then((res) => {
                const { data } = res
                setDrawData(data?.data || [])
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
            })
    }

    const showGuestPrizesDetail = (e) => {
        setLoading(true)

        let obj = {
            userId: e?.businessUser?._id ? e?.businessUser?._id : businessUserID,
            date1: dateRange?.date1,
            date2: dateRange?.date1
        }

        axios.post(POST?.GET_PRIZES_FOR_GUEST, obj)
            .then((res) => {
                const { data } = res
                setGuestPrizesData(data?.data || [])
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                console.log(e)
            })
    }

    const columns = [
        {
            title: 'Business Name',
            dataIndex: 'businessName',
            key: 'businessName',
        },
        {
            title: 'Business Address',
            dataIndex: 'country',
            key: 'country'
        },
        ,
        {
            title: 'Total Lotteries',
            dataIndex: 'lotteries',
            key: 'lotteries',
            render: (lotteries) => <p style={{ paddingTop: 20, marginTop: -5 }}>{lotteries?.length}</p>
        },
        {
            title: 'Created Date',
            dataIndex: 'created_at',
            key: 'created_at',
            // onFilter: (value, record) => record.created_at.indexOf(value) === 0,
            // render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e.slice(0, 7)}</p>
        },
        {
            title: 'Action',
            dataIndex: '_id',
            key: '_id',
            render: (e) => <>
                <Button
                    onClick={() => { showMoreLotteryDetail(e); setShowDraw(true) }}
                >
                    Show
                </Button>
                <Button
                    onClick={() => { setbusinessUserID(e?.businessUser?._id); showGuestPrizesDetail(e); setShowGuestPrizes(true) }}
                >
                    Prizes
                </Button>
            </>
        }
    ]
    const dataSource = lotterySaving?.map((info, i) => {
        return {
            _id: info,
            businessName: info?.businessUser?.businessName,
            country: info?.businessUser?.country,
            lotteries: info?.lotteries,
            created_at: moment(info?.created).format('YYYY-MM-DD'),
        }
    })

    const createReportRange = (d) => {
        setDateRange({
            date1: d?.format('YYYY-MM')
        })
    }

    let currentDate = moment().format('YYYY-MM')

    return (
        <div className='flex-column menu-main'>
            {!showDraw && !showGuestPrizes ?
                <div className='flex-mobile' style={{ display: 'flex' }} >

                    <DatePicker
                        defaultValue={moment(currentDate, 'YYYY-MM')}
                        style={{ marginBottom: 10, width: '25%' }}
                        picker={'month'}
                        onChange={createReportRange}
                        allowClear={false}
                    />
                </div>
                : null}
            <div className='button-end button-end-scroll'>
                {!showDraw && !showInvoices && !showGuestPrizes ?
                    <>
                        {!user?.doubleChances ? <DoubleChances reRender={getAllUserData(user?._id, userActions)} {...props} />
                            :
                            <Popconfirm
                                title='Are you sure you want to remove this subscription?'
                                onConfirm={confirm}
                                onCancel={cancel}
                                okText='Yes'
                                cancelText='No'
                            >
                                <Button type='primary' className='btn-end invite-guest invite-guest3'>
                                    Remove Double of Chances
                                </Button>
                            </Popconfirm>
                        }
                        {/* <Button
                            type='primary'
                            className='btn-end invite-guest invite-guest3'
                            onClick={() => setshowInvoices(true)}

                        >
                            Double of Chances Invoices
                        </Button> */}
                        <AddLottery getAllSavings={() => getAllSavings()} aginRender={getAllUserData(user?._id, userActions)} {...props} />
                    </>
                    // : showChances ?

                    :
                    <Button
                        type='primary'
                        className='btn-end invite-guest invite-guest3'
                        onClick={() => { setShowDraw(false); setshowInvoices(false); setShowGuestPrizes(false) }}

                    >
                        Back
                    </Button>}
            </div>
            <div className='user-table'>

                {showDraw && !showGuestPrizes ?
                    <Draws {...props} loading1={loading} drawData={drawData} setShowDraw={() => setShowDraw(false)} />
                    : !showDraw && showGuestPrizes && !showInvoices ?
                        <GuestPrizes {...props} loading={loading} guestPrizesData={guestPrizesData} />
                        : showInvoices ?
                            <Invoice {...props} />
                            :
                            <Table
                                loading={loading}
                                dataSource={dataSource}
                                columns={columns}
                                pagination={{ hideOnSinglePage: true }}
                                scroll={{ x: 800 }}
                            />
                }
            </div>
        </div>
    )
}

export default GuestLottery
