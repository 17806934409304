import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Typography } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { POST } from '../../utils/apis'
import { errorMessage, getAllUserData, successMessage } from '../../utils/helpers'
import { useSelector } from 'react-redux'
const { Title } = Typography

const AddCashback = (props) => {
  const { user, userActions, reRender ,getCashBackForm} = props
  const [visible, setVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState({})
  const [amount, setAmount] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const wallet = useSelector(state => state?.userReducer?.wallet || [])

  useEffect(() => {
    getAllUserData(user?._id, userActions)
  }, [])

  // console.log('wallet',wallet[0]?.amount)
  const showModal = () => {
    setVisible(true)
  }

  const handleOk = () => {
    setConfirmLoading(true)
    let obj = {
      guestId: `${user?._id}`,
      amount: Number(amount),
      serviceCharge: 0.5
    }
    console.log('(amount + 0.5) > wallet[0]?.amount',(amount + 0.5) > wallet[0]?.amount)
    console.log('wallet[0]?.amount',wallet[0]?.amount)
    // console.log('amount',Number(amount))
    setLoading(true)
    if (Number(amount) > wallet[0]?.amount) {
      setLoading(false)
      console.log('abc')
      errorMessage('Insufficient Balacnce in your Wallet')
    } else if ((Number(amount) + 0.5) > wallet[0]?.amount) {
     
      setLoading(false)
      errorMessage(`The maximum amount you can withdraw is € ${wallet[0]?.amount - 0.5}, as 0.5 is Service Charges`)

      
    }else {
      setLoading(true)
      axios.post(POST.CASH_BACK_REQUEST, obj)
        .then((res) => {
          const { data } = res
          setLoading(false)
          if (data.success) {
            getCashBackForm()
            successMessage(data?.message)
            setVisible(false)
            // }
          }
          else {
            console.log('else')
            errorMessage(data?.message)
          }
        })
        .catch((e) => {
          console.log('e ', e)
          setVisible(false)

          setLoading(false)
          errorMessage()
        })
    }


    showModal1()
  }

  const handleCancel = () => {
    setVisible(false)
    showModal1()
  }

  const showModal1 = () => {
    setIsModalVisible(true)
  }

  const handleCancel1 = () => {
    setImage({})
  }

  return (
    <div>
      <Button type='primary' className='btn-end' onClick={showModal} /* onClick={getCashBackForm} */>
        Cashback Request
      </Button>
      <Modal
        title={<center>
          <Title level={4}>Cash Back From</Title>
        </center>}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        closable={false}
        footer={[
          <Button key='back' onClick={handleCancel} className='form-input'>
            Cancel
          </Button>,
          <Button
            key='ok'
            onClick={handleOk}
            // disabled={amount?.length !== 11}
            className='form-input'
            type='primary'
            loading={loading}
          >
            ok
          </Button>
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h4>Enter your Cash Back Amount</h4>
          <Input
            className='form-input'
            autoFocus
            required
            maxLength={11}
            value={amount}
            prefix="€"
            // onChange={handleCouponInput}
            onChange={(e) => setAmount(e.target.value)}
            placeholder='e.g: 145'
          />
          <p>The maximum amount you can withdraw is € {(wallet[0]?.amount - 0.5).toFixed(2)}, as 0.5 is Service Charges</p>
        </div>
      </Modal>


    </div>
  )
}

export default AddCashback