import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Typography, Menu, Dropdown, Carousel } from 'antd'
import axios from 'axios'
import { QrReader } from 'react-qr-reader'
import React, { useEffect, useState } from 'react'
import { POST } from '../../utils/apis'
import { constraints } from '../../utils/constants'
import { errorMessage, getAllUserData, successMessage } from '../../utils/helpers'
// import { Carousel } from 'react-responsive-carousel'

const { Title } = Typography
const contentStyle = {
    height: '160px',
    color: '#fff',
    // lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};
const AddLottery = (props) => {
    const { user, userActions, getAllSavings } = props
    const [visible, setVisible] = useState(false)
    const [visibleQR, setVisibleQR] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState([])
    const [lottery, setLottery] = useState('')
    const [QRCode, setQRCode] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)

    useEffect(() => {
        getAllUserData(user?._id, userActions)
    }, [])
    useEffect(() => {
        if (QRCode && !loading) {
            console.log('QRCode', QRCode)
            handleOk(QRCode)
        }
    }, [QRCode])

    const menu = (
        <Menu>
            <Menu.Item onClick={() => setVisible(true)} key='1'>Claim By Code</Menu.Item>
            <Menu.Item onClick={() => setVisibleQR(true)} key='2'>Claim By QR Code</Menu.Item>
        </Menu>
    )

    const handleOk = (code = null) => {
        setConfirmLoading(true)
        setLoading(true)

        axios.post(POST.CLAIM_LOTTERY, { lotteryCode: lottery || code, userId: user?._id })
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    handleCancel()
                    successMessage(data?.message)
                    setVisible(false)
                    setQRCode(null)
                    setVisibleQR(false)
                    setConfirmLoading(false)
                    getAllUserData(user?._id, userActions)
                    getAllSavings()
                    if (data?.advertisement || data?.groupAdminAdvertisement) {
                        // console.log('data ADV', [data?.advertisement, data?.groupAdminAdvertisement])
                        setImage([data?.advertisement, data?.groupAdminAdvertisement] || [])
                    }

                }
                else {
                    errorMessage(data?.message)
                }
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            })
            .catch((e) => {
                console.log('e ', e)
                setVisible(false)
                setConfirmLoading(false)
                setLoading(false)
                errorMessage()
            })

        showModal1()
    }

    const handleCancel = () => {
        setVisible(false)
        setVisibleQR(false)
        showModal1()
        setLottery('')
    }

    const showModal1 = () => {
        setIsModalVisible(true)
    }

    const handlelotteryInput = (e) => {

        let value = e?.target?.value?.replace(/ /g, '')
        let event = e?.nativeEvent?.data

        if (event && ((value?.length === 3 || value?.length === 7) || (lottery?.length === 3 || lottery.length === 7))) {
            if (lottery?.length === 3 || lottery.length === 7) {
                value = `${lottery}-${event}`
            }
            else {
                value = `${value}-`
            }
        }

        setLottery(value)
    }

    const handleCancel1 = () => {
        setImage({})
    }

    const scanQr = async qr => {
        let code = qr?.text

        if (code) {
            setQRCode(code)
        }
    }

    return (
        <div>
            <Dropdown overlay={menu} placement='bottomLeft'>
                <Button type='primary' className='btn-end'>
                    Claim Lottery
                </Button>
            </Dropdown>
            <Modal
                title={<center>
                    <Title level={4}>Lottery</Title>
                </center>}
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={[
                    <Button key='back' onClick={handleCancel} className='form-input'>
                        Cancel
                    </Button>,
                    <Button
                        key='ok'
                        onClick={handleOk}
                        disabled={lottery?.length !== 11}
                        className='form-input'
                        type='primary'
                        loading={loading}
                    >
                        Claim Lottery
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4>Enter your Lottery number</h4>
                    <Input
                        className='form-input'
                        autoFocus
                        required
                        maxLength={11}
                        value={lottery}
                        onChange={handlelotteryInput}
                        placeholder='e.g: 456-786-ab6'
                    />
                </div>
            </Modal>

            <Modal
                title={<center>
                    <Title level={4}>Activate QR</Title>
                </center>}
                visible={visibleQR}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={[
                    <Button key='back' onClick={handleCancel} className='form-input'>
                        Cancel
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4>Scan your QR</h4>
                    <QrReader
                        delay={1000}
                        onError={e => errorMessage('WebCam Not Found!')}
                        constraints={constraints}
                        onResult={scanQr}
                        style={{ width: '100%' }}
                    />
                </div>
            </Modal>

            <Modal
                bodyStyle={{ padding: '0' }}
                footer={null}
                visible={image[0]?.imageUrl}
                // visible={true}
                onCancel={handleCancel1}
                height={10}
                // width={'fit-content'}
                closable={false}
            >
                <button class='close' onClick={handleCancel1}>
                    <CloseCircleOutlined />
                </button>
                <Carousel
                swipe
                    // speed={2}
                    autoplaySpeed={1000}
                    autoplay
                    arrows
                >
                    {image.length && image.map((v, i) => (
                        <div>
                            <img style={{ width: '100%', height: '50vh' }} src={v?.imageUrl} />
                        </div>
                    ))}
                </Carousel>
                {/* <div>
                    <img style={{ width: '100%', height: '50vh' }} src={image?.imageUrl} alt='advertisement' />
                </div> */}
            </Modal>
        </div >
    )
}

export default AddLottery