import { ALL_GUESTS, SEGMENT_MESSAGES, SUBSCRIPTIONS, POSTAL_CARD, INVOICES, USER_SEGMENTS, ALL_SUBSCRIBE_COUPONS, SAVINGS, LOTTERIES_SAVINGS, FORMS, FORM_REPORTS, GET_COUPONS, GET_NOTIFICATIONS, GET_CARDS, GET_ADVERTISEMENT, WALLET,ALL_SUBSCRIBE_LOTTERY,USER_JOINED_GROUPS,USER_PENDING_GROUPS } from '../types'

const reducer = (state = {}, action) => {
    switch (action.type) {
        case ALL_GUESTS: {
            return { ...state, guests: action.guests }
        }
        case SEGMENT_MESSAGES: {
            return { ...state, segmentMessages: action.segmentMessages }
        }
        case SUBSCRIPTIONS: {
            return { ...state, subscriptions: action.subscriptions }
        }
        case POSTAL_CARD: {
            return { ...state, postalCard: action.postalCard }
        }
        case INVOICES: {
            return { ...state, invoices: action.invoices }
        }
        case USER_SEGMENTS: {
            return { ...state, segments: action.segments }
        }
        case ALL_SUBSCRIBE_COUPONS: {
            return { ...state, couponsDetail: action.couponsDetail }
        }
        case ALL_SUBSCRIBE_LOTTERY: {
            return { ...state, lotteryDetail: action.lotteryDetail }
        }
        case SAVINGS: {
            return { ...state, savings: action.savings }
        }
        case LOTTERIES_SAVINGS: {
            return { ...state, lotterySavings: action.lotteries }
        }
        case FORMS: {
            return { ...state, forms: action.forms }
        }
        case FORM_REPORTS: {
            return { ...state, formReports: action.formReports }
        }
        case GET_COUPONS: {
            return { ...state, coupons: action.coupons }
        }
        case GET_NOTIFICATIONS: {
            return { ...state, notification: action.notification }
        }
        case GET_CARDS: {
            return { ...state, cards: action.cards }
        }
        case GET_ADVERTISEMENT: {
            return { ...state, advertisements: action.advertisements }
        }
        case WALLET: {
            return { ...state, wallet: action.wallet }
        }
        case USER_JOINED_GROUPS: {
            return { ...state, joined: action.joined }
        }
        case USER_PENDING_GROUPS: {
            return { ...state, pending: action.pending }
        }
        default: {
            return state
        }
    }
}

export default reducer