import { Image, Table } from 'antd';
import moment from 'moment-timezone';
import React from 'react';

const GuestPrizes = (props) => {
    const { guestPrizesData, loading } = props

    const columns = [
        {
            title: 'Business Name',
            key: 'userId',
            render: (e) => <p>{e?.userId?.businessName}</p>
        },
        {
            title: 'Public',
            key: 'isPublic',
            sorter: (a, b) => a.isPublic - b.isPublic,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? 'True' : 'False'}</p>
        },
        {
            title: 'Created Date',
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{moment(e).utcOffset(0, true).format('DD-MM-YYYY')}</p>
        },
        {
            title: 'Prize Name',
            key: 'name',
            render: (e) => <p>{e?.name}</p>
        },
        {
            title: 'Prize',
            dataIndex: 'prize',
            key: 'prize',
            // render: (e) => <img src={`${e}`} style={{ marginRight: 10, width: 86, height: 60 }} />
            render: (e) => {
                return <Image src={`${e}`} style={{ marginRight: 10, width: 86, height: 60 }} />
            }
        }
    ]
    // console.log('lotteryInfo', lotteryInfo)
    // const dataSource = drawData.map((info, i) => {
    //     return {
    //         userId: info.userId,
    //         name: info?.name,
    //         isClaimed: info?.isClaimed,
    //         lotteryCode: info?.lotteryCode,
    //         prize: info?.prize,
    //         withDraw: info?.withDraw,
    //         lotteryType: info?.lotteryType === 'physicalLottery	' ? 'Physical Lottery' : 'Online Lottery',
    //         date: moment(info?.date).utcOffset(0, true).format('DD-MM-YYYY'),
    //     }
    // })

    return (
        <Table
            loading={loading}
            dataSource={guestPrizesData}
            columns={columns}
            pagination={{ hideOnSinglePage: true }}
            scroll={{ x: 800 }}
        />
    )
}

export default GuestPrizes