import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllUserData } from '../../../utils/helpers'
import LotteryCodes from './LotteryCodes'
import { GET, POST, ADMIN } from '../../../utils/apis'
import axios from 'axios';
import { Table, Button, DatePicker, Modal, Tooltip, Select } from 'antd'
import _ from 'lodash'
const { RangePicker } = DatePicker


const DoubleChance = (props) => {
    const { user, userActions, dateRange } = props
    const [loading, setLoading] = useState(false)
    const [doubleChance, setdoubleChance] = useState([])
    const [filteredArray4, setfilteredArray4] = useState([])

    useEffect(() => {
        getLotteriesData()
    }, [dateRange])



    const getLotteriesData = (e) => {
        setLoading(true)
        let obj = { date1: dateRange?.date1, date2: dateRange?.date2 }

        axios.post(ADMIN.GET_DOUBLE_OF_CHANCE_GUEST, {})
            .then((res) => {
                const { data } = res
                setdoubleChance(data?.data || [])
                // console.log('Admin', data?.data)
                setLoading(false)
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
            })
    }

    const columns = [
        {
            title: 'Guest Name',
            dataIndex: 'fullName',
            key: 'fullName',
            sorter: (a, b) => a.fullName - b.fullName,

        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            sorter: (a, b) => a.country - b.country,

        },
        {
            title: 'Double of Chances',
            dataIndex: 'doubleChances',
            key: 'doubleChances',
            sorter: (a, b) => a.doubleChances - b.doubleChances,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}> {e ? 'TRUE' : 'FALSE'}</p>
        }
    ]
    return (
        <>
            <div className='user-table'>
                <h1 className='text-center'>Double of Chances</h1>
            </div>
            <Table
                loading={loading}
                dataSource={doubleChance}
                columns={columns}
                pagination={{ hideOnSinglePage: true }}
                scroll={{ x: 800 }}
            />
        </>
    )
}

export default DoubleChance