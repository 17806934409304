import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, Upload } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { POST } from '../../utils/apis'
import { errorMessage, inputPlace, requiredMessage, successNotification, warningMessage } from '../../utils/helpers'

const AddLoyaltyAdvertisement = (props) => {
    const { onCancel, edit, getAllData } = props
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const { user } = props

    const onFinish = (values) => {
        values.file = fileList
        edit?._id && (values._id = edit?._id)
        values.userId = user?._id


        if (!values._id && !values?.file?.length) {
            return warningMessage('Please Upload Image')
        }

        setLoading(true)

        let formData = new FormData()
        if (values?.file?.length) {
            formData.append('file', values?.file[0])
        }
        if (values?._id) {
            formData.append('_id', values?._id)
        }

        formData.append('name', values?.name)
        formData.append('userId', values?.userId)
        if (!edit?._id) {
            axios.post(POST.CREATE_LOYALTY_ADVERTISEMENT, formData)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data.success) {
                        successNotification("Advertisement Create Successfully")
                        onCancel()
                        getAllData()
                    }
                    // errorMessage(data.message)
                })
                .catch((e) => {
                    setLoading(false)
                    errorMessage()
                    console.log('e', e)
                })
        }
        else {
            let editObj = {
                _id : edit?._id,
                userId: user?._id,
                name : values?.name
            }
            axios.post(POST.EDIT_LOYALTY_ADVERTISEMENT, editObj)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data.success) {
                        successNotification(data?.message)
                        onCancel()
                        getAllData()
                    }
                    // errorMessage(data.message)
                })
                .catch((e) => {
                    setLoading(false)
                    errorMessage()
                    console.log('e', e)
                })
        }

    }

    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage('You can only upload Images!')
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'> {edit?._id ? 'Update' : 'Create'} Loyalty Advertisement</p>
                    <Form
                        name='form'
                        onFinish={onFinish}
                        layout={'vertical'}
                    >
                        <Form.Item
                            name='name'
                            label='Name'
                            hasFeedback
                            initialValue={edit?.name}
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Name')
                                }
                            ]}
                        >
                            <Input
                                className='form-input'
                                placeholder={inputPlace('Name')}
                            />
                        </Form.Item>
                        {!edit?._id ? <Form.Item
                            name='file'
                            label='File'
                        >
                            <Upload
                                name='file'
                                multiple={false}
                                beforeUpload={() => false}
                                accept='image/png, image/jpeg'
                                onChange={normFile}
                                fileList={fileList}
                            >
                                <Button icon={<UploadOutlined />}>Click to upload</Button>
                            </Upload>
                            {/* {edit?.fileName ? <span><FileOutlined />&nbsp;{edit?.fileName}</span> : null} */}
                        </Form.Item> : null}
                        <Form.Item>
                            <Button
                                type='primary'
                                className='form-button'
                                block
                                htmlType='submit'
                                loading={loading}
                            >
                                {edit?._id ? 'Update' : 'Create'}
                            </Button>
                            <Button
                                style={{ borderRadius: 4, marginTop: 6 }}
                                block
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}



export default AddLoyaltyAdvertisement