import { Tabs } from 'antd';
import React from 'react';
import JoinedTable from './JoinedTable';
import PendingTable from './PendingTable';

const { TabPane } = Tabs;
// joined
const Group = (props) => {

  return (
    <div className='flex-column menu-main card-container'>
      <div className='card-container' >
        <Tabs type='card'  >
          <TabPane tab='Joined' key='1' >
            <div className='user-table'>
              <JoinedTable {...props}/>
            </div>
          </TabPane>
          <TabPane tab='Pending' key='2'>
            <div className='user-table'>
              <PendingTable {...props}/>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default Group