import { Button, Form, InputNumber, Upload, Select, Checkbox, Input } from 'antd'
import axios from 'axios'
import { useState } from 'react'
import DatePicker from 'react-multi-date-picker'
import { POST } from '../../utils/apis'
import { successMessage, errorMessage, warningMessage, successNotification } from '../../utils/helpers'
import TagsInput from './TagsInput'
import moment from 'moment';
import UploadFile from './UploadFile'
import { UploadOutlined, FileOutlined } from '@ant-design/icons'

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 20,
            offset: 4,
        },
    },
}

const { Option } = Select
const format = 'YYYY-MM'


const UpdateLottery = (props) => {
    const [form] = Form.useForm()
    const { onCancel, user, edit, getLottery } = props

    const [loading, setLoading] = useState(false)
    const [isPublic, setisPublic] = useState(edit?._id ? edit?.isPublic : false)
    let [dates, setDates] = useState([])
    let [ePrize, setePrize] = useState(edit?.prize)
    let [formObj, setFormObj] = useState({})
    let [finalObj, setfinalObj] = useState({})
    const [fileList, setFileList] = useState([])

    let myObj = formObj

    const onSubmit = async () => {
        let promise = []


        for (var i in myObj) {
            console.log('I ==>', i)
            let formdata = new FormData()
            formdata.append('userId', user?._id)
            formdata.append('isPublic', isPublic)
            formdata.append('date', i)
            for (var j in myObj[i]) {
                // console.log('j', j, myObj[i][j])
                if (Array.isArray(myObj[i][j])) {
                    for (var k of myObj[i][j]) {
                        // console.log('j, k', j, k)
                        formdata.append('files', k?.originFileObj)
                    }
                }
                else {
                    console.log('else work')
                    formdata.append(j, myObj[i][j])
                }
            }

            setLoading(true)
            promise.push(await axios.post(`${POST?.SET_LOTTERY_PRIZE}`, formdata, { headers: { 'Content-Type': 'multipart/form-data' } }))
        }
        console.log('PROMISE', promise)
        Promise.all(promise)
            .then((res) => {
                console.log('res', res)
                const { data } = res[0] || res
                if (data?.success) {
                    setLoading(false)
                    onCancel()
                    form.resetFields()
                    successMessage('Successfully Set lottery Prizes')
                    setTimeout(getLottery(), 3000)
                } else {
                    setLoading(false)
                    // errorMessage(data?.message || 'Please Join Group To create Public Lotteries...!')
                    errorMessage(data?.message || 'Please Join Group To create Public Lotteries...!')
                }
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
                errorMessage()
            })


    }

    const onChange = (value) => {
        setePrize(value)
    };
    // UPDATE PRIZE CONTROLLER
    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage('You can only upload Images!')
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    const onUpdatedFinish = (values) => {
        console.log('val', values)
        values.file = fileList
        edit?._id && (values._id = edit?._id)
        values.userId = user?._id

        if (!values._id && !values?.file?.length) {
            return warningMessage('Please Upload Image')
        }
        setLoading(true)

        let formData = new FormData()
        if (values?.file?.length) {
            formData.append('file', values?.file[0])
        }
        if (values?._id) {
            formData.append('_id', values?._id)
        }
        formData.append('name', values?.name)
        formData.append('isPublic', isPublic)
        if(!fileList.length && edit?.prize){
            formData.append('imageUrl', edit?.prize)
        }
        formData.append('userId', user?._id)
        for (const value of formData.values()) {
            console.log("OBJ", value)
        }
        axios.post(POST.UPDATE_LOTTERY_PRIZE, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(data?.message)
                    return setTimeout(() => {
                        onCancel()
                        getLottery()
                    }, 300)
                }
                errorMessage(data.message)
            })
            .catch((e) => {
                setLoading(false)
                errorMessage()
            })
    }
    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2' style={{ minHeight: '500px' }}>
                    <p className='heading head-center'>{edit?._id ? 'Update' : 'Create'} Lottery Prizes {edit?._id ? `of ${edit?.date?.slice(0, 7)}` : null}</p>
                    {!edit?._id ?
                        <Form name="dynamic_form_item" /* {...formItemLayoutWithOutLabel} */>
                            <Form.Item
                                name='date'
                                label='Select Date'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select your Date!',
                                        type: 'array',
                                    },
                                ]}
                            >
                                <DatePicker
                                    style={{ width: '100%', }}
                                    value={dates}
                                    onChange={setDates}
                                    multiple
                                    sort
                                    format={format}
                                    onlyMonthPicker
                                    calendarPosition='bottom-center'
                                    selected={new Date()}
                                    minDate={moment().toDate()}
                                />
                            </Form.Item>

                            <Form.List
                                name="price"
                                label='Add Price'
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {dates.map((field, index) => (
                                            <Form.Item
                                                name="price"
                                                // label='Add Price'
                                                label={`Add Prices of ${field}`}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please set your Price!',
                                                    },
                                                ]}
                                                key={index}
                                            >
                                                <Form.Item
                                                    key={index}
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please fill input field.",
                                                        },
                                                    ]}
                                                // noStyle
                                                >

                                                    <UploadFile
                                                        key={index}
                                                        // setFileList={setFileList}
                                                        // fileList={fileList}
                                                        field={field}
                                                        fields={fields}
                                                        myObj={myObj}
                                                        setFormObj={setFormObj}
                                                        setfinalObj={setfinalObj}
                                                    />
                                                </Form.Item>

                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            <Form.Item name="checkbox-group" label="Public">
                                <Checkbox
                                    defaultChecked={isPublic}
                                    onChange={(e) => setisPublic(e.target.checked)}
                                    style={{
                                        lineHeight: '10px',
                                    }}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    style={{ borderRadius: 4, marginBottom: 10, width: '100%' }}
                                    onClick={onSubmit}
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                >
                                    Submit
                                </Button>
                                <Button
                                    style={{ borderRadius: 4/* , marginLeft: 6 */, width: '100%' }}
                                    block
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                            </Form.Item>
                        </Form>
                        :
                        <Form name="dynamic_form_item" onFinish={onUpdatedFinish} {...formItemLayoutWithOutLabel}>

                            <Form.Item
                                initialValue={edit?.name}
                                name='name'
                                label='Prize Name'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter your Prize Name!',
                                        // type: 'array',
                                    },
                                ]}
                            >
                                <Input type='text' />
                            </Form.Item>
                            <Form.Item
                                name='file'
                                label='File'
                            >
                                <Upload
                                    initialValue={edit?.prize}
                                    name='file'
                                    multiple={false}
                                    beforeUpload={() => false}
                                    accept='image/png, image/jpeg'
                                    onChange={normFile}
                                    fileList={fileList}
                                >
                                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                                </Upload>
                                {edit?.prize && !fileList.length ? <span><img src={`${edit?.prize}`} style={{ marginRight: 10, width: 76, height: 60 }} /></span> : null}
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    style={{ borderRadius: 4, marginBottom: 10, width: '100%' }}
                                    // onClick={() => updateLottery(edit?._id)}
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                >
                                    Submit
                                </Button>
                                <Button
                                    style={{ borderRadius: 4, width: '100%' }}
                                    block
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                            </Form.Item>
                        </Form>
                    }

                </div>
            </div>
        </div >
    )
}

export default UpdateLottery