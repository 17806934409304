import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GET, POST, ADMIN } from '../../../utils/apis'
import axios from 'axios';
import { Table, Button, DatePicker, Modal, Tooltip, Select, Image, Popconfirm } from 'antd'
import _ from 'lodash'
import AddGiftCards from './AddGiftCards'
import { getAllData, convertMessage, createLoyaltyCard, createGiftCard } from '../../../utils/helpers'
import GiftNotifications from './GiftNotifications'
import GiftRequests from './GiftRequests'
import BuGiftCards from './BuGiftCards';
import GiftCardImages from './GiftCardImages';
import AddGiftCardImages from './AddGiftCardImages';
import GiftCardUsers from './GiftCardUsers';

const AdminGiftCards = (props) => {
    const { user, userActions } = props
    const [showCreate, setShowCreate] = useState(false)
    const [loading, setLoading] = useState(false)
    const [cards, setCards] = useState([])
    const [showRequests, setShowRequests] = useState(false)
    const [showNotifications, setShowNotifications] = useState(false)
    const [showBuGiftCards, setShowBuGiftCards] = useState(false)
    const [showGiftCardImages, setShowGiftCardImages] = useState(false)
    const [showGiftCardUsers, setShowGiftCardUsers] = useState(false)
    const [addGiftCardImages, setAddGiftCardImages] = useState(false)

    useEffect(() => {
        getCards()
    }, [])
    const allStatesFalse = () => {
        setShowCreate(false)
        setShowRequests(false)
        setShowNotifications(false)
        setShowBuGiftCards(false)
        setShowGiftCardImages(false)
        setShowGiftCardUsers(false)
    }
    const getCards = () => {
        setLoading(true)
        axios.get(`${ADMIN.GET_ALL_ADMIN_GIFT_CARDS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                setLoading(false)
                let tableData = Object.entries(data?.data)
                tableData = tableData?.map((v) => {
                    return {
                        id: v[0],
                        value: v[1]
                    }
                })
                setCards([...tableData])
            })
            .catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    let columns = [
        {
            title: 'Series Name',
            key: 'id',
            dataIndex: 'id',
            ellipsis: true,
            sorter: (a, b) => a.id.length - b.id.length,

        },
        {
            title: 'Actions',
            key: 'actions',
            render: (e) => <div style={{ paddingTop: 15, marginTop: -5, display: 'flex', flexDirection: 'column' }}>
                <a onClick={() => createGiftCard(e?.value)}>Show Total Pdfs</a>
            </div>
        }
    ]

    return (
        <>
            <div className='flex-column menu-main'>
                {
                    showCreate ?
                        <AddGiftCards getCards={getCards} onCancel={() => setShowCreate(false)} {...props} />
                        :
                        addGiftCardImages ?
                            <AddGiftCardImages /* getCards={getCards} */ onCancel={() => setAddGiftCardImages(false)} {...props} />
                            :
                            <>

                                {!showRequests && !showNotifications && !showBuGiftCards && !showGiftCardImages && !showGiftCardUsers ?
                                    <div className='button-end button-end-scroll'>
                                        <Button
                                            type='primary'
                                            className='btn-end invite-guest invite-guest3'
                                            onClick={() => setShowNotifications(true)}
                                        >
                                            Notifications
                                        </Button>
                                        <Button
                                            type='primary'
                                            className='btn-end invite-guest invite-guest3'
                                            onClick={() => setShowRequests(true)}
                                        >
                                            Requests
                                        </Button>
                                        <Button
                                            type='primary'
                                            className='btn-end invite-guest invite-guest3'
                                            onClick={() => setShowBuGiftCards(true)}
                                        >
                                            BU Gift Cards
                                        </Button>
                                        <Button
                                            type='primary'
                                            className='btn-end invite-guest invite-guest3'
                                            onClick={() => setShowGiftCardUsers(true)}
                                        >
                                            Gift Card Users
                                        </Button>
                                        <Button
                                            type='primary'
                                            className='btn-end invite-guest invite-guest3'
                                            onClick={() => setShowCreate(true)}
                                        >
                                            Create Gift Cards
                                        </Button>
                                        <Button
                                            type='primary'
                                            className='btn-end invite-guest invite-guest3'
                                            onClick={() => setShowGiftCardImages(true)}
                                        >
                                            Gift Card Images
                                        </Button>
                                    </div>
                                    :
                                    <div className='button-end button-end-scroll'>
                                        {showGiftCardImages ?
                                            <Button
                                                type='primary'
                                                className='btn-end invite-guest invite-guest3'
                                                onClick={() => setAddGiftCardImages(true)}
                                            >
                                                Add Gift Card Image
                                            </Button> : null
                                        }

                                        <Button
                                            type='primary'
                                            className='btn-end invite-guest invite-guest3'
                                            onClick={allStatesFalse}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                }
                                <div className='user-table'>

                                    {showNotifications ?
                                        <GiftNotifications {...props} />
                                        : showRequests ?
                                            <GiftRequests {...props} />
                                            : showBuGiftCards ?
                                                <BuGiftCards {...props} />
                                                : showGiftCardImages ?
                                                    <GiftCardImages {...props} />
                                                    : showGiftCardUsers ?
                                                        <GiftCardUsers {...props} />
                                                        :
                                                        <Table
                                                            loading={loading}
                                                            dataSource={cards}
                                                            columns={columns}
                                                            pagination={{ hideOnSinglePage: true }}
                                                            scroll={{ x: 800 }}
                                                        />}
                                </div >
                            </>
                }
            </div >
        </>
    )
}

export default AdminGiftCards