// import * as React from 'react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import { Box, Divider, Typography } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { allPaths } from '../../utils/constants'
import Person from '@mui/icons-material/Person'
import Logout from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import Stack from '@mui/material/Stack'
import { Button } from 'antd'
import { getAllUserData, successNotification } from '../../utils/helpers'
import axios from 'axios'
import { GET } from '../../utils/apis'

const HeaderAppBar = (props) => {
  const { updateWallet } = props
  // console.log('HEADER IS WORKING', updateWallet)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorEl2, setAnchorEl2] = React.useState(null)
  const open = Boolean(anchorEl)
  const open2 = Boolean(anchorEl2)
  const { authActions, history, user, userActions } = props
  const [auth, setAuth] = useState(true)
  const wallet = useSelector(state => state?.userReducer?.wallet || [])
  const [walletAmount, setWalletAmount] = useState([])
  const [isAmount, setIsAmount] = useState(false)

  useEffect(() => {
    getAllUserData(user?._id, userActions)
  }, [])

  useEffect(() => {
    getWalletAmount()
  }, [updateWallet])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const getWalletAmount = () => {
    axios.get(`${GET?.GET_USER_WALLET}/${user?._id}`)
      .then((res) => {
        const { data } = res
        if (data.success) {
          setWalletAmount(data?.data || [])
        } else {
          console.log('else e', data?.message)
        }
      }).catch((e) => {
        setWalletAmount([])
        console.log('e', e)
      })
  }


  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setAnchorEl2(null)
  }

  const profile = () => {
    handleClose()
    history?.push(allPaths.PROFILE_SETTINGS)
  }

  const logout = () => {
    setAnchorEl(null)
    setAnchorEl2(null)
    authActions?.removeUser()
  }

  return (
    <>
      <AppBar position='static'>
        <Toolbar>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='menu'
            sx={{ mr: 2 }}
            onClick={() =>
              props?.generalActions?.setCollapsed(!props?.inlineCollapsed)
            }
          >
            <MenuIcon style={{ fontSize: 30 }} />
          </IconButton>
          <Typography
            variant='h6'
            component='div'
            className='mui-size'
            sx={{ flexGrow: 1 }}
          >
            &nbsp;
          </Typography>
          {auth && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Stack direction="row" spacing={4}>
                <IconButton
                  type='submit'
                  sx={{
                    p: 0,
                    color: 'white',
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'transparent'
                    }
                  }}
                  aria-controls={open ? 'fade-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                // onClick={handleClick2}
                >
                  <Typography
                    sx={{ color: 'text' }}
                    variant='h5'
                    component='div'
                  >
                    {/* {user?.userType === 'guest' &&
                      <b>Wallet Balance is €
                        {wallet[0]?.amount ? wallet[0]?.amount?.toFixed(2) : 0}</b>
                    } */}
                    {user?.userType === 'guest' &&
                      <b>Wallet Balance is €
                        {walletAmount[0]?.amount ? walletAmount[0]?.amount?.toFixed(2) : 0}</b>
                    }
                  </Typography>
                  {/* <AccountBalanceWalletIcon style={{ fontSize: 26, mr: 5 }} /> */}
                  {/* <>Balance is $ 14</> */}
                </IconButton>
                <IconButton
                  type='submit'
                  sx={{
                    p: 0,
                    color: 'white',
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'transparent'
                    }
                  }}
                  aria-controls={open ? 'fade-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <AccountCircle style={{ fontSize: 26 }} />
                </IconButton>
              </Stack>

              <Menu
                id='fade-menu'
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl2}
                open={open2}
                onClose={handleClose}
                TransitionComponent={Fade}
                style={{ marginTop: 8 }}
              >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                  <Typography
                    sx={{ color: 'text.primary' }}
                    variant='h5'
                    component='div'
                  >
                    <b>Balance is $ 14</b>
                  </Typography>
                </Box>
              </Menu>
              <Menu
                id='fade-menu'
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                style={{ marginTop: 8 }}
              >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                  <Typography
                    sx={{ color: 'text.primary' }}
                    variant='h5'
                    component='div'
                  >
                    <b>{user?.fullName}</b>
                  </Typography>
                  <Typography
                    // sx={{ color: 'text.secondary' }}
                    variant='p'
                    component='div'
                  >
                    {user?.email}
                  </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                <MenuItem onClick={profile} className='mui-size'>
                  <Person style={{ fontSize: 22 }} /> &nbsp; Profile
                </MenuItem>
                <MenuItem onClick={logout} className='mui-size'>
                  <Logout style={{ fontSize: 22 }} /> &nbsp; Logout
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </>
  )
}

export default HeaderAppBar