import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, Space, Spin, Table, Tooltip, Modal } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useSelector } from 'react-redux'
import QRCode from 'qrcode.react'
import HTML2Canvas from 'html2canvas'
import moment from 'moment-timezone'
import { POST, GET } from '../../utils/apis'
import { getAllUserData, successNotification } from '../../utils/helpers'
import CouponInfo from './CouponInfo'

const Coupons = (props) => {
  const { user, userActions } = props

  let [back, setBack] = useState(true)
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [visible, setVisible] = useState(false)
  const [download, setDownload] = useState(false)
  const [showCouponInfo, setshowCouponInfo] = useState(false)
  const [couponCode, setCouponCode] = useState('')
  const [couponInformation, setCouponInformation] = useState([])
  const [withdrawalAmount, setWithdrawalAmount] = useState()
  const { Search } = Input
  const [state, setState] = useState({ searchText: '', searchedColumn: '' })
  const coupons = useSelector(state => state?.userReducer?.coupons || [])

  // console.log('user', user?._id)

  useEffect(() => {
    setLoading1(true)
    getAllUserData(user?._id, userActions)

    // if (!coupons?.length) {
    //   setLoading(true)
    // }

    axios.get(`${GET.USER_BY_ID}/${user._id}`)
      .then((result) => {
        const { data } = result
        if (data.success) {
          setWithdrawalAmount(data?.user?.minimumWithdrawalAmount)
          setLoading1(false)
        }
      })
      .catch(() => {
        setLoading1(false)
      })

  }, [])

  useEffect(() => {
    if (download) {
      downloadQR()
    }
  }, [download])


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setState({ searchText: '' })
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({ closeDropdown: false })
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              })
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : '',

    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const getWithdrawalAmount = (e) => {
    setLoading1(true)

    let obj = { userId: user?._id, minimumWithdrawalAmount: +e }
    axios.post(`${POST?.MINIMUM_WITHDRAWAL_AMOUNT}`, obj)
      .then((result) => {
        const { data } = result
        if (data?.success) {
          setLoading1(false)
          successNotification('WithDrawal Amount Update Successfully!')
          axios.get(`${GET.USER_BY_ID}/${user._id}`)
            .then((result) => {
              const { data } = result
              if (data.success) {
                setWithdrawalAmount(data?.user?.minimumWithdrawalAmount)
              }
            })
        }
      }).catch(e => {
        setLoading1(false)
      })
  }

  const fetchAllData = (e) => {
    setLoading(true)
    setBack(!back)
    let valObj = { userId: `${user?._id}`, invoice: e?.invoice, couponType: e?.couponType }
    axios.post(`${POST.VIEW_COUPON_DETAIL}`, valObj)
      .then((res) => {
        const { data } = res
        if (data?.success) {
          setLoading(false)
          setCouponInformation(data?.data || [])
        }
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)

      })
  }
  const columns = [
    {
      title: 'Coupon Type',
      dataIndex: 'couponType',
      key: 'couponType',
      // sorter: true
      sorter: (a, b) => a.couponType - b.couponType
    },
    {
      title: 'No. of Coupons',
      dataIndex: 'numberCoupons',
      key: 'numberCoupons',
      sorter: (a, b) => a.numberCoupons - b.numberCoupons
    },
    {
      title: 'Total Coupons Used',
      dataIndex: 'totalUsed',
      key: 'totalUsed',
      sorter: (a, b) => a.totalUsed - b.totalUsed,
      render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e}</p>
    },
    {
      title: 'Total Coupons Claimed',
      dataIndex: 'totalClaimed',
      key: 'totalClaimed',
      sorter: (a, b) => a.totalClaimed - b.totalClaimed,
      render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e}</p>
    },
    {
      title: 'Total Coupon Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {(e)}</p>
    },
    {
      title: 'Total Claimed Amount',
      dataIndex: 'claimedAmount',
      key: 'claimedAmount',
      sorter: (a, b) => a.claimedAmount - b.claimedAmount,
      render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>
    },
    {
      title: 'Purchased Date',
      dataIndex: 'datePurchase',
      key: 'datePurchase',
    },
    {
      title: 'Action',
      dataIndex: '_id',
      key: '_id',
      render: (e) => <><Button onClick={() => fetchAllData(e)}>Details</Button></>
    }
  ]

  const datacol = coupons.map((v, i) => {
    // console.log('v?.totalAmount',(v?.totalAmount)?.tofixed(1))
    return {
      couponType: v?.couponType == 'physicalCoupon' ? 'Physical' : 'Online',
      numberCoupons: v?.total,
      totalUsed: v?.used,
      totalClaimed: v?.claimed,
      totalAmount: v?.totalAmount ? (v?.totalAmount)?.toFixed(2) : 0,
      claimedAmount: v?.claimedAmount,
      datePurchase: moment(v?.created).utcOffset(0, true).format('DD/MM/YYYY'),
      _id: v
    }
  })

  const downloadQR = async () => {
    let id = download ? 'qrCodeDownload' : 'qrCode'
    const canvas = document.getElementById(id)

    let html2Canvas = await HTML2Canvas(canvas, { allowTaint: true, useCORS: true })

    const pngUrl = html2Canvas.toDataURL()
    let downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = `coupon-code.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)

    setDownload(false)
  }

  const disableModal = () => setVisible(false)

  const showQR = () => {
    return (
      <Modal
        visible={visible}
        title='Coupon Code'
        onCancel={disableModal}
        footer={[
          <Button key='back' onClick={disableModal}>
            Close
          </Button>
        ]}
      >
        <div
          style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}
        >
          <div id='qrCode' style={{ textAlign: 'center' }}>
            <QRCode
              value={couponCode}
              size={250}
              level={'H'}
              includeMargin={true}
            />
            <p>{couponCode}</p>
          </div>
          <a onClick={downloadQR}> Download QR </a>
        </div>
      </Modal>
    )
  }

  const columns2 = [
    {
      title: 'Coupon',
      dataIndex: 'couponsNumber',
      key: 'couponsNumber',
      // sorter: true
    },
    {
      title: 'Coupon QR Code',
      dataIndex: 'couponsNumber',
      key: 'couponQRCode',
      render: (e) => (
        <span>
          <Button type='link' onClick={() => (setCouponCode(e), setVisible(true))}>View</Button>
          <Button type='link' onClick={() => (setCouponCode(e), setDownload(true))}>Download</Button>
        </span>
      )
    },
    {
      title: 'Claim',
      dataIndex: 'isClaimed',
      key: 'isClaimed',
      // sorter: true
    },
    {
      title: 'Used',
      dataIndex: 'isUsed',
      key: 'isUsed',
      // sorter: true
    },
    {
      title: 'Used by',
      dataIndex: 'usedBy',
      key: 'usedBy',
      ...getColumnSearchProps('usedBy'),
      // sorter: true
    },
    {
      title: 'Amount',
      dataIndex: 'couponAmount',
      key: 'couponAmount',
      // sorter: true
      render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>€ {e}</p>
    },
    {
      title: 'Expiry Date',
      dataIndex: 'dateExpiry',
      key: 'dateExpiry',
      // sorter: true
    }
  ]
  const dataSource2 = couponInformation.map((info, i) => {
    return {
      key: info?._id,
      couponsNumber: info?.couponCode,
      isClaimed: info?.isWithDraw === true ? 'true' : 'false',
      isUsed: info?.isUsed === true ? 'true' : 'false',
      usedBy: info?.guest?.email ? info?.guest?.email : '-',
      couponAmount: info?.amount,
      dateExpiry: moment(info?.expiry).utcOffset(0, true).format('DD/MM/YYYY'),
    }
  })

  return (
    <div className='flex-column menu-main'>
      {showQR()}
      <div
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}
      >
        <div
          style={{ textAlign: 'center' }}
          id='qrCodeDownload'
          className='fixed-qr'
        >
          <QRCode
            value={couponCode}
            size={250}
            level={'H'}
            includeMargin={true}
          />
          <p>{couponCode}</p>
        </div>
      </div>
      {!showCouponInfo ?
        <><div className='Buselect'>
          {/* <Tooltip title='Minimum withdrawal amount'> */}
            <Search
              type='number'
              placeholder='Enter Amount'
              enterButton='Set Minimum Withdrawal Amount'
              onSearch={(e) => getWithdrawalAmount(e)}
              value={withdrawalAmount}
              min={0}
              // step={0.1}
              onChange={(e) => setWithdrawalAmount(e.target.value)}
              size='large'
              loading={loading1}
            />
          {/* </Tooltip> */}
        </div>
          <br /></> : null}

      <div className='button-end button-end-scroll'>
        {!showCouponInfo && back ?
          <Button
            type='primary'
            className='btn-end invite-guest invite-guest3'
            onClick={() => setshowCouponInfo(true)}
          >
            Coupon Info
          </Button>
          : showCouponInfo ?
            <Button
              type='primary'
              className='btn-end invite-guest invite-guest3'
              onClick={() => setshowCouponInfo(false)}
            >
              back
            </Button> : null
        }
      </div>
      <div className='user-table'>
        {back === true && !showCouponInfo ?
          <Table
            // loading={coupons.length}
            // loading={true}
            columns={columns}
            dataSource={datacol}
            pagination={{ hideOnSinglePage: true }}
            scroll={{ x: 800 }}
          />
          : showCouponInfo ?
            <CouponInfo {...props} /> :
            <>
              <Button
                type='primary'
                className='btn-end'
                onClick={() => setBack(true)}
              >
                Back
              </Button>
              <br />
              <br />
              <Table
                loading={loading ? <Spin /> : ''}
                columns={columns2}
                dataSource={dataSource2}
                pagination={{ hideOnSinglePage: true }}
                scroll={{ x: 800 }}
              /></>}
      </div>



    </div>
  )
}

export default Coupons