import { ALL_USERS, ALL_SEGMENTS, ALL_CARDS, PROVISION_REPORTS, USERS_INVOICES, MESSAGES_LIST, REPORTS, FINANCIAL_REPORTS, STOCK_REPORTS, ALL_PUBLIC_CARDS, POSTAL_CARDS, ALL_BUSINESS_USERS, ALL_GROUPS, ADMIN_FORM_REPORTS } from '../types'

const addAllUsers = (users) => {
    return {
        type: ALL_USERS,
        users
    }
}

const addAllBusinessUsers = (buUsers) => {
    return {
        type: ALL_BUSINESS_USERS,
        buUsers
    }
}

const addAllSegments = (segments) => {
    return {
        type: ALL_SEGMENTS,
        segments
    }
}

const addAllCards = (cards) => {
    return {
        type: ALL_CARDS,
        cards
    }
}

const addAllGroups = (groups) => {
    return {
        type: ALL_GROUPS,
        groups
    }
}

const addAllPublicCards = (publicCards) => {
    return {
        type: ALL_PUBLIC_CARDS,
        publicCards
    }
}

const addAllUsersInvoices = (usersInvoices) => {
    return {
        type: USERS_INVOICES,
        usersInvoices
    }
}

const addAllMessagesList = (messagesList) => {
    return {
        type: MESSAGES_LIST,
        messagesList
    }
}

const addAllPostalCardsList = (postalCards) => {
    return {
        type: POSTAL_CARDS,
        postalCards
    }
}

const addAllReports = (reports) => {
    return {
        type: REPORTS,
        reports
    }
}

const addAllAdminFormReports = (adminFormReports) => {
    return {
        type: ADMIN_FORM_REPORTS,
        adminFormReports
    }
}

const addAllFinancialReports = (financialReports) => {
    return {
        type: FINANCIAL_REPORTS,
        financialReports
    }
}
const addAllProvisionReport = (provisionReport) => {
    return {
        type: PROVISION_REPORTS,
        provisionReport
    }
}

const addAllStockReports = (stockReports) => {
    return {
        type: STOCK_REPORTS,
        stockReports
    }
}

export {
    addAllUsers,
    addAllSegments,
    addAllCards,
    addAllGroups,
    addAllUsersInvoices,
    addAllMessagesList,
    addAllReports,
    addAllFinancialReports,
    addAllStockReports,
    addAllPublicCards,
    addAllPostalCardsList,
    addAllBusinessUsers,
    addAllAdminFormReports,
    addAllProvisionReport
}