import { Button, Form, Input, Select, Radio } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { ADMIN, POST } from '../../utils/apis'
import { requiredMessage, successMessage } from '../../utils/helpers'

const { Option } = Select

const CreateForm = (props) => {
    const [form] = Form.useForm()
    const { onCancel, user, copyForm, setCopyForm ,openForm} = props
    const [questions, setQuestions] = useState([])
    const [loading, setLoading] = useState(false)
    const [questionType, setQuestionType] = useState('questionnaire')

    useEffect(() => {
        axios.get(`${ADMIN.GET_QUESTION_WITH_TYPE}/?userId=${user?._id}&questionsType=${questionType}`)
            .then((res) => {
                const { data } = res
                setQuestions(data?.data || [])
            })
    }, [])

    const onFinish = (values) => {
        values.userId = `${user?._id}`
        setLoading(true)
        axios.post(`${POST.CREATE_QUESTIONS_FORM}`, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    form.resetFields()
                    openForm()
                    setCopyForm({})
                    successMessage('Form Created Successfully!')
                }
                onCancel()
            })
            .catch((e) => {
                setLoading(false)
            })
    }

    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'>Create Form</p>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        name='Form'
                        layout='vertical'
                        requiredMark={true}>
                        <Form.Item
                            name='formName'
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Form name'),
                                }
                            ]}
                            label='Enter form name'
                            required
                            initialValue={copyForm?.formName}
                        >
                            <Input placeholder='e.g Enter Form Name' />
                        </Form.Item>
                        <Form.Item
                            name='questions'
                            label='Select Questions'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select your Questions!',
                                    type: 'array',
                                },
                            ]}
                            initialValue={_.map(copyForm?.questions, '_id')}
                        >
                            <Select mode='multiple' placeholder='what is your Name?'>
                                {questions.map((v, i) => {
                                    return (
                                        <Option value={v?._id}>{v.question}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item >
                            <Button
                                htmlType='submit'
                                style={{ borderRadius: 4, width: '100%' }}
                                type='primary'
                                loading={loading}
                            >
                                Submit
                            </Button>
                            <Button
                                style={{ borderRadius: 4, marginTop: 6 }}
                                block
                                onClick={() => { onCancel(); setCopyForm({}) }}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div >
    )
}

export default CreateForm