import { Button, Form, Input, Select, Radio, InputNumber } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { ADMIN, POST } from '../../../utils/apis'
import { requiredMessage, successMessage } from '../../../utils/helpers'

const { Option } = Select

const CreateAdminForm = (props) => {
    const [form] = Form.useForm()
    const { onCancel, user, copyForm, setCopyForm, openForm } = props
    const [questions, setQuestions] = useState([])
    const [filteredQuestions, setFilteredQuestions] = useState([])
    const [loading, setLoading] = useState(false)
    const [formType, setFormType] = useState(copyForm?.formType || 'personal')
    const [questionType, setQuestionType] = useState(copyForm?.questionsType || 'questionnaire')
    const [totalPoints, setTotalPoints] = useState(copyForm?.totalPoints || 0)
    const [chapters, setChapter] = useState([])
    const [selectedChapters, setSelectedChapters] = useState([])
    const [paragraph, setParagraph] = useState([])
    const [selectedParagraph, setSelectedParagraph] = useState([])
    const [topics, setTopics] = useState([])
    const [selectedTopics, setSelectedTopics] = useState([])

    useEffect(() => {
        axios.get(`${ADMIN.GET_QUESTION_WITH_TYPE}/?userId=${user?._id}&questionsType=${questionType}`)
            .then((res) => {
                const { data } = res
                setQuestions(data?.data || [])
                setFilteredQuestions(data?.data || [])
                setChapter(_.uniqBy(data?.data?.map((v, i) => v?.chapter?.chapterName)))
                setParagraph(_.uniqBy(data?.data?.map((v, i) => v?.paragraph?.paragraphName)))
                setTopics(_.uniqBy(data?.data?.map((v, i) => v?.topics?.map((x) => x?.topicName)).flat()))
                // setTopics(_.uniqBy(data?.data?.map((v, i) => v?.topics?.map((x)=> x?.topicName))))

            })
    }, [questionType])

    console.log(questions)
    const onQuestionTypeChange = (e) => {
        setQuestionType(e.target.value)
    }
    const onFinish = (values) => {
        values.userId = `${user?._id}`
        values.formType = formType
        values.questionsType = questionType
        values.totalPoints = totalPoints
        // console.log('values', values)
        // return true

        setLoading(true)
        axios.post(`${POST.CREATE_QUESTIONS_FORM}`, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    form.resetFields()
                    openForm()
                    setCopyForm({})
                    openForm()
                    successMessage('Form Created Successfully!')
                }
                onCancel()
            })
            .catch((e) => {
                setLoading(false)
            })
    }

    const onChange = (e) => {
        setFormType(e.target.value)
    }
    const onChange1 = (value) => {
        let filteredQuestion = questions?.filter((v) =>
            value?.includes(v?._id)
        )
        setTotalPoints(_.sumBy(filteredQuestion, 'point'))
    }
    const onChangeChapter = (value) => {
        setSelectedChapters(value)

        if (value) {
            setParagraph(_.uniqBy(questions.filter((v) => v?.chapter?.chapterName === value).map((v) => v?.paragraph.paragraphName)))
        } else {
            setParagraph(_.uniqBy(questions?.map((v, i) => v?.paragraph?.paragraphName)))
        }

    }
    const onChangeParagraph = (value) => {
        setSelectedParagraph(value)
        if (value) {
            setTopics(_.uniqBy(questions.filter((v) => v?.paragraph?.paragraphName === value).map((v, i) => v?.topics?.map((x) => x?.topicName)).flat()))
        } else {
            setTopics(_.uniqBy(questions?.map((v) => v?.topics?.map((x) => x?.topicName)).flat()))
        }
    }
    const onChangeTopic = (value) => {
        setSelectedTopics(value)
    }
    const checkChapter = (v) => {
        if (selectedChapters?.length) {
            return v?.chapter?.chapterName === selectedChapters
        } else {
            return v
        }
    }
    const checkParagraph = (v) => {
        if (selectedParagraph?.length) {
            return v?.paragraph?.paragraphName === selectedParagraph
        } else {
            return v
        }
    }
    const checkTopics = (v) => {
        if (selectedTopics?.length) {
            console.log('v',v)
            return v?.topics?.filter((x) => selectedTopics?.includes(x?.topicName))?.length
        } else {
            return v
        }
    }
    useEffect(() => {
        setFilteredQuestions(questions.filter(checkChapter).filter(checkParagraph).filter(checkTopics))
    }, [selectedChapters, selectedParagraph, selectedTopics])
    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='card-form'>
                <div className='card2'>
                    <p className='heading head-center'>Create Form</p>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        name='Form'
                        layout='vertical'
                        requiredMark={true}>
                        <Form.Item
                            required
                            name='formType'
                            label='Form Type'
                            initialValue={copyForm?.formType}
                        >
                            <Radio.Group onChange={onChange} defaultValue='personal'>
                                <Radio.Button value='personal'>Personal</Radio.Button>
                                <Radio.Button value='anonymous'>Anonymous</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name='formName'
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Form name'),
                                }
                            ]}
                            label='Form Name'
                            required
                            initialValue={copyForm?.formName}
                        >
                            <Input placeholder='e.g Enter Form Name' />
                        </Form.Item>
                        <Form.Item
                            name='questionsType'
                            label='Questions Type'
                            required
                            initialValue={copyForm?.questionsType}
                        >
                            <Radio.Group onChange={onQuestionTypeChange} defaultValue='questionnaire'>
                                <Radio.Button value='questionnaire'>Questionnaire</Radio.Button>
                                <Radio.Button value='test'>Test</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name='chapters'
                            label='Select Chapters'
                        >
                            <Select onChange={onChangeChapter} placeholder='Select Chapter!'>
                                <Option value={null}>{null}</Option>
                                {chapters.map((v, i) => {
                                    return (
                                        <Option key={i} value={v}>{v}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name='paragraphs'
                            label='Select Paragraph'
                        >
                            <Select onChange={onChangeParagraph} placeholder='Select Paragraph!'>
                                <Option value={null}>{null}</Option>

                                {paragraph.map((v, i) => {
                                    return (
                                        <Option key={i} value={v}>{v}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name='topics'
                            label='Select Topics'
                        >
                            <Select mode='multiple' onChange={onChangeTopic} placeholder='Select Topics!'>
                                <Option value={null}>{null}</Option>

                                {topics.map((v, i) => {
                                    return (
                                        <Option key={i} value={v}>{v}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        {/* {formType === 'personal' ?
                            <>
                                <Form.Item
                                    name='email'
                                    rules={[
                                        {
                                            required: true,
                                            message: requiredMessage('Question'),
                                        }
                                    ]}
                                    label='Email'
                                    required
                                    initialValue={copyForm?.email}
                                >
                                    <Input type='email' placeholder='e.g Email' />
                                </Form.Item>
                                <Form.Item
                                    name='name'
                                    rules={[
                                        {
                                            required: true,
                                            message: requiredMessage('Question'),
                                        }
                                    ]}
                                    label='Name'
                                    required
                                    initialValue={copyForm?.name}
                                >
                                    <Input placeholder='e.g Email' />
                                </Form.Item>
                            </>
                            : null} */}
                        <Form.Item
                            name='questions'
                            label='Select Questions'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select your Questions!',
                                    type: 'array',
                                },
                            ]}
                            initialValue={_.map(copyForm?.questions, '_id')}
                        >
                            <Select mode='multiple' onChange={onChange1} placeholder='what is your Name?'>

                                {filteredQuestions.map((v, i) => {
                                    return (
                                        <Option value={v?._id}>{v.question}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name='totalPoints'
                            label='Total points'
                            initialValue={totalPoints || copyForm?.totalPoints}
                        >
                            <div>
                                <Input disabled value={totalPoints} />
                            </div>
                        </Form.Item>
                        <Form.Item
                            name='nTerm'
                            label='N-term'
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('N-term'),
                                }
                            ]}
                            required
                            initialValue={copyForm?.nTerm || 1.00}
                        >
                            <InputNumber
                                style={{ width: '100%' }}
                                defaultValue={1}
                                min={0}
                                step={0.01}
                                precision={2}
                                // onChange={onChange}
                                type='number'
                            />
                        </Form.Item>
                        <Form.Item
                            name='freePoints'
                            label='Free point'
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage('Free point'),
                                }
                            ]}
                            required
                            initialValue={copyForm?.freePoints || 1.00}
                        >
                            <InputNumber
                                style={{ width: '100%' }}
                                defaultValue={1}
                                min={0}
                                step={0.01}
                                precision={2}
                                // onChange={onChange}
                                type='number'
                            />
                        </Form.Item>
                        <Form.Item >
                            <Button
                                htmlType='submit'
                                style={{ borderRadius: 4, width: '100%' }}
                                type='primary'
                                loading={loading}
                            >
                                Submit
                            </Button>
                            <Button
                                style={{ borderRadius: 4, marginTop: 6 }}
                                block
                                onClick={() => { onCancel(); setCopyForm({}) }}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div >
    )
}

export default CreateAdminForm