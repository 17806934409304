import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import momentTz from 'moment-timezone'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import Header from './Header'
import SideMenu from '../SideMenu/SideMenu'
import { GET } from '../../utils/apis'
import apiUrl from '../../Config/api'
import { setCollapsed } from '../../Redux/actions/generalActions'
import { addAllSegments, addAllCards } from '../../Redux/actions/adminActions'
import { addAllGuests, addAllSegmentMessage, addAllSubscriptions, addPostalCardPrice, addAllInvoices, addAllUserSegments, addAllCouponsSubscriptionDetail, addAllSavings, addAllForms, addAllFormReports, addAllCoupons, addNotifictaion, addAllCard, addAllAdvertisement, addAllLotterySavings, addWallet, addAllLotterySubscriptionDetail, addUserPendingGroups, addUserJoinedGroups } from '../../Redux/actions/userActions'
import { allPaths } from '../../utils/constants'
import axios from 'axios'

const MenuLayout = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.authReducer.user)
    const inlineCollapsed = useSelector(state => state.generalReducer.inlineCollapsed)
    const [updateWallet, setUpdateWallet] = useState(false)

    useEffect(() => {
        setUpdateWallet(true)
        window.addEventListener('resize', setWindowWidth)
        setWindowWidth()
    }, [])

    const setWindowWidth = () => {
        window.innerWidth < 500 ? dispatch(setCollapsed(true)) : dispatch(setCollapsed(false))
    }

    const authActions = {
        loginUser: (e) => dispatch(loginUser(e)),
        removeUser: () => dispatch(removeUser())
    }

    const adminActions = {
        addAllSegments: (e) => dispatch(addAllSegments(e)),
        addAllCards: (e) => dispatch(addAllCards(e))
    }

    const userActions = {
        addAllGuests: (e) => dispatch(addAllGuests(e)),
        addAllSegmentMessage: (e) => dispatch(addAllSegmentMessage(e)),
        addAllSubscriptions: (e) => dispatch(addAllSubscriptions(e)),
        addPostalCardPrice: (e) => dispatch(addPostalCardPrice(e)),
        addAllInvoices: (e) => dispatch(addAllInvoices(e)),
        addAllUserSegments: (e) => dispatch(addAllUserSegments(e)),
        addAllCouponsSubscriptionDetail: (e) => dispatch(addAllCouponsSubscriptionDetail(e)),
        addAllLotterySubscriptionDetail: (e) => dispatch(addAllLotterySubscriptionDetail(e)),
        addAllSavings: (e) => dispatch(addAllSavings(e)),
        addAllLotterySavings: (e) => dispatch(addAllLotterySavings(e)),
        addAllForms: (e) => dispatch(addAllForms(e)),
        addAllFormReports: (e) => dispatch(addAllFormReports(e)),
        addAllCoupons: (e) => dispatch(addAllCoupons(e)),
        addUserJoinedGroups: (e) => dispatch(addUserJoinedGroups(e)),
        addUserPendingGroups: (e) => dispatch(addUserPendingGroups(e)),
        addNotifictaion: (e) => dispatch(addNotifictaion(e)),
        addAllCard: (e) => dispatch(addAllCard(e)),
        addAllAdvertisement: (e) => dispatch(addAllAdvertisement(e)),
        addWallet: (e) => dispatch(addWallet(e))
    }

    const generalActions = {
        setCollapsed: (e) => dispatch(setCollapsed(e))
    }

    if (!user) {
        return (
            <Redirect to={allPaths?.LOGIN} />
        )
    }

    return (
        <Route
            {...rest}
            render={props => <AddMenu {...props} component={Component} user={user} authActions={authActions} inlineCollapsed={inlineCollapsed} dispatch={dispatch} generalActions={generalActions} adminActions={adminActions} userActions={userActions} updateWallet={updateWallet} setUpdateWallet={setUpdateWallet} />}
        />
    )
}

const AddMenu = ({ component: Component, ...props }) => {
    const { user, dispatch, history, location } = props

    useEffect(() => {
        if (user) {
            getUser()
            let timeZone = momentTz.tz.zonesForCountry(user?.country || 'NL')
            momentTz.tz.setDefault(timeZone[0] || 'Europe/Amsterdam')
        }

        try {
            axios.get(`${apiUrl}/api/get/test`)
        }
        catch (e) {
        }
    }, [props?.location?.pathname])

    const getUser = () => {
        axios.get(`${GET.USER_BY_ID}/${user._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    dispatch(loginUser(data.user))
                }
                else {
                    history?.replace(allPaths?.LOGIN)
                    dispatch(removeUser())
                }
            })
            .catch((e) => {
                dispatch(removeUser())
            })
    }

    if (!user) {
        return (
            <Redirect to={allPaths?.LOGIN} />
        )
    }

    return (
        <div className='helper-main'>
            <div className='menu-header'>
                <Header
                    {...props}
                />
            </div>
            <div className='menu-flex'>
                <div className='side-menu1'>
                    <SideMenu {...props} />
                </div>
                <div
                    className={`helper-comp ${location?.pathname !== allPaths.HOME && 'background-layout'}`}
                    style={{
                        marginLeft: props?.inlineCollapsed ? 80 : user?.userType === 'business' && user?.subscription?.subscriptionTypes && user?.subscription?.subscriptionTypes?.indexOf('loyalty') !== -1 ? 180 : 164,
                    }}
                >
                    <div style={{ paddingLeft: 20 }}>
                        <Component
                            {...props}
                            getUser={getUser}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenuLayout