import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import moment from 'moment-timezone'
import chunk from 'lodash/chunk'
import { Table, Button, DatePicker, Modal, Tooltip } from 'antd'
import { getAllData, convertMessage, createPostalCard, convertDate } from '../../../utils/helpers'
import { ADMIN } from '../../../utils/apis'
import ViewCard from '../Cards/ViewCard'

const { RangePicker } = DatePicker

const PostalCards = (props) => {
    const { user, adminActions } = props
    const [card, setCard] = useState({})
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({})
    const [guest, setGuest] = useState({})
    const [userData, setUserData] = useState({})
    const [showData, setShowData] = useState(false)
    const [showMessageData, setShowMessageData] = useState(false)
    const postalCards = chunk(useSelector(state => state?.adminReducer?.postalCards || []), 8)

    useEffect(() => {
        getAllData(user?._id, adminActions)
    }, [])

    const showTooltip = (data) => {
        const sendingDate1 = data[0]?.sendingDate1 || data[0]?.sendingDate2 || data[0]?.sendingDate3 || data[0]?.sendingDate4 || data[0]?.sendingDate5
        const sendingDate2 = data?.slice(-1)[0]?.sendingDate1 || data?.slice(-1)[0]?.sendingDate2 || data?.slice(-1)[0]?.sendingDate3 || data?.slice(-1)[0]?.sendingDate4 || data?.slice(-1)[0]?.sendingDate5
        const date1 = convertDate(sendingDate1)
        const date2 = convertDate(sendingDate2)
        const dateRange = `${date1}-${date2}`

        return (
            <Tooltip placement='topLeft' title={dateRange}>
                {dateRange}
            </Tooltip>
        )
    }

    const getData = (obj) => {
        setLoading(true)
        axios.post(`${ADMIN.GET_POSTAL_CARDS}`, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                adminActions?.addAllPostalCardsList(data?.data || [])
            })
    }

    const getLatestData = (d) => {
        let obj = {
            date1: d[0].format('YYYY-MM-DD'),
            date2: d[1].format('YYYY-MM-DD')
        }

        getData(obj)
    }

    const onCancel = () => {
        setCard({})
        setShowData(false)
    }

    let columns = [
        {
            title: 'User Name',
            key: 'Date',
            ellipsis: true,
            sorter: true,
            render: (e) => showTooltip(e)
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (e) => <div style={{ paddingTop: 15, marginTop: -5, display: 'flex', flexDirection: 'column' }}>
                <a onClick={() => createPostalCard(e)}>Show Total Pdfs</a>
            </div>
        }
    ]

    return (
        <div className='flex-column menu-main'>
            <div className='user-table'>
                <h1 className='text-center'>Postal Cards</h1>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20, width: 300 }}>
                <h3>Date Range</h3>
                <RangePicker
                    defaultValue={[moment(), moment().add(7, 'days')]}
                    onChange={getLatestData}
                    allowClear={false}
                />
            </div>
            <div className='user-table'>
                <Table
                    columns={columns}
                    dataSource={postalCards}
                    loading={loading}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div>
            {showData ? <ViewCard card={card} onCancel={onCancel} showData={showData} /> : null}

            <Modal
                title='Dates And Messages'
                visible={showMessageData}
                onCancel={() => setShowMessageData(false)}
                className='modal-view'
                footer={[
                    <Button
                        key='back'
                        onClick={() => setShowMessageData(false)}
                        className='form-input'
                    >
                        Return
                    </Button>
                ]}
            >
                <div>
                    <h1>Message: </h1>
                    <h3>{convertMessage(message, guest, userData)}</h3>
                </div>
            </Modal>
        </div>
    )
}

export default PostalCards