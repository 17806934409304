import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Typography } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { POST, GET } from '../../utils/apis'
import { errorMessage, getAllUserData, successMessage } from '../../utils/helpers'
import { useSelector } from 'react-redux'
const { Title } = Typography

const DoubleChances = (props) => {
    const { user, userActions, reRender } = props
    const [visible, setVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState({})
    const [lottery, setLottery] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false)
    useEffect(() => {
        getAllUserData(user?._id, userActions)
    }, [])

    const showModal = () => {
        setVisible(true)
    }
    
    const handleOk = () => {
        setConfirmLoading(true)
        setLoading(true)

        let obj = { guestId: user?._id, amount: 0.45, doubleChances: true }
        axios.post(POST.DOUBLE_CHANCES_SUBSCRIPTION, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successMessage(data?.message)
                    setVisible(false)
                    setConfirmLoading(false)
                    getAllUserData(user?._id, userActions)
                    return window.location.reload()
                }
                else {
                    setConfirmLoading(false)
                    errorMessage(data?.message)
                }
            })
            .catch((e) => {
                console.log('e ', e)
                setVisible(false)
                setConfirmLoading(false)
                setLoading(false)
                errorMessage()
            })

    }

    const handleCancel = () => {
        setVisible(false)
        reRender()
    }


    return (
        <div>
            <Button type='primary' className='btn-end invite-guest invite-guest3' onClick={showModal}>
                Double your chances
            </Button>
            <Modal
                title={<center>
                    <Title level={4}>Game Of Chances</Title>
                </center>}
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                closable={false}
                footer={[
                    <Button key='back' onClick={handleCancel} className='form-input'>
                        Cancel
                    </Button>,
                    <Button
                        key='ok'
                        onClick={handleOk}
                        // disabled={lottery?.length !== 11}
                        className='form-input'
                        type='primary'
                        loading={loading}
                    >
                        Buy
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <p>This subscription costs <b>€ 0.45</b>, by buying this subscription you can double the chances of winning a Lottery</p>
                </div>
            </Modal>

        </div>
    )
}

export default DoubleChances