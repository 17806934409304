import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import moment from 'moment-timezone'
import { Table, Button, DatePicker, Spin, Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { getAllData, convertTitle, successNotification, createProvisionInvoice } from '../../../utils/helpers'
import { apiUrl } from '../../../utils/constants'
import { ADMIN } from '../../../utils/apis'
import ViewCard from '../Cards/ViewCard'

const { RangePicker } = DatePicker

const ProvisionReport = (props) => {
    const { user, adminActions } = props
    const [spin, setSpin] = useState({})
    const [invoice, setInvoice] = useState({})
    const [showInvoice, setShowInvoice] = useState(false)

    const reports = useSelector(state => state?.adminReducer?.provisionReport || [])
    console.log('reports', reports)
    useEffect(() => {
        getAllData(user?._id, adminActions)
    }, [])
    const viewInvoice = (fileUrl) => {
        setInvoice({
            designName: 'Invoice',
            fileUrl
        })

        setShowInvoice(true)
    }
    const onCancel = () => {
        setInvoice({})
        setShowInvoice(false)
    }

    let columns = [
        {
            title: 'Report Range',
            key: 'creatingDate',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{moment(e?.dateRange.date1).format('YYYY-MM-DD') + " to " + moment(e?.dateRange.date2).format('YYYY-MM-DD')}</p>
        },
        {
            title: 'View Report',
            key: 'invoice',
            render: (e) => <a onClick={() => {
                createProvisionInvoice(e, 'view', viewInvoice)
            }}>View</a>
        },
        {
            title: 'Download Report',
            key: 'download',
            render: (e) => <a onClick={() => {
                createProvisionInvoice(e, 'download', viewInvoice)
            }
            }>Download</a>
        },
        {
            title: 'Created Date',
            dataIndex: 'created',
            key: 'created',
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{moment(e).format('YYYY-MM-DD')}</p>
        }
    ]

    return (
        <div className='flex-column menu-main'>
            <div className='user-table'>
                <Table
                    columns={columns}
                    dataSource={reports}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div>
            {showInvoice ? <ViewCard card={invoice} onCancel={onCancel} showData={showInvoice} /> : null}
        </div>
    )
}

export default ProvisionReport