import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { ADMIN } from '../../../utils/apis'
import axios from 'axios';
import { Table } from 'antd'
import _ from 'lodash'

const Draws = (props) => {
    const { user, dateRange } = props
    const [loading, setLoading] = useState(false)

    const [lotteryCodes, setLotteryCodes] = useState([])
    const [filteredArray, setfilteredArray] = useState([])
    const [filteredArray2, setfilteredArray2] = useState([])
    const [filteredArray3, setfilteredArray3] = useState([])

    useEffect(() => {
        setfilteredArray(_.uniqBy(makArray, 'text'))
        setfilteredArray2(_.uniqBy(makArray2, 'text'))
        setfilteredArray3(_.uniqBy(buemailArray, 'text'))

    }, [lotteryCodes])

    useEffect(() => {
        getLotteriesData()
    }, [dateRange])

    const getLotteriesData = (e) => {
        setLoading(true)
        let obj = {
            _id: user?._id,
            date1: dateRange?.date1, date2: dateRange?.date2
        }
        axios.post(ADMIN.EACH_MONTH_ALL_LOTTETY_CODES, obj)
            .then((res) => {
                const { data } = res
                setLotteryCodes(data?.data || [])
                // console.log('data?.data', data?.data)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
            })
    }

    const columns = [
        {
            title: 'Business Name',
            dataIndex: 'businessName',
            key: 'businessName',
            filters: filteredArray2,
            onFilter: (value, record) => record.businessName.indexOf(value) === 0
        },
        {
            title: 'User Email',
            dataIndex: 'UserEmail',
            key: 'UserEmail',
            filters: filteredArray3,
            onFilter: (value, record) => record.UserEmail.indexOf(value) === 0
        },
        {
            title: 'Lottery Code',
            dataIndex: 'lotteryCode',
            key: 'lotteryCode'
        },
        {
            title: 'Lottery type',
            dataIndex: 'lotteryType',
            key: 'lotteryType',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.lotteryType - b.lotteryType
        },
        {
            title: 'isClaimed',
            dataIndex: 'isClaimed',
            key: 'isClaimed',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.isClaimed - b.isClaimed,
            render: (e) => e ? 'true' : 'false'
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            filters: filteredArray,
            onFilter: (value, record) => record.created.indexOf(value) === 0
        }
    ]

    const dataSource2 = lotteryCodes.map((info, i) => {
        return {
            key: info?._id,
            businessName: info?.userId.businessName,
            UserEmail: info?.userId?.email,
            lotteryCode: info?.lotteryCode,
            lotteryType: info?.lotteryType,
            isClaimed: info?.isClaimed,
            created: moment(info?.created).utcOffset(0, true).format('DD-MM-YYYY'),
        }
    })

    const makArray = lotteryCodes.map((v, i) => {
        return {
            text: moment(v?.created).utcOffset(0, true).format('DD-MM-YYYY'),
            value: moment(v?.created).utcOffset(0, true).format('DD-MM-YYYY')
        }
    })

    const makArray2 = lotteryCodes.map((v, i) => {
        return {
            text: v?.userId.businessName,
            value: v?.userId.businessName
        }
    })
    
    const buemailArray = lotteryCodes.map((v, i) => {
        return {
            text: v?.userId?.email,
            value: v?.userId?.email
        }
    })

    return (
        <>
            <div className='flex-column menu-main'>
                <div className='user-table'>
                    <h1 className='text-center'>All Lottery Codes</h1>
                </div>
                <Table
                    loading={loading}
                    dataSource={dataSource2}
                    columns={columns}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800 }}
                />
            </div>
        </>
    )
}

export default Draws