import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { errorMessage, getAllUserData, successMessage } from '../../../utils/helpers'
import { GET, POST, ADMIN } from '../../../utils/apis'
import axios from 'axios';
import { Table, Button, DatePicker, Modal, Tooltip, Select, Image, Popconfirm } from 'antd'
import _ from 'lodash'
import AddGiftCards from './AddGiftCards'

const GiftRequests = (props) => {
    const { user, userActions } = props
    const [requests, setRequests] = useState([])
    const [loading, setLoading] = useState(false)
    const [detail, setDetail] = useState({})
    const [showDetails, setShowDetails] = useState(false)

    useEffect(() => {
        getRequests()
    }, [])

    const getRequests = () => {
        setLoading(true)
        axios.get(`${ADMIN.GET_ALL_GIFT_CARD_REQUEST}/${user?._id}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setRequests(data?.data)
                console.log('dara',data?.data)
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }

    const handleOk = () => {
        setShowDetails(false)
    }
    
    const acceptRequests = (e) => {
        let obj = { _id: e?._id, adminId: user?._id }
        axios.post(`${ADMIN.ACCEPT_GIFT_CARD_REQUET}`, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                successMessage(data?.message)
                getRequests()
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
            })
    }
    const columns = [
        {
            title: 'Gift Card Code',
            dataIndex: 'giftCardCode',
            key: 'giftCardCode',
        },
        {
            title: 'Requester Email',
            dataIndex: ['requesterId', 'email'],
            key: 'UserEmail',
        },
        {
            title: 'Requester Name',
            dataIndex: ['requesterId', 'fullName'],
            key: 'fullName',
        },
        {
            title: 'Price',
            dataIndex: ['giftCard', 'amount'],
            key: 'amount',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (e) => (
                <>
                    <Button onClick={() => {
                        setDetail(e?.requesterId)
                        setShowDetails(true)
                    }} > View Detail</Button >
                    &nbsp; &nbsp; &nbsp;
                    {
                        e?.isApproved == true ?
                            <Button disabled> Accepted</Button>
                            :
                            <Button onClick={() => acceptRequests(e)}>Accept</Button>
                    }
                </>
            )
        }
    ]

    return (
        <>
            <Table
                loading={loading}
                dataSource={requests}
                columns={columns}
                pagination={{ hideOnSinglePage: true }}
                scroll={{ x: 800 }}
            />
            <Modal title='User Details' visible={showDetails} onOk={handleOk} onCancel={handleOk} footer={null}>
                <ul>
                    <li>fullName: {detail?.fullName ? detail?.fullName : '-------'} </li>
                    <li>email: {detail?.email ? detail?.email : '-------'} </li>
                    <li>bankAccount: {detail?.bankAccount ? detail?.bankAccount : '-------'}</li>
                    <li>IBAN: {detail?.IBAN ? detail?.IBAN : '-------'}</li>
                    <li>Address: {detail?.streetNumber ? detail?.streetNumber : '-------'}</li>
                </ul>
            </Modal>
        </>
    )
}

export default GiftRequests